import { createLabel } from '@Components/fields';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createModalForm } from '@UIkit/components/panels';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';

/**
 * Methods for fns documents
 * @author Vsevolod Liapin
 */
Ext.namespace('edi.methods');
Ext.Object.merge(edi.methods, {
	documents: {
		getDocumentOwnStatusName: function (initData, docType) {
			var stateHTML;
			if (edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD === docType) {
				var utochCreated = edi.utils.getAttributeByName(initData.data.attributes, 'utochCreated') === 'true',
					totalCode = edi.utils.getAttributeByName(initData.data.attributes, 'КодИтога'),
					status = edi.utils.getObjectProperty(initData, 'data.state'),
					docTimePart2 = edi.utils.getAttributeByName(initData.data.attributes, 'docTimePart2'),
					docTimeUtoch = edi.utils.getAttributeByName(initData.data.attributes, 'docTimeUtoch');

				var convertDate = function (date) {
					var splitDate = date.split(' ').join('.').split('.');
					return new Date(
						splitDate[2],
						splitDate[1] - 1,
						splitDate[0],
						splitDate[3],
						splitDate[4],
						splitDate[5]
					).getTime();
				};

				if (!utochCreated && status === edi.constants.STATE.COMPLETED && (totalCode == 3 || totalCode == 2)) {
					stateHTML = edi.renderers.status(edi.constants.STATE.COMPLETED + '_' + totalCode);
				} else if (
					!utochCreated &&
					status === edi.constants.STATE.SIGNED_BY_CONSUMER &&
					(totalCode == 3 || totalCode == 2)
				) {
					stateHTML = edi.renderers.status(edi.constants.STATE.SIGNED_BY_CONSUMER + '_' + totalCode);
				} else if (
					utochCreated &&
					status === edi.constants.STATE.COMPLETED &&
					docTimePart2 &&
					convertDate(docTimeUtoch) < convertDate(docTimePart2) &&
					(totalCode == 3 || totalCode == 2)
				) {
					stateHTML = edi.renderers.status(edi.constants.STATE.COMPLETED + '_' + totalCode);
				} else if (
					utochCreated &&
					status === edi.constants.STATE.SIGNED_BY_CONSUMER &&
					docTimePart2 &&
					convertDate(docTimeUtoch) < convertDate(docTimePart2) &&
					(totalCode == 3 || totalCode == 2)
				) {
					stateHTML = edi.renderers.status(edi.constants.STATE.SIGNED_BY_CONSUMER + '_' + totalCode);
				}
			}
			return stateHTML;
		}
	},
	validators: {
		strLen15: function (value) {
			return String(value).length <= 15;
		}
	},
	/**
	 * методы и структуры, нужные для создания, редактирования и отображения ТОРГ2
	 */
	torg2: {
		/**
		 * используется отдельный метод ставок НДС, потому что бэк-энд ожидает
		 *  ставку именно в таком виде, со знаком %
		 *  (а иначе, конечно же, надо использовать edi.stores.fnsNTaxRates)
		 * @returns {*[]}
		 */
		getTaxRatesList: function () {
			var taxRates = [
				'0%',
				'10%',
				'18%',
				'20%',
				'10/110%',
				'18/118%',
				'20/120',
				'без НДС',
				'НДС исчисляется налоговым агентом'
			];
			var resultList = [];
			taxRates.forEach(function (elem) {
				resultList.push({
					id: elem,
					name: elem
				});
			});
			return resultList;
		},
		getTaxRatePercentByName: function (rateStr) {
			var result = null;
			switch (rateStr) {
				case '0%':
					result = 0;
					break;
				case '10%':
					result = 10;
					break;
				case '18%':
					result = 18;
					break;
				case '20%':
					result = 20;
					break;
				case '10/110%':
					result = 10 / 110;
					break;
				case '18/118%':
					result = 18 / 118;
					break;
				case '20/120':
					result = 20 / 120;
					break;
				case 'без НДС': // для "без НДС" и "НДС исчисляется налоговым агентом" возвращаем null
				case 'НДС исчисляется налоговым агентом':
					break;
				default:
					console.warn('getTaxRatePercentByName: неизвестная ставка НДС - ' + rateStr);
					break;
			}
			return result;
		},
		getCreationCircumstances: function () {
			return [
				{
					id: 1,
					name: '1 - ' + edi.i18n.getMessage('document.torg2.create.doc.option.during.acceptance')
				},
				{
					id: 2,
					name: '2 - ' + edi.i18n.getMessage('document.torg2.create.doc.option.after.acceptance')
				}
			];
		},
		getAdditionalInfoOptions: function () {
			return [
				{
					id: 1,
					name: '1 - ' + edi.i18n.getMessage('document.torg2.additional.info.absent')
				},
				{
					id: 2,
					name:
						'2 - ' +
						edi.i18n.getMessage('document.torg2.additional.info.values.posted.and.accepted.by.buyer')
				},
				{
					id: 3,
					name: '3 - ' + edi.i18n.getMessage('document.torg2.additional.info.accepted.by.buyer')
				},
				{
					id: 4,
					name: '4 - ' + edi.i18n.getMessage('document.torg2.additional.info.more.by.buyer')
				},
				{
					id: 5,
					name: '5 - ' + edi.i18n.getMessage('document.torg2.additional.info.acceptance.results.by.seller')
				},
				{
					id: 6,
					name: '6 - ' + edi.i18n.getMessage('document.torg2.additional.info.more.by.seller')
				},
				{
					id: 7,
					name:
						'7 - ' + edi.i18n.getMessage('document.torg2.additional.info.acceptance.results.by.transporter')
				},
				{
					id: 8,
					name: '8 - ' + edi.i18n.getMessage('document.torg2.additional.info.more.by.transporter')
				}
			];
		}
	},
	/**
	 * Find Bank Name by BIK and fill form value
	 * @param      {Object}      cmp
	 * @param      {String}      newValue
	 */
	fillBankNameByBik: function (cmp, newValue) {
		if (newValue.length === 9) {
			var modal = cmp.modalInstance,
				form = modal ? modal.formPanel : null;
			if (!form) {
				return;
			}
			var input = form.down('textfield[name=bank_name]');
			if (input) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.BIK.GET, {
						bik: newValue
					}),
					'GET',
					undefined,
					function (data) {
						if (data && data.items && data.items.length === 1 && data.items[0].nameRus) {
							input.setValue(data.items[0].nameRus);
						}
					},
					undefined,
					undefined,
					{
						suppressDefaultError: true
					}
				);
			}
		}
	},
	/**
	 * set object property by dot separated path from converting object by method convertValuesFromMultipleFields
	 * @param      {Object}     fromObject  input object
	 * @param      {Object}     toObject    ounput object
	 * @param      {String}     path        property separated path ("prop1.prop2") to value
	 */
	setObjectValuesWithConverts: function (fromObject, toObject, path) {
		edi.utils.setObjectProperty(
			toObject,
			path,
			edi.methods.convertValuesFromMultipleFields(edi.utils.getObjectProperty(fromObject, path))
		);
	},
	/**
	 * Fill UPD form signer fields from user signer settings and cert data
	 * @param      {String}     docType         document type, for which get active certificate
	 * @param      {Object}     documentSigner  document signer data
	 * @param      {Object}     org             organisation data
	 * @param      {Function}   callback
	 * @param      {Object}     direction       document direction
	 * @param      {String}     docVersion      document version
	 * @param      {Boolean}	isCreating		флажок создания документа (false = editing)
	 */
	fillUPDSignerValues: function (docType, documentSigner, org, callback, direction, docVersion, isCreating) {
		let dataCert = {},
			signerSettings = {},
			fieldsFromCert = {};
		direction = direction || edi.constants.DIRECTIONS.OUTBOX;
		const isDocCreating = isCreating || false;
		const isEmptySigner = !Object.values(documentSigner).filter(Boolean).length;
		const fillValues = function () {
			if (isDocCreating) {
				// создание документа - приоритет отдаётся сохранённым полномочиям подписания для пользователя
				if (signerSettings.authorityArea) {
					fieldsFromCert.authorityArea = true;
					documentSigner.authorityArea = signerSettings.authorityArea;
				} else {
					documentSigner.authorityArea =
						documentSigner.authorityArea || (direction === edi.constants.DIRECTIONS.INBOX ? '1' : '6');
				}

				if (signerSettings.state) {
					fieldsFromCert.state = true;
					documentSigner.state = signerSettings.state;
				} else {
					let inn = dataCert?.innle || dataCert?.inn;
					if (inn) {
						if (inn.slice(0, 2) === '00') {
							inn = inn.slice(2, 12);
						}
						if (inn === org.company_inn) {
							documentSigner.state = documentSigner.state || '1';
						} else {
							if (!documentSigner.state) {
								documentSigner.state = '3';
								fieldsFromCert.state = true;
							}
						}
					} else {
						documentSigner.state = documentSigner.state || '1';
					}
				}

				if (signerSettings.baseAuthory) {
					documentSigner.baseAuthory = signerSettings.baseAuthory || documentSigner.baseAuthory;
				} else {
					let isState4 = documentSigner.state === '4' ? '.state.4' : '';
					documentSigner.baseAuthory =
						documentSigner.baseAuthory ||
						edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default' + isState4);
				}

				if (signerSettings.baseOrgAuthory) {
					documentSigner.baseOrgAuthory = signerSettings.baseOrgAuthory || documentSigner.baseOrgAuthory;
				}
			} else {
				// не создание, а редактирование документа

				//Берем сохранённые полномочиям подписания, если их нет в документе
				if (isEmptySigner && !Ext.Object.isEmpty(signerSettings)) {
					documentSigner.authorityArea = signerSettings.authorityArea;
					documentSigner.state = signerSettings.state;
					documentSigner.baseAuthory = signerSettings.baseAuthory;
					documentSigner.baseOrgAuthory = signerSettings.baseOrgAuthory;
				}

				documentSigner.authorityArea ||= direction === edi.constants.DIRECTIONS.INBOX ? '1' : '6';

				let inn = dataCert?.innle || dataCert?.inn;
				if (inn) {
					if (inn.slice(0, 2) === '00') {
						inn = inn.slice(2, 12);
					}
					if (inn === org.company_inn) {
						documentSigner.state ||= '1';
					} else {
						if (!documentSigner.state) {
							documentSigner.state = '3';
							fieldsFromCert.state = true;
						}
					}
				} else {
					documentSigner.state ||= '1';
				}

				let isState4 = documentSigner.state === '4' ? '.state.4' : '';
				documentSigner.baseAuthory ||= edi.i18n.getMessage(
					'documents.fns_upd.signer.baseAuthory.default' + isState4
				);
			}

			if (!documentSigner.legalEntity) {
				documentSigner.legalEntity = {};
			}
			if (!documentSigner.legalEntity.fullName) {
				documentSigner.legalEntity.fullName = {};
			}
			if (dataCert && Object.keys(dataCert).length > 0) {
				//должность и фио - это boolean, они просто показывают что перетерлись данные signer данными из серта
				fieldsFromCert.position = !!(documentSigner.legalEntity.position = dataCert.position || null);
				let innFromCert = dataCert.innle || dataCert.inn;
				if (innFromCert) {
					fieldsFromCert.inn = innFromCert;
				}

				if (dataCert.ogrnip) {
					fieldsFromCert.ogrnip = dataCert.ogrnip;
				}

				fieldsFromCert.firstName = !!(documentSigner.legalEntity.fullName.firstName =
					dataCert.firstname || null);
				fieldsFromCert.middleName = !!(documentSigner.legalEntity.fullName.middleName =
					dataCert.middlename || null);
				fieldsFromCert.lastName = !!(documentSigner.legalEntity.fullName.lastName = dataCert.lastname || null);
			}

			let haveFirstName, haveLastName;

			if (!documentSigner.legalEntity.position) {
				documentSigner.legalEntity.position = edi.i18n.getMessage('signer.job.position');
			}

			if (!documentSigner.legalEntity.fullName.firstName) {
				haveFirstName = false;
				documentSigner.legalEntity.fullName.firstName = edi.i18n.getMessage('first.name');
			} else {
				haveFirstName = documentSigner.legalEntity.fullName.firstName !== edi.i18n.getMessage('first.name');
			}

			if (!documentSigner.legalEntity.fullName.lastName) {
				haveLastName = false;
				documentSigner.legalEntity.fullName.lastName = edi.i18n.getMessage('last.name');
			} else {
				haveLastName = documentSigner.legalEntity.fullName.lastName !== edi.i18n.getMessage('last.name');
			}

			if (!documentSigner.legalEntity.fullName.middleName && !(haveFirstName && haveLastName)) {
				documentSigner.legalEntity.fullName.middleName = edi.i18n.getMessage('patronymic.name');
			}

			callback(fieldsFromCert, signerSettings, docVersion);
		};

		const getActiveCertData = function () {
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.USER.SELF.CERTIFICATE.ACTIVE.GET,
					{
						type: docType
					},
					true
				),
				'GET',
				null,
				function (responseData) {
					if (responseData.success && responseData?.data?.data?.subjectName) {
						const respData = responseData.data.data;
						dataCert = edi.utils.certificateObject(respData.subjectName);
						if (!dataCert.inn && respData.inn) {
							dataCert.inn = respData.inn;
						}
						fillValues();
					}
				},
				function () {
					if (direction === edi.constants.DIRECTIONS.INBOX && edi.constants.CERTIFICATE.ONLY_ACTIVE) {
						edi.core.showError(edi.constants.CERTIFICATE.NO_ACTIVE_ERROR);
					} else {
						fillValues();
					}
				},
				undefined,
				{
					suppressDefaultError: true
				}
			);
		};

		edi.rest.sendRequest(
			edi.utils.compileURL(edi.rest.services.USER_SELF_SIGNER_SETTINGS.GET, {
				docType,
				direction: direction
			}),
			'GET',
			null,
			function (data) {
				if (data.success && Array.isArray(data.items) && data.items.length) {
					for (var i = 0; i < data.items.length; i++) {
						if (data.items[i].direction === direction) {
							signerSettings = data.items[i];
							break;
						}
					}
				}
			},
			undefined,
			getActiveCertData,
			{
				suppressDefaultError: true
			}
		);
	},
	createInfoColumns: function (organizationInfoFields, renderer) {
		var columns = [];
		if (Array.isArray(organizationInfoFields) && organizationInfoFields.length) {
			for (i = 0; i < organizationInfoFields.length; i++) {
				if ('ProductTextInfos' === organizationInfoFields[i].docPart) {
					organizationInfoFields = organizationInfoFields[i];
					break;
				}
			}
			if (
				[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD, edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD].some(
					(it) => it === organizationInfoFields.docType
				)
			) {
				if (
					organizationInfoFields &&
					Array.isArray(organizationInfoFields.fieldInfo) &&
					organizationInfoFields.fieldInfo.length
				) {
					var i, field, column;
					for (i = 0; i < organizationInfoFields.fieldInfo.length; i++) {
						field = organizationInfoFields.fieldInfo[i];
						if (field.allowGridView && field.gridViewName) {
							column = {
								draggable: false,
								text: field.gridViewName,
								infoId: field.infoId,
								dataIndex: 'fieldInfo',
								width: Ext.grid.header.Container.prototype.defaultWidth,
								renderer: renderer
									? renderer
									: function (value, metaData, record) {
											var arrInf = record.get('textInfos');
											if (Array.isArray(arrInf) && arrInf.length) {
												var i, elm;
												for (i = 0; i < arrInf.length; i++) {
													elm = arrInf[i];
													if (elm.identifier && elm.identifier === metaData.column.infoId) {
														return elm.value;
													}
												}
											}
											return '';
									  }
							};
							columns.push(column);
						}
					}
				}
			}
		}
		if (columns.length) {
			return {
				fieldInfo: {
					text: 'column.org.document.data.doc.rules',
					columns: columns
				}
			};
		}
		return null;
	},
	checkMarkingPartners: function () {
		edi.rest.sendRequest(
			edi.rest.services.ORG_MARKING.HAS_PARTNER.GET,
			'GET',
			null,
			function (resp) {
				if (resp && resp.data === false) {
					var modalConfig = {
						title: edi.i18n.getMessage('warn'),
						width: MODAL_SIZE.widthLarge,
						items: [
							createModalForm({
								items: [
									createLabel({
										html: edi.i18n.getMessage('markNotification.modal.text')
									})
								]
							})
						],
						buttonsBefore: [
							createButton({
								cls: BUTTON_CLS.primary,
								text: edi.i18n.getMessage('markNotification.dont.show.again'),
								glyph: edi.constants.ICONS.DONE,
								handler: function () {
									edi.rest.sendRequest(
										edi.rest.services.ORG_MARKING.DISABLE_NOTIFICATION.PUT,
										'PUT',
										null,
										function () {
											modal.close();
										},
										edi.rest.getErrorHandler()
									);
								}
							})
						]
					};
					var modal = createModalPanel(modalConfig);
					modal.show();
				}
			},
			edi.rest.getErrorHandler()
		);
	},
	/**
	 * Checks document's attributes for showing crpt print btn
	 * @param	{Object}	attributes
	 * @returns	{Boolean}	true == allow to show crpt print btn
	 */
	checkAllowToShowCrptPrintBtn(attributes) {
		return !!(
			edi.utils.getAttributeByName(attributes, 'APERAK_CRPT_ID_SELLER') ||
			edi.utils.getAttributeByName(attributes, 'APERAK_CRPT_ID_BUYER') ||
			edi.utils.getAttributeByName(attributes, 'APERAK_POA_CRPT_SELLER') ||
			edi.utils.getAttributeByName(attributes, 'APERAK_POA_CRPT_BUYER') ||
			edi.utils.getAttributeByName(attributes, 'APERAK_CRPT_ID') ||
			edi.utils.getAttributeByName(attributes, 'APERAK_POA_CRPT')
		);
	}
});
