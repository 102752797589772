import { createActionsColumnConfig, createCheckboxSelectionModel, createToolBar } from '@Components/grid';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { createModalRemoteSelect } from '@Core/specialComponents/modals';
import { createWebSocket } from './ws';
import { BADGE_ALIGN, BADGE_PRESETS, PTYPE_BADGE } from '@UIkit/components/badge/Badge';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { createHint } from '@UIkit/components/hint/Hint';
import { createLabel } from '@UIkit/components/fields';

Ext.apply(BUTTON_CLS, {
	notifications_button: 'ui-button-notifications'
});

/**
 * Singleton class for notifications
 * @author Anatoli Deryshev
 */
Ext.define('edi.notifications', {
	singleton: true,
	WS_COUNT_GET_URL: 'client/notification/count',
	REST_COUNT_GET_URL: 'client/notification',
	actionButton: null,
	countUnread: 0,
	/**
	 * Displays notifications modal dialog
	 */
	show() {
		let me = this;
		var modal,
			columns,
			selectedRecords,
			topBar,
			readButton,
			btnReadAll,
			buttonText = edi.i18n.getMessage('document.mark.read'),
			gridReload = function () {
				modal.down('grid').getStore().load();
				selectedRecords = [];
			},
			openDetails = function (data, notificationId) {
				if (data) {
					edi.document.actions.openDetailsModule(data.type, data, undefined, function () {
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.NOTIFICATIONS.READ.PUT, {
								id: notificationId
							}),
							'PUT',
							Ext.encode({}),
							gridReload,
							edi.document.actions.defaultFailureHandler(modal, 'notification.error.setting.read')
						);
					});
				}
			};
		if (edi.permissions.hasPermission('READ_CLIENT_NOTIFICATIONS')) {
			columns = edi.columns.get('client_notifications');
			columns.push(
				createActionsColumnConfig({
					align: 'center',
					items: [
						{
							glyph: edi.constants.ICONS.DETAILS,
							handler: function (grid, rowIndex) {
								var record = grid.getStore().getAt(rowIndex);
								edi.document.actions.getDocumentHeaderData(record.get('objectId'), function (data) {
									data.versionId = record.get('versionId');
									openDetails(data, record.get('id'));
								});
							},
							isActionDisabled: function (view, rowIndex, colIndex, item, record) {
								var result = !edi.document.actions.checkDocumentActionPermission(
									record.get('type'),
									edi.constants.DOCUMENT_ACTIONS.DETAILS
								);
								if (!result) {
									result = edi.constants.STATE.DELETED === record.get('state');
								}
								return result;
							}
						}
					]
				})
			);
			topBar = createToolBar({
				items: [
					(readButton = createButton({
						cls: BUTTON_CLS.light,
						tooltip: buttonText,
						text: buttonText,
						disabled: true,
						glyph: edi.document.actions.getActionIcon('READ'),
						handler: function () {
							var ids = [],
								i;
							for (i = 0; i < selectedRecords.length; i++) {
								ids.push(selectedRecords[i].get('id'));
							}
							edi.rest.sendRequest(
								edi.rest.services.NOTIFICATIONS.READ.RANGE.PUT,
								'PUT',
								Ext.encode(ids),
								gridReload,
								edi.document.actions.defaultFailureHandler(modal, 'notification.error.setting.read')
							);
						}
					}))
				]
			});

			var gridUrl = edi.utils.compileURL(edi.rest.services.NOTIFICATIONS.GET, {
				gridName: 'all'
			});

			let hint;

			const onCmpRender = function (host, hint) {
				host.addEventListener('mouseleave', function () {
					hint.__hintShow = false;

					let timer = setTimeout(() => {
						if (!hint.__hintShow) {
							hint.destroy();
						}
						clearTimeout(timer);
					}, hint.hintHideTimeOut); // EW override after uikit-1.0.6.0-RC11
				});

				hint.on({
					mouseenter: function (e, el) {
						hint.__hintShow = true;
					},
					mouseleave: function (e, el) {
						hint.__hintShow = false;

						let timer = setTimeout(() => {
							if (!hint.__hintShow) {
								hint.destroy();
							}
							clearTimeout(timer);
						}, hint.hintHideTimeOut); // EW override after uikit-1.0.6.0-RC11
					}
				});
			};

			modal = createModalRemoteSelect(gridUrl, undefined, {
				width: MODAL_SIZE.widthLarge,
				model: 'CLIENT_NOTIFICATION',
				createFilterFormItems: [],
				title: 'notifications.modal.title',
				noFilter: true,
				storeConfig: {
					sorters: undefined,
					autoLoad: true,
					listeners: {
						load: function (store) {
							me.countUnread = store.getTotalCount();
							me.setUnreadCount();
							btnReadAll.setDisabled(!me.countUnread);
						}
					}
				},
				gridConfig: {
					columns: columns,
					selModel: createCheckboxSelectionModel({
						listeners: {
							selectionchange: function (model, selected) {
								selectedRecords = selected;
								topBar.suspendLayouts();
								readButton.setTooltip(buttonText + ' (' + selected.length || 0 + ')');
								readButton.setDisabled(!selected.length);
								topBar.resumeLayouts();
							}
						}
					}),
					dockedItems: topBar,
					listeners: {
						cellclick: function (grid, td, cellIndex, record, tr, rowIndex, e, eOpts) {
							const clickedColumn = grid.getColumnManager().getHeaderAtIndex(cellIndex);
							if (clickedColumn.dataIndex === 'actionText') {
								const hint = createHint({
									popupAlign: 'b',
									host: Ext.fly(td),
									destroyHintOnHidden: true,
									isAddDefaultHintListeners: true,
									isAddDefaultHostListeners: true,
									items: [
										createLabel({
											html: record.get('actionText')
										})
									]
								});
								hint.show();
							}
						}
					}
				},
				pagingBarConfig: {
					allowExport: false
				},
				hideSaveButton: true,
				additionalControls: [
					(btnReadAll = createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('notification.btn.read.all'),
						disabled: true,
						glyph: edi.constants.ICONS.READ,
						handler: function () {
							edi.core.confirm(
								undefined,
								edi.utils.formatString(edi.i18n.getMessage('notification.read.question'), {
									total: me.countUnread
								}),
								function () {
									edi.rest.sendRequest(
										edi.rest.services.NOTIFICATIONS.READ.ALL.PUT,
										'PUT',
										Ext.encode({}),
										function () {
											modal.close();
											me.countUnread = 0;
											me.setUnreadCount();
										},
										edi.document.actions.defaultFailureHandler(
											modal,
											'notification.error.setting.read'
										)
									);
								}
							);
						}
					}))
				]
			});
		}
		return modal;
	},
	/**
	 * Loads number of unread notifications form backend
	 */
	getCountNotifications(callback) {
		let me = this;

		if (!edi.permissions.hasPermission('READ_CLIENT_NOTIFICATIONS')) {
			return;
		}

		if ('function' == typeof callback) {
			callback(me.countUnread);
		}

		if (!me._ws) {
			me._ws = createWebSocket({
				url: me.WS_COUNT_GET_URL,
				autoReconnectMaxAttempts: 5,
				onmessage(msg) {
					let msgData = Ext.decode(msg?.data, true);
					if (!msgData || +msgData.status !== 200) {
						edi.core.logMessage(msgData, 'error');
					} else if (msgData && msgData.hasOwnProperty('total')) {
						me.countUnread = msgData.total;
						me.setUnreadCount();
					}
				},
				onStateChange: (socket, state) => {
					if (state === me._ws._states.CONNECTED) {
						me.getInitialCount();
					}
					me.processButtonState(state);
				}
			});
		}
	},
	/**
	 * Fetches rom backend unread messages count via rest
	 * cause of backend did not send correct count while connecting
	 */
	getInitialCount: function () {
		const me = this;
		const success = function (resp) {
			const count = +resp.total;
			if (Number.isFinite(count)) {
				me.countUnread = count;
				me.setUnreadCount();
			}
		};
		const fail = () => {};
		const url = edi.constants.DEFAULT.REST_PREFIX + me.REST_COUNT_GET_URL + '?gridName=all&page=1&start=0&limit=1';
		edi.rest.sendRequest(url, 'GET', null, success, fail, null, { suppressDefaultError: true });
	},
	/**
	 * Returns notifications header action button instance
	 * @returns {Object}
	 */
	getActionButton() {
		let me = this;
		if (!me.actionButton || me.actionButton.isDestroyed) {
			me.createActionButton();
		}
		return me.actionButton;
	},
	/**
	 * Creates notifications header action button
	 */
	createActionButton() {
		let me = this;
		me.actionButton = createButton({
			cls: [BUTTON_CLS.light, BUTTON_CLS.rounded, BUTTON_CLS.notifications_button],
			glyph: edi.constants.ICONS.NOTIFICATIONS,
			hidden: true,
			margin: '0 10 0 0',
			plugins: [
				{
					ptype: PTYPE_BADGE,
					cls: BADGE_PRESETS.buttonCounter,
					fixed: true,
					align: BADGE_ALIGN.trc
				}
			],
			handler: function () {
				if (me._ws.state === me._ws._states.CLOSED) {
					edi.core.confirm('error', 'confirm.ws.notifications.reconnect', function () {
						me._ws.reconnect({ force: true });
					});
				} else if (me._ws.state === me._ws._states.CONNECTED) {
					me.show();
				}
			}
		});
	},
	/**
	 * Updates notification button's glyph based on actual service state
	 */
	processButtonState: function (state) {
		let me = this;
		if (!me.actionButton || me.actionButton.isDestroyed) {
			return;
		}

		if (state === me._ws._states.CLOSED) {
			me.actionButton.removeCls('notification-button__loading');
			me.actionButton.setDisabled(false);
			me.actionButton.setGlyph(edi.constants.ICONS.NOTIFICATIONS_OFF);
			me.actionButton.setTooltip(edi.i18n.getMessage('notification.btn.tooltip.connection.closed'));
		} else if (state === me._ws._states.CONNECTING) {
			me.actionButton.addCls('notification-button__loading');
			me.actionButton.setDisabled(true);
			me.actionButton.setGlyph(edi.constants.ICONS.NOTIFICATIONS);
			me.actionButton.setTooltip(edi.i18n.getMessage('notification.btn.tooltip.reconnecting'));
		} else {
			me.actionButton.removeCls('notification-button__loading');
			me.actionButton.setDisabled(false);
			me.actionButton.setGlyph(edi.constants.ICONS.NOTIFICATIONS);
			me.actionButton.setTooltip(null);
		}
		me.actionButton.setVisible(edi.permissions.hasPermission('READ_CLIENT_NOTIFICATIONS'));
	},
	/**
	 * Updates text on notifications header action button
	 */
	setUnreadCount() {
		let me = this;
		if (me.actionButton) {
			let cnt = +me.countUnread;
			cnt = cnt >= 100 ? '99+' : cnt;
			cnt = cnt > 0 ? cnt : null;
			me.actionButton.badgeCmp.setText(cnt >= 100 ? '99+' : cnt);
		}
	}
});
