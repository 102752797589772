import { getPurposeTypeValue, PURPOSES_TYPES } from '@Fns/modules/documents/EDI_FNS_UPD/methods';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createCheckbox, createCombo, createDateField, createHiddenField, createTextField } from '@Components/fields';
import { createFieldSet, createFieldBlock, createModalForm, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createRowsBlock } from '@Components/createRows/create.rows';
import { createCertificateHandler } from '@App/js/signature/handlers/createCertificateHandler';
import { createPoaHandler } from '@App/js/signature/handlers/createPoaHandler';

Ext.namespace('edi.methods');
Ext.merge(edi.methods, {
	createDocumentPart2ForTorg2: {
		start: function (documentsData, callback) {}
	},
	createDocumentPart2: {
		mappingFields: [
			{
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				mapping: {
					authorityArea: 'signer.authorityArea',
					baseAuthory: 'signer.baseAuthory',
					baseOrgAuthory: 'signer.baseOrgAuthory',
					state: 'signer.state',
					dateReceiptGoods: 'contentFactsEconomicLife.infoGoodsReception.dateReceiptGoods',
					operationContent: 'contentFactsEconomicLife.infoGoodsReception.operationContent',
					resultAcceptance: 'contentFactsEconomicLife.infoGoodsReception.operationContentCode.outcomeCode',
					discrepancyDocName: 'contentFactsEconomicLife.infoGoodsReception.operationContentCode.diffDocName',
					discrepancyDocType: 'contentFactsEconomicLife.infoGoodsReception.operationContentCode.diffDocType',
					discrepancyDocNumber:
						'contentFactsEconomicLife.infoGoodsReception.operationContentCode.diffDocNumber',
					discrepancyDate: 'contentFactsEconomicLife.infoGoodsReception.operationContentCode.diffDocDate',
					discrepancyDocFileId:
						'contentFactsEconomicLife.infoGoodsReception.operationContentCode.diffDocFileId',
					textInfos: 'contentFactsEconomicLife.factEconomicLiveInfo.textInfos'
				},
				objectMapping: {
					signer: 'signer',
					workerReceiptGoods: 'contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg'
				}
			},
			{
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
				mapping: {
					authorityArea: 'signer.authorityArea',
					baseAuthory: 'signer.baseAuthory',
					baseOrgAuthory: 'signer.baseOrgAuthory',
					state: 'signer.state',
					dateReceiptGoods: 'contentFactsEconomicLife.infoGoodsReception.dateReceiptGoods',
					operationContent: 'contentFactsEconomicLife.infoGoodsReception.operationContent'
				},
				objectMapping: {
					signer: 'signer'
				}
			},
			{
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
				mapping: {
					authorityArea: 'signers.authorityArea',
					baseAuthory: 'signers.baseAuthory',
					baseOrgAuthory: 'signers.baseOrgAuthory',
					state: 'signers.state',
					dateReceiptGoods: 'contentFactsEconomicLife.agreementInfo.dateAgreement',
					operationContent: 'contentFactsEconomicLife.agreementInfo.agreementContent'
				},
				objectMapping: {
					signer: 'signers'
				}
			},
			{
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2,
				mapping: {
					authorityArea: 'signer.authorityArea',
					baseAuthory: 'signer.baseAuthory',
					baseOrgAuthory: 'signer.baseOrgAuthory',
					state: 'signer.status',
					dateResultAcceptance: 'document.resultAcceptanceInfo.dateResultAcceptance',
					contentResultAcceptance: 'document.resultAcceptanceInfo.contentResultAcceptance',
					textInfo: 'document.resultAcceptanceInfo.info.textInfo',
					fileId: 'document.resultAcceptanceInfo.info.fileId'
				},
				objectMapping: {
					signer: 'signer'
				},
				convertToRowsEditingArrayNames: ['document.resultAcceptanceInfo.info.textInfo']
			}
		],
		allowToCreateP2: function (docHeader) {
			return edi.action.isAvailable(
				edi.constants.DOCUMENT_ACTIONS.SIGN,
				edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', docHeader))
			);
		},
		needToSignIzvpol: function (docHeader) {
			let bpName = edi.utils.getAttributeByName(docHeader.attributes, 'bpName');
			let isUpd = bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING;
			let isUkd = bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.DIS_ROAMING;
			return (isUpd || isUkd) && docHeader.state === edi.constants.STATE.SENT;
		},
		findIzvpol: function (docs, containerType) {
			docs = docs || [];
			let izvpol = null;
			if (containerType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD) {
				izvpol = docs.find((d) => {
					return (
						d.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_DP_IZVPOL &&
						d.state === edi.constants.STATE.RECEIVER_UPD_RECEIVED_ACK
					);
				});
			} else if (containerType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD) {
				izvpol = docs.find((d) => {
					return (
						d.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_DP_IZVPOL &&
						d.state === edi.constants.STATE.RECEIVER_UKD_RECEIVED_ACK
					);
				});
			}
			return izvpol;
		},
		signIzvpol: function (docHeader, callback, certHandler, poaHandler) {
			if (edi.methods.createDocumentPart2.needToSignIzvpol(docHeader)) {
				let success = function (resp) {
					let izvPol = edi.methods.createDocumentPart2.findIzvpol(resp?.items, docHeader?.type);
					if (izvPol) {
						edi.utils.sign(
							izvPol,
							undefined,
							function (failed, responseData) {
								if (failed) {
									edi.core.showError(edi.utils.formatComplexServerError(responseData), function () {
										callback();
									});
								} else {
									callback();
								}
							},
							undefined,
							undefined,
							undefined,
							certHandler,
							{
								signContentUrlMethod: 'PUT',
								signContentUrl: edi.utils.formatString(
									'AB' === edi.login.getAuthType()
										? edi.rest.services.DOCUMENTS.SIGN_AB.GET
										: edi.rest.services.DOCUMENTS.SIGN.GET,
									{ documentId: izvPol.id }
								)
							},
							poaHandler
						);
					} else {
						callback();
					}
				};

				let url = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.TREE.GET, {
					depth: edi.constants.DEFAULT.TREE_DEPTH,
					documentId: docHeader.id
				});
				edi.rest.sendRequest(url, 'GET', undefined, success, callback);
			} else {
				callback();
			}
		},
		start: function (documentsData, callback) {
			var userData = edi.core.getUserData(),
				createdPart2 = [],
				successDocumentIds = [];
			var signerDates = {},
				nowKey,
				j = 0,
				docTypes = [];
			let selectedCertificate, selectedPoa;
			Object.keys(documentsData).forEach(function (key) {
				docTypes.push(key);
			});

			var startCalcSinersData = function () {
				nowKey = docTypes[j];
				signerDates[nowKey] = {
					type: nowKey,
					data: {}
				};
				var versionId;
				if (Array.isArray(documentsData[nowKey])) {
					var docData = documentsData[nowKey];
					if (docData.length === 1 && docData[0].versionId) {
						versionId = docData[0].versionId;
					}
					if (
						nowKey === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
						nowKey === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
					) {
						Ext.each(docData, function (fnsUPDDoc) {
							versionId = fnsUPDDoc.versionId === '5.01-N' ? fnsUPDDoc.versionId : null;
						});
					}
				}
				edi.methods.fillUPDSignerValues(
					nowKey,
					signerDates[nowKey].data,
					userData.org,
					fillSignerDataObject,
					edi.constants.DIRECTIONS.INBOX,
					versionId,
					true
				);
			};

			var fillSignerDataObject = function (dataFromActiveCert, signerSettingsSaved, versionFormat) {
				signerDates[nowKey].dataFromCert = dataFromActiveCert;
				if (docTypes.length === ++j) {
					var iteration = 0;
					signerDates = Object.values(signerDates);

					var createDocPart = function (signer) {
						if (signerDates.length === iteration && 'function' == typeof callback) {
							callback(createdPart2, successDocumentIds, selectedCertificate, selectedPoa);
						} else {
							let signerType =
								userData?.org?.orgType === edi.constants.ORGANIZATION_TYPES.INDIVIDUAL
									? 'individualPerson'
									: 'legalEntity';
							const { inn, ogrnip } = signer.dataFromCert;
							const signerTypeFIOMap = {
								individualPerson: 'name',
								individualInfo: 'fullName',
								legalEntity: 'fullName'
							};
							if (inn?.length === 12 && inn?.slice(0, 2) !== '00') {
								signerType = 'individualInfo';
							}
							//В сертификате ИП в отличие от ФЛ, есть сведения об основном регистрационном номере ИП (ОГРНИП)
							if (inn?.length === 12 && inn?.slice(0, 2) !== '00' && ogrnip) {
								signerType = 'individualPerson';
							}
							const signerInfo = {
								signerType,
								signerFioKey: signerTypeFIOMap[signerType],
								isNotLegalEntity: ['individualPerson', 'individualInfo'].some(
									(type) => type === signerType
								)
							};
							edi.methods.createDocumentPart2.createModalFillSignersData(
								signer.data,
								signer.dataFromCert,
								signer.type,
								signerInfo,
								function (formValues, modal) {
									edi.methods.createDocumentPart2.createPart2(
										signer.type,
										formValues,
										documentsData[signer.type],
										userData.org,
										signerInfo,
										function (documents, documentIds, certFromIzvPol, poaFromIzvpol) {
											selectedCertificate = certFromIzvPol;
											selectedPoa = poaFromIzvpol;
											modal.close();
											successDocumentIds = successDocumentIds.concat(documentIds);
											createdPart2 = createdPart2.concat(documents);
											createDocPart(signerDates[++iteration]);
										}
									);
								},
								edi.constants.DIRECTIONS.INBOX,
								signerSettingsSaved,
								versionFormat
							);
						}
					};
					createDocPart(signerDates[iteration]);
				} else {
					startCalcSinersData();
				}
			};
			startCalcSinersData();
		},
		createModalFillSignersData: function (
			signerData,
			dataFromActiveCert,
			docType,
			signerInfo,
			callback,
			direction,
			signerSettingsSaved,
			versionFormat
		) {
			let modal, form;
			let orgData = edi.core.getUserData().org;
			const FIELD_PADDING = '0 0 24 0';
			const FIELD_CHECKBOX_PADDING = '27 0 21 0';

			let mapConfig = edi.methods.createDocumentPart2.mappingFields.find(function (item) {
				return item.docType === docType;
			});
			const { isNotLegalEntity } = signerInfo;

			let isUPD501N = '5.01-N' === versionFormat && edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD === docType;
			let isUKD501N = '5.01-N' === versionFormat && edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD === docType;
			let isServiceAkt = edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT === docType;
			let isTorg2 = edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2 === docType;

			let getStoreItems = function (items, ids) {
				return edi.stores.getStoreItemsByIds(items, ids);
			};

			let baseAuthority = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
				layout: {
					type: 'grid',
					area: [[6, 6]]
				},
				items: [
					createTextField({
						maxLength: 255,
						allowBlank: false,
						name: 'baseAuthory',
						value:
							edi.utils.getObjectProperty(signerData, 'baseAuthory') ||
							edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default')
					}),
					createTextField({
						maxLength: 255,
						fieldLabel: edi.i18n.getMessage('document.torg2.foundation.authority.organization'),
						name: 'baseOrgAuthory',
						validator: function (value) {
							if (!value) {
								return true;
							}
							return (
								value === '' ||
								!edi.constants.VALIDATORS.SPACES.test(value) ||
								edi.i18n.getMessage('invalid.value.format')
							);
						},
						value: edi.utils.getObjectProperty(signerData, 'baseOrgAuthory')
					})
				]
			});

			const createWorkerReceiptGoods = function (conf) {
				let confFields = [];
				let confPositionInput = conf.positionInput ? conf.positionInput : {};
				let field = createTextField(
					Ext.applyIf(confPositionInput, {
						fieldLabel: edi.i18n.getMessage('job.title'),
						allowBlank: false,
						name: 'workerReceiptGoods.position'
					})
				);
				confFields.push(field);
				let confLastName = conf.lastName ? conf.lastName : {};
				field = createTextField(
					Ext.applyIf(confLastName, {
						fieldLabel: edi.i18n.getMessage('last.name'),
						allowBlank: false,
						name: 'workerReceiptGoods.fullName.lastName'
					})
				);
				confFields.push(field);
				let confFirstName = conf.firstName ? conf.firstName : {};
				field = createTextField(
					Ext.applyIf(confFirstName, {
						fieldLabel: edi.i18n.getMessage('first.name'),
						allowBlank: false,
						name: 'workerReceiptGoods.fullName.firstName'
					})
				);
				confFields.push(field);
				let confMiddleName = conf.middleName ? conf.middleName : {};
				field = createTextField(
					Ext.applyIf(confMiddleName, {
						fieldLabel: edi.i18n.getMessage('patronymic.name'),
						name: 'workerReceiptGoods.fullName.middleName'
					})
				);
				confFields.push(field);
				return createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					padding: '0 0 12 0',
					title: edi.i18n.getMessage('documents.fns_upd.p2.workerReceiptGoods'),
					layout: {
						type: 'grid',
						area: [6, [4, 4, 4]]
					},
					items: confFields
				});
			};

			const createdDocumentDate = function (conf) {
				return createDateField(
					Ext.applyIf(conf, {
						emptyText: edi.i18n.getMessage('date'),
						allowBlank: false,
						submitFormat: edi.constants.DATE_FORMAT.FNS,
						value: edi.utils.formatDate(new Date().getTime(), edi.constants.DATE_FORMAT.FNS),
						name: 'dateResultAcceptance'
					})
				);
			};

			const createAuthority = function (conf) {
				let confOverlay = conf.overlay ? conf.overlay : {};
				let confInput = conf.input ? conf.input : {};
				return createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: confOverlay?.title ?? '',
					items: [
						createTextField(
							Ext.applyIf(confInput, {
								maxLength: 120,
								allowBlank: false,
								name: 'workerReceiptGoods.authority'
							})
						)
					]
				});
			};

			const createSigner = function (
				isIP,
				conf,
				dataFromActiveCert,
				orgData,
				signerData,
				versionFormat,
				docType
			) {
				let confPositionInput = conf.positionInput ? conf.positionInput : {};
				let position = !isNotLegalEntity
					? createTextField(
							Ext.applyIf(confPositionInput, {
								fieldLabel: edi.i18n.getMessage('job.title'),
								maxLength:
									'5.01-N' === versionFormat && edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD === docType
										? 128
										: 10000,
								name: 'position',
								readOnly: true,
								qtipText: dataFromActiveCert.position
									? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
									: !edi.constants.CERTIFICATE.ONLY_ACTIVE
									? edi.i18n.getMessage('document.signer.get.data.from.cert')
									: undefined,
								value: edi.utils.getObjectProperty(signerData, 'legalEntity.position')
							})
					  )
					: null;
				let confLastName = conf.lastName ? conf.lastName : {};
				let lastName = createTextField(
					Ext.applyIf(confLastName, {
						fieldLabel: edi.i18n.getMessage('last.name'),
						readOnly: true,
						qtipText: dataFromActiveCert.lastName
							? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
							: !edi.constants.CERTIFICATE.ONLY_ACTIVE
							? edi.i18n.getMessage('document.signer.get.data.from.cert')
							: undefined,
						value: edi.utils.getObjectProperty(signerData, 'legalEntity.fullName.lastName'),
						name: 'fullName.lastName'
					})
				);
				let confFirstName = conf.firstName ? conf.firstName : {};
				let firstName = createTextField(
					Ext.applyIf(confFirstName, {
						fieldLabel: edi.i18n.getMessage('first.name'),
						readOnly: true,
						qtipText: dataFromActiveCert.firstName
							? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
							: !edi.constants.CERTIFICATE.ONLY_ACTIVE
							? edi.i18n.getMessage('document.signer.get.data.from.cert')
							: undefined,
						value: edi.utils.getObjectProperty(signerData, 'legalEntity.fullName.firstName'),
						name: 'fullName.firstName'
					})
				);
				let confMiddleName = conf.middleName ? conf.middleName : {};
				let middleName = createTextField(
					Ext.applyIf(confMiddleName, {
						fieldLabel: edi.i18n.getMessage('patronymic.name'),
						readOnly: true,
						qtipText: dataFromActiveCert.middleName
							? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
							: !edi.constants.CERTIFICATE.ONLY_ACTIVE
							? edi.i18n.getMessage('document.signer.get.data.from.cert')
							: undefined,
						value: edi.utils.getObjectProperty(signerData, 'legalEntity.fullName.middleName'),
						name: 'fullName.middleName'
					})
				);
				return createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					padding: FIELD_PADDING,
					title: conf.label
						? edi.i18n.getMessage(conf.label)
						: edi.i18n.getMessage('documents.fns_upd.p2.signer'),
					layout: {
						type: 'grid',
						area: [6, 6, [4, 4, 4]]
					},
					items: [
						createHiddenField({
							value: dataFromActiveCert.inn
								? dataFromActiveCert.inn.slice(0, 2) === '00'
									? dataFromActiveCert.inn.slice(2, 12)
									: dataFromActiveCert.inn
								: orgData.inn,
							name: 'tin'
						}),
						position,
						lastName,
						firstName,
						middleName
					]
				});
			};

			const createAuthorityArea = function (conf) {
				let authorityArea = null;
				let confFields = [];
				if (conf.authorityAreaComboConfig) {
					confFields.push(
						createCombo(
							Ext.applyIf(conf.authorityAreaComboConfig, {
								name: 'authorityArea',
								store: edi.stores.createMemoryStore(
									getStoreItems(edi.stores.data.authorityAreas, [1, 2, 3]),
									'AUTHORITY_AREA'
								),
								displayField: 'name',
								allowBlank: false,
								forceSelection: true
							})
						)
					);
				}
				if (conf.statusComboConfig) {
					confFields.push(
						createCombo(
							Ext.applyIf(conf.statusComboConfig, {
								name: 'state',
								store: edi.stores.initSignerInboxStore(),
								displayField: 'name',
								fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.status'),
								qtipText: !dataFromActiveCert.state
									? edi.i18n.getMessage('document.signer.notice.fill.authority.area.in.profile')
									: undefined,
								autoValue: edi.utils.getObjectProperty(signerData, 'state'),
								editable: false,
								allowBlank: false,
								forceSelection: true,
								listeners: {
									change: onChangeStatus
								}
							})
						)
					);
				}
				if (confFields.length > 0) {
					authorityArea = createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						padding: '0 0 4 0',
						title: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
						layout: {
							type: 'grid',
							area: [[6, 6]]
						},
						items: confFields
					});
				}
				return authorityArea;
			};

			const getIsIP = function (orgData) {
				return orgData.orgType === edi.constants.ORGANIZATION_TYPES.INDIVIDUAL;
			};

			const createSignerFormTorg2 = function (conf) {
				var authorityArea = createAuthorityArea(conf);

				var createInfoField = function () {
					var identifier, value;
					identifier = createTextField({
						maxLength: 50,
						columnWidth: 0.5,
						name: 'textInfo.identifier',
						emptyText: edi.i18n.getMessage('document.torg2.identifier'),
						listeners: {
							change: function (comp) {
								var val = comp.getValue();
								value.allowBlank = !val;
								value.isValid();
							}
						}
					});
					value = createTextField({
						maxLength: 2000,
						columnWidth: 0.5,
						margin: '0 0 0 5',
						name: 'textInfo.value',
						emptyText: edi.i18n.getMessage('document.torg2.value'),
						listeners: {
							change: function (comp) {
								var val = comp.getValue();
								identifier.allowBlank = !val;
								identifier.isValid();
							}
						}
					});
					return createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						padding: FIELD_PADDING,
						layout: {
							type: 'grid',
							area: [[6, 6]]
						},
						items: [identifier, value]
					});
				};

				const additionInfo = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					padding: FIELD_PADDING,
					title: edi.i18n.getMessage('document.torg2.additional.information.head'),
					layout: {
						type: 'grid',
						area: [6]
					},
					items: [
						createTextField({
							columnWidth: 0.8,
							regex: /^[\w-_]{36}$/,
							regexText: edi.i18n.getMessage('document.torg2.id.file.tooltip'),
							emptyText: edi.i18n.getMessage('document.torg2.inspection.id.file'),
							name: 'fileId'
						})
					]
				});

				const additionInfoArray = createRowsBlock({
					createContentFieldsFn: createInfoField,
					onAddRow() {
						form.isValid();
					},
					onRemoveRow() {
						form.isValid();
					}
				});

				const operationContent = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					padding: FIELD_PADDING,
					layout: {
						type: 'grid',
						area: [[6, 6]]
					},
					items: [
						createTextField({
							columnWidth: 0.8,
							allowBlank: false,
							fieldLabel: edi.i18n.getMessage('document.torg2.p2.info.acceptance.values'),
							name: 'contentResultAcceptance',
							value: edi.i18n.getMessage('document.torg2.p2.document.affirm')
						}),
						createdDocumentDate({
							fieldLabel: edi.i18n.getMessage('document.torg2.date.create.information')
						})
					]
				});

				var isIP = getIsIP(orgData);
				var signer = createSigner(
					isIP,
					{
						positionTitle: {
							title: edi.i18n.getMessage('company.signer')
						},
						positionInput: {
							maxLength: 128,
							name: 'position',
							allowBlank: false
						},
						lastName: {
							maxLength: 60,
							allowBlank: false
						},
						firstName: {
							maxLength: 60,
							allowBlank: false
						},
						middleName: {
							maxLength: 60
						}
					},
					dataFromActiveCert,
					orgData,
					signerData
				);

				var fieldItems = [
					operationContent,
					additionInfo,
					additionInfoArray,
					signer,
					authorityArea,
					baseAuthority
				];
				return createModalForm({
					cls: 'edi-form',
					bodyPadding: 10,
					submitEmptyText: false,
					items: fieldItems
				});
			};

			const createSignerForm = function (conf) {
				conf = 'object' === typeof conf ? conf : {};
				let resultAcceptance, resultAcceptanceCombo, discrepancyDocPanel, operationContentCombo;

				let checkFieldsAllowBlank = function () {
					if (!resultAcceptanceCombo || !operationContentCombo) {
						return;
					}
					let resultAcceptanceIsBlank = !resultAcceptanceCombo.getValue();
					let operationContentIsBlank = !operationContentCombo.getValue();
					if (resultAcceptanceIsBlank && operationContentIsBlank) {
						resultAcceptanceCombo.allowBlank = false;
						operationContentCombo.allowBlank = false;
					} else {
						resultAcceptanceCombo.allowBlank = !operationContentIsBlank;
						operationContentCombo.allowBlank = !resultAcceptanceIsBlank;
					}
					resultAcceptanceCombo.isValid();
					operationContentCombo.isValid();
				};

				if (conf.showResultAcceptanceArea) {
					// Итог приемки товара
					resultAcceptanceCombo = createCombo({
						store: edi.stores.createSimpleInlineStore([1, 2, 3], function (id) {
							return edi.i18n.getMessage('document.part2.create.form.resultAcceptance.' + id);
						}),
						editable: false,
						maxLength: 255,
						autoValue: false,
						allowBlank: true,
						name: 'resultAcceptance',
						value: 1,
						queryMode: 'local',
						forceSelection: false,
						triggers: {
							close: {
								extraCls: 'edi-icon edi-icon-CLOSE',
								handler: function (cmp, trigger) {
									if (trigger) {
										this.clearValue();
									}
								}
							}
						},
						triggerAction: 'last',
						listeners: {
							select(comp) {
								let val = comp.getValue();
								if (val === '2' || val === '3') {
									discrepancyDocPanel.show();
								} else {
									discrepancyDocPanel.hide();
									discrepancyDocTypeCombo.clearValue();
									discrepancyDocNumber.down('[name=discrepancyDocNumber]').setValue('');
									discrepancyDocName.down('[name=discrepancyDocName]').setValue('');
									discrepancyDate.down('[name=discrepancyDate]').setValue('');
									discrepancyDocFileId.down('[name=discrepancyDocFileId]').setValue('');
								}
							},
							change() {
								checkFieldsAllowBlank();
							}
						}
					});

					resultAcceptance = createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						padding: FIELD_PADDING,
						title: edi.i18n.getMessage('documents.fns_upd.p2.resultAcceptance'),
						layout: {
							type: 'grid',
							area: [10]
						},
						items: [resultAcceptanceCombo]
					});

					// Вид документа о расхождениях
					let discrepancyDocTypeCombo = createCombo({
						store: edi.stores.createSimpleInlineStore([2, 3], function (id) {
							return edi.i18n.getMessage('document.part2.create.form.discrepancyDocType.' + id);
						}),
						forceSelection: false,
						editable: false,
						maxLength: 255,
						allowBlank: true,
						name: 'discrepancyDocType',
						triggers: {
							close: {
								extraCls: 'edi-icon edi-icon-CLOSE',
								handler: function (cmp, trigger) {
									if (trigger && trigger.target && trigger.target.localName !== 'input') {
										this.clearValue();
										this.fireEvent('select', discrepancyDocTypeCombo);
									}
								}
							}
						},
						listeners: {
							change: function () {
								// this.triggerBlur();
							}
						},
						triggerAction: 'last'
					});
					discrepancyDocTypeCombo.on('focus', function (comp) {
						!comp.isExpanded ? comp.expand() : null;
					});
					let discrepancyDocNumber = createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documents.fns_upd.p2.discrepancyDocNumber'),
						items: [
							createTextField({
								maxLength: 255,
								allowBlank: true,
								name: 'discrepancyDocNumber'
							})
						]
					});
					let discrepancyDocType = createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documents.fns_upd.p2.discrepancyDocType'),
						items: [discrepancyDocTypeCombo]
					});

					// Наименование документа о расхождениях
					let discrepancyDocName = createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documents.fns_upd.p2.discrepancyDocName'),
						items: [
							createTextField({
								maxLength: 255,
								allowBlank: true,
								name: 'discrepancyDocName',
								value: ''
							})
						]
					});
					let discrepancyDate = createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documents.fns_upd.p2.discrepancyDate'),
						layout: {
							type: 'grid',
							area: [7]
						},
						items: [
							createDateField({
								allowBlank: true,
								submitFormat: edi.constants.DATE_FORMAT.FNS,
								name: 'discrepancyDate'
							})
						]
					});

					// Идентификатор файла документа о расхождениях
					let discrepancyDocFileId = createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documents.fns_upd.p2.discrepancyDocFileId'),
						items: [
							createTextField({
								maxLength: 255,
								allowBlank: true,
								name: 'discrepancyDocFileId',
								value: ''
							})
						]
					});

					discrepancyDocPanel = createFieldSet({
						cls: 'fieldset-without-header',
						layout: {
							type: 'grid',
							area: [[7, 5], [7, 5], 7]
						},
						items: [
							discrepancyDocType,
							discrepancyDocNumber,
							discrepancyDocName,
							discrepancyDate,
							discrepancyDocFileId
						]
					});
				}

				let updLineList = ['productsAccepted', 'resultsAccepted', 'servicesAccepted'],
					correctingLineList = ['agreedWithChanges', 'costChangesTakenIntoAccount'],
					isCorrecting = docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
					operationContentComboList = isCorrecting ? correctingLineList : updLineList;

				operationContentCombo = createCombo({
					store: edi.stores.createSimpleInlineStore(
						operationContentComboList.map((item) =>
							edi.i18n.getMessage('document.part2.create.form.operationContent.' + item)
						)
					),
					forceSelection: false,
					maxLength: 255,
					allowBlank: resultAcceptanceCombo ? !!resultAcceptanceCombo.getValue() : false,
					name: 'operationContent',
					triggers: {
						close: {
							extraCls: 'edi-icon edi-icon-CLOSE',
							handler: function (cmp, trigger) {
								if (trigger) {
									this.clearValue();
								}
							}
						}
					},
					listeners: {
						change() {
							checkFieldsAllowBlank();
						}
					}
				});

				let operationContent = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					padding: FIELD_PADDING,
					title: edi.i18n.getMessage('documents.fns_upd.p2.operationContent'),
					layout: {
						type: 'grid',
						area: [10]
					},
					items: [operationContentCombo]
				});

				let dateReceiptGoods = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('documents.fns_upd.p2.dateReceiptGoods'),
					layout: {
						type: 'grid',
						area: [3]
					},
					items: [
						createdDocumentDate({
							name: 'dateReceiptGoods'
						})
					]
				});

				let forInternalPurpose = isUPD501N
					? createCheckbox({
							padding: FIELD_CHECKBOX_PADDING,
							name: 'forInternalPurpose',
							boxLabel: edi.i18n.getMessage('upd.detail.field.for.internal.purpose'),
							inputValue: true
					  })
					: null;

				let isIP = getIsIP(orgData);
				let signer = createSigner(isIP, conf, dataFromActiveCert, orgData, signerData, versionFormat, docType);

				let certificateRegistrationIP = isIP
					? createFieldBlock({
							cls: FIELD_BLOCK_CLS.small,
							padding: FIELD_PADDING,
							title: edi.i18n.getMessage('individual.certificateRegistration'),
							items: [
								createTextField({
									maxLength: 255,
									name: 'certificateRegistrationIP',
									value: edi.utils.getObjectProperty(signerData, 'certificateRegistrationIP')
								})
							]
					  })
					: null;

				let userSavedAuthorityArea = edi.utils.getObjectProperty(signerData, 'authorityArea');

				let authorityAreaStore = isUPD501N
					? edi.stores.createMemoryStore(
							getStoreItems(
								edi.stores.data.authorityAreas,
								edi.constants.SIGN_DOCUMENTS.AUTHORITY_AREAS_FOR_DOC_TYPES[
									edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
								]['INBOX']
							),
							'AUTHORITY_AREA'
					  )
					: isUKD501N
					? edi.stores.createMemoryStore(
							getStoreItems(
								edi.stores.data.authorityAreasUKD,
								edi.constants.SIGN_DOCUMENTS.AUTHORITY_AREAS_FOR_DOC_TYPES[
									edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
								]['INBOX']
							),
							'AUTHORITY_AREA'
					  )
					: isServiceAkt
					? edi.stores.createMemoryStore(
							getStoreItems(
								edi.stores.data.authorityAreas,
								edi.constants.SIGN_DOCUMENTS.AUTHORITY_AREAS_FOR_DOC_TYPES[
									edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
								]['INBOX']
							),
							'AUTHORITY_AREA'
					  )
					: edi.stores.createMemoryStore(
							getStoreItems(
								edi.stores.data.authorityAreas,
								edi.constants.SIGN_DOCUMENTS.AUTHORITY_AREAS_FOR_OBSOLETE_UKDS['INBOX']
							),
							'AUTHORITY_AREA'
					  );

				let statusStore = isUPD501N
					? edi.stores.initSignerInboxStore()
					: isUKD501N
					? edi.stores.initSignerInboxStore()
					: edi.stores.createMemoryStore(
							getStoreItems(
								edi.stores.data.signerBuyerStatuses,
								edi.constants.SIGN_DOCUMENTS.STATUSES_FOR_OBSOLETE_UKDS['INBOX']
							),
							'AUTHORITY_AREA'
					  );

				let authorityArea =
					conf.authorityAreaComboConfig || conf.statusComboConfig
						? createAuthorityArea(conf)
						: createAuthorityArea({
								authorityAreaComboConfig: {
									store: authorityAreaStore,
									qtipText: !dataFromActiveCert.authorityArea
										? edi.i18n.getMessage('document.signer.notice.fill.authority.area.in.profile')
										: undefined,
									autoValue: userSavedAuthorityArea
										? userSavedAuthorityArea
										: docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
										? 3
										: 6
								},
								statusComboConfig: {
									store: statusStore,
									listeners: {
										change: onChangeStatus
									}
								}
						  });

				let workerReceiptGoods = createWorkerReceiptGoods({});

				let authority = createAuthority({
					overlay: {
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory')
					}
				});

				let acceptorSameAsReceiver = createCheckbox({
					padding: FIELD_CHECKBOX_PADDING,
					boxLabel: edi.i18n.getMessage('documents.fns_upd.p2.acceptor.same.as.receiver'),
					inputValue: true,
					name: 'sameAsReceiver',
					listeners: {
						change: function (cbx, value) {
							value ? workerReceiptGoods.hide() : workerReceiptGoods.show();
							value ? authority.hide() : authority.show();
							authority.down('textfield[name=workerReceiptGoods.authority]').setDisabled(value);
							workerReceiptGoods.down('textfield[name=workerReceiptGoods.position]').setDisabled(value);
							workerReceiptGoods
								.down('textfield[name=workerReceiptGoods.fullName.lastName]')
								.setDisabled(value);
							workerReceiptGoods
								.down('textfield[name=workerReceiptGoods.fullName.firstName]')
								.setDisabled(value);
							workerReceiptGoods
								.down('textfield[name=workerReceiptGoods.fullName.middleName]')
								.setDisabled(value);
							form.isValid();
						}
					}
				});

				let fieldItems = [
					resultAcceptance,
					operationContent,
					dateReceiptGoods,
					forInternalPurpose,
					discrepancyDocPanel,
					signer,
					certificateRegistrationIP,
					authorityArea,
					baseAuthority
				];
				if (edi.utils.getObjectProperty(mapConfig, 'objectMapping.workerReceiptGoods')) {
					fieldItems = fieldItems.concat([acceptorSameAsReceiver, workerReceiptGoods, authority]);
				}
				return createModalForm({
					submitEmptyText: false,
					items: fieldItems
				});
			};

			const onChangeStatus = function (comp) {
				let value = comp.getValue();
				let baseAuthorityInput = baseAuthority.down('[name=baseAuthory]');
				let baseOrgAuthorityInput = baseAuthority.down('[name=baseOrgAuthory]');
				if (value && baseAuthorityInput) {
					baseOrgAuthorityInput.allowBlank = value !== '3';
					baseOrgAuthorityInput.isValid();
				}
				form.isValid();
			};

			if (isUPD501N) {
				form = createSignerForm({
					showResultAcceptanceArea: true,
					authorityAreaComboConfig: {
						qtipText: !dataFromActiveCert.authorityArea
							? edi.i18n.getMessage('document.signer.notice.fill.authority.area.in.profile')
							: undefined,
						readOnly: docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
						autoValue:
							docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
								? 3
								: edi.utils.getObjectProperty(signerData, 'authorityArea')
					},
					statusComboConfig: {
						store: edi.stores.initSignerInboxStore(),
						listeners: {
							change: onChangeStatus
						}
					}
				});
			} else if (edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2 === docType) {
				form = createSignerFormTorg2({
					authorityAreaComboConfig: {
						store: edi.stores.createInlineStore(
							edi.stores.data.torg2AuthorityAreas.INBOX,
							'AUTHORITY_AREA'
						),
						autoValue: edi.utils.getObjectProperty(signerData, 'authorityArea')
					},
					statusComboConfig: {
						store: edi.stores.createInlineStore(
							edi.stores.data.torg2SignerStatuses.INBOX,
							'AUTHORITY_AREA'
						),
						listeners: {
							change: onChangeStatus
						}
					}
				});
			} else {
				form = createSignerForm();
			}

			form.isValid();

			let titleKey = isTorg2 ? 'document.part2.create.title' : 'document.part2.create.title.buyer';
			let docTypeTranslation = edi.i18n.getMessage('documents.doctype.' + docType);
			modal = createModalPanel({
				title: edi.i18n.getMessage(titleKey, [docTypeTranslation]),
				width: MODAL_SIZE.widthLarge,
				maxHeight: 688,
				items: [form],
				buttonsBefore: [
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('form.btn.sign'),
						bindToForm: form,
						formBind: true,
						disabled: true,
						handler: function () {
							let values = edi.utils.collectFormValues(form);
							if (isUPD501N) {
								if (values['forInternalPurpose']) {
									values.textInfos = [
										{
											identifier: getPurposeTypeValue(PURPOSES_TYPES.SV_VIBITIYA),
											value: '1'
										}
									];
								}
								delete values['forInternalPurpose'];
							}

							if (!dataFromActiveCert.state || !dataFromActiveCert.authorityArea) {
								edi.core.confirm(
									null,
									'document.part2.create.confirm.is.save.authority.area',
									function () {
										let signerSettingsValues = {
											direction: direction,
											authorityArea: values.authorityArea,
											state: values.state,
											baseAuthory: values.baseAuthory,
											baseOrgAuthory: values.baseOrgAuthory,
											docType: docType
										};

										edi.rest.sendRequest(
											!signerSettingsSaved.id
												? edi.rest.services.USER_SELF_SIGNER_SETTINGS.POST
												: edi.utils.formatString(
														edi.rest.services.USER_SELF_SIGNER_SETTINGS.PUT,
														{
															id: signerSettingsSaved.id
														}
												  ),
											signerSettingsSaved.id ? 'PUT' : 'POST',
											Ext.encode(signerSettingsValues)
										);
									},
									undefined,
									function () {
										callback(values, modal);
									}
								);
							} else {
								callback(values, modal);
							}
						}
					})
				]
			});
			modal.show();
		},
		createPart2: function (docType, fieldsValues, documents, orgData, signerInfo, callback) {
			var resultValues = {};

			const certHandler = createCertificateHandler();
			const poaHandler = createPoaHandler();

			var mapConfig = edi.methods.createDocumentPart2.mappingFields.find(function (item) {
				return item.docType === docType;
			});

			Object.entries(mapConfig.mapping).forEach(function ([fieldName, propName]) {
				var fieldValue = edi.utils.getObjectProperty(fieldsValues, fieldName);
				if (fieldValue) {
					edi.utils.setObjectProperty(resultValues, propName, fieldValue);
				}
			});

			var setAdditionalData = function (data) {
				let { signerType, isNotLegalEntity, signerFioKey } = signerInfo;

				if (data.state === '4') {
					let inn = edi.utils.getObjectProperty(data, 'tin');
					if (inn.length !== 12) {
						inn += '123456789012';
						inn = inn.slice(0, 12);
					}
					let nameProperty =
						docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
							? 'fullName'
							: isNotLegalEntity
							? signerFioKey
							: 'name';
					let signerObj = {
						[nameProperty]: {
							lastName: edi.utils.getObjectProperty(data, 'fullName.lastName'),
							firstName: edi.utils.getObjectProperty(data, 'fullName.firstName'),
							middleName: edi.utils.getObjectProperty(data, 'fullName.middleName')
						},
						tin: inn
					};
					if (docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2) {
						signerObj.position = edi.utils.getObjectProperty(data, 'position');
					}

					let individualPropPath =
						docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
							? mapConfig.objectMapping.signer + '.individual'
							: mapConfig.objectMapping.signer + `.${isNotLegalEntity ? signerType : 'individualPerson'}`;
					edi.utils.setObjectProperty(resultValues, individualPropPath, signerObj);
				} else {
					edi.utils.setObjectProperty(resultValues, mapConfig.objectMapping.signer + `.${signerType}`, {
						[signerFioKey]: {
							lastName: edi.utils.getObjectProperty(data, 'fullName.lastName'),
							firstName: edi.utils.getObjectProperty(data, 'fullName.firstName'),
							middleName: edi.utils.getObjectProperty(data, 'fullName.middleName')
						},
						certificateRegistrationIP: edi.utils.getObjectProperty(data, 'certificateRegistrationIP'),
						tin: edi.utils.getObjectProperty(data, 'tin'),
						position: isNotLegalEntity ? null : edi.utils.getObjectProperty(data, 'position')
					});
				}

				if (data.sameAsReceiver && !isNotLegalEntity) {
					edi.utils.setObjectProperty(resultValues, mapConfig.objectMapping.workerReceiptGoods, {
						fullName: {
							lastName: edi.utils.getObjectProperty(data, 'fullName.lastName'),
							firstName: edi.utils.getObjectProperty(data, 'fullName.firstName'),
							middleName: edi.utils.getObjectProperty(data, 'fullName.middleName')
						},
						position: edi.utils.getObjectProperty(data, 'position'),
						authority: edi.utils.getObjectProperty(data, 'baseAuthory')
					});
				} else if (mapConfig.objectMapping.workerReceiptGoods) {
					edi.utils.setObjectProperty(
						resultValues,
						mapConfig.objectMapping.workerReceiptGoods,
						edi.utils.getObjectProperty(data, 'workerReceiptGoods')
					);
				}
				if (mapConfig.convertToRowsEditingArrayNames) {
					mapConfig.convertToRowsEditingArrayNames.forEach(function (name) {
						var rowToConvert = edi.utils.getObjectProperty(resultValues, name);
						if (rowToConvert) {
							edi.utils.setObjectProperty(
								resultValues,
								name,
								edi.methods.convertValuesFromMultipleFields(rowToConvert)
							);
						}
					});
				}

				resultValues.signer = [resultValues.signer];
				resultValues.nameEconomicSubject = orgData.name;
				if (docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT) {
					edi.utils.setObjectProperty(resultValues, 'contentFactsEconomicLife.function', 'DOP');
				}
			};

			setAdditionalData(fieldsValues);
			edi.utils.clearEmptyValues(resultValues);

			var stringified = null;
			if (docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2) {
				edi.utils.setObjectProperty(resultValues, 'document.signer', resultValues.signer);
				delete resultValues.signer;
				stringified = Ext.encode(resultValues);
			} else {
				stringified = Ext.encode({
					buyerInfo: resultValues
				});
			}
			var i = 0,
				createdDocuments = [],
				successDocumentsIds = [];

			var markRead = function (docData, afterReadHandler) {
				var docOptions = edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', docData), undefined, {
					direction: edi.utils.getDocumentDirection(docData.toOrg, docData.fromOrg),
					hasPart2: !!edi.utils.getAttributeByName(docData.attributes, 'hasPart2')
				});
				let readActionConfig = edi.document.actions.buttonsConfig[edi.constants.STATE.READ] || {};
				if (
					readActionConfig.hasOwnProperty('isAvailable')
						? 'function' == typeof readActionConfig.isAvailable
							? readActionConfig.isAvailable(docOptions)
							: readActionConfig.isAvailable
						: edi.action.isAvailable(edi.constants.STATE.READ, docOptions)
				) {
					let postData = {
						[edi.constants.BUSINESS_PROCESS_PROPERTIES.TASK_ID]: edi.constants.STATE.SENT
					};
					edi.rest.sendRequest(
						edi.utils.formatString(
							edi.rest.services.DOCUMENTS.SEND.PUT,
							{
								documentId: docData.id
							},
							true
						),
						'PUT',
						Ext.encode(postData),
						function () {
							afterReadHandler(true);
						},
						function () {
							afterReadHandler();
						}
					);
				} else {
					afterReadHandler();
				}
			};

			var createDocPart = function () {
				var startCreate = function () {
					var isCreated = edi.utils.getAttributeByName(documents[i].attributes, 'hasPart2');
					var documentProperties = {
						data: stringified,
						number: documents[i].number,
						//date: documents[i].doctime,
						dateFormat: edi.constants.DATE_FORMAT.SERVER,
						versionId: documents[i].versionId
					};
					if (
						docType !== edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD &&
						docType !== edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
					) {
						documentProperties.date = documents[i].doctime;
					}
					edi.document.actions.processDocument(
						documents[i].fromOrg.id,
						undefined,
						edi.constants.PARTS_DOC_TYPES[documents[i].type].P2,
						documents[i].id,
						isCreated ? documents[i].id : undefined,
						function (resultData) {
							if (resultData.success) {
								successDocumentsIds.push(documents[i].id);
								if (isCreated !== 'true') {
									createdDocuments.push(resultData.data);
									i++;
									createDocPart();
								} else {
									edi.rest.sendRequest(
										edi.utils.formatString(
											edi.rest.services.DOCUMENTS.LINKED.GET,
											{
												documentId: documents[i].id
											},
											true
										),
										'GET',
										undefined,
										function (data) {
											if (data && data.data && data.data.children && data.data.children.length) {
												for (var j = 0; j < data.data.children.length; j++) {
													if (
														data.data.children[j].type ===
														edi.constants.PARTS_DOC_TYPES[documents[i].type].P2
													) {
														createdDocuments.push(data.data.children[j]);
														break;
													}
												}
											}
										},
										undefined,
										function () {
											i++;
											createDocPart();
										}
									);
								}
							}
						},
						edi.document.actions.createSaveErrorHandler(false, undefined, documents[i].type),
						documentProperties
					);
				};

				if (documents.length <= i) {
					callback(createdDocuments, successDocumentsIds, certHandler.get(), poaHandler.get());
				} else {
					if (edi.methods.createDocumentPart2.allowToCreateP2(documents[i]) === true) {
						edi.methods.createDocumentPart2.signIzvpol(
							documents[i],
							function () {
								//DOP_ROAMING пушается при помощи подписания ИоП, значит отмечать прочитанным его не нужно
								//let bpName = edi.utils.getAttributeByName(documents[i].attributes, 'bpName');
								if (
									documents[i].state === edi.constants.STATE.SENT
									//&& bpName !== edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING
									//&& bpName !== edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.DIS_ROAMING
								) {
									markRead(documents[i], function (isSuccess) {
										if (!isSuccess) {
											i++;
											createDocPart();
										} else {
											startCreate();
										}
									});
								} else {
									startCreate();
								}
							},
							certHandler,
							poaHandler
						);
					} else {
						i++;
						createDocPart();
					}
				}
			};

			createDocPart();
		}
	}
});
