import { DocumentsGridModule } from '@Edi/modules/documentGrids/documents';
import { EW_GRID_COMMON_COLUMN_CONFIG_NAME } from '@Ediweb/columns';
import { filterMethods } from '@Ediweb/modules/documentGrids/filter';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { createAcceptance } from '@Ediweb/modules/ACCEPTANCE/acceptance';

export class EWDocumentsGridModule extends DocumentsGridModule {
	constructor() {
		super();
	}

	getActionsForActionColumn(gridName) {
		const items = super.getActionsForActionColumn(gridName);
		items.unshift({
			glyph: edi.constants.ICONS.EDIT,
			tooltip: edi.i18n.getMessage('ediweb.columns.action.edit'),
			handler(grid, rIndex, cIndex, item, e, record) {
				let recordData = record.getData();
				edi.document.actions.openCreateModule(recordData.type, recordData, true, recordData.versionId);
			},
			isActionDisabled: function (view, rowIndex, colIndex, item, record) {
				return record.get('state') !== edi.constants.STATE.DRAFT;
			},
			isHidden: function (view, rowIndex, colIndex, item, record) {
				return gridName === 'deleted';
			}
		});

		return items;
	}

	createFilterForm() {
		const me = this;
		const { formItemsMap, items } = filterMethods.createCommonFormItems();

		const form = createModuleFilterForm(
			{
				saveFilter: {
					name: `${me.moduleData.modName}_all_grids`
				},
				toggleAutoSearch: true,
				autosearchEnabled: false,
				searchOnFilterCollapse: true,
				bodyPadding: 0,
				formItemsMap,
				items,
				setFormDefaults: function () {
					let defaultFieldName = edi.utils.getUserDefaultSearchPeriodField(
						edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE
					);
					let defaultModifyDateRange = edi.utils.getUserDefaultSearchPeriod(
						edi.constants.DEFAULT.FILTER.PERIODS.WEEK
					);
					formItemsMap[defaultFieldName].activatePeriod(defaultModifyDateRange);
				}
			},
			me._fireSearchCommon
		);
		return form;
	}

	// private
	getGridProps(gridName) {
		const me = this;

		return {
			gridCfg: {
				viewConfig: {
					emptyTextTplOptions: {
						enabled: true,
						title: edi.i18n.getMessage('ediweb.grid.empty.text.tpl.title.' + gridName),
						contentText: edi.i18n.getMessage('ediweb.grid.empty.text.tpl.contentText.' + gridName),
						beforeButtonText: edi.i18n.getMessage(
							'ediweb.grid.empty.text.tpl.beforeButtonText.' + gridName
						),
						buttonText: edi.i18n.getMessage('ediweb.grid.empty.text.tpl.buttonText.' + gridName),
						buttonName: 'emptyTextButton',
						buttonClickHandler: function (e, btn, name) {
							me.filterForm.toggleHandler();
						}
					}
				}
			}
		};
	}

	createGrids() {
		const me = this;
		const grids = super.createGrids();

		return Object.assign(grids, {
			notarchived: me.createGridFn(
				'notarchived',
				EW_GRID_COMMON_COLUMN_CONFIG_NAME,
				me.getGridProps('notarchived')
			),
			incoming: me.createGridFn('incoming', EW_GRID_COMMON_COLUMN_CONFIG_NAME, me.getGridProps('incoming')),
			outgoing: me.createGridFn('outgoing', EW_GRID_COMMON_COLUMN_CONFIG_NAME, me.getGridProps('outgoing')),
			deleted: me.createGridFn('deleted', EW_GRID_COMMON_COLUMN_CONFIG_NAME, me.getGridProps('deleted')),
			rejected: me.createGridFn('rejected', EW_GRID_COMMON_COLUMN_CONFIG_NAME, me.getGridProps('rejected'))
		});
	}
}
edi.modules['documents'] = EWDocumentsGridModule;
