import { createLabel } from '@Components/fields';
import {
	createActionsColumnConfig,
	createGrid,
	createGridActionBar,
	createGridCheckboxSelectionModel
} from '@Components/grid';
import { createModulePanel } from '@Components/panels';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';

Ext.namespace('edi.modules');
/**
 * Factorplat reports request grid
 */
edi.modules['deferred.reports'] = function () {
	var moduleData,
		grid,
		filterForm,
		filterObject,
		filterFromConfig = {};

	/**
	 * Creates form for grid filtering
	 * @returns {Object}                Instance of form
	 */
	var createFilterForm = function () {
		const { formItemsMap, items } = edi.filters.config.deferred_reports.createFormItems();
		return createModuleFilterForm(
			Object.assign(
				{
					usePlaceholderWrapper: true,
					formItemsMap,
					items
				},
				filterFromConfig
			),
			fireSearch
		);
	};

	/**
	 * Performs action on grid record
	 * @param      requestObj         Selecetd request
	 * @param      actionId        Selected action id
	 * @param      rowIndex        record rowIndex (for delete action)
	 */
	var performAction = function (actionId, requestObj, rowIndex) {
		if (actionId !== edi.constants.DOCUMENT_ACTIONS.EXPORT) {
			var isDeleteAction = actionId === edi.constants.DOCUMENT_ACTIONS.DELETE,
				restAction = isDeleteAction ? 'DELETE' : 'PUT',
				url = edi.rest.services.REPORTS[restAction];

			var failure = function (data) {
				var defaultMsg = isDeleteAction
					? 'error.deleting.deferred.report.request'
					: 'error.repeating.deferred.report.request';

				edi.core.showError(edi.utils.formatComplexServerError(data, defaultMsg));
			};

			var success = function () {
				moduleData.isChanged = false;
				if (isDeleteAction) {
					grid.getStore().removeAt(rowIndex);
				}

				edi.events.deferredReport.fireEvent('change', {
					id: requestObj.id,
					deleted: isDeleteAction
				});
			};

			edi.rest.sendRequest(
				edi.utils.formatString(url, {
					id: requestObj.id
				}),
				restAction,
				undefined,
				success,
				failure,
				function () {
					moduleData.tab.setLoading(false);
				}
			);
		} else {
			var request = requestObj.reportRequest || {},
				docId = requestObj.doc.id;

			edi.rest.downloadFile(
				edi.utils.formatString(
					edi.rest.services.REPORT.DOWNLOAD.GET,
					{
						docId: docId,
						reportId: request.id
					},
					false
				),
				docId
			);
		}
	};

	/**
	 * Checks if action is availiable for request
	 * @param      actionId       Action id
	 * @param      record         Request
	 * @return     {boolean}
	 */
	var isActionAvailable = function (actionId, record) {
		var state = record.get('state'),
			result = false;

		if (actionId === edi.constants.DOCUMENT_ACTIONS.DELETE) {
			result = state !== edi.constants.REPORT_REQUEST.STATE.WAIT_TO_CREATE_DOCUMENT;
		} else if (actionId === edi.constants.DOCUMENT_ACTIONS.EXPORT) {
			result = state === edi.constants.REPORT_REQUEST.STATE.COMPLETED;
		}

		return result;
	};

	/**
	 * Initializes grid
	 * @returns {Object}        Instance of grid
	 */
	var createModuleGrid = function () {
		var columns = edi.columns.get('deferred_report') || [],
			actionItems = [],
			groupActionsItems = [],
			topBar;
		let totalsLabel = createLabel({
			text: edi.i18n.getMessage('records.selected.totals', {
				selectedRows: 0
			}),
			cls: 'edi-tbar-total-label-right'
		});

		/**
		 * Create record action buttons
		 * @param       actionId      Action id
		 * @param       iconId        Icon id
		 * @return      {Object}
		 */
		var createActionItem = function (actionId, iconId) {
			return {
				glyph: edi.constants.ICONS[iconId],
				handler: function (grid, rowIndex) {
					var requestObj = grid.getStore().getAt(rowIndex).getData();
					performAction(actionId, requestObj, rowIndex);
				},
				isActionDisabled: function (view, rowIndex, colIndex, item, record) {
					return !isActionAvailable(actionId, record);
				}
			};
		};

		actionItems.push(createActionItem(edi.constants.DOCUMENT_ACTIONS.EXPORT, 'FILE_DOWNLOAD'));

		if (edi.permissions.hasPermission('DELETE_EDI_REPORT_REQUEST_PROCESSING')) {
			actionItems.push(createActionItem(edi.constants.DOCUMENT_ACTIONS.DELETE, 'REMOVE'));

			groupActionsItems.push({
				id: edi.constants.DOCUMENT_ACTIONS.DELETE,
				name: edi.i18n.getMessage('document.delete.document')
			});
		}

		columns.push(
			createActionsColumnConfig({
				items: actionItems
			})
		);

		if (groupActionsItems.length) {
			var onGroupActionClick = function (actionId) {
				var options = {
					grid: grid,
					moduleData: moduleData,
					action: actionId,
					processEvent: {
						object: 'deferredReport',
						event: 'change'
					},
					processUrlParamCollector: function (record) {
						return {
							id: record.get('id')
						};
					},
					confirmAction: true,
					createList: function (items) {
						return edi.document.actions.createItemsList(items, {
							requestType: {
								title: 'column.deferred.report.request.type',
								renderer: function (value) {
									return value ? edi.i18n.getMessage('deferred.report.type.' + value) : '';
								}
							},
							attributes: {
								title: 'column.deferred.report.format',
								renderer: function (value) {
									return value && value.reportFormat ? value.reportFormat.value : '';
								}
							}
						});
					},
					actionCheckCallback: function (record) {
						return isActionAvailable(actionId, record);
					}
				};

				Object.assign(options, {
					processUrl: edi.rest.services.REPORTS.DELETE,
					processMethod: 'DELETE',
					loadingMessage: 'report.request.delete.started',
					confirmTitle: 'confirm.report.request.delete.title',
					confirmQuestion: 'confirm.report.request.delete.question'
				});

				edi.document.actions.documentGridActionProcess(options);
			};
		}

		topBar = createGridActionBar({
			actionCfgs: groupActionsItems,
			defaultHandler: onGroupActionClick
		});

		var grid = createGrid({
			saveSorters: true,
			savedSortersName: 'deferred_reports',
			proxyConfig: {
				type: 'ajax',
				url: edi.rest.services.REPORTS.GET
			},
			storeConfig: {
				model: edi.models.getModel('DEFERRED_REPORT'),
				sortOnLoad: true,
				sorters: {
					property: 'creationDate',
					direction: 'DESC'
				},
				autoLoad: false
			},
			gridConfig: {
				columns: columns,
				padding: 0,
				region: 'center',
				layout: 'fit',
				border: 1,
				selModel:
					topBar && topBar.menuActionItemsCnt
						? createGridCheckboxSelectionModel({
								topBar: topBar,
								selectionProcessor: function (model, selected, actionItems) {
									edi.methods.gridSelModules(model, selected, actionItems, topBar, totalsLabel);
								}
						  })
						: undefined,
				tbar: topBar
			},
			pagingBarConfig: {
				totalsLabel: topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined
			}
		});
		return grid;
	};

	var fireSearch = function () {
		if (!filterObject) {
			filterObject = edi.filters.createGridFilter(
				edi.rest.services.REPORTS.GET,
				filterForm,
				grid,
				function (values) {
					if (values['creationDateFrom']) {
						values.creationDateFrom = edi.utils.getSearchTime(
							values['creationDateFrom'],
							edi.constants.DATE_FORMAT.SERVER
						);
					}
					if (values['creationDateTo']) {
						values.creationDateTo = edi.utils.getSearchTime(
							values['creationDateTo'],
							edi.constants.DATE_FORMAT.SERVER,
							edi.constants.SEARCH_DAY_IN_MS
						);
					}
					return values;
				},
				null,
				{
					persistence: {
						enabled: true,
						name: `${moduleData.modName}_reports`
					}
				}
			);
		}
		filterObject.filter();
	};

	/**
	 * Routine that must be done before module destroy
	 */
	var onDestroy = function () {
		edi.events.deferredReport.un('create', fireSearch);
		edi.events.deferredReport.un('change', fireSearch);
		edi.events.deferredReport.un('delete', fireSearch);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name, null);
		return true;
	};

	/**
	 * Renders page layout
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});

		grid = createModuleGrid();
		filterForm = createFilterForm();
		modulePanel.add(filterForm);
		modulePanel.add(grid);
		moduleData.tab.add(modulePanel);
		if (typeof initCallBack === 'function') {
			initCallBack();
		}

		fireSearch();
	};

	/**
	 * Module initialization
	 * @param {Object} data                Data from modules handler
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		if (data.initData.data && data.initData.data.filterFromConfig) {
			filterFromConfig = data.initData.data.filterFromConfig;
		}
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		if (filterObject && filterObject.searchTimeout) {
			clearTimeout(filterObject.searchTimeout);
		}
		edi.events.deferredReport.on('create', fireSearch);
		edi.events.deferredReport.on('change', fireSearch);
		edi.events.deferredReport.on('delete', fireSearch);
	};
};
