import { ActionRules } from '@App/js/action';

export const actionRulesForPackingListModule: ActionRules = {
	EXPORT: {
		OUTGOING: {},
		INCOMING: {}
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {}
	},
	EXPORT_GROUP_XML: {
		OUTGOING: {},
		INCOMING: {}
	},
	EXPORT_GROUP: {
		OUTGOING: {},
		INCOMING: {}
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {}
	},
	DELETE: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		permissionChanging: [
			{
				change: function () {
					return 'CLIENT_DELETE_PACKING_LIST';
				}
			}
		]
	},
	COMPLETE: {
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		permissionChanging: [
			{
				change: function () {
					return 'CLIENT_COMPLETE_PACKING_LIST';
				}
			}
		],
		conditions: []
	}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.PACKING_LIST]: actionRulesForPackingListModule
});
