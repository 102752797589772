export const PACKING_LIST_LINE_MODEL = 'PACKING_LIST_LINE';
export const PACKING_LIST_SUMMARY_MODEL = 'PACKING_LIST_SUMMARY';

edi.models.setModels({
	[PACKING_LIST_LINE_MODEL]: {
		fields: [
			{
				name: 'lineNumber',
				type: 'number'
			},
			{
				name: 'itemDescription',
				type: 'string'
			},
			{
				name: 'ean',
				type: 'string'
			},
			{
				name: 'buyerItemCode',
				type: 'string'
			},
			{
				name: 'supplierItemCode',
				type: 'string'
			},
			{
				name: 'packageIdentification'
			},
			{
				name: 'packageMeasurements',
				type: 'string'
			},
			{
				name: 'orderedQuantity',
				type: 'number'
			},
			{
				name: 'unitOfMeasure',
				type: 'string'
			},
			{
				name: 'unitNetPrice',
				type: 'number'
			},
			{
				name: 'unitGrossPrice',
				type: 'number'
			},
			{
				name: 'taxRate',
				type: 'string'
			},
			{
				name: 'netAmount',
				type: 'number'
			},
			{
				name: 'taxAmount',
				type: 'number'
			},
			{
				name: 'grossAmount',
				type: 'number'
			}
		]
	},
	[PACKING_LIST_SUMMARY_MODEL]: {
		fields: [
			{
				name: 'totalLines',
				type: 'number'
			},
			{
				name: 'totalPSequence',
				type: 'number'
			}
		]
	}
});

export const getPackingListLinesModel = () => edi.models.getModel(PACKING_LIST_LINE_MODEL);
export const getPackingListSummaryLinesModel = () => edi.models.getModel(PACKING_LIST_SUMMARY_MODEL);
