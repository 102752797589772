import {
	createFieldBlock,
	createContainer,
	createModalForm,
	createFormContainer,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { showConfirmWithException, createModalPanel, MODAL_SIZE, MODAL_CLS } from '@UIkit/components/modal';
import { createLabel, createDetailLabel } from '@UIkit/components/fields';
import { BUTTON_CLS, createButton, createTool } from '@UIkit/components/buttons';
import { createGrid } from '@UIkit/components/grid';
import { createModalRemoteSelect } from '@Core/specialComponents/modals';
import { showWarningToast } from '@Ediweb/core';
import { DUPLICATE_CERTIFICATES_MODEL, USER_CERTIFICATES_MODEL } from '@Ediweb/modules/user_profile/models';
import { DUPLICATE_MODAL_CERTIFICATE_COLUMN, MODAL_CERTIFICATE_COLUMN } from '@Ediweb/modules/user_profile/columns';
import { showErrorToast, showSuccessToast } from '@Ediweb/core';
import { getValueByTypeError } from './modules/ORG_PROFILE/methods';
import { certificatesAPI } from '@Ediweb/modules/CONTROL_CENTER/tabs/EMPLOYEE_CERTIFICATES/rest';
import { createInfoElementPlugin } from '@Core/methods';
import { createProxyConfig } from '@Components/storeComponents';
import { createTextField } from '@Components/fields';
import { POA_CONST } from '@Edi/modules/power_of_attorney/configuration';

Ext.namespace('edi.methods');

Ext.merge(edi.methods, {
	gridActionProcessWithToasts: function (options) {
		let promisesElements = [],
			readyElements = [],
			errorInfoElements = {
				groupedErrors: {},
				notGroupedErrors: {}
			},
			moduleData;
		options = 'object' == typeof options ? options : {};
		/**
		 * Fires backend request for element processing
		 * @param record
		 * @param callback
		 */
		const processElement = function (id, success, failure) {
			const processUrl = options.processUrl,
				processMethod = options.processMethod,
				urlParams = {
					id: id
				};
			return new Promise((resolve, reject) => {
				edi.rest.sendRequest(
					edi.utils.formatString(processUrl, urlParams),
					processMethod,
					{},
					function (data) {
						success(data);
						resolve();
					},
					function (data) {
						failure(data);
						reject();
					}
				);
			});
		};

		const defaults = {
			ids: [],
			processUrl: 'elements/{id}/get',
			processMethod: 'GET',
			confirmTitle: 'confirm.title',
			confirmQuestion: 'confirm.question',
			confirmExceptionsTitle: 'confirm.exceptions.title',
			errorToastTitle: 'error.toast.title',
			countSuccessToastTitle: 'count.success.toast.title',
			confirmAction: true,
			createList: null,
			exceptionCreateList: null,
			successCallback: null,
			isManyDelete: false,
			groupedErrorTypes: []
		};
		Object.assign(defaults, options);
		moduleData = options.moduleData;
		if (moduleData && options.ids) {
			const showToastError = function (typeError, additionalDataStr) {
				let toastText = edi.i18n.getMessage(typeError, [additionalDataStr]);
				let toastTitle = edi.i18n.getMessage(options.errorToastTitle);
				const additionalDataArr = additionalDataStr.split(', ');
				const additionalDataCount = additionalDataArr.length;
				if (typeError === 'controller.route.delete.error.has.related.approvals') {
					const routeTextWord = edi.i18n.decline(additionalDataCount, 'routeDatPadezh');
					toastText = edi.i18n.customTranslation(
						'custom.routes.toast.msg',
						{ routeWord: routeTextWord, employees: additionalDataStr },
						toastText
					);
					const routeTitleWord = edi.i18n.decline(additionalDataCount, 'routeImPadezh');
					toastTitle = edi.i18n.customTranslation(
						'custom.routes.toast.msg.title',
						{ routeWord: routeTitleWord },
						toastTitle
					);
				}
				if (typeError === 'controller.employee.has.not.user.error') {
					const employeeTextWord = edi.i18n.decline(additionalDataCount, 'employeeRodPadezh');
					toastText = edi.i18n.customTranslation(
						'custom.employee.has.not.user.toast.msg',
						{ employeeWord: employeeTextWord, employees: additionalDataStr },
						toastText
					);
					const employeeTitleWord = edi.i18n.decline(additionalDataCount, 'employeeRodPadezh');
					toastTitle = edi.i18n.customTranslation(
						'custom.employee.toast.msg.title',
						{ employeeWord: employeeTitleWord },
						toastTitle
					);
				}
				if (typeError === 'controller.employee.delete.error.route.has.related.approvals') {
					let employeeTextWord = edi.i18n.decline(additionalDataCount, 'employeeImPadezh');
					employeeTextWord = employeeTextWord.charAt(0).toUpperCase() + employeeTextWord.slice(1);
					const inTextWord = edi.i18n.decline(additionalDataCount, 'inVerb');
					toastText = edi.i18n.customTranslation(
						'custom.employee.route.has.related.approvals.toast.msg',
						{ employeeWord: employeeTextWord, employees: additionalDataStr, inWord: inTextWord },
						toastText
					);
					const employeeTitleWord = edi.i18n.decline(additionalDataCount, 'employeeRodPadezh');
					toastTitle = edi.i18n.customTranslation(
						'custom.employee.toast.msg.title',
						{ employeeWord: employeeTitleWord },
						toastTitle
					);
				}
				showErrorToast(toastTitle, toastText, {
					showCloseBtn: true
				});
			};

			const handlerAction = function () {
				moduleData.tab.setLoading();
				options.ids.forEach((id) => {
					const promise = processElement(
						id,
						(result) => {
							readyElements.push(result);
						},
						(result) => {
							const additionalData = getValueByTypeError(result);
							const additionalDataStr = Array.isArray(additionalData)
								? additionalData.join(', ')
								: additionalData;

							if (options.isManyDelete && options.groupedErrorTypes.indexOf(result.typeError) > -1) {
								const typeError = errorInfoElements.groupedErrors[result.typeError];
								if (!typeError) {
									errorInfoElements.groupedErrors[result.typeError] = [additionalDataStr];
								} else {
									errorInfoElements.groupedErrors[result.typeError].push(additionalDataStr);
								}
							} else if (options.isManyDelete) {
								if (!errorInfoElements.notGroupedErrors.hasOwnProperty(result.typeError)) {
									errorInfoElements.notGroupedErrors[result.typeError] = additionalDataStr;
								}
							} else {
								showToastError(result.typeError, additionalDataStr);
							}
						}
					);
					promisesElements.push(promise);
				});

				Promise.allSettled(promisesElements).then(() => {
					moduleData.tab.setLoading(false);

					const showGroupedErrorToast = function (groupedErrors) {
						for (const key in groupedErrors) {
							const additionalDataStr = groupedErrors[key];
							showToastError(key, additionalDataStr.join(', '));
						}
					};

					const showNotGroupedErrorToast = function (notGroupedErrors) {
						for (const key in notGroupedErrors) {
							showToastError(key, notGroupedErrors[key]);
						}
					};

					if (options.isManyDelete) {
						//сообщение о количестве удаленных сотрудников
						showSuccessToast(
							null,
							edi.i18n.getMessage(options.countSuccessToastTitle, [
								readyElements.length,
								options.ids.length
							])
						);

						if (!edi.utils.isEmptyObject(errorInfoElements.notGroupedErrors)) {
							showNotGroupedErrorToast(errorInfoElements.notGroupedErrors);
						}

						if (!edi.utils.isEmptyObject(errorInfoElements.groupedErrors)) {
							showGroupedErrorToast(errorInfoElements.groupedErrors);
						}
					}
					if ('function' == typeof options.successCallback) {
						options.successCallback();
					}
				});
			};

			if (options.confirmAction && options.isManyDelete) {
				if ('function' == typeof handlerAction) {
					const namesStr = options.createList ? options.createList() : null;
					const exceptionNamesStr = options.exceptionCreateList ? options.exceptionCreateList() : null;

					showConfirmWithException({
						title: options.confirmTitle,
						msgText: edi.i18n.getMessage(options.confirmQuestion, [namesStr]),
						exceptionText: !!exceptionNamesStr
							? edi.i18n.getMessage(options.confirmExceptionsTitle, [exceptionNamesStr])
							: '',
						success: function () {
							handlerAction();
						}
					});
				}
			} else {
				if ('function' == typeof handlerAction) {
					handlerAction();
				}
			}
		}
	},
	advertising_tape: {
		getContent: function (id, contentType, callback) {
			var failure = function (data) {
				// не показываем стандартную модалку с ошибкой чтобы не блокировать отображение баннеров
				'function' == typeof callback ? callback('') : null;
			};

			if (id && contentType) {
				Ext.Ajax.request(
					{
						binary: true,
						headers: {
							'Content-Type': contentType
						},
						url: edi.utils.formatString(edi.rest.services.ADVERTISING_TAPE.CONTENT.GET, {
							id: id
						}),
						success: function (response) {
							function u_btoa(buffer) {
								var binary = [];
								for (var i = 0, il = buffer.byteLength; i < il; i++) {
									binary.push(String.fromCharCode(buffer[i]));
								}
								return window.btoa(binary.join(''));
							}

							'function' == typeof callback
								? callback('data:' + contentType + ';base64,' + u_btoa(response.responseBytes))
								: null;
						}
					},
					failure
				);
			}
		}
	},
	document_packages: {
		/**
		 * Display modal list of documents to add to the package
		 * @param    {Number}    toOrg        id organisation is toOrg in package
		 * @param    {Number}    packageId    id of package
		 * @param    {Function}  callback     callback function
		 * @param    {Number}    docId        id of document to add in package
		 */
		addDocuments: function (toOrg, packageId, callback, docId) {
			var selectedToOrg;
			var extraParams = {
				packageId: 'null'
			};
			if (toOrg) {
				extraParams.toOrg = toOrg;
			}
			let { formItemsMap, items } = edi.filters.config.document_packages.createFormItems();
			var modal = createModalRemoteSelect(
				edi.rest.services.DOCUMENTS.GET,
				function (records) {
					var docIds = [];
					if (Array.isArray(records)) {
						for (var i = 0; i < records.length; i++) {
							docIds.push(records[i].id);
						}
						if (docId) {
							docIds.push(docId);
						}

						if (docIds.length) {
							edi.rest.sendRequest(
								packageId
									? edi.utils.formatString(edi.rest.services.DOCUMENT_PACKAGES.ADD_DOCUMENTS.PUT, {
											packageId: packageId
									  })
									: edi.rest.services.DOCUMENT_PACKAGES.POST,
								packageId ? 'PUT' : 'POST',
								Ext.encode(docIds),
								callback,
								edi.rest.getErrorHandler()
							);
						}
					}
				},
				{
					title: 'documents.package.add.documents',
					width: MODAL_SIZE.widthLarge,
					hideHeaderCheckbox: !packageId,
					checkboxes: true,
					groupOperation: true,
					model: 'DOCUMENT',
					columns: 'documents_all',
					storeConfig: {
						autoLoad: true,
						filters: docId
							? [
									function (item) {
										return item.get('id') != docId;
									}
							  ]
							: undefined
					},
					proxyConfig: {
						extraParams: extraParams
					},
					createFormItemsMap: formItemsMap,
					createFilterFormItems: items,
					createArgs: function (values) {
						if (!toOrg && selectedToOrg) {
							values.toOrg = selectedToOrg;
						}

						return values;
					}
				},
				function (selected, filterForm) {
					if (!toOrg) {
						if (selected.length) {
							selectedToOrg = selected[0].getData().toOrg.id;
						} else {
							selectedToOrg = null;
						}
						if (selected.length <= 1) {
							filterForm.fireSearch();
						}
					}
				}
			);
			modal.show();
		},
		/**
		 * Display confirm modal to delete document from package
		 * @param    {Number}    docId       id of document
		 * @param    {Function}  callback    callback function
		 * @param    {Function}  failure     failure function
		 */
		removeDocument: function (docId, callback, failure) {
			edi.core.confirm(null, 'documents.package.delete.document.confirm', function () {
				failure =
					'function' == typeof failure
						? failure
						: edi.rest.getErrorHandler('document.error.in.package.delete');
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENT_PACKAGES.DELETE_DOCUMENT.PUT, {
						docId: docId
					}),
					'PUT',
					null,
					callback,
					failure
				);
			});
		}
	},
	beforeLoadModules: {
		showAgreementPersonalData: function (callback) {
			//для проверки работы просто раскоментируй строчку ниже
			//edi.core.setExtraData('user.personalDataConsent', false);

			if (edi.core.getExtraData('user.personalDataConsent') === 'true') {
				callback();
				return;
			}

			const showWindow = function (htmlContent) {
				const htmlContainer = createContainer({
					html: htmlContent
				});

				const confirmBtn = createButton({
					text: edi.i18n.getMessage('agreement_personal_data.confirm'),
					cls: BUTTON_CLS.primary,
					handler: function () {
						edi.core.setExtraData('user.personalDataConsent', true, function () {
							callback();
							modal.close();
						});
					}
				});

				const modalForm = createModalForm({
					bodyPadding: 24,
					scrollable: true,
					items: [
						htmlContainer,
						createContainer({
							layout: 'hbox',
							items: [confirmBtn]
						})
					]
				});

				const modal = createModalPanel(
					{
						header: false,
						closable: false,
						draggable: false,
						width: MODAL_SIZE.widthLarge,
						verticalMargin: 80,
						layout: 'fit',
						items: [modalForm]
					},
					true
				);
				modal.show();
			};

			const fail = edi.rest.getErrorHandler('ediweb.personal.consent.error', function () {
				window.location.reload();
			});

			const success = function (resp) {
				if (resp.data?.templateText) {
					showWindow(resp.data?.templateText);
				} else {
					fail(resp);
				}
			};

			const url = edi.utils.compileURL(edi.rest.services.PERSONAL_DATA_CONSENT.GET, {
				language: 'ru',
				messageType: 'LOGIN_CONSENT_MESSAGE'
			});
			edi.rest.sendRequest(url, 'GET', null, success, fail);
		},
		introModal: function (callback) {
			callback();

			// Для очистки ExtraData
			// edi.core.setExtraData("user.introModal", false);

			if (edi.core.getExtraData('user.introModal') !== 'true') {
				let modal,
					imgComp,
					msgComp,
					steps,
					text,
					buttons,
					isSetMinHeight = false;

				let dataPages = new Promise((resolve, reject) => {
					edi.rest.sendRequest(edi.rest.services.INTRO_PAGES.GET, 'GET', undefined, resolve, reject);
				});

				let updateData = (items) => {
					let stepCount = items.length;

					let nextStep = function (step = 0) {
						let item = items[step];

						steps.update(
							items.map((i) => {
								return {
									flag: item.id == i.id
								};
							})
						);

						text.update({
							text: item.text
						});
						if (step === 0) {
							text.addCls('edi-ediweb-intro-text-tpl-first');
						} else {
							text.removeCls('edi-ediweb-intro-text-tpl-first');
						}

						imgComp.update({
							url: item.image_url
						});
						const modalHeight = modal.getHeight();
						const panelMinHeight = item?.minHeight ?? 0;
						if (modalHeight < panelMinHeight || isSetMinHeight) {
							isSetMinHeight = true;
							imgComp.setMinHeight(panelMinHeight);
							msgComp.setMinHeight(panelMinHeight);
							modal.updateLayout();
						}

						buttons.removeAll();
						if (step !== 0) {
							buttons.add(
								createButton({
									text: edi.i18n.getMessage('ediweb.back.btn'),
									margin: '0 32 0 0',
									cls: BUTTON_CLS.secondary,
									glyph: edi.constants.ICONS.ARROW_BACK,
									handler: function () {
										nextStep(step - 1);
									}
								})
							);
						}
						buttons.add(
							createButton({
								cls: BUTTON_CLS.primary,
								text: edi.i18n.getMessage(
									step === stepCount - 1 ? 'ediweb.start.btn' : 'ediweb.further.btn'
								),
								handler: function () {
									if (step !== stepCount - 1) {
										nextStep(step + 1);
									} else {
										edi.core.setExtraData('user.introModal', true, function () {
											var userData = edi.core.getUserData();
											var success = function () {
												edi.events.employees.fireEvent('change');
												modal.destroy();
											};
											var failure = function (data) {
												edi.core.showError(
													edi.utils.formatComplexServerError(data, 'error.server')
												);
												modal.destroy();
											};
											edi.rest.sendRequest(
												edi.utils.formatString(
													edi.rest.services.ORG_PROFILE.EMPLOYEES.CONFIRM.PUT,
													{
														id: userData.id
													}
												),
												'PUT',
												Ext.encode({}),
												success,
												failure
											);
										});
									}
								}
							})
						);
					};

					nextStep();
				};

				dataPages.then((data) => {
					if (data && data.items && data.items.length) {
						updateData(data.items);
					}
					modal.setLoading(false);
				});

				steps = Ext.create('Ext.view.View', {
					cls: 'edi-ediweb-intro-steps',
					region: 'north',
					itemSelector: 'div',
					tpl: Ext.create(
						'Ext.XTemplate',
						'<tpl for=".">',
						'<div class="{[this.getClass(values)]}"></div>',
						'</tpl>',
						{
							getClass: function (val) {
								return val.flag ? `edi-ediweb-intro-steps-active` : '';
							}
						}
					)
				});

				text = createContainer({
					cls: 'edi-ediweb-intro-text',
					region: 'center',
					tpl: Ext.create(
						'Ext.XTemplate',
						"<div class='edi-ediweb-intro-text-tpl-container'>",
						'{text}',
						'</div>'
					)
				});
				buttons = createContainer({
					cls: 'edi-ediweb-intro-button',
					region: 'south',
					layout: {
						type: 'hbox',
						pack: 'end'
					}
				});

				modal = createModalPanel(
					{
						cls: 'edi-ediweb-intro',
						header: false,
						layout: {
							type: 'hbox',
							align: 'stretch'
						},
						resizable: false,
						width: MODAL_SIZE.widthLarge,
						height: 480,
						padding: 0,
						bodyPadding: 0,
						listeners: {
							destroy: function () {
								edi.constants.INTRO_MODAL_IS_VISIBLE = false;
							},
							close: function () {
								edi.constants.INTRO_MODAL_IS_VISIBLE = false;
							}
						},
						items: [
							(imgComp = createContainer({
								cls: 'edi-ediweb-intro-left',
								width: 400,
								height: '100%',
								tpl: Ext.create(
									'Ext.XTemplate',
									"<div class='edi-ediweb-intro-img'>",
									"<img src='{url}' />",
									'</div>'
								)
							})),
							(msgComp = createContainer({
								cls: 'edi-ediweb-intro-right',
								flex: 1,
								padding: 40,
								height: '100%',
								layout: 'border',
								items: [steps, text, buttons]
							}))
						]
					},
					true
				);

				modal.show();
				edi.constants.INTRO_MODAL_IS_VISIBLE = true;
				modal.setLoading({
					msg: null
				});
			}
		}
	},
	tariffs: {
		getActualTariffs: function () {
			return new Promise((resolve, reject) => {
				edi.rest.sendRequest(edi.rest.services.TARIFFS.ACTUAL.GET, 'GET', undefined, resolve, reject);
			});
		},
		getActualPoaTariffs: function () {
			return new Promise((resolve, reject) => {
				edi.rest.sendRequest(edi.rest.services.TARIFFS.ACTUAL.POA.GET, 'GET', undefined, resolve, reject);
			});
		},

		isCanCreateDocHandler: function () {
			let toastOptions = {
				showButton: true,
				buttonText: 'form.btn.select',
				buttonHandler: function () {
					edi.core.openModule(
						'org.profile',
						undefined,
						undefined,
						undefined,
						undefined,
						undefined,
						function () {
							edi.events.org_profile.fireEvent('activatetab', 'tariffs');
						}
					);
				}
			};

			let title = 'ediweb.tariffs.completed.package.documents.toast.title';
			let content = 'ediweb.tariffs.completed.package.documents.toast.content';
			if (edi.constants.IS_EXPIRED_DOCUMENT_TARIFF) {
				title = 'ediweb.tariffs.expired.package.documents.toast.title';
				content = 'ediweb.tariffs.expired.package.documents.toast.content';
			}
			showWarningToast(edi.i18n.getMessage(title), edi.i18n.getMessage(content), toastOptions);
		},
		isCanCreatePoaHandler: function () {
			let toastOptions = {
				showButton: true,
				buttonText: 'form.btn.select',
				buttonHandler: function () {
					edi.core.openModule(
						'org.profile',
						undefined,
						undefined,
						undefined,
						undefined,
						undefined,
						function () {
							edi.events.org_profile.fireEvent('activatetab', 'tariffs');
						}
					);
				}
			};
			let title = 'ediweb.tariffs.completed.package.poa.toast.title';
			let content = 'ediweb.tariffs.completed.package.poa.toast.content';
			if (edi.constants.IS_EXPIRED_POA_TARIFF) {
				title = 'ediweb.tariffs.expired.package.poa.toast.title';
				content = 'ediweb.tariffs.expired.package.poa.toast.content';
			}
			showWarningToast(edi.i18n.getMessage(title), edi.i18n.getMessage(content), toastOptions);
		},

		checkIsCanCreateDoc: function () {
			let failure = function (data) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
			};
			let userData = edi.core.getUserData();
			let withoutTariff = edi.utils.getAttributeByName(userData?.org?.attributes, 'WITHOUT_TARIFF') === 'true';
			if (withoutTariff) {
				edi.constants.IS_CAN_CREATE_DOCUMENT = true;
				edi.constants.IS_EXPIRED_DOCUMENT_TARIFF = false;
				return;
			}
			edi.methods.tariffs
				.getActualTariffs()
				.then((responseData) => {
					if (responseData) {
						if (responseData.items) {
							const isFuture = function (data) {
								return new Date(data?.duration?.periodFrom) > new Date();
							};
							if (responseData.items.length > 1) {
								edi.constants.IS_CAN_CREATE_DOCUMENT = !isFuture(responseData.items[0]);
								edi.constants.IS_EXPIRED_DOCUMENT_TARIFF = false;
							} else {
								edi.constants.IS_CAN_CREATE_DOCUMENT = false;
								edi.constants.IS_EXPIRED_DOCUMENT_TARIFF = false;
								if (responseData.items.length === 1) {
									let tariffData = responseData.items[0];
									let tariff = tariffData.tariff;
									edi.constants.IS_CAN_CREATE_DOCUMENT =
										(tariffData.remainDoc > 0 || tariff.unlimited) && !isFuture(tariffData);
									edi.constants.IS_EXPIRED_DOCUMENT_TARIFF =
										(tariffData.remainDoc === 0 && !tariff.unlimited) ||
										tariff.expired ||
										tariffData.wasted;
								}
							}
						}
					}
				})
				.catch((error) => {
					failure(error);
				});
		},
		checkIsCanCreatePoa: function () {
			let failure = function (data) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
			};
			let userData = edi.core.getUserData();
			let withoutTariff = edi.utils.getAttributeByName(userData?.org?.attributes, 'WITHOUT_TARIFF') === 'true';
			if (withoutTariff) {
				edi.constants.IS_CAN_CREATE_POA = true;
				edi.constants.IS_EXPIRED_POA_TARIFF = false;
				return;
			}
			edi.methods.tariffs
				.getActualPoaTariffs()
				.then((responseData) => {
					if (responseData) {
						if (responseData.items) {
							const isFuture = function (data) {
								return new Date(data?.duration?.periodFrom) > new Date();
							};
							if (responseData.items.length > 1) {
								edi.constants.IS_CAN_CREATE_POA = !isFuture(responseData.items[0]);
								edi.constants.IS_EXPIRED_POA_TARIFF = false;
							} else {
								edi.constants.IS_CAN_CREATE_POA = false;
								edi.constants.IS_EXPIRED_POA_TARIFF = false;
								if (responseData.items.length === 1) {
									let tariffData = responseData.items[0];
									let tariff = tariffData.tariff;
									edi.constants.IS_CAN_CREATE_POA =
										(tariffData.remainDoc > 0 || tariff.unlimited) && !isFuture(tariffData);
									edi.constants.IS_EXPIRED_POA_TARIFF =
										(tariffData.remainDoc === 0 && !tariff.unlimited) ||
										tariff.expired ||
										tariffData.wasted;
								}
							}
						}
					}
				})
				.catch((error) => {
					failure(error);
				});
		}
	},
	application_to_fns: {
		getApplicationToFNSModal: function (
			certificateHandler,
			application,
			callback,
			poa,
			isFirstAddCertificate,
			isSilentSign
		) {
			let poaHandler = {
				_selectedPoa: poa,
				get: function () {
					return this._selectedPoa;
				},
				set: function (poa) {
					this._selectedPoa = poa;
				},
				_poaConfirmChecked: false,
				setPoaConfirmCheck: function (poaConfirmChecked) {
					const handler = this;
					handler._poaConfirmChecked = poaConfirmChecked;
				},
				getPoaConfirmCheck: function () {
					const handler = this;
					return handler._poaConfirmChecked;
				}
			};

			certificateHandler = certificateHandler || {
				_selectedCertificate: null,
				get: function () {
					return this._selectedCertificate;
				},
				set: function (cert) {
					this._selectedCertificate = cert;
				}
			};
			const createCertDetails = function () {
				const subjectName = edi.utils.fullStringCertParse(certificateHandler.get()?.SubjectName);
				let inn = subjectName.innle ? subjectName.innle : subjectName.inn;
				const locationItems = [];
				subjectName.location && locationItems.push(subjectName.location);
				subjectName.street && locationItems.push(subjectName.street);
				const location = application?.address || locationItems.join(', ');

				const headData = createFieldBlock({
					cls: 'edi-ediweb-details-block edi-ediweb-details-block-certificate-application-modal',
					title: edi.i18n.getMessage('ediweb.application_to_fns.modal.org.details'),
					layout: {
						type: 'grid',
						gap: 8
					},
					items: [
						createDetailLabel({
							contentTextArray: [
								{
									title: edi.i18n.getMessage('company.name'),
									content: subjectName.organization ?? subjectName.commonName
								}
							]
						}),
						subjectName.kpp
							? createDetailLabel({
									contentTextArray: [
										{
											title: edi.i18n.getMessage('ediweb.application_to_fns.modal.inn.kpp'),
											content: inn + '-' + subjectName.kpp
										}
									]
							  })
							: createDetailLabel({
									contentTextArray: [
										{
											title: edi.i18n.getMessage('company.inn.short'),
											content: inn
										}
									]
							  }),
						subjectName.ogrn || subjectName.ogrnip
							? createDetailLabel({
									contentTextArray: [
										{
											title: edi.i18n.getMessage('company.ogrn.short'),
											content: subjectName.ogrn ?? subjectName.ogrnip
										}
									]
							  })
							: null,
						location
							? createDetailLabel({
									contentTextArray: [
										{
											title: edi.i18n.getMessage('company.address.header'),
											content: location
										}
									]
							  })
							: null
					]
				});

				const grid = createGrid({
					store: edi.stores.createMemoryStore([certificateHandler.get()], USER_CERTIFICATES_MODEL),
					gridConfig: {
						title: edi.i18n.getMessage('ediweb.application_to_fns.modal.grid.title'),
						columns: edi.columns.get(MODAL_CERTIFICATE_COLUMN),
						padding: 0,
						border: 0,
						disablePaging: true
					}
				});

				return createFormContainer({
					gap: 32,
					items: [headData, grid]
				});
			};

			let modal,
				modalItems = [],
				buttons = [],
				errorData;

			const closeAll = function () {
				if (!modal.isDestroyed) {
					modal.close();

					if (typeof callback === 'function') {
						certificateHandler.get().readyToUse = false;
						callback(true, errorData, certificateHandler.get(), poaHandler.get(), true);
					}
				}
			};

			let failure = edi.document.actions.defaultFailureHandler(undefined, 'error.server', closeAll);

			let failureModal = function () {
				let confirm = showConfirmWithException({
					title: edi.i18n.getMessage('ediweb.failure.sign.certificate.title'),
					msgText: edi.i18n.getMessage('ediweb.failure.sign.certificate.text'),
					exceptionText: edi.i18n.getMessage('ediweb.failure.sign.certificate.exceptions'),
					callback: closeAll,
					buttons: [
						createButton({
							cls: BUTTON_CLS.primary,
							glyph: edi.constants.ICONS.DONE,
							text: edi.i18n.getMessage('button.ok'),
							handler: function () {
								confirm.close();
							}
						})
					]
				});

				return confirm;
			};

			let signCert = function (modal) {
				edi.utils.sign(
					application?.ediDocument?.id,
					modal,
					function (failed, data, certificate, poa) {
						if (failed) {
							errorData = data;
							if (data.typeError === 'certificate.data.not.matched.with.organization') {
								failureModal();
							} else {
								failure(data, null, () => {
									certificateHandler.get().readyToUse = false;
									callback(true, data, certificateHandler.get(), poaHandler.get());
								});
							}
						} else {
							modal?.close();
							edi.events.certificates.fireEvent('change');

							// проверка на !isFirstAddCertificate нужна для того чтобы при первой загрузке серта
							//второй раз не вызывался рест certificatesAPI.WITH_CHECK.POST
							if (typeof callback === 'function' && !isFirstAddCertificate) {
								certificateHandler.get().readyToUse = true;
								callback(false, data, certificateHandler.get(), poaHandler.get());
							}
						}
					},
					undefined,
					undefined,
					undefined,
					certificateHandler,
					{
						signContentUrlMethod: 'GET',
						force: true,
						disabledPoa: true,
						signRefuse: function () {
							failureModal();
						}
					},
					poaHandler
				);
			};
			if (!isSilentSign) {
				let previous = edi.utils.getAttributeByName(application?.ediDocument?.attributes, 'PREVIOUS');
				let type = !previous
					? edi.constants.DOCUMENT_TYPES.EDI_APPLICATION_TO_FNS
					: edi.constants.DOCUMENT_TYPES.EDI_APPLICATION_TO_FNS_CHANGE;
				let subTitle = createLabel({
					typography: 'heading_02',
					text: edi.i18n.getMessage('ediweb.application_to_fns.modal.subtitle.' + type),
					plugins: [
						createInfoElementPlugin({
							hintConfig: {
								html: edi.i18n.getMessage('ediweb.application_to_fns.modal.info.tooltip')
							},
							refEl: 'textEl'
						})
					]
				});

				let subText = createLabel({
					typography: 'body-short_03',
					text: edi.i18n.getMessage('ediweb.application_to_fns.modal.text.' + type)
				});

				modalItems.push(subTitle);
				modalItems.push(subText);
				modalItems.push(createCertDetails());

				const cancelHandler = function () {
					modal.close();
					if (typeof callback === 'function') {
						certificateHandler.get().readyToUse = false;
						callback(true, errorData, certificateHandler.get(), poaHandler.get(), true);
					}
				};

				buttons.push(
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('document.sign.document'),
						glyph: edi.constants.ICONS.SIGN,
						handler: function () {
							edi.core.confirm(null, 'application.to.fns.sign.confirmation', function () {
								if (edi.sign.isAvailable()) {
									edi.sign.getCertificates({
										callback: function (data) {
											var cert;
											if (data.data) {
												cert = edi.utils.findObjectInArray(
													data.data,
													'Thumbprint',
													certificateHandler.get()?.Thumbprint
												);
											}
											if (cert) {
												signCert(modal);
												if (!poaHandler.get()) {
													poaHandler.set(certificateHandler.get()?.poaData?.header);
												}
											} else {
												edi.utils.async(
													edi.core.showError('user.profile.certificate.not.found')
												);
											}
										}
									});
								} else {
									edi.sign.displayNotAvailableMessage();
								}
							});
						}
					})
				);
				buttons.push(
					createButton({
						text: edi.i18n.getMessage('ediweb.cancel.btn'),
						cls: BUTTON_CLS.secondary,
						handler: cancelHandler
					})
				);

				modal = createModalPanel({
					cls: MODAL_CLS.withShadows,
					title: edi.i18n.getMessage('ediweb.application_to_fns.modal.title'),
					width: MODAL_SIZE.widthLarge,
					items: createModalForm({
						bodyPadding: '32 24',
						layout: {
							type: 'grid',
							gap: 32
						},
						items: modalItems
					}),
					buttons: buttons
				});
				modal.show();

				modal.header.tools.close.handler = cancelHandler;
			} else {
				signCert();
			}
		},

		signApplicationAndCert: function (certificateHandler, isShowDuplicateModal = false, callback, options) {
			options = options || {};
			var signContent = new Date().getTime();
			certificateHandler = certificateHandler || {
				_selectedCertificate: null,
				get: function () {
					return this._selectedCertificate;
				},
				set: function (cert) {
					//если мы по какой-то причине не подписали, и окно остается открытым, то и серт не надо сбрасывать
					if (cert) {
						this._selectedCertificate = cert;
					}
				}
			};
			let poaHandler = options.poaHandler || {
				_selectedPoa: options.selectedPoa,
				get: function () {
					return this._selectedPoa;
				},
				set: function (poa) {
					this._selectedPoa = poa;
				},
				_poaConfirmChecked: false,
				setPoaConfirmCheck: function (poaConfirmChecked) {
					const handler = this;
					handler._poaConfirmChecked = poaConfirmChecked;
				},
				getPoaConfirmCheck: function () {
					const handler = this;
					return handler._poaConfirmChecked;
				}
			};

			let signUrl = options.loadFromUserProfile ? certificatesAPI.POST : certificatesAPI.WITH_CHECK.POST;
			edi.utils.sign(
				undefined,
				options.maskEl,
				function (failed, data, selectedCertificate, poa, silent) {
					let failureModal = function () {
						const closeAll = function () {
							selectedCertificate.readyToUse = false;
							if (
								!(
									options.loadFromUserProfile &&
									data.typeError === 'certificate.data.not.matched.with.organization'
								)
							) {
								callback(failed, data, selectedCertificate, poa, silent);
							}
						};
						let confirm = showConfirmWithException({
							title: edi.i18n.getMessage('ediweb.failure.sign.certificate.title'),
							msgText: edi.i18n.getMessage('ediweb.failure.sign.certificate.text'),
							exceptionText: edi.i18n.getMessage('ediweb.failure.sign.certificate.exceptions'),
							callback: closeAll,
							buttons: [
								createButton({
									cls: BUTTON_CLS.primary,
									glyph: edi.constants.ICONS.DONE,
									text: edi.i18n.getMessage('button.ok'),
									handler: function () {
										confirm.close();
										closeAll();
									}
								})
							]
						});

						return confirm;
					};
					if (failed) {
						if (data?.typeError === 'exist.active.certificate') {
							selectedCertificate.state = data.additionalData[0];
							var modalConfirm = createModalPanel({
								title: edi.i18n.getMessage('documents.flow.rule.modal.title'),
								width: MODAL_SIZE.widthMedium,
								bodyPadding: '0 24',
								layout: {
									type: 'vbox',
									align: 'stretch'
								},
								items: [
									createLabel({
										padding: '0 0 16 0',
										cls: 'body-short_01',
										text: edi.i18n.getMessage('user.profile.certificate.activate.confirmation')
									}),
									createGrid({
										store: edi.stores.createMemoryStore(
											[selectedCertificate],
											DUPLICATE_CERTIFICATES_MODEL
										),
										gridConfig: {
											columns: edi.columns.get(DUPLICATE_MODAL_CERTIFICATE_COLUMN),
											border: 0,
											disablePaging: true
										}
									})
								],
								buttons: [
									createButton({
										text: edi.i18n.getMessage('btn.understand'),
										glyph: edi.constants.ICONS.DONE,
										cls: BUTTON_CLS.primary,
										handler: function () {
											modalConfirm.close();
										}
									})
								]
							});

							modalConfirm.show();
							return;
						}
						if (data?.typeError === 'certificate.data.not.matched.with.organization') {
							failureModal();
						} else {
							selectedCertificate.readyToUse = false;
							callback(failed, data, selectedCertificate, poa, silent);
						}
					} else {
						if (data?.data?.ediDocument?.state === edi.constants.STATE.COMPLETED) {
							selectedCertificate.readyToUse = true;
							if (options.loadFromUserProfile) {
								edi.events.certificates.fireEvent('change');
							} else {
								callback(false, data, selectedCertificate, poa ?? data.data?.poaId);
							}
						} else {
							selectedCertificate.readyToUse = false;
							edi.methods.application_to_fns.getApplicationToFNSModal(
								certificateHandler,
								data?.data || {},
								callback,
								poa ?? data?.data?.poaId,
								isShowDuplicateModal,
								options?.isSilentSign
							);
						}
					}
				},
				undefined,
				options.beforeSign,
				options.skipMaskHandling,
				certificateHandler,
				{
					signUrl: signUrl,
					signContent: edi.utils.base64.encode(signContent),
					signObjectProcessor: function (signature) {
						return {
							sign: signature,
							content: signContent,
							availableDocTypesForSignature: ['ALL']
						};
					},
					signRefuse: options.signRefuse,
					signUrlMethod: 'POST',
					disabledPoa: options.loadFromUserProfile || options.disabledPoa,
					doNotUseDefaultCert: true,
					isShowDuplicateModal: isShowDuplicateModal,
					loadFromUserProfile: options.loadFromUserProfile
				},
				poaHandler
			);
		}
	}
});

const getDCRoleDependence = function (values) {
	let ids = [];
	if (values.includes('consignor') && values.includes('carrier')) {
		ids.push('forwarder');
	}

	if (values.includes('consignor')) {
		ids.push('person_from');
	}

	if (values.includes('forwarder')) {
		ids.push('consignor');
		ids.push('carrier');
		ids.push('person_from');
		ids.push('medical_org');
	}

	if (values.includes('person_from')) {
		ids.push('consignor');
		ids.push('forwarder');
	}

	if (values.includes('carrier')) {
		ids.push('medical_org');
	}

	if (values.includes('medical_org')) {
		ids.push('forwarder');
		ids.push('carrier');
	}
	return ids;
};

edi.constants.AGREEMENT_PERSONAL_DATA_TEXT = 'ediweb.agreement_personal_data.text_ediweb';

export { getDCRoleDependence };
