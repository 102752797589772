Ext.namespace('edi.modulesCfg');
new (function () {
	const path = 'ediweb/modules';

	Object.assign(edi.modulesCfg, {
		sandbox: {
			title: 'SANDBOX',
			modName: 'sandbox',
			folder: path,
			//isMain: true,
			permissions: ['READ_DOCUMENTS_REST_SERVICES']
		},
		main: {
			title: 'navigation.main',
			modName: 'main',
			folder: path,
			isMain: true,
			iconCls: 'edi-ediweb-icon-home',
			permissions: ['READ_DOCUMENTS_REST_SERVICES']
		},
		organization: {
			title: 'navigation.organization',
			modName: 'organization',
			folder: path,
			permissions: ['READ_DOCUMENTS_REST_SERVICES']
		},
		control_center: {
			title: 'control.center',
			modName: 'control.center.module.panel',
			folder: path + '/CONTROL_CENTER',
			permissions: ['READ_DOCUMENTS_REST_SERVICES']
		},
		'org.profile': {
			title: 'org.profile',
			modName: 'org.profile',
			folder: path,
			permissions: {
				OR: ['CLIENT_SUBDIVISIONS_READ', 'CLIENT_EMPLOYEES_READ', 'CLIENT_ROUTES_READ']
			}
		},
		'approval.documents': {
			title: 'approval.documents',
			modName: 'approval.documents',
			folder: path,
			permissions: ['CLIENT_APPROVAL_DOCUMENTS_READ']
		},
		'approval.documents.create': {
			title: 'approval.documents.create',
			modName: 'approval.documents.create',
			folder: path,
			permissions: ['CLIENT_APPROVAL_DOCUMENTS_CREATE'],
			permissionsEdit: ['CLIENT_APPROVAL_DOCUMENTS_READ']
		},
		'approval.documents.details': {
			title: 'approval.documents.details',
			modName: 'approval.documents.details',
			folder: path,
			permissions: ['CLIENT_APPROVAL_DOCUMENTS_READ']
		},
		'approval.employees.create': {
			title: 'approval.employees.create',
			modName: 'approval.employees.create',
			folder: path,
			permissions: ['CLIENT_APPROVAL_DOCUMENTS_CREATE'],
			permissionsEdit: ['CLIENT_APPROVAL_DOCUMENTS_READ']
		},
		'approval.employees.details': {
			title: 'approval.employees.details',
			modName: 'approval.employees.details',
			folder: path,
			permissions: ['CLIENT_APPROVAL_DOCUMENTS_READ']
		},
		'acceptance.details': {
			title: 'acceptance.details',
			modName: 'acceptance.details',
			folder: path,
			permissions: ['READ_REGISTRATION_ACCEPTANCE']
		},
		invitations: {
			title: 'navigation.ediweb.invitations',
			modName: 'invitations',
			folder: path + '/INVITATIONS',
			permissions: ['CLIENT_INVITATION_READ']
		},
		'invitations.person': {
			title: 'ediweb.invitations.person',
			modName: 'invitations.person',
			folder: path + '/INVITATIONS',
			highlightMenuId: 'invitations',
			permissions: ['CLIENT_INVITATION_CREATE'],
			permissionsEdit: ['CLIENT_INVITATION_READ']
		},
		'invitations.create.mass.person': {
			title: 'ediweb.invitations.mass.person',
			modName: 'invitations.create.mass.person',
			highlightMenuId: 'invitations',
			folder: path + '/MASS_INVITATIONS',
			permissions: ['CLIENT_MASS_INVITATION_CREATE'],
			permissionsEdit: ['CLIENT_MASS_INVITATION_EDIT']
		},
		'invitations.details.mass.person': {
			title: 'ediweb.invitations.details.mass.person',
			highlightMenuId: 'invitations',
			modName: 'invitations.details.mass.person',
			folder: path + '/MASS_INVITATIONS',
			permissions: ['CLIENT_MASS_INVITATION_EDIT']
		},
		documents: {
			title: 'navigation.documents',
			modName: 'documents',
			folder: path,
			permissions: ['READ_DOCUMENTS_REST_SERVICES']
		},
		settings: {
			title: 'navigation.settings',
			modName: 'settings',
			folder: path,
			permissions: ['READ_DOCUMENTS_REST_SERVICES']
		}
	});
})();
