import { createProxyConfig } from '@Components/storeComponents';
import { createModulePanel, createPanel, createTab, createTabPanel, TAB_PANEL_CLS } from '@Components/panels';
import { createCombo, createLabel, createSearchTypeSelectionFieldForGrid } from '@Components/fields';
import { createContainer } from '@Components/miscComponents';
import {
	createActionsColumnConfig,
	createGrid,
	createGridActionBar,
	createGridCheckboxSelectionModel
} from '@Components/grid';
import { createAutocomplete } from '@Core/specialComponents/fields';
import { createDocumentTypeFilterSelectForGrid } from '@Edi/specialComponents/documentTypeFilterSelect';
import { createModuleFilterForm } from '@Components/moduleFilterForm';
import { createDateRangeField } from '@UIkit/components/fields/Date/DateRangeNew';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { showMessageBox } from '@UIkit/components/modal/MessageBox';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { POA_LIST_COLUMNS } from '@Edi/modules/power_of_attorney/columns';
import { POA_LIST_MODEL } from '@Edi/modules/power_of_attorney/models';
import { createToolBar } from '@UIkit/components/grid';
import { exportGroupHandler } from '@Edi/modules/power_of_attorney/methods';
import { createPoAFilterArgs, createPoAFormItems } from '@Edi/modules/power_of_attorney/filters';
import { filterMethods } from './filter';
import { GRID_ARCHIVE_COLUMN_CONFIG_NAME } from './columns';

export class ArchiveDocumentsGridModule {
	moduleData;

	constructor() {
		const me = this;
		me.aperakTypes = edi.constants.MODULES.APERAK_DOCUMENT_TYPES;
		me.includedTypes = edi.constants.MODULES.DOCUMENTS.INCLUDED_TYPES;
		me.includedStates = edi.constants.MODULES.DOCUMENTS.INCLUDED_STATES;
	}

	/**
	 * Module initialization
	 * @param {Object} data                Data from modules handler
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	init(data, initCallBack) {
		const me = this;
		me.moduleData = data;
		me.renderData(initCallBack);
		return me.onDestroy.bind(this);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {}

	createDocumentTab() {
		const me = this;
		const url = edi.utils.compileURL(edi.rest.services.DOCUMENTS.GET, {
			gridName: 'archived'
		});

		/**
		 * Creates filter form
		 * @returns {Object}
		 */
		const createFilterForm = function () {
			let filterObject;
			const fireSearch = function () {
				if (!filterObject) {
					filterObject = edi.filters.createGridFilter(
						url,
						filterForm,
						grid,
						filterMethods.createArgsCommon,
						{
							gridName: 'archived'
						},
						{
							persistence: {
								enabled: true,
								name: `${me.moduleData.modName}_archive_grid`
							}
						}
					);
				}
				filterObject.filter();
			};

			let docTypes = [],
				docStates = [],
				docDateRange,
				modifyDateRange,
				creationDateRange,
				bpNamesUPD = Array.isArray(edi.constants.UPD_BP_NAMES_FOR_FILTER)
					? edi.constants.UPD_BP_NAMES_FOR_FILTER
					: [],
				bpNamesUKD = Array.isArray(edi.constants.UKD_BP_NAMES_FOR_FILTER)
					? edi.constants.UKD_BP_NAMES_FOR_FILTER
					: [];

			Object.values(edi.constants.DOCUMENT_TYPES).forEach(function (value) {
				if (me.includedTypes.some((it) => it === value)) {
					docTypes.push({
						id: value,
						name: edi.i18n.getMessage('documents.doctype.' + value)
					});

					if (value === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD && bpNamesUPD.length) {
						//Add all UPD BP names as separate options
						docTypes = docTypes.concat(
							bpNamesUPD.map(function (bpName) {
								return {
									id: bpName,
									name: edi.i18n.getMessage('documents.doctype.EDI_FNS_UPD.' + bpName)
								};
							})
						);
					} else if (value === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD && bpNamesUKD.length) {
						//Add all UKD BP names as separate options
						docTypes = docTypes.concat(
							bpNamesUKD.map(function (bpName) {
								return {
									id: bpName,
									name: edi.i18n.getMessage('documents.doctype.EDI_FNS_UKD.' + bpName)
								};
							})
						);
					}
				}
			});

			Object.values(edi.constants.STATE).forEach(function (value) {
				if (
					me.includedStates.some((it) => it === value) &&
					!edi.constants.MODULES.DOCUMENTS.FILTER_REMOVED_STATES.some((it) => it === value)
				) {
					const searchMap = ['BUSINESS_STATE', value];
					if (
						value === edi.constants.STATE.SENT ||
						value === edi.constants.STATE.WAIT_RECEIVER_DECISION ||
						value === edi.constants.STATE.READ ||
						value === edi.constants.STATE.SIGNED_BY_CONSUMER
					) {
						searchMap.push('DOCUMENTS_FILTER');
					}
					docStates.push({
						id: value,
						name: edi.i18n.getTr(searchMap)
					});
				}
			});

			docStates.push(
				{
					id: 'ACCEPTED',
					name: edi.i18n.getMessage('documents.status.APERAK_CODE_29')
				},
				{
					id: 'ACCEPTED_WITH_COMMENT_13',
					name: edi.i18n.getMessage('documents.status.APERAK_CODE_13')
				},
				{
					id: edi.constants.STATE.MANUAL_PROCESSING,
					name: edi.i18n.getMessage('documents.status.MANUAL_PROCESSING')
				}
			);

			const setAllowBlankForDateRange = function (dateRangeFields, allowBlank) {
				if (dateRangeFields && dateRangeFields.dateFrom && dateRangeFields.dateTo) {
					dateRangeFields.dateFrom.allowBlank = allowBlank;
					dateRangeFields.dateTo.allowBlank = allowBlank;
				}
			};

			const dateFieldsValidator = function () {
				var docDateFields,
					docDatesSelected,
					modifyDateFields,
					modifyDatesSelected,
					creationDateFields,
					creationDatesSelected;
				if (docDateRange && modifyDateRange && creationDateRange) {
					docDateFields = docDateRange.getFields();
					modifyDateFields = modifyDateRange.getFields();
					creationDateFields = creationDateRange.getFields();
					docDatesSelected = docDateFields.dateTo.getValue() || docDateFields.dateFrom.getValue();
					modifyDatesSelected = modifyDateFields.dateTo.getValue() || modifyDateFields.dateFrom.getValue();
					creationDatesSelected =
						creationDateFields.dateTo.getValue() || creationDateFields.dateFrom.getValue();

					if (
						(docDatesSelected && modifyDatesSelected && creationDatesSelected) ||
						(!docDatesSelected && !modifyDatesSelected && !creationDatesSelected)
					) {
						setAllowBlankForDateRange(docDateFields, false);
						setAllowBlankForDateRange(creationDateFields, false);
						setAllowBlankForDateRange(modifyDateFields, false);
					} else if (creationDatesSelected) {
						setAllowBlankForDateRange(creationDateFields, false);
						setAllowBlankForDateRange(docDateFields, true);
						setAllowBlankForDateRange(modifyDateFields, true);
					} else if (docDatesSelected) {
						setAllowBlankForDateRange(docDateFields, false);
						setAllowBlankForDateRange(creationDateFields, true);
						setAllowBlankForDateRange(modifyDateFields, true);
					} else {
						//If modifyDate selected
						setAllowBlankForDateRange(modifyDateFields, false);
						setAllowBlankForDateRange(docDateFields, true);
						setAllowBlankForDateRange(creationDateFields, true);
					}
				}
				return true;
			};

			let enableUnlimitedDatesSearch = edi.permissions.hasPermission('CLIENT_DOCUMENTS_UNLIMITED_PERIOD'),
				attrValueInput = null;

			const formItemsMap = {
				docNumber: createSearchTypeSelectionFieldForGrid(
					{
						fieldLabel: edi.i18n.getMessage('document.form.number'),
						fieldConf: {
							name: 'number'
						}
					},
					undefined,
					true
				),
				docType: createDocumentTypeFilterSelectForGrid(),
				docStatus: createCombo({
					fieldLabel: edi.i18n.getMessage('documents.column.status'),
					emptyText: '',
					name: 'includeBusinessStates',
					store: edi.stores.createInlineStore(docStates, 'SIMPLE', undefined, {
						addEmptyRecord: true
					}),
					multiSelect: true,
					setLastValueAfterTextClear: true,
					anyMatch: true
				}),
				attributes: createContainer({
					layout: {
						type: 'grid',
						area: [[4, 8]],
						gap: 0
					},
					items: [
						createCombo({
							fieldLabel: edi.i18n.getMessage('documents.column.attribute'),
							emptyText: '',
							name: 'attribute',
							ignoreAutoFilter: true,
							store: edi.stores.createSimpleInlineStore(
								['orderNumber', 'desadvNumber', 'contractNumber'],
								function (id) {
									return edi.i18n.getMessage('documents.column.' + id);
								}
							),
							listeners: {
								select: function () {
									if (attrValueInput && attrValueInput.getValue()) {
										const autoSearchCheckbox = filterForm.down(
											"checkbox[name='filterFormAutoSearchCheckbox']"
										);
										if (
											filterForm &&
											'function' === typeof filterForm.fireSearch &&
											(!autoSearchCheckbox || !!autoSearchCheckbox.getValue())
										) {
											filterForm.fireSearch();
										}
									}
								}
							}
						}),
						createSearchTypeSelectionFieldForGrid(
							{
								fieldConf: {
									name: 'attrNumber',
									chipTitle: edi.i18n.getMessage('documents.column.attribute'),
									multiMode: false
								}
							},
							undefined,
							true
						)
					]
				}),
				deliveryPoint: createSearchTypeSelectionFieldForGrid(
					{
						fieldLabel: edi.i18n.getMessage('document.delivery.point.gln'),
						fieldConf: {
							name: 'deliveryGLN'
						}
					},
					['exact', 'LikeRight'],
					true
				),
				partner: createCombo({
					fieldLabel: edi.i18n.getMessage('documents.form.partner'),
					emptyText: '',
					store: edi.stores.initRelatedOrganizationsFirstEmptyStore(),
					anyMatch: true,
					autoValue: true,
					name: 'partner',
					showQtips: true,
					listeners: {
						change: function (combo, newValue) {
							let selectedOrg = combo.getStore().getById(newValue)?.data;
							let filiationsCombo = formItemsMap.includedFilials;
							let filiationsStore = filiationsCombo?.getStore();

							filiationsCombo?.setVisible(selectedOrg?.hasFilials);
							if (filiationsStore) {
								if (selectedOrg?.hasFilials) {
									filiationsStore.setProxy(
										createProxyConfig({
											type: 'ajax',
											url: edi.utils.compileURL(edi.rest.services.FILIATIONS.GET, {
												headOrgId: selectedOrg.id
											})
										})
									);
									filiationsStore.load();
								} else {
									filiationsCombo.setValue(null);
								}
							}
						}
					}
				}),
				includedFilials: edi.permissions.hasPermission('CLENT_GET_FILIALS')
					? createAutocomplete(
							{
								fieldLabel: edi.i18n.getMessage('documents.partner.filiation'),
								emptyText: '',
								name: 'includedFilials',
								setLastValueAfterTextClear: true,
								multiSelect: true,
								showQtips: true,
								hidden: true
							},
							{
								autoLoad: false,
								proxy: createProxyConfig({
									type: 'ajax',
									url: edi.rest.services.FILIATIONS.GET
								}),
								model: edi.models.getModel('ORGANIZATIONS')
							}
					  )
					: null,
				[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE]: (docDateRange = createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('documents.column.date'),
					validator: dateFieldsValidator,
					maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 366,
					fieldsConfig: {
						common: {
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							enabledUTC: true
						},
						from: {
							chipTitle: edi.i18n.getMessage('documents.column.date') + ': ',
							fieldLabel: edi.i18n.getMessage('document.date.from'),
							name: 'fromDocTime'
						},
						to: {
							chipTitle: ' — ',
							name: 'toDocTime'
						}
					}
				})),
				[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: (creationDateRange = createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.create'),
					title: edi.i18n.getMessage('document.date.create.from'),
					validator: dateFieldsValidator,
					fieldsConfig: {
						common: {
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
							fieldLabel: edi.i18n.getMessage('document.date.create.from'),
							name: 'creationDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'creationDateTo'
						}
					}
				})),
				[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]: (modifyDateRange = createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.modify'),
					period: edi.utils.getUserDefaultSearchPeriod(edi.constants.DEFAULT.FILTER.PERIODS.TODAY),
					validator: dateFieldsValidator,
					maxSearchPeriodDays: enableUnlimitedDatesSearch ? null : 366,
					fieldsConfig: {
						common: {
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.modify') + ': ',
							fieldLabel: edi.i18n.getMessage('document.modify.date.from')
						},
						to: {
							chipTitle: ' — '
						}
					}
				}))
			};

			// EW override
			let filterForm = createModuleFilterForm(
				{
					saveFilter: {
						name: `${me.moduleData.modName}_archive_grid`
					},
					formItemsMap,
					usePlaceholderWrapper: true,
					items: [
						createPanel({
							layout: {
								type: 'grid',
								area: [[4, 4, 4], [4, 4, 4], 4, [4, 4, 4]]
							},
							items: [
								formItemsMap.docNumber,
								formItemsMap.docType,
								formItemsMap.docStatus,
								formItemsMap.attributes,
								formItemsMap.deliveryPoint,
								formItemsMap.partner,
								formItemsMap.includedFilials,
								formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.DOC_DATE],
								formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE],
								formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE]
							]
						})
					],
					setFormDefaults: function () {
						let defaultModifyDateRange = edi.utils.getUserDefaultSearchPeriod('week');
						formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.MODIFY_DATE].activatePeriod(
							defaultModifyDateRange
						);
					},
					listeners: {
						afterrender: function (filterForm) {
							filterForm.fireSearch();
						},
						destroy: function () {
							if (filterObject && filterObject.searchTimeout) {
								clearTimeout(filterObject.searchTimeout);
							}
						}
					}
				},
				fireSearch
			);

			if (edi.constants.IS_EDIWEB_CLIENT) {
				let { formItemsMap, items } = edi.filters.config.ew_archive.createFormItems();
				filterForm = createModuleFilterForm(
					{
						saveFilter: {
							name: `${me.moduleData.modName}_archive_grid`
						},
						border: 1,
						formItemsMap,
						items,
						setFormDefaults: function () {
							let defaultModifyDateRange = edi.utils.getUserDefaultSearchPeriod('week');
							formItemsMap.creationDateRange.activatePeriod(defaultModifyDateRange);
						},
						listeners: {
							afterrender: function (filterForm) {
								filterForm.fireSearch();
							},
							destroy: function () {
								if (filterObject && filterObject.searchTimeout) {
									clearTimeout(filterObject.searchTimeout);
								}
							}
						}
					},
					fireSearch
				);
			}

			attrValueInput = filterForm.down('[name="attrNumber"]');
			filterForm.fireSearch = fireSearch;
			return filterForm;
		};

		/**
		 * Initializes grid
		 * @returns {Object} Instance of grid
		 */
		const createGridFn = function () {
			const gridDataChangedHandler = function () {
				edi.events.documents.fireEvent('change');
				grid.getStore().reload();
			};
			const columns = edi.columns.get(GRID_ARCHIVE_COLUMN_CONFIG_NAME);
			const actionItems = [],
				totalsLabel = createLabel({
					text: edi.i18n.getMessage('documents.selected.totals', {
						selectedRows: 0,
						totalSumm: '0.00'
					}),
					cls: 'edi-tbar-total-label-right'
				});
			if (edi.permissions.hasPermission('RESTORING_ARCHIVED_DOCUMENT')) {
				actionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.ARCHIVE_RESTORE,
					name: edi.i18n.getMessage('documents.archive.restore.document')
				});
			}

			actionItems.push({
				id: edi.constants.DOCUMENT_ACTIONS.PRINT,
				name: edi.i18n.getMessage('action.print'),
				handler: function () {
					edi.methods.documentsGroupPrint(grid, me.moduleData);
				}
			});

			const actionColumn = createActionsColumnConfig({
				align: 'center',
				items: [
					{
						glyph: edi.constants.ICONS.FILE_DOWNLOAD,
						tooltip: edi.i18n.getMessage('documents.archive.download'),
						handler: function (grid, rowIndex) {
							me.downloadArchive(null, grid.getStore().getAt(rowIndex).get('id'));
						},
						isActionDisabled: function () {
							return !edi.permissions.hasPermission('LOAD_ARCHIVED_DOCUMENT');
						}
					},
					{
						glyph: edi.constants.ICONS.RESTORE,
						tooltip: edi.i18n.getMessage('documents.archive.restore.document'),
						handler: function (grid, rowIndex) {
							me.restoreDocument(null, grid.getStore().getAt(rowIndex).get('id'), gridDataChangedHandler);
						},
						isActionDisabled: function () {
							return !edi.permissions.hasPermission('RESTORING_ARCHIVED_DOCUMENT');
						}
					}
				]
			});
			columns.push(actionColumn);

			let topBar = undefined;
			if (actionItems.length) {
				let percent,
					progress,
					packId,
					userStopMe = false;
				const onMenuItemClick = function (id) {
					const options = {
						grid: grid,
						moduleData: me.moduleData,
						action: id
					};
					if (edi.constants.DOCUMENT_ACTIONS.ARCHIVE_RESTORE === id) {
						Object.assign(options, {
							loadingMessage: 'documents.archive.restore.document.started',
							confirmTitle: 'documents.archive.restore.document',
							confirmAction: false,
							handler: function (record, grid, callback, failure) {
								const id = record.get('id');
								const url = edi.utils.formatString(
									edi.rest.services.DOCUMENTS.ARCHIVE.RESTORE.GET,
									{ archivedObjectId: id },
									true
								);
								let fail = function (respData) {
									let errorText = edi.utils.formatComplexServerError(respData, 'error.getting.data');
									if (errorText) {
										edi.core.showError(errorText);
									}
									failure(record, errorText);
								};
								let success = function () {
									grid.getSelectionModel().deselect(record);
									callback && 'function' == typeof callback ? callback() : null;
									gridDataChangedHandler();
								};
								edi.rest.sendRequest(url, 'GET', undefined, success, fail);
							}
						});
					}
					edi.document.actions.documentGridActionProcess(options);
				};
				const success = function (data) {
					if (!userStopMe) {
						if (data && data.data && data.data.hasOwnProperty('percentageOfReadiness')) {
							percent = data.data.percentageOfReadiness;
						}
						progress.updateProgress(percent / 100, percent + ' %');
						if (percent === 100 && progress) {
							progress.hide();
							progress = null;
							if (
								data.data.hasOwnProperty('state') &&
								edi.constants.EXPORT_GROUP_REQUEST.STATE.READY === data.data.state
							) {
								edi.rest.downloadFile(
									edi.utils.formatString(edi.rest.services.EXPORT.GROUP_REQUEST.EXPORT.GET, {
										id: data.data.id
									})
								);
							}
						} else {
							setTimeout(startSearch, 1000);
						}
					} else {
						if (progress) {
							progress.hide();
							progress = null;
						}
						const closeMsg = edi.core.confirm(
							edi.i18n.getMessage('export.group.request.progress.title'),
							edi.i18n.getMessage('export.group.request.progress.close'),
							undefined,
							undefined,
							undefined,
							undefined,
							{
								closable: false,
								buttons: [
									createButton({
										cls: BUTTON_CLS.primary,
										text: edi.i18n.getMessage('form.btn.download.later'),
										handler: function () {
											closeMsg.close();
										}
									}),
									createButton({
										cls: BUTTON_CLS.secondary,
										text: edi.i18n.getMessage('form.btn.stop'),
										handler: function () {
											edi.rest.sendRequest(
												edi.utils.formatString(
													edi.rest.services.EXPORT.GROUP_REQUEST.PUT,
													{
														id: packId
													},
													true
												),
												'PUT',
												Ext.encode({ REJECT: true }),
												undefined,
												undefined
											);
										}
									})
								]
							}
						);
					}
				};
				const failure = function (data) {
					if (progress && userStopMe) {
						progress.hide();
						progress = null;
					}
					edi.core.showError(edi.utils.formatComplexServerError(data, 'error.accept.registry.find'));
					me.moduleData.tab.setLoading(false);
				};
				const startSearch = function () {
					if (!progress) {
						percent = 0;
						progress = showMessageBox({
							closable: false,
							title: edi.i18n.getMessage('export.group.request.progress.title'),
							width: MODAL_SIZE.widthMedium,
							progress: true,
							progressBarConfig: {
								title: edi.i18n.getMessage('export.group.request.progress.msg'),
								description: percent + ' %'
							},
							okBtnConfig: {
								text: edi.i18n.getMessage('form.btn.close'),
								handler: function () {
									progress.close();
									userStopMe = true;
								}
							}
						});
					} else {
						progress.updateProgress(percent / 100, percent + ' %');
					}
					const url = edi.utils.formatString(edi.rest.services.EXPORT.GROUP_REQUEST.GET, { id: packId });
					edi.rest.sendRequest(url, 'GET', null, success, failure);
				};
				topBar = createGridActionBar({
					actionCfgs: actionItems,
					defaultHandler: onMenuItemClick,
					totalsLabel: totalsLabel
				});
			}
			/**
			 * Checkbox selection model processor linked with grid top bar action items
			 * @param model
			 * @param selected
			 * @param actionItems
			 * @param isProcessible
			 */
			const selectionProcessor = function (model, selected, actionItems, isProcessible) {
				let i,
					j,
					groupActionBtn,
					actionConf,
					totalSum = 0;
				for (i = 0; i < actionItems.length; i++) {
					groupActionBtn = actionItems[i];
					actionConf = groupActionBtn.rulesData;
					if (isProcessible(actionConf)) {
						var allowedDocs,
							allowFromGridConf = edi.document.actions.availableFromGrid[actionConf.id] || {},
							isAvailableFromGrid = true;

						allowedDocs = selected.filter(function (docRecord) {
							isAvailableFromGrid = true;
							var document = docRecord.getData();

							if (!Ext.isEmpty(allowFromGridConf) && Array.isArray(allowFromGridConf)) {
								Ext.each(allowFromGridConf, function (config) {
									if ('function' == typeof config.allow) {
										//Check action for allow performing from grid if doctypes  aren't specified in allowFromGrid conf
										//Or if  doctype exists in specified doctypes
										if (
											!config.documents ||
											(Array.isArray(config.documents) &&
												config.documents.indexOf(document.type) > -1)
										) {
											isAvailableFromGrid = config.allow(document);
										}
									}
								});
							}

							return (
								isAvailableFromGrid &&
								edi.action.isAvailable(actionConf.id, edi.action.getDocumentData(docRecord))
							);
						});

						if (edi.constants.DOCUMENT_ACTIONS.COPY === actionConf.id && allowedDocs.length > 1) {
							groupActionBtn.setDisabled(true);
						} else if (!actionConf.notDisabled) {
							groupActionBtn.setDisabled(!allowedDocs.length);
						}
					}
				}
				for (j = 0; j < selected.length; j++) {
					totalSum += Math.round(selected[j].get('totalSumm') * 100) / 100;
				}
				edi.methods.grid.displaySelectedTotals(grid, totalSum, selected.length, 'documents.selected.totals');
			};
			const grid = createGrid({
				saveSorters: true,
				savedSortersName: 'document_archive',
				proxyConfig: {
					type: 'ajax',
					url
				},
				storeConfig: {
					pageSize: edi.constants.ITEMS_PER_PAGE,
					model: edi.models.getModel('DOCUMENT_BASE_ARCH'),
					groupField: null,
					sortOnLoad: true,
					sorters: {
						property: 'modifyDate',
						direction: 'DESC'
					},
					autoLoad: false
				},
				gridConfig: {
					columns: columns,
					padding: 0,
					region: 'center',
					layout: 'fit',
					border: 1,
					selModel:
						topBar && topBar.menuActionItemsCnt
							? createGridCheckboxSelectionModel({
									topBar: topBar,
									selectionProcessor: selectionProcessor
							  })
							: undefined,
					tbar: topBar
				},
				pagingBarConfig: {
					itemsPerPage: edi.constants.ITEMS_PER_PAGE,
					totalsLabel: topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined
				}
			});
			grid.totalsLabel = topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined;
			return grid;
		};

		const grid = createGridFn();

		return createTab({
			title: edi.i18n.getMessage('navigation.documents'),
			closable: false,
			bodyPadding: 0,
			layout: 'border',
			border: 0,
			autoScroll: false,
			items: [createFilterForm(), grid]
		});
	}

	createPoATab() {
		const me = this;
		const url = edi.utils.compileURL(edi.rest.services.POWER_OF_ATTORNEY.LIST.GET, {
			gridName: 'archived'
		});

		const createFilterForm = function () {
			let filterObject;

			const fireSearch = function () {
				if (!filterObject) {
					filterObject = edi.filters.createGridFilter(url, filterForm, grid, createPoAFilterArgs, null, {
						persistence: {
							enabled: true,
							name: `${me.moduleData.modName}_archive_poa_grid`
						}
					});
				}
				filterObject.filter();
			};
			const { formItemsMap, items } = createPoAFormItems();

			const filterForm = createModuleFilterForm(
				{
					saveFilter: {
						name: 'powers_of_attorney_list'
					},
					usePlaceholderWrapper: true,
					toggleAutoSearch: true,
					autosearchEnabled: false,
					searchOnFilterCollapse: true,
					bodyPadding: 0,
					formItemsMap,
					items,
					listeners: {
						afterrender: function (filterForm) {
							filterForm.fireSearch();
						},
						destroy: function () {
							if (filterObject && filterObject.searchTimeout) {
								clearTimeout(filterObject.searchTimeout);
							}
						}
					}
				},
				fireSearch,
				undefined,
				{
					afterDefault: [
						createButton({
							text: edi.i18n.getMessage('deferred.reports.result.export'),
							tooltip: edi.i18n.getMessage('deferred.reports.result.export.tooltip'),
							cls: BUTTON_CLS.primary,
							glyph: edi.constants.ICONS.FILE_DOWNLOAD,
							handler: function () {
								edi.methods.createDocumentListReport(
									edi.constants.REPORT_FORMATS.XLS,
									me.moduleData.tabName,
									filterForm.getValues(),
									me.moduleData
								);
							}
						})
					]
				}
			);

			filterForm.fireSearch = fireSearch;
			return filterForm;
		};

		const createPowersList = function () {
			const columns = edi.columns.get(POA_LIST_COLUMNS);
			const groupActionItems = [];
			const gridDataChangedHandler = function () {
				edi.events.powerOfAttorney.fireEvent('change');
				grid.getStore().reload();
			};

			columns.push(
				createActionsColumnConfig({
					align: 'center',
					items: [
						{
							glyph: edi.constants.ICONS.FILE_DOWNLOAD,
							tooltip: edi.i18n.getMessage('documents.archive.download'),
							hidden: !edi.permissions.hasPermission('LOAD_ARCHIVED_DOCUMENT'),
							handler: function (grid, rowIndex) {
								me.downloadArchive(null, grid.getStore().getAt(rowIndex).get('docId'));
							}
						},
						{
							glyph: edi.constants.ICONS.RESTORE,
							tooltip: edi.i18n.getMessage('documents.archive.restore.document'),
							hidden: !edi.permissions.hasPermission('RESTORING_ARCHIVED_DOCUMENT'),
							handler: function (grid, rowIndex) {
								me.restoreDocument(
									null,
									grid.getStore().getAt(rowIndex).get('docId'),
									gridDataChangedHandler
								);
							}
						}
					]
				})
			);

			const totalsLabel = createLabel({
				text: edi.i18n.getMessage('records.selected.totals', {
					selectedRows: 0
				}),
				cls: 'edi-tbar-total-label-right'
			});

			if (edi.permissions.hasPermission('RESTORING_ARCHIVED_DOCUMENT')) {
				groupActionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.ARCHIVE_RESTORE,
					name: edi.i18n.getMessage('documents.archive.restore.document')
				});
			}

			if (edi.permissions.hasPermission('LOAD_ARCHIVED_DOCUMENT') && !edi.constants.IS_EDIWEB_CLIENT) {
				// EW override
				groupActionItems.push({
					id: edi.constants.DOCUMENT_ACTIONS.EXPORT,
					itemId: edi.constants.EXPORT_GROUP_REQUEST.TYPE.COMMON,
					name: edi.i18n.getMessage('documents.archive.download'),
					handler: function () {
						exportGroupHandler(
							{ grid, moduleData: me.moduleData },
							edi.constants.EXPORT_GROUP_REQUEST.TYPE.COMMON
						);
					}
				});
			}

			const groupActionsItemClick = function (id) {
				const options = {
					grid: grid,
					moduleData: me.moduleData,
					action: id
				};

				switch (id) {
					case edi.constants.DOCUMENT_ACTIONS.ARCHIVE_RESTORE:
						Object.assign(options, {
							loadingMessage: 'documents.archive.restore.document.started',
							confirmTitle: 'documents.archive.restore.document',
							confirmAction: false,
							completeEvent: {
								object: 'powerOfAttorney',
								event: 'change',
								data: undefined
							},
							actionCheckCallback: function () {
								return true;
							},
							handler: function (record, grid, callback, failure) {
								const id = record.get('docId');
								const url = edi.utils.formatString(
									edi.rest.services.DOCUMENTS.ARCHIVE.RESTORE.GET,
									{ archivedObjectId: id },
									true
								);
								const fail = function (respData) {
									let errorText = edi.utils.formatComplexServerError(respData, 'error.getting.data');
									if (errorText) {
										edi.core.showError(errorText);
									}
									failure(record, errorText);
								};
								const success = function () {
									grid.getSelectionModel().deselect(record);
									callback && 'function' == typeof callback ? callback() : null;
									gridDataChangedHandler();
								};
								edi.rest.sendRequest(url, 'GET', undefined, success, fail);
							}
						});
						break;
				}
				edi.document.actions.documentGridActionProcess(options);
			};

			const topBar = createGridActionBar({
				actionCfgs: groupActionItems,
				defaultHandler: groupActionsItemClick
			});

			return createGrid({
				saveSorters: true,
				savedSortersName: 'powers_of_attorney_list',
				proxyConfig: {
					type: 'ajax',
					url
				},
				storeConfig: {
					pageSize: edi.constants.ITEMS_PER_PAGE,
					model: edi.models.getModel(POA_LIST_MODEL),
					autoLoad: false,
					remoteSort: true,
					sortOnLoad: true,
					sorters: {
						property: 'modifyDate',
						direction: 'DESC'
					}
				},
				gridConfig: {
					cls: 'edi-grid-with-toolbar-buttons',
					columns: columns,
					region: 'center',
					border: 1,
					selModel:
						topBar && topBar.menuActionItemsCnt
							? createGridCheckboxSelectionModel({
									topBar: topBar,
									selectionProcessor: function (model, selected, actionItems) {
										edi.methods.gridSelModules(model, selected, actionItems, topBar, totalsLabel);
									}
							  })
							: undefined,
					tbar: topBar
				}
			});
		};

		const grid = createPowersList();
		return createTab({
			title: edi.i18n.getMessage('document.POWER_OF_ATTORNEY'),
			closable: false,
			bodyPadding: 0,
			layout: 'border',
			border: 0,
			autoScroll: false,
			items: [createFilterForm(), grid]
		});
	}

	/**
	 * Downloads archived document
	 * @param    {Object}    view    grid view data object
	 * @param    {Number}    id   id of store record of clicked row
	 */
	downloadArchive(view, id) {
		const me = this;
		edi.rest.downloadFile(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.ARCHIVE.NEW_EXPORT.GET, {
				archivedObjectId: id
			}),
			id,
			undefined,
			me.moduleData.tab
		);
	}
	/**
	 * Restores archived document
	 * @param	{Object}	view    grid view data object
	 * @param	{Number}	id    id from store record of clicked row
	 * @param	{Function}	onRestore
	 */
	restoreDocument(view, id, onRestore) {
		const me = this;
		me.moduleData.tab.setLoading();
		let failure = edi.document.actions.defaultFailureHandler(me.moduleData.tab, 'error.getting.data', () =>
			me.moduleData.tab.setLoading(false)
		);
		edi.rest.sendRequest(
			edi.utils.formatString(
				edi.rest.services.DOCUMENTS.ARCHIVE.RESTORE.GET,
				{
					archivedObjectId: id
				},
				true
			),
			'GET',
			undefined,
			function () {
				if (typeof onRestore === 'function') {
					onRestore();
				}
				edi.rest.sendRequest(
					edi.utils.formatString(
						edi.rest.services.DOCUMENTS.HEADER.GET,
						{
							documentId: id
						},
						true
					),
					'GET',
					undefined,
					function (data) {
						if (data && data.data) {
							edi.document.actions.openDetailsModule(data.data.type, data.data); // EW override
							me.moduleData.tab.setLoading(false);
						} else {
							failure(data);
						}
					},
					failure
				);
			},
			failure
		);
	}

	/**
	 * Renders page layout
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	renderData(initCallBack) {
		const me = this;
		const modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});

		modulePanel.add(
			createTabPanel(
				{
					cls: [TAB_PANEL_CLS.simple, 'module-tabs', 'edi-additional-tbar'],
					items: [
						me.createDocumentTab(),
						edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_READ') ? me.createApprovalTab() : null, // EW override
						edi.permissions.hasPermission('READ_POWER_OF_ATTORNEY') ? me.createPoATab() : null
					]
				},
				true
			)
		);

		me.moduleData.tab.add(modulePanel);

		if (typeof initCallBack === 'function') {
			initCallBack();
		}
	}

	/**
	 * Routine that must be done before module destroy
	 */
	onDestroy() {
		const me = this;
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
	}
}

Ext.namespace('edi.modules');
edi.modules['document.archive'] = ArchiveDocumentsGridModule;
