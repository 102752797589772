edi.converters.registerConverters({
	convertPricatPartie: function (org) {
		var nameData = org.name;
		if (org.orgType === edi.constants.ORGANIZATION_TYPES.INDIVIDUAL && org.individualOrgAdditionalData) {
			nameData =
				org.individualOrgAdditionalData.lastName +
				' ' +
				org.individualOrgAdditionalData.firstName +
				(org.individualOrgAdditionalData.middleName ? ' ' + org.individualOrgAdditionalData.middleName : '');
		}

		return {
			id: org.id,
			fns_id: org.fnsId,
			company_iln: org.iln,
			company_inn: org.inn,
			company_kpp: org.kpp,
			company_name: nameData,
			contact_person: '',
			contact_email: ''
		};
	},
	/**
	 * convert delcat or loccat item to org
	 * @param    {Object}    src
	 * @returns    {Object}
	 */
	convertCatToOrg: function (src) {
		src = src ? src : {};
		if (!src.stateCode && src.state) {
			src.stateCode = src.state;
		}
		const hq = src.headquarters || {};
		const country = edi.utils.getCountryFULLByISO(src.country || 'RU');

		return {
			orgType:
				src.type == '002'
					? edi.constants.ORGANIZATION_TYPES.INDIVIDUAL
					: edi.constants.ORGANIZATION_TYPES.LEGAL_ENTITY,
			name: src.name || hq.name,
			inn: src.taxID || hq.taxID,
			kpp: src.taxRegistrationReasonCode,
			iln: src.iln || hq.iln,
			regNr: src.utilizationRegisterNumber || hq.utilizationRegisterNumber,
			billingType: undefined,
			fnsId: undefined,
			phones: src.phoneNumber,
			acc: src.accountNumber,
			bank: undefined,
			bankBIK: undefined,
			addresses: [
				{
					zip: src.postalCode,
					regionCode: src.stateCode,
					street: src.streetAndNumber,
					building: src.houseNumber,
					locality: src.locality,
					block: src.housing,
					flat: src.apartment,
					city: src.cityName,
					countryCode: country.iso_2 || 'RU',
					area: src.district,
					type: edi.constants.ADDRESS_TYPES.LEGAL
				}
			]
		};
	},
	/**
	 * convert value to organization
	 * @param    {Object}    org        value data
	 * @returns    {Object}            legacy organization's data
	 */
	convertValuesToOrg: function (values) {
		return {
			name: values.company_name,
			iln: values.company_iln,
			addresses: [
				{
					countryCode: values.addr_country,
					regionCode: values.addr_region_code,
					city: values.addr_city,
					zip: values.addr_zip,
					street: values.addr_street,
					type: edi.constants.ADDRESS_TYPES.LEGAL
				}
			]
		};
	},
	/**
	 * convert organization to legacy partie
	 * @param    {Object}    org        organization's data
	 * @returns    {Object}            legacy partie's data
	 */
	convertOrgToLegacyPartie: function (org) {
		return edi.converters.convertOrgToPartie(org, {
			separateMainData: false,
			separateAddress: false
		});
	}
});
