import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
// @ts-ignore
import { createLabel, createLabelBlockForDetails } from '@UIkit/components/fields';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import {
	createFieldBlockForDetails,
	createFieldSetForDetails,
	createMaxWidthContainerForDetail
	// @ts-ignore
} from '@UIkit/components/panels';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { packingListSelector } from './selectors';
import { PACKING_LIST_LINE_MODEL, PACKING_LIST_SUMMARY_MODEL } from './models';
import { PACKING_LIST_LINE_CONFIG_NAME, PACKING_LIST_TOTAL_COLUMNS_CONFIG_NAME } from './columns';
import { createTreeGrid } from '@Components/tree.grid';
import { createContainer } from '@Components/miscComponents';
// @ts-ignore
import { createTab, createTabPanel, TAB_PANEL_CLS } from '@UIkit/components/tab';
import { createGrid } from '@Components/grid';
import { createProxyConfig } from '@Components/storeComponents';
import { isPackingRecord, isProductRecord, Packing, PackingBase, Product, TREE_MODE, TreeRecord } from './definitions';
import './style.scss';

class PackingList {
	moduleData: ModuleData<DocumentHeader>;
	documentHeader: DocumentHeader;
	documentContent: AnyObject;
	productsTreeGrid: ExtComponent;
	treeView: {
		product: AnyObject[];
		package: AnyObject[];
	};

	init(this: PackingList, data: ModuleData<DocumentHeader>, initCallBack: ModuleInitCallback) {
		const me = this;
		me.moduleData = data;
		me.documentHeader = data.initData.data;
		me.treeView = {
			product: [],
			package: []
		};

		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	changeHandler(this: PackingList, data?: AnyObject) {
		const me = this;

		edi.document.actions.changeHandler(
			data ?? me.documentHeader,
			me.moduleData,
			function (headerData: { data: DocumentHeader }) {
				me.moduleData.initData.data = headerData.data;
			},
			me.renderData.bind(me)
		);
	}

	createModuleActionsPanel(this: PackingList) {
		const me = this;

		const actionsPanel = createActionsPanel();
		const direction: string = edi.utils.getDocumentDirection(me.documentHeader.toOrg, me.documentHeader.fromOrg);

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: me.documentHeader,
			direction: direction,
			moduleData: me.moduleData,
			actionProps: {
				COMPLETE: {
					label: edi.i18n.getMessage('action.complete')
				},
				REFRESH: {
					handler: function () {
						me.changeHandler();
					}
				},
				EXPORT: {
					showInFirstHalf: true,
					exportBtnLabel: edi.i18n.getMessage('action.export.short')
				}
			}
		});

		return actionsPanel;
	}

	createHeaderPanel(this: PackingList) {
		const me = this;

		return createDocumentHeaderPanel(me.moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noUsage: !!(me.moduleData.initData && me.moduleData.initData.isDocumentSharing)
		});
	}

	createMainDataBlock(this: PackingList) {
		const me = this;
		const orderNumber = {
			title: edi.i18n.getMessage('order.number'),
			text: edi.utils.getObjectProperty(me.documentContent, 'packingListHeader.buyerOrderNumber')
		};
		const orderDate = {
			title: edi.i18n.getMessage('order.date'),
			text: edi.renderers.fnsDateFromClient(
				edi.utils.getObjectProperty(me.documentContent, 'packingListHeader.buyerOrderDate')
			)
		};
		const waybillNumber = {
			title: edi.i18n.getMessage('reference.waybill.number'),
			text: edi.utils.getObjectProperty(me.documentContent, 'packingListHeader.despatchNumber'),
			isNewLine: true
		};
		const waybillDate = {
			title: edi.i18n.getMessage('reference.waybill.date'),
			text: edi.renderers.fnsDateFromClient(
				edi.utils.getObjectProperty(me.documentContent, 'packingListHeader.despatchDate')
			)
		};
		const expectedDeliveryDate = {
			title: edi.i18n.getMessage('expected.delivery.period.DATE'),
			text: edi.renderers.fnsDateFromClient(
				edi.utils.getObjectProperty(me.documentContent, 'packingListHeader.estimatedDeliveryDate')
			)
		};
		return createLabelBlockForDetails({
			contents: [orderNumber, orderDate, waybillNumber, waybillDate, expectedDeliveryDate]
		});
	}

	createOS(this: PackingList, selectorConfig: AnyObject) {
		const me = this;

		const parties = edi.utils.getObjectProperty(me.documentContent, 'packingListParties');
		const defaultProps = {
			is_valid: true,
			readOnly: true,
			valuesByMap: true,
			notReturnedVoid: true,
			fieldValues: parties
		};

		return createOrgSelector(Object.assign(defaultProps, selectorConfig));
	}

	createPartiesBlock(this: PackingList) {
		const me = this;

		const buyer = me.createOS({
			selectedOrg: edi.utils.getOrg({
				orgId: me.documentHeader.toOrg.id
			}),
			onFormCreate: edi.selectors.fieldControls.updateInnField,
			...packingListSelector.getBuyerSelectors()
		});

		const seller = me.createOS({
			selectedOrg: edi.utils.getOrg({
				orgId: me.documentHeader.fromOrg.id
			}),
			onFormCreate: edi.selectors.fieldControls.updateInnField,
			...packingListSelector.getSellerSelectors()
		});

		const shipFrom = me.createOS({
			...packingListSelector.getShipFromSelectors()
		});

		const delivery = me.createOS({
			...packingListSelector.getDeliveryPointSelectors()
		});

		const ultimateCustomer = me.createOS({
			...packingListSelector.getUltimateCustomerSelectors()
		});

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('document.section.parties'),
			collapsible: true,
			items: createMaxWidthContainerForDetail({
				layout: {
					type: 'grid',
					gap: 24,
					area: [[6, 6], [6, 6], 6]
				},
				items: [
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('document.buyer'),
						items: [buyer]
					}),
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('document.seller'),
						items: [seller]
					}),
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('document.ship.from'),
						items: [shipFrom]
					}),
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('document.delivery'),
						items: [delivery]
					}),
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('documents.ultimate.customer'),
						items: [ultimateCustomer]
					})
				]
			})
		});
	}

	buildProductTreeView(this: PackingList) {
		const me = this;
		const lines = Ext.clone(me.documentContent['packingListConsignment']['packingSequence']['line']);
		if (me.treeView.product.length) return;

		const productView = lines.reduce((productView: AnyObject[], line: AnyObject) => {
			const product = line['lineItem'];
			const packages = line['packageIdentification']?.['goodsIdentity'];

			if (packages) {
				product.children = [];
				packages?.forEach((productPackage: AnyObject) => {
					const thirdLevelPackage = productPackage['thirdLevelPackaging'];

					if (thirdLevelPackage) {
						productPackage.children = [thirdLevelPackage];
					}
					product.children.push(productPackage);
				});
			}
			productView.push(product);
			return productView;
		}, []);

		me.treeView.product = productView;
		return productView;
	}

	buildPackageTreeView(this: PackingList) {
		const me = this;
		const lines = Ext.clone(me.documentContent['packingListConsignment']['packingSequence']['line']);
		if (me.treeView.package.length) return;

		const productPackages: {
			[packageSSCC: string]: AnyObject;
		} = {};
		const productsWithoutPackage = [] as AnyObject[];

		lines.forEach((line: AnyObject) => {
			const product = line['lineItem'] as Product;
			const packages = line['packageIdentification']?.['goodsIdentity'];
			let productQuantity = product['orderedQuantity'] ?? 0;

			if (packages) {
				packages?.forEach((productPackage: AnyObject) => {
					const itemsCountInPackage = productPackage['quantityPerPack'] ?? 0;
					productQuantity -= itemsCountInPackage;

					const packageID = productPackage['range']?.['idBegin'];
					if (!packageID) return;

					if (productPackages[packageID]) {
						productPackages[packageID].children.push(product);
					} else {
						productPackage.children = [product];
						productPackages[packageID] = productPackage;
					}
				});
			}

			if (productQuantity) productsWithoutPackage.push(product);
		});

		const thirdLevelPackages = {} as AnyObject;
		Object.entries(productPackages).forEach(([packageID, productPackage]) => {
			const thirdLevelPackage = productPackage['thirdLevelPackaging'];
			if (thirdLevelPackage) {
				const thirdLevelPackageID = thirdLevelPackage['range']?.['idBegin'];

				if (thirdLevelPackages[thirdLevelPackageID]) {
					thirdLevelPackages[thirdLevelPackageID].children.push(productPackage);
				} else {
					thirdLevelPackage.children = [productPackage];
					thirdLevelPackages[thirdLevelPackageID] = thirdLevelPackage;
				}
			}
		});

		const packageView = [
			...Object.values(thirdLevelPackages),
			...Object.values(productPackages).filter((productPackage) => !productPackage.thirdLevelPackaging),
			...productsWithoutPackage
		];

		me.treeView.package = packageView;
		return packageView;
	}

	loadProductsIntoTree(this: PackingList) {
		const me = this;

		me.buildPackageTreeView();
		me.buildProductTreeView();
		me.productsTreeGrid.loadData(me.treeView.product, true);
	}

	loadTotalValues(this: PackingList) {
		const me = this;
		const totalsGrid = me.productsTreeGrid.productsTotalGrid;
		const totalValues = Ext.clone([me.documentContent['packingListSummary']]) ?? [];

		if (totalsGrid) {
			totalsGrid.getStore().loadData(totalValues);
			totalsGrid.getView().refresh();
		}
	}

	createTreeGrid(this: PackingList) {
		const me = this;

		return (me.productsTreeGrid = createTreeGrid({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			showSettingsButton: true,
			cls: 'edi-product-grid-container with-row-lines',
			readOnly: true,
			productValues: [],
			gridModelConfig: PACKING_LIST_LINE_MODEL,
			gridColumnsConfig: PACKING_LIST_LINE_CONFIG_NAME,
			viewConfig: {
				layout: 'fit',
				maxHeight: edi.constants.DEFAULT.FORM_GRID.SCROLLABLE_DESADV,
				scrollable: {
					x: 'auto',
					y: 'auto'
				},
				rowLines: true,
				emptyText: edi.i18n.getMessage('grid.empty')
			}
		}) as ExtComponent);
	}

	rebuildTree(this: PackingList, { viewMode }: { viewMode: TREE_MODE }) {
		const me = this;
		const treeData = viewMode === TREE_MODE.PRODUCT ? me.treeView.product : me.treeView.package;

		me.productsTreeGrid.getRootNode().removeAll();

		me.productsTreeGrid.suspendLayouts();
		me.productsTreeGrid.__addChildrenItems(treeData, me.productsTreeGrid.getStore().getRootNode());
		me.productsTreeGrid.expandAll(function () {
			me.productsTreeGrid.resumeLayouts();
		});

		me.productsTreeGrid.getView().refresh();
		me.productsTreeGrid.getStore().sort();
	}

	createProductTreeGrid(this: PackingList) {
		const me = this;

		me.createTreeGrid();

		me.productsTreeGrid.titleContainer = createContainer({
			layout: {
				type: 'hbox',
				align: 'stretch'
			},
			items: [
				createLabel({
					style: {
						alignItems: 'center'
					},
					text: edi.i18n.getMessage('packing.list.distributed.items'),
					typography: 'heading_01'
				}),
				{ xtype: 'tbspacer', flex: 1 }
			]
		}) as ExtComponent;

		me.productsTreeGrid.modesTabPanel = createTabPanel({
			cls: [TAB_PANEL_CLS.simpleWithoutPadding],
			margin: '8 0',
			items: [
				createTab({
					title: edi.i18n.getMessage('form.btn.product'),
					itemId: TREE_MODE.PRODUCT,
					closable: false
				}),
				createTab({
					title: edi.i18n.getMessage('tab.title.packaging'),
					itemId: TREE_MODE.PACKING,
					closable: false
				})
			],
			listeners: {
				tabchange: function (cmp: ExtComponent, newCard: ExtComponent) {
					me.rebuildTree({ viewMode: newCard.itemId });
				}
			}
		}) as ExtComponent;

		me.productsTreeGrid.productsTotalGrid = createGrid({
			storeConfig: {
				proxy: createProxyConfig({
					type: 'memory'
				}),
				autoLoad: false,
				model: edi.models.getModel(PACKING_LIST_SUMMARY_MODEL),
				remoteSort: false
			},
			gridConfig: {
				margin: '24 0 0 0',
				title: edi.i18n.getMessage('grid.title.total'),
				columns: edi.columns.get(PACKING_LIST_TOTAL_COLUMNS_CONFIG_NAME),
				disablePaging: true,
				disableSelection: true,
				enableColumnMove: false,
				enableColumnResize: false,
				sortableColumns: false
			}
		}) as ExtComponent;

		me.productsTreeGrid.wrapper = createContainer({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: 0,
				area: [12, 12, 12, [9, 3]]
			},
			items: [
				me.productsTreeGrid.titleContainer,
				me.productsTreeGrid.modesTabPanel,
				me.productsTreeGrid,
				{ xtype: 'tbspacer' },
				me.productsTreeGrid.productsTotalGrid
			]
		}) as ExtComponent;

		me.loadProductsIntoTree();
		me.loadTotalValues();

		return me.productsTreeGrid.wrapper;
	}

	createDetailsPanel(this: PackingList) {
		const me = this;

		return createFormForModule({
			cls: 'edi-details-panel packing-list',
			items: [
				me.createHeaderPanel(),
				me.createMainDataBlock(),
				me.createPartiesBlock(),
				me.createProductTreeGrid()
			]
		});
	}

	createModalPanel(this: PackingList) {
		const me = this;

		const modulePanel = createDetailsModulePanel() as ExtComponent;
		modulePanel.add(me.createDetailsPanel());

		me.moduleData.tab.add(me.createModuleActionsPanel());
		me.moduleData.tab.add(modulePanel);
	}

	setLoading(this: PackingList, { load = true, component }: { load?: boolean; component?: ExtComponent } = {}) {
		const me = this;
		component ??= me.moduleData.tab;
		if (component && !component.isDestroyed) {
			component.setLoading(load);
		}
	}

	loadContent() {
		const me = this;
		const url = edi.document.actions.formatDetailsUri(me.moduleData.initData);

		return edi.rest.asyncSendRequest({
			url,
			method: 'GET',
			params: {}
		});
	}

	async renderData(this: PackingList, initCallBack?: ModuleInitCallback) {
		const me = this;

		me.setLoading();

		const failure = edi.document.actions.defaultFailureHandler(me.moduleData.tab, 'error.getting.data', () =>
			edi.modulesHandler.removeModule(me.moduleData)
		);

		const { success, data } = await me.loadContent();

		if (success && data?.data) {
			me.documentContent = data.data;

			me.createModalPanel();
			me.setLoading({ load: false });

			if ('function' == typeof initCallBack) {
				initCallBack();
			}
		} else {
			failure(data);
		}
	}

	onRender(this: PackingList) {
		const me = this;
		edi.events.documents.on('change', me.changeHandler.bind(me));
	}

	onDestroy(this: PackingList) {
		const me = this;

		edi.events.documents.un('change', me.changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name, 'debug');
		return true;
	}
}

Ext.namespace('edi.modulesCfg');
edi.modulesCfg['packing.list'] = {
	title: 'packing.list.title',
	modName: 'packing.list',
	permissions: ['READ_PACKING_LIST']
};

Ext.namespace('edi.modules');
edi.modules['packing.list'] = PackingList;
