import { createProductGridBase } from '@Components/product.grid';
import {
	getAuthorityAreasByBpName,
	getInfoInvoicesByBpName,
	getPurposesByTypeAndFunction,
	getPurposesTypes,
	getPurposeTypeFromValue,
	getPurposeTypeValue,
	isSCHF_BusinessProcess,
	isDOP_BusinessProcess,
	isSCHFDOP_BussinessProcess,
	PURPOSES_TYPES,
	documentFunctionsComboItems
} from './methods';
import { createRowsBlock } from '@Components/createRows/create.rows';
import {
	createForm,
	createPanel,
	createContainer,
	createFieldBlock,
	createFieldSet,
	createModalForm,
	createButtonContainer,
	createMaxWidthContainer
} from '@UIkit/components/panels';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createAddModulePanel, createActionsPanel, createFormForModule } from '@Components/panels';
import {
	createCombo,
	createDateField,
	createHiddenField,
	createTextField,
	createNumberField
} from '@UIkit/components/fields';
import { createLabel } from '@UIkit/components/fields_old/Label';
import { createGrid, ROW_COLOR_CLS } from '@UIkit/components/grid';
import { PTYPE_COMBO_ITEMS_DISABLEABLE } from '@UIkit/plugins';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createLabelWithIcon } from '@Components/LabelWithIcon/LabelWithIcon';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { createSendButton } from '@Components/buttons';
import { showApprovalModal } from '@Ediweb/modules/APPROVAL_DOCUMENTS/methods';

const TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER = 'цена_с_ндс';

/**
 * @author Anna Sizova, Vsevolod Liapin
 * Class for new upd creation
 */
Ext.namespace('edi.modules');
edi.modules['document.create.fns_upd_5_01n'] = function () {
	let moduleData,
		parentData,
		isNumberAutoGenerated = false,
		parentId,
		id,
		signerFillFromCert = {},
		clearSecondSignerBtn,
		userData = edi.core.getUserData(),
		availableAreasData = [],
		productValues = { products: [] },
		isEdit = false,
		isCopy = false,
		initialData,
		isCreate = false,
		isCorrect = false,
		buyerOrg,
		buyerOrgValues,
		seller,
		buyer,
		shipFromOrg,
		deliveryOrg,
		cargoReceiver,
		authorityStatus,
		shipFrom,
		carrier,
		productsGrid,
		mainForm,
		invoiceDate,
		contract,
		signerAuthorityAreaValue,
		factorInfo,
		infoInvoiceCombo,
		availableInvoicesData,
		isCreatedFromDoc,
		documentData = {},
		bpName,
		contentAction,
		signerContainer,
		signerSecond = null,
		authorityAreaInput,
		secondAuthorityField,
		infoInvoiceValue,
		isSCHFSelected = false,
		paymentInformationColumns,
		invoiceTextInfosColumns,
		baseShipmentOfGoodsColumns,
		baseShipmentOfGoods,
		cargoReceiverAddressType,
		shipFromAddressType,
		transportAndCargoColumns,
		taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE,
		orgInfoFields = [],
		orgProductFields = [],
		shipFromInfoField,
		deliveryInfoField,
		isIP = userData.org.orgType === edi.constants.ORGANIZATION_TYPES.INDIVIDUAL,
		isPerson = userData.org.orgType === edi.constants.ORGANIZATION_TYPES.PERSON,
		shipDocConfirmationColumns,
		factors,
		assignmentBasisName,
		contentActionField,
		shipDocConfirmationName,
		shipDocConfirmationNumber,
		shipDocConfirmationDate,
		numberGovConField,
		dateGovContractField,
		okvStore,
		currencyName,
		orgNameInput,
		isHasWorkers = edi.stores.initPositionsStore().data.items.length > 0;
	let showProjectCombo, projectCombo, initialProjectValue, currentOrgProjects;
	let isFillingFromBackend = false;
	let isInitialBuild = false;
	let certSignerSettings;
	let PROJECT_ATTR_NAME = 'project_s';
	let PROJECT_ATTR_ID = 'projectId';
	let handingGoodsType = {
		sellerOrganizationWorker: '1',
		shippedGoodsWorker: '2',
		individualPersonShipsGoogs: '3'
	};
	const orgConverter = (org) => {
		let values = edi.converters.convertOrgToPartie(org);
		//addr_category переключает шаблон в новый режим
		//но данные сформированны для старого шаблона.
		//вероятно лучше исправить convertCommonAddress в шаблоне company.selector
		delete values.addr_category;
		return values;
	};
	let forceChangeFields = {
		addr_rus_zip: true,
		addr_rus_region: true,
		addr_rus_county: true,
		addr_rus_city: true,
		addr_rus_community: true,
		addr_rus_street: true,
		addr_rus_home: true,
		addr_rus_block: true,
		addr_rus_flat: true
	};

	const forceChangeFieldsForShips = Object.assign(
		{
			okpo: true,
			company_inn: true,
			company_kpp: true,
			company_name: true,
			individual_firstname: true,
			individual_lastname: true,
			individual_patronymicname: true,
			foreign_name: true,
			contact_phone: true,
			contact_email: true,
			bank_id: true,
			bank_name: true,
			bank_acc: true,
			bank_corr_acc: true,
			addr_for_country: true,
			addr_for_country_name: true,
			addr_for_text: true,
			structural_subdivision: true
		},
		forceChangeFields
	);

	let modalAdditionalConf = {
		company_gln: null,
		company_name: {
			allowBlank: false
		},
		company_inn: {
			allowBlank: false
		},
		main: {
			company_okpo: null,
			company_gln: null,
			individual_gln: null,
			individual_inn: {
				allowBlank: false
			}
		},
		address: {
			block: {
				maxLength: 20
			}
		},
		bank: {
			bank_acc: {
				allowBlank: true
			},
			bank_corr_acc: {
				minLength: 20,
				maxLength: 20
			},
			bank_id: {
				regex: edi.constants.VALIDATORS.BIK,
				invalidText: edi.i18n.getMessage('invalid.value.bank.id'),
				listeners: {
					change: function (cmp, newValue) {
						edi.methods.fillBankNameByBik(cmp, newValue);
					}
				}
			},
			bank_name: {
				maxLength: 1000
			}
		},
		contact: {
			fax: null
		}
	};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;

		showProjectCombo = edi.permissions.hasPermission('SELECT_PROJECT_UPD_5_01_N');
		initialProjectValue = edi.utils.getAttributeByName(moduleData.initData.data?.attributes, PROJECT_ATTR_ID);

		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		if (moduleData.initData.hasOwnProperty('cupd') && moduleData.initData.cupd) {
			// интересный вопрос: этот код сейчас вообще работает? В заголовке ИУПД ничего такого не видно
			documentData = moduleData.initData.cupd.document;
			isCorrect = true;
			if (moduleData.initData.meta.hasOwnProperty('agreement') && moduleData.initData.meta.agreement) {
				contract = moduleData.initData.meta.agreement;
			}
			buyerOrg = moduleData.initData.meta.toOrg;
			bpName = edi.utils.getAttributeByName(moduleData.initData.meta.attributes, 'bpName');
			getAuthorityAreasByBP();
			getInfoInvoicesByBP();
		} else if (moduleData.initData.data && moduleData.initData.data.data && moduleData.initData.data.bpName) {
			isCreatedFromDoc = true;
			documentData = edi.utils.getObjectProperty(moduleData.initData.data.data, 'document');
			documentData.skipGetObjectTotalProperty = true;
			bpName = moduleData.initData.data.bpName;
			getAuthorityAreasByBP();
			getInfoInvoicesByBP();
			isSCHFSelected = isSCHF_BusinessProcess(bpName);
			buyerOrg = moduleData.initData.data.buyerOrg;
			parentId = moduleData.initData.data.parentId;
			isEdit = true;
			isCreate = true;
		}

		if (buyerOrg && buyerOrg.id) {
			if (!buyerOrg.hasOwnProperty('attributes')) {
				buyerOrg = edi.utils.getOrg({
					orgId: buyerOrg.id
				});
			}
			buyerOrgValues = edi.converters.convertOrgToPartie(buyerOrg);
			taxCalculationMethod = edi.utils.getAttributeByName(buyerOrg.attributes, 'taxCalculationMethod');
			if (!taxCalculationMethod || taxCalculationMethod === 'null') {
				taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
			}
		}

		getFactors(function () {
			renderData(initCallBack);
		});

		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	//------------Manage functions----------------------

	const isValidOrganization = function (params) {
		params = params || {};

		const organization = params.organization;
		const organizationType = params.organizationType;
		const useOrganizationMap = params.useOrganizationMap;
		const getFieldValue = function (mapKey) {
			const organizationFieldsMap = {
				company_name: 'identification.legalEntity.orgName',
				company_kpp: 'identification.legalEntity.kpp',
				company_inn: 'identification.legalEntity.tin',

				individual_lastname: 'identification.individualPerson.name.lastName',
				individual_firstname: 'identification.individualPerson.name.firstName',
				individual_inn: 'identification.individualPerson.tin',
				okpo: 'okpo',

				person_lastname: 'identification.individualParticipant.name.lastName',
				person_firstname: 'identification.individualParticipant.name.firstName',
				person_inn: 'identification.individualParticipant.tin',

				physical_lastname: 'identification.individualParticipant.name.lastName',
				physical_firstname: 'identification.individualParticipant.name.firstName',
				physical_inn: 'identification.individualParticipant.tin',

				addr_rus_region: 'address.addressRu.regionCode',
				addr_for_country: 'address.addressInfo.codeOfCountry',
				addr_for_text: 'address.addressInfo.addressText',
				addr_code_gar: 'address.codeStateAddressRegister',

				bank_acc: 'bankInfo.accountNumber'
			};
			const fieldName = useOrganizationMap ? organizationFieldsMap[mapKey] : mapKey;
			return edi.utils.getObjectProperty(organization, fieldName);
		};

		let valid = getFieldValue('company_name');
		valid =
			valid ||
			(getFieldValue('individual_lastname') && getFieldValue('individual_firstname')) ||
			(getFieldValue('physical_lastname') && getFieldValue('physical_firstname'));

		if ('seller' === organizationType || 'buyer' === organizationType) {
			valid = valid && (getFieldValue('company_inn') || getFieldValue('individual_inn'));
			valid = valid && (!getFieldValue('company_inn') || getFieldValue('company_kpp'));
		}
		if (['cargoReceiver', 'cargoSender', 'carrier'].includes(organizationType)) {
			valid = valid || getFieldValue('foreign_name');
			valid = valid && (!getFieldValue('bank_acc') || getFieldValue('bank_acc').toString().length === 20);
		}
		valid =
			valid &&
			(getFieldValue('addr_rus_region') ||
				(getFieldValue('addr_for_country') && getFieldValue('addr_for_text')) ||
				getFieldValue('addr_code_gar'));
		return !!valid;
	};

	var setNameForCompanySelector = function (cmp, title) {
		if (cmp?.modalConf) {
			cmp.modalConf.title = edi.i18n.getMessage(title);
		}
	};

	//Checks if rows-panel has all rows empty
	const isEmptyValuesPanelFields = function (object) {
		const rows = object.items.items;
		for (let i = 0; i < rows.length; i++) {
			if (rows[i].isNonEmptyRow()) {
				return false;
			}
		}

		return true;
	};

	//Checks company controls, and marks them, if not valid
	const validateCompanySelectors = function () {
		const isBuyerValid = buyer.isValid(),
			isSellerValid = seller.isValid(),
			isCargoReceiverValid = cargoReceiver.isValid(),
			isShipFromValid = shipFrom.isValid(),
			isCarrier = carrier.isValid();

		return isBuyerValid && isSellerValid && isCargoReceiverValid && isShipFromValid && isCarrier;
	};
	//Checks document validity and enables/disables create button
	const checkValid = function () {
		if (!mainForm) {
			return false;
		}
		const productsLength = productValues.products.length;
		mainForm.isValid();
		const validForm = !mainForm.hasInvalidField();
		const validCompanies = validateCompanySelectors();
		const validGrid = productsGrid.isValid();
		return productsLength && validForm && validCompanies && validGrid;
	};

	const switchCompanyTypeBlocks = function (component) {
		edi.selectors.fieldControls.switchCompanyTypeBlocks(
			component,
			function (form, orgType, modal) {
				const isModalReadOnly = edi.utils.getObjectProperty(modal, 'modalConf.readOnly') || false,
					forceChangeFieldsMap = edi.utils.getObjectProperty(modal, 'forceChangeFields') || {},
					forceChangeAnyField = !!modal.forceChangeAnyField,
					fieldValues = modal ? modal.selectedOrgValues : null,
					fieldsForm = form.getForm();

				const enableFieldBlockByType = function (fieldName, type) {
					const field = fieldsForm.findField(fieldName);
					if (field && field.ownerCt) {
						edi.selectors.utils.enableFieldBlock(
							field.ownerCt,
							orgType === type,
							fieldValues,
							isModalReadOnly,
							forceChangeFieldsMap,
							forceChangeAnyField
						);
					}
				};
				enableFieldBlockByType('foreign_info', 'legalForeigner');
				enableFieldBlockByType('foreign_name', 'legalForeigner');
				enableFieldBlockByType('foreign_identifier', 'legalForeigner');
				enableFieldBlockByType('physical_inn', 'individualParticipant');
				enableFieldBlockByType('physical_requisites', 'individualParticipant');
				enableFieldBlockByType('physical_additInfo', 'individualParticipant');
				enableFieldBlockByType('physical_lastname', 'individualParticipant');
				enableFieldBlockByType('physical_firstname', 'individualParticipant');
				enableFieldBlockByType('physical_patronymicname', 'individualParticipant');
			},
			function (fieldValues) {
				let orgType;
				if (fieldValues.foreign_name) {
					orgType = 'legalForeigner';
				} else if (fieldValues.individual_firstname) {
					orgType = 'individual';
				} else if (fieldValues.company_name) {
					orgType = 'company';
				} else if (fieldValues.physical_firstname) {
					orgType = 'individualParticipant';
				}

				return orgType;
			}
		);
	};

	const switchAddressBlocks = function (addressTypeCombo) {
		const modal = addressTypeCombo.modalInstance;
		const form = modal ? modal.formPanel : null;
		const isModalReadOnly = edi.utils.getObjectProperty(modal, 'modalConf.readOnly') || false;
		const forceChangeFieldsMap = edi.utils.getObjectProperty(modal, 'forceChangeFields') || {};
		const forceChangeAnyField = !!modal.forceChangeAnyField;
		const value = addressTypeCombo.getValue();
		const org = modal ? modal.selectedOrgValues : null;
		const types = {
			0: 'rus',
			1: 'foreign',
			2: 'codeStateAddressRegister'
		};

		if (!form) {
			return;
		}
		const enableFieldBlockByType = function (fieldName, type) {
			const fields = form.down('textfield[name=' + fieldName + ']');
			if (fields) {
				const block = fields.up('fieldset');
				if (block) {
					edi.selectors.utils.enableFieldBlock(
						block,
						types[value] === type,
						org,
						isModalReadOnly,
						forceChangeFieldsMap,
						forceChangeAnyField
					);
				}
			}
		};
		enableFieldBlockByType('addr_rus_region', 'rus');
		enableFieldBlockByType('addr_for_text', 'foreign');
		enableFieldBlockByType('addr_code_gar', 'codeStateAddressRegister');

		if (form) {
			form.isValid();
		}
	};

	const getDefaultsNewFNSFields = (config = {}) => {
		return Ext.merge(
			{
				main: {
					company_data: {
						fields: {
							company_type_fields_block: {
								containerConf: {
									layout: {
										area: [4, [4, 4], 8, 8]
									}
								}
							},
							okpo: {
								name: 'company_okpo',
								title: 'company.okpo',
								emptyText: 'company.okpo',
								regex: edi.constants.VALIDATORS.OKPO,
								maskRe: /\d/i
							}
						}
					},
					company_type: edi.selectors.mergeConfigs('company_type_combo', {
						fieldConfig: {
							store: 'initCompany4TypeStore',
							disabled: function (org) {
								return (
									!!org &&
									(org.type === 'company' ||
										org.type === 'individual' ||
										org.type === 'legalForeigner' ||
										org.type === 'individualParticipant')
								);
							},
							listeners: {
								change: switchCompanyTypeBlocks,
								afterRender: switchCompanyTypeBlocks
							}
						}
					}),
					//foreign fields
					foreign_fields: {
						type: 'panel',
						panelConf: {
							layout: 'anchor',
							defaults: {
								anchor: '100%'
							}
						},
						fields: {
							foreign_name: {
								title: 'company.name',
								name: 'foreign_name',
								emptyText: 'company.name.comment',
								allowBlank: false,
								maxLength: 1000
							},
							foreign_identifier: {
								title: 'column.log.object.id',
								name: 'foreign_identifier',
								emptyText: 'column.log.object.id',
								allowBlank: true,
								maxLength: 1000
							},
							foreign_info: {
								title: 'company.type.legalForeigner.field.info',
								labelConf: {
									margin: 0
								},
								isTextarea: true,
								maxLength: 255,
								name: 'foreign_info'
							},
							info_for_members: {
								title: 'company.infoForMembers',
								name: 'info_for_members',
								allowBlank: true,
								maxLength: 255
							},
							short_name: {
								title: 'company.shortName',
								name: 'short_name',
								allowBlank: true,
								maxLength: 255
							}
						}
					},
					//physical fields
					physical_fields: {
						type: 'panel',
						panelConf: {
							layout: 'anchor',
							defaults: {
								anchor: '100%'
							}
						},
						fields: {
							physical_lastname: {
								name: 'physical_lastname',
								title: 'last.name',
								emptyText: 'company.last.name.comment',
								allowBlank: false
							},
							physical_name: {
								name: 'physical_firstname',
								title: 'first.name',
								emptyText: 'company.first.name.comment',
								allowBlank: false
							},
							physical_patronymicname: {
								name: 'physical_patronymicname',
								title: 'patronymic.name',
								emptyText: 'company.patronymic.name.comment'
							},
							physical_inn: {
								name: 'physical_inn',
								title: 'company.inn.individual',
								emptyText: 'company.inn.individual.error',
								maskRe: /\d/i,
								regex: edi.constants.VALIDATORS.INDI_INN,
								regexText: 'company.inn.individual.error',
								allowBlank: true
							},
							physical_requisites: {
								name: 'physical_requisites',
								title: 'company.physical.requisites',
								maxLength: 100,
								allowBlank: true
							},
							physical_additInfo: {
								name: 'physical_additInfo',
								title: 'company.physical.additInfo',
								maxLength: 225,
								allowBlank: true
							}
						}
					},
					individual_data: {
						fields: {
							individual_data_block: {
								containerConf: {
									layout: {
										area: [[4, 4], 12, 12, 8]
									}
								}
							}
						}
					}
				},
				address: {
					addressType: edi.selectors.mergeConfigs('address_type_combo', {
						fieldConfig: {
							store: 'initNewUPDAddressTypeStore',
							value: function (org) {
								return org.address_type == 0 || org.address_type == 1 || org.address_type == 2
									? org.address_type
									: 0;
							},
							listeners: {
								change: switchAddressBlocks,
								afterRender: switchAddressBlocks
							},
							disabled: false
						}
					}),
					codeStateAddressRegisterAddress: {
						type: 'fieldset',
						title: 'company.address.type.codeStateAddressRegister',
						fields: {
							addr_code_gar: {
								name: 'addr_code_gar',
								title: 'company.address.type.codeStateAddressRegister',
								emptyText: 'company.address.type.codeStateAddressRegister',
								maxLength: 36,
								allowBlank: false
							}
						}
					}
				}
			},
			config
		);
	};

	//Get authority areas related to selected BP name
	const getAuthorityAreasByBP = function () {
		availableAreasData = Ext.clone(
			edi.stores.getStoreItemsByIds(edi.stores.data.authorityAreas, getAuthorityAreasByBpName(bpName))
		);
	};

	//Get Info Invoices related to selected BP name
	const getInfoInvoicesByBP = function () {
		availableInvoicesData = Ext.clone(
			edi.stores.getStoreItemsByIds(edi.stores.data.infoInvoices, getInfoInvoicesByBpName(bpName))
		);
	};

	const getOrganizationAdditionalFields = function (orgId) {
		const failure = function (responseData) {
			const errorMsg = edi.utils.formatComplexServerError(responseData, 'org.document.data.load.failed');
			edi.core.showWarn(errorMsg, updateInfoFields);
		};

		if (!orgId) {
			updateInfoFields();
		} else {
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.DOCUMENTS.INFO_FIELDS.GET, {
					docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
					orgId: orgId
				}),
				'GET',
				null,
				function (data) {
					if (data && data.items) {
						updateInfoFields(data.items);
					} else {
						failure();
					}
				},
				failure
			);
		}
	};

	const getInfoFieldValueByMap = function (valueFieldName) {
		if (!valueFieldName) {
			return null;
		} else if (valueFieldName === 'sellerILN') {
			return userData.org.iln;
		} else if (valueFieldName === 'buyerILN' && buyerOrg) {
			return buyerOrg.iln;
		} else if (valueFieldName === 'shipFromILN' && shipFromOrg) {
			return shipFromOrg.iln || shipFromOrg.company_iln || shipFromOrg.individual_gln;
		} else if (valueFieldName === 'cargoReceiverILN' && deliveryOrg) {
			return deliveryOrg.iln || deliveryOrg.company_iln || deliveryOrg.individual_gln;
		}
		return valueFieldName;
	};

	const removeOrgAdditionalInfoFields = () => {
		const allRows = invoiceTextInfosColumns.getRows();
		const orgAdditionalRows = allRows.filter((row) => {
			const identifier = row.identifierInput.getValue();
			const isOrgAdditionalRow = invoiceTextInfosColumns.orgAdditionalInfoIdentifiers?.includes(identifier);
			return identifier && isOrgAdditionalRow;
		});
		orgAdditionalRows.forEach((row) => invoiceTextInfosColumns.remove(row));
		invoiceTextInfosColumns.orgAdditionalInfoIdentifiers = [];
	};

	//Update fields in "Text info" block of UPD
	const processOrgInvoiceInfos = function (orgAdditionalInfoFields) {
		orgInfoFields = orgAdditionalInfoFields;
		const isNewUPD = !isEdit && !isCorrect && !isCreatedFromDoc && !isCopy;

		const createTooltip = function (targetCmp, tooltipText) {
			targetCmp.toolTip = Ext.create('Ext.tip.ToolTip', {
				target: targetCmp.getEl(),
				trackMouse: true,
				html: tooltipText
			});

			targetCmp.on('destroy', function () {
				if (targetCmp.toolTip) {
					targetCmp.toolTip.destroy();
				}
			});
		};

		//Sets tooltip & value for row according to its config
		const updateRowDueToConfig = function (row, rowConfig) {
			if (rowConfig.tooltip) {
				createTooltip(row.identifierInput, rowConfig.tooltip);
			}

			//Remember  shipFrom or delivery row to set value for it when changing appropriate org in selector
			if (rowConfig.valueSrc === 'shipFromILN') {
				shipFromInfoField = row;
			} else if (rowConfig.valueSrc === 'cargoReceiverILN') {
				deliveryInfoField = row;
			}

			//Set values for field only when create UPD
			//For corrected UPD, UPD from transformation values shouldn't be replaced

			//In new UPD   OR  In UPD from transformation, fill field only if fieldValue is empty
			if (isNewUPD || ((isCopy || isCreatedFromDoc) && !row.valueInput.getValue())) {
				if (rowConfig.valueSrc) {
					row.valueInput.setValue(getInfoFieldValueByMap(rowConfig.valueSrc));
				} else if (!rowConfig.valueSrc && rowConfig.value) {
					row.valueInput.setValue(rowConfig.value);
				}
			}

			row.valueInput.isValid();
			row.identifierInput.isValid();
		};

		//Checks if there already exists a row with 'id' as an identifier
		const getIdentifierRow = function (id) {
			return invoiceTextInfosColumns.getRows().find(function (row) {
				return row.identifierInput.getValue() == id;
			});
		};

		orgAdditionalInfoFields.forEach(function (fieldConfig, fieldIndex) {
			let row = getIdentifierRow(fieldConfig.infoId),
				isMandatoryField = fieldConfig.required;

			//For editing UPD  we don't add rows, just check already added
			if (row) {
				row.identifierInput.setReadOnly(true);
				row.valueInput.allowBlank = !isMandatoryField;
				row.removeBtn.setDisabled(isMandatoryField);
			} else if (isNewUPD || isCreatedFromDoc || isCopy) {
				//For creating UPD we add info row if there is no such row yet
				row = invoiceTextInfosColumns.createRowWithAddRemoveButtons(
					!fieldIndex,
					{
						identifier: fieldConfig.infoId
					},
					{
						hideAddButton: true,
						isMandatory: isMandatoryField,
						disableChangeIdentifier: true,
						disableRemoveButton: isMandatoryField
					}
				);

				invoiceTextInfosColumns.insert(0, row);
			}
			invoiceTextInfosColumns.orgAdditionalInfoIdentifiers?.push(fieldConfig.infoId);

			if (row) {
				updateRowDueToConfig(row, fieldConfig);
			}
		});
	};

	//Shows additional text info fields, specified for selected buyer
	const updateInfoFields = function (organizationInfoFields) {
		organizationInfoFields = organizationInfoFields || [];
		if (!!productsGrid?.store) {
			productsGrid.reconfigColumns(edi.methods.createInfoColumns(organizationInfoFields));
		}

		let invoiceInfoObj =
				organizationInfoFields.find(function (item) {
					return item.docPart === 'InvoiceTextInfos';
				}) || {},
			productInfosObj =
				organizationInfoFields.find(function (item) {
					return item.docPart === 'ProductTextInfos';
				}) || {},
			invoiceInfoFields = edi.utils.getObjectProperty(invoiceInfoObj, 'fieldInfo', true),
			productFields = edi.utils.getObjectProperty(productInfosObj, 'fieldInfo', true);

		if (!isEdit && !isCopy) {
			invoiceTextInfosColumns.getRows().forEach(function (row) {
				row.identifierInput.allowBlank = true;
				row.identifierInput.setReadOnly(false);
				row.identifierInput.isValid();

				row.valueInput.allowBlank = true;
				row.valueInput.isValid();

				row.removeBtn.setDisabled(false);
			});
		}

		orgProductFields = productFields.map(function (fieldConfig) {
			return {
				identifier: fieldConfig.infoId,
				value: !fieldConfig.valueSrc && fieldConfig.value ? fieldConfig.value : '',
				allowEmptyValue: !fieldConfig.required,
				infoFieldTooltip: fieldConfig.tooltip || null,
				valueSrc: fieldConfig.valueSrc || null
			};
		});

		if (invoiceInfoFields.length) {
			processOrgInvoiceInfos(invoiceInfoFields);
		}
	};
	const validateGovContractFields = function (value) {
		dateGovContractField.allowBlank = !value;
		numberGovConField.allowBlank = !value;
		dateGovContractField.isValid();
		numberGovConField.isValid();
	};

	const isSCHFTransportFieldsEmpty = function () {
		let allFieldsEmpty = carrier.isEmptyValues(),
			i,
			fieldInput;
		const fieldsToCheck = [
			'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.position',
			'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.firstName',
			'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.lastName',
			'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.middleName',
			'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.authority',
			'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.orgName',
			transportAndCargoColumns,
			baseShipmentOfGoodsColumns,
			'receiptPartDoc.transferInformation.thingTransferInfo.info',
			'receiptPartDoc.transferInformation.thingTransferInfo.date',
			'receiptPartDoc.transferInformation.contentAction',
			'receiptPartDoc.transferInformation.typeAction',
			'receiptPartDoc.transferInformation.shippingDate',
			'receiptPartDoc.transferInformation.startingDate',
			'receiptPartDoc.transferInformation.endingDate'
		];

		for (i = 0; i < fieldsToCheck.length && allFieldsEmpty; i++) {
			if ('string' == typeof fieldsToCheck[i]) {
				fieldInput = mainForm.down('[name=' + fieldsToCheck[i] + ']');
				allFieldsEmpty = !fieldInput.getValue();
			} else if ('object' == typeof fieldsToCheck[i]) {
				allFieldsEmpty = isEmptyValuesPanelFields(fieldsToCheck[i]);
			}
		}
		return allFieldsEmpty;
	};

	const checkSCHFTransportFieldsIfNeeded = function (allSCHFTransportFieldsIsEmpty) {
		if (!isSCHFSelected) {
			return;
		}
		let allFieldsEmpty = allSCHFTransportFieldsIsEmpty || isSCHFTransportFieldsEmpty(),
			i,
			fieldInput;

		const mandatoryFields = [
			'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.position',
			'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.firstName',
			'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.lastName',
			'receiptPartDoc.transferInformation.contentAction'
		];

		let fieldName = '';
		for (i = 0; i < mandatoryFields.length; i++) {
			let allowBlank = !!allFieldsEmpty;
			if ('string' == typeof mandatoryFields[i]) {
				fieldName = mandatoryFields[i];
			} else if ('object' == typeof mandatoryFields[i] && mandatoryFields[i].comp && mandatoryFields[i].field) {
				fieldName = mandatoryFields[i].field;
				if (!isEmptyValuesPanelFields(mandatoryFields[i].comp)) {
					allowBlank = false;
				}
			}
			fieldInput = mainForm.down('[name=' + fieldName + ']');
			if (fieldInput.isVisible()) {
				fieldInput.allowBlank = allowBlank;
			}
			fieldInput.validate(fieldInput.getValue());
		}

		const noAllowBlankFieldsName = ['receiptPartDoc.transferInformation.contentAction'];
		noAllowBlankFieldsName.forEach(function (field) {
			let fieldInput = mainForm.down('[name=' + field + ']');
			fieldInput.allowBlank = true;
			fieldInput.validate(fieldInput.getValue());
		});

		mainForm.isValid();
	};

	const checkCarrierSelector = function () {
		carrier.is_valid =
			isValidOrganization({
				organization: carrier.getValues(),
				organizationType: 'carrier'
			}) ||
			(carrier.isEmptyValues() && carrier.allowBlank);

		carrier.isValid();
	};

	const checkMandatoryFields = function () {
		const changeMandatoryfieldsName = [
			'receiptPartDoc.transferInformation.contentAction',
			'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.position',
			'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.firstName',
			'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.lastName'
		];

		for (let i = 0; i < changeMandatoryfieldsName.length; i++) {
			let fieldInput = mainForm.down('[name=' + changeMandatoryfieldsName[i] + ']');
			if (fieldInput && fieldInput.isVisible()) {
				fieldInput.allowBlank = isSCHFSelected;
			}
		}

		let authorityAreaInp = mainForm.down('[name=signer.authorityArea]');
		authorityAreaInp.setValue(null);

		if (isSCHFSelected || isDOP_BusinessProcess(bpName)) {
			checkSCHFTransportFieldsIfNeeded();
		}
		mainForm.isValid();
	};

	const checkSignersFields = function (field, addSignerFieldsHandler) {
		let value = field.getValue();
		if (isSCHFDOP_BussinessProcess(bpName) && ['0', '1', '2', '3'].indexOf(value) > -1) {
			if (!signerSecond?.length && addSignerFieldsHandler) {
				signerSecond = signerContainer.add(
					addSignerFieldsHandler({}, true, edi.i18n.getMessage('company.signer') + ' 2')
				);
				signerContainer.layout.redoLayout();
			}
		} else if (signerSecond?.length) {
			for (let i = 0; i < signerSecond.length; i++) {
				signerContainer.remove(signerSecond[i]);
			}
			signerSecond = null;
			secondAuthorityField = null;
			signerContainer.layout.redoLayout();
		}
		checkValid();
	};

	//Converts productValues to suitable object
	const getProductsData = function (productValues) {
		let okeiStore = edi.stores.initOkeiStore();

		productValues.products.forEach(function (product) {
			edi.utils.getObjectProperty(product, 'productOkei').length > 0
				? edi.utils.setObjectProperty(product, 'defProductOkei', null)
				: edi.utils.setObjectProperty(product, 'defProductOkei', '-');
			String(edi.utils.getObjectProperty(product, 'taxSum.taxSum')).length > 0
				? edi.utils.setObjectProperty(product, 'taxSum.defTax', null)
				: edi.utils.setObjectProperty(product, 'taxSum.defTax', '-');

			edi.utils.getObjectProperty(product, 'customsDeclarationInfo', true).forEach(function (item) {
				edi.utils.getObjectProperty(item, 'countryOrigin') !== ''
					? edi.utils.setObjectProperty(item, 'defCountryOrigin', null)
					: edi.utils.setObjectProperty(item, 'defCountryOrigin', '-');
			});
			edi.utils.setObjectProperty(product, 'identifyProductToolNumbers', null);

			edi.utils.getObjectProperty(product, 'additionalProductInfo.infoTracking', true).forEach(function (item) {
				const trackingUnitMes = edi.utils.getObjectProperty(item, 'trackingUnitMes');
				let okeiRec = okeiStore.findRecord('id', trackingUnitMes, 0, false, false, true);
				edi.utils.setObjectProperty(item, 'trackingUnitMesName', okeiRec?.get('name') || trackingUnitMes);
			});

			let productTextInfos = edi.utils.getObjectProperty(product, 'textInfos', true);
			if (productTextInfos.length) {
				productTextInfos = productTextInfos.filter(function (info) {
					return info.identifier && info.value;
				});

				product.textInfos = productTextInfos;
			}
		});

		return {
			productInfo: productValues.products,
			totalPay: {
				totalTaxAmount: {
					taxSum: productValues.totalNDS,
					defTax: String(productValues.totalNDS).length > 0 ? null : '-'
				},
				totalNumber: productValues.products.length,
				totalWithoutNDS: productValues.totalWithoutNDS,
				defTotalWithNDS: String(productValues.totalWithNDS).length > 0 ? null : '-',
				totalWithNDS: productValues.totalWithNDS
			}
		};
	};

	const convertHandingGoodsValues = function (values) {
		let handingGoodsTypeValue = edi.utils.getObjectProperty(values, 'handingGoodsType'),
			handingGoods;
		delete values.handingGoodsType;

		switch (handingGoodsTypeValue) {
			case handingGoodsType.sellerOrganizationWorker:
				delete values.orgName;
				handingGoods = {
					sellerOrganizationWorker: values
				};
				//handingGoods.sellerOrganizationWorker.baseAuthority = handingGoods.sellerOrganizationWorker.authority;
				delete handingGoods.sellerOrganizationWorker.authority;
				break;
			case handingGoodsType.shippedGoodsWorker:
				handingGoods = {
					anotherPerson: {
						shippedGoodsWorker: values
					}
				};
				handingGoods.anotherPerson.shippedGoodsWorker.additionalInfo =
					handingGoods.anotherPerson.shippedGoodsWorker.additInfo;
				delete handingGoods.anotherPerson.shippedGoodsWorker.additInfo;
				if (!!handingGoods.anotherPerson.shippedGoodsWorker.baseAuthority) {
					handingGoods.anotherPerson.shippedGoodsWorker.rightPresentResults =
						handingGoods.anotherPerson.shippedGoodsWorker.baseAuthority;
					delete handingGoods.anotherPerson.shippedGoodsWorker.baseAuthority;
				} else if (!!handingGoods.anotherPerson.shippedGoodsWorker.authority) {
					handingGoods.anotherPerson.shippedGoodsWorker.rightPresentResults =
						handingGoods.anotherPerson.shippedGoodsWorker.authority;
					delete handingGoods.anotherPerson.shippedGoodsWorker.authority;
				}

				break;
			case handingGoodsType.individualPersonShipsGoogs:
				delete values.orgName;

				handingGoods = {
					anotherPerson: {
						individualPersonShipsGoogs: values
					}
				};
				handingGoods.anotherPerson.individualPersonShipsGoogs.additionalInfo =
					handingGoods.anotherPerson.individualPersonShipsGoogs.additInfo;
				delete handingGoods.anotherPerson.individualPersonShipsGoogs.additInfo;
				handingGoods.anotherPerson.individualPersonShipsGoogs.authority =
					handingGoods.anotherPerson.individualPersonShipsGoogs.baseAuthority;
				delete handingGoods.anotherPerson.individualPersonShipsGoogs.baseAuthority;
				delete handingGoods.anotherPerson.individualPersonShipsGoogs.position;

				break;
		}
		return handingGoods;
	};

	/**
	 * Compare text infos values with values in valueSrc
	 * @param      infos                Text infos array
	 * @param      fieldsToCheckMap     Map of fieldNames to check and their valueSrc-s
	 * @param      successCallback      Callback to call after "Ok" button pressed
	 */
	const checkSpecifiedInfosActuality = function (infos, fieldsToCheckMap, successCallback) {
		let notActual = [],
			valueSrc = null,
			actualValue;

		const showCompareModal = function (notActualIdentifiers) {
			// Creates html of non-equal values
			const createList = function (items) {
				let html,
					item,
					emptyValue = edi.i18n.getMessage('form.combo.not.selected'),
					infoIdHeader = edi.i18n.getMessage('documents.fns_upd.special_identifiers.compare.infoId'),
					currentValueHeader = edi.i18n.getMessage(
						'documents.fns_upd.special_identifiers.compare.currentValue'
					),
					actualValueHeader = edi.i18n.getMessage(
						'documents.fns_upd.special_identifiers.compare.actualValue'
					);

				html =
					"<table width='100%'>" +
					'<thead>' +
					"<th width='30%'>" +
					infoIdHeader +
					'</th>' +
					"<th width='30%'>" +
					currentValueHeader +
					'</th>' +
					"<th width='30%'>" +
					actualValueHeader +
					'</th>' +
					'</thead>' +
					'<tbody>';

				for (let i = 0; i < items.length; i++) {
					item = items[i];
					html +=
						"<td class='edi-label label-center'>" +
						item.identifier +
						'</td>' +
						"<td class='edi-label label-center edi-data-good'>" +
						(item.actualValue || emptyValue) +
						'</td>' +
						"<td class='edi-label label-center edi-data-error'>" +
						(item.currentValue || emptyValue) +
						'</td></tr>';
				}

				html += '</tbody></table>';
				return html;
			};

			const str = edi.i18n.getMessage('documents.fns_upd.special_identifiers.compare.warn');

			let modal = createModalPanel(
				{
					width: MODAL_SIZE.widthMedium,
					title: edi.i18n.getMessage('documents.fns_upd.special_identifiers.compare.title'),
					layout: 'fit',
					items: [
						createForm({
							bodyPadding: 10,
							items: [
								createLabel({
									html: edi.utils.formatString(str, [createList(notActualIdentifiers)])
								})
							],
							buttons: [
								createButton({
									cls: BUTTON_CLS.primary,
									text: edi.i18n.getMessage('documents.fns_upd.special_identifiers.compare.save'),
									formBind: true,
									disabled: true,
									handler: function () {
										modal.close();
										if ('function' == typeof successCallback) {
											successCallback();
										}
									}
								}),
								createButton({
									cls: BUTTON_CLS.secondary,
									text: edi.i18n.getMessage('btn.cancel'),
									glyph: edi.constants.ICONS.CANCEL,
									handler: function () {
										moduleData.tab.setLoading(false);
										modal.close();
									}
								})
							]
						})
					],
					listeners: {
						close: function () {
							moduleData.tab.setLoading(false);
						}
					}
				},
				true
			);
			modal.show();
		};

		infos.forEach(function (info) {
			if (fieldsToCheckMap.hasOwnProperty(info.identifier)) {
				valueSrc = fieldsToCheckMap[info.identifier];
				actualValue = getInfoFieldValueByMap(valueSrc);
				if (actualValue && info.value !== actualValue) {
					notActual.push({
						identifier: info.identifier,
						currentValue: info.value,
						actualValue: actualValue
					});
				}
			}
		});

		if (notActual.length) {
			showCompareModal(notActual);
		} else if ('function' == typeof successCallback) {
			successCallback();
		}
	};

	/**
	 * Get projects by organization's id and set store and value in the projectCombo
	 * @param	{Number}	orgId
	 * @param	{String}	forcedNewValue
	 * @returns	{Promise<void>}
	 */
	let updateProjectsByOrg = async function (orgId, newValue) {
		if (!showProjectCombo || !projectCombo) {
			return;
		}

		let prevValue = newValue || projectCombo.getValue();
		//В приоритете проекты покупателя, если их нет то своей организации
		if (orgId) {
			let buyersProjects = await getBuyerProjects(orgId);
			projectCombo.setItems(buyersProjects.length > 0 ? buyersProjects : currentOrgProjects);
		} else {
			projectCombo.setItems(currentOrgProjects);
		}

		//Восстановим значение в комбо, если оно есть в сторе
		if (
			projectCombo
				.getStore()
				.getRange()
				.some((p) => p.get('id') === prevValue)
		) {
			projectCombo.setValue(prevValue);
		} else {
			projectCombo.setValue(null);
		}
	};

	let getProjectName = function (val) {
		if (val) {
			let prj_name = edi.i18n.getMessage('project.name.' + val);
			return prj_name !== 'project.name.' + val ? prj_name : val;
		}
		return '';
	};

	/**
	 * Gets buyer's  projects
	 * @return {Promise<Object[]>}
	 */
	let getBuyerProjects = (buyerId) =>
		new Promise((resolve) => {
			if (!showProjectCombo) {
				resolve();
				return;
			}

			const failure = function (resp) {
				edi.core.showError(edi.utils.formatComplexServerError(resp, 'error.getting.projects'));
				resolve([]);
			};

			const success = function (resp) {
				if (Array.isArray(resp.items)) {
					resolve(
						resp.items.map((proj) => ({
							id: proj.id,
							name: getProjectName(proj.name)
						}))
					);
				} else {
					resolve([]);
				}
			};

			let url = edi.utils.formatString(edi.rest.services.PROJECTS.PARTNERS_PROJECTS.GET, {
				partnerId: buyerId
			});
			edi.rest.sendRequest(url, 'GET', null, success, failure);
		});

	/**
	 * Gets current organization's projects
	 * @return {Promise<Object[]>}
	 */
	let getCurrentOrgProjects = () =>
		new Promise((resolve) => {
			if (!edi.permissions.hasPermission('READ_PROJECT_AVAILABLE')) {
				resolve([]);
				return;
			}

			const failure = function (resp) {
				edi.core.showError(edi.utils.formatComplexServerError(resp, 'error.getting.projects'));
				resolve([]);
			};

			const success = function (resp) {
				if (Array.isArray(resp.items)) {
					resolve(
						resp.items.map((proj) => ({
							id: proj.id,
							name: getProjectName(proj.name)
						}))
					);
				} else {
					resolve([]);
				}
			};

			edi.rest.sendRequest(edi.rest.services.PROJECTS.OWN_PROJECTS.GET, 'GET', null, success, failure);
		});

	/**
	 * Retrieve current organization's, and buyer's  projects and init projectCombo
	 * @param	{Function}	callback
	 */
	let initProjectCombo = async function (callback) {
		let cb = typeof callback === 'function' ? callback : () => {};

		if (!showProjectCombo) {
			cb();
			return;
		}

		currentOrgProjects = await getCurrentOrgProjects();
		updateProjectsByOrg(buyer.selectedOrg?.id, initialProjectValue);

		cb();
	};

	/**
	 * Reads factors data from backend
	 * @param    {Function}    callback     callback that will be called on success
	 */
	const getFactors = function (callback) {
		const success = function (data) {
			factors = [];
			if (data && data.items && data.items.length) {
				for (let i = 0; i < data.items.length; i++) {
					factors.push(edi.models.createInstance('ORGANIZATION', data.items[i]).getData());
				}
			}
			'function' == typeof callback ? callback() : null;
		};
		const failure = function (data) {
			edi.core.handleException('Factors did not loaded properly');
			edi.core.showError(
				edi.utils.formatComplexServerError(data, 'offset.error.getting.factors.data'),
				function () {
					edi.modulesHandler.removeModule(moduleData);
				}
			);
		};
		edi.rest.sendRequest(edi.rest.services.FACTORS.GET, 'GET', null, success, failure);
	};

	const createOS = function (selectorConfig) {
		Ext.applyIf(selectorConfig, {
			cls: '',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			selectedRelationByMap: true,
			valuesByMap: true,
			useHiddenFields: true,
			disableAutoValidation: true,
			hideInTemplate: ['company_iln'],
			disableCommonAddressRenderer: true,
			disableCommonAddressTitleRenderer: true,
			forceChangeFields: {
				bank_id: true,
				bank_corr_acc: true,
				bank_name: true,
				bank_acc: true
			}
		});
		return createOrgSelector(selectorConfig);
	};

	const clearSecondSigner = function () {
		const filds = [
			'signer.legalEntity.position',
			!isIP ? 'signer.legalEntity.fullName.firstName' : 'signer.individualPerson.name.firstName',
			!isIP ? 'signer.legalEntity.fullName.lastName' : 'signer.individualPerson.name.lastName',
			!isIP ? 'signer.legalEntity.fullName.middleName' : 'signer.individualPerson.name.middleName',
			'signer.authorityArea',
			'signer.baseAuthory',
			'signer.baseOrgAuthory',
			'signer.state'
		];

		if (signerSecond && signerSecond.length) {
			for (let j = 0; j < filds.length; j++) {
				for (let i = 0, len = signerSecond.length; i < len; i++) {
					let elm = signerSecond[i].down('[name=' + filds[j] + ']');
					if (elm) {
						elm.setValue('');
					}
				}
			}
		}
		clearSecondSignerBtn.setDisabled(true);
	};

	const showModalListOfSecondSigner = function () {
		const rowClick = function (record) {
			moduleData.tab.setLoading(true);
			const data = record.getData(),
				mapper = {
					'subjectName.position': 'signer.legalEntity.position',
					'subjectName.firstname': !isIP
						? 'signer.legalEntity.fullName.firstName'
						: 'signer.individualPerson.name.firstName',
					'subjectName.lastname': !isIP
						? 'signer.legalEntity.fullName.lastName'
						: 'signer.individualPerson.name.lastName',
					'subjectName.middlename': !isIP
						? 'signer.legalEntity.fullName.middleName'
						: 'signer.individualPerson.name.middleName'
				},
				second_signer_default = {
					authorityArea: '6',
					baseAuthory: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default'),
					baseOrgAuthory: '',
					state: '1'
				};

			if (data && data.user && data.user.id) {
				clearSecondSignerBtn.setDisabled(false);
				edi.rest.sendRequest(
					edi.rest.services.SIGNER.SIGNERS.GET,
					'GET',
					{
						direction: edi.constants.DIRECTIONS.OUTBOX,
						userId: data.user.id,
						docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
					},
					function (headerData) {
						let prop, elm;
						if (signerSecond && signerSecond.length) {
							for (prop in mapper) {
								for (let i = 0; i < signerSecond.length; i++) {
									if (mapper.hasOwnProperty(prop)) {
										if (signerSecond[i] instanceof UI.components.FieldBlock) {
											elm = signerSecond[i]?.down('[name=' + mapper[prop] + ']');
											if (elm) {
												elm.setValue(edi.utils.getObjectProperty(data, prop));
												break;
											}
										}
									}
								}
							}
							if (headerData && headerData.items) {
								const itemsLen = headerData.items.length;
								let item; // must be One item or Zero
								if (itemsLen) {
									item = headerData.items[0]; // must be One item or Zero
								}
								isFillingFromBackend = true;
								const fillOrder = ['authorityArea', 'state', 'baseAuthory', 'baseOrgAuthory'];
								for (let k = 0; k < fillOrder.length; ++k) {
									if (second_signer_default.hasOwnProperty(fillOrder[k])) {
										prop = fillOrder[k];
										for (let j = 0, len = signerSecond.length; j < len; j++) {
											if (signerSecond[j] instanceof UI.components.FieldBlock) {
												elm = signerSecond[j].down('[name=' + 'signer.' + prop + ']');
												if (elm) {
													if (item && item.hasOwnProperty(prop)) {
														if (
															!(
																'authorityArea' === prop &&
																authorityAreaInput.getValue() === item[prop]
															)
														) {
															elm.setValue(item[prop]);
														}
													} else if (null !== second_signer_default[prop]) {
														elm.setValue(second_signer_default[prop]);
													}
													break;
												}
											}
										}
									}
								}
								isFillingFromBackend = false;
							}
						}
					},
					undefined
				);
			}
			moduleData.tab.setLoading(false);
			modal.close();
		};

		let grid = createGrid({
			proxyConfig: {
				type: 'ajax',
				url: edi.utils.formatString(edi.rest.services.USER.CERTIFICATE.CERTIFICATES.ACTIVE.GET, {
					type: 'EDI_FNS_UPD'
				}),
				extraParams: {
					excludeCurrentUser: true
				}
			},
			storeConfig: {
				model: edi.models.getModel('USER_CERT'),
				remoteSort: true,
				sortOnLoad: true,
				sorters: {
					property: 'id',
					direction: 'ASC'
				}
			},
			gridConfig: {
				cls: 'modal-grid',
				region: 'center',
				columns: edi.columns.get('user_cert'),
				disablePaging: false,
				disableSelection: false,
				enableTextSelection: true,
				checkboxes: false,
				listeners: {
					select: function (comp, record) {
						rowClick(record);
					}
				},
				dockedItems: undefined
			}
		});

		let modal = createModalPanel({
			cls: 'edi-modal-documents-selector',
			title: edi.i18n.getMessage('btn.tooltip.secondSigner'),
			width: MODAL_SIZE.widthLarge,
			height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
			layout: 'fit',
			items: [
				createModalForm({
					items: [grid]
				})
			],
			buttonsBefore: []
		});
		modal.show();
	};

	const getSignerPathForTypeOfOwnership = function () {
		if (isIP) return 'individualPerson';
		if (isPerson) return 'individual';
		return 'legalEntity';
	};

	const createSignerFields = function (signer, isSecond, signerTitle) {
		const hasPermission =
			edi.permissions.hasPermission('READ_CERTIFICATE_DOCUMENT') &&
			edi.permissions.hasPermission('CLIENT_READ_EDI_SIGNER');

		clearSecondSignerBtn = hasPermission
			? createButton({
					cls: [BUTTON_CLS.light, BUTTON_CLS.small],
					tooltip: edi.i18n.getMessage('btn.tooltip.reset'),
					text: '',
					disabled: true,
					glyph: edi.constants.ICONS.HIGHLIGHT_OFF,
					handler: clearSecondSigner
			  })
			: null;

		const topText = !!isSecond
			? createLabelWithIcon({
					padding: 16,
					text: edi.i18n.getMessage('documents.fns_upd.signer.beforeSecondSigner'),
					glyph: edi.constants.ICONS.REJECT
			  })
			: null;

		const getSecondSignerBtn = !!isSecond
			? createButton({
					width: 200,
					margin: '8 0 16 0',
					cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
					tooltip: edi.i18n.getMessage('btn.tooltip.secondSigner'),
					text: edi.i18n.getMessage('form.btn.select.product'),
					disabled: false,
					glyph: edi.constants.ICONS.SEARCH,
					handler: showModalListOfSecondSigner
			  })
			: null;

		const baseAuthory = createContainer({
			items: [
				createTextField({
					maxLength: 255,
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
					allowBlank: false,
					name: 'signer.baseAuthory',
					value: edi.utils.getObjectProperty(signer, 'baseAuthory')
				})
			]
		});

		const baseOrgAuthory = createContainer({
			items: [
				createTextField({
					maxLength: 255,
					name: 'signer.baseOrgAuthory',
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
					value: edi.utils.getObjectProperty(signer, 'baseOrgAuthory'),
					listeners: {
						change: function (comp) {
							const value = comp.getValue(),
								authorityInput = authorityAreaAndStatus.down('[name=signer.state]'),
								baseOrgAuthoryInput = baseOrgAuthory.down('[name=signer.baseOrgAuthory]');

							if (value && authorityInput) {
								baseOrgAuthoryInput.allowBlank = authorityInput.getValue() !== '3';
								baseOrgAuthoryInput.isValid();
							}
							comp.isValid();
						}
					}
				})
			]
		});

		const signerPositionCombo = !isIP
			? createCombo({
					fieldLabel: edi.i18n.getMessage('job.title'),
					store: edi.stores.initPositionsStore(),
					displayField: 'shortName',
					autoSelect: true,
					forceSelection: false,
					maxLength: 128,
					allowBlank: false,
					qtipText: !isSecond
						? signerFillFromCert.position
							? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
							: edi.i18n.getMessage('document.signer.get.data.from.cert')
						: undefined,
					readOnly: !isSecond,
					name: 'signer.legalEntity.position',
					value: edi.utils.getObjectProperty(signer, 'legalEntity.position')
			  })
			: null;

		const signerSection = createContainer({
			layout: {
				type: 'grid',
				area: [[0, 3, 3, 2, 2]]
			},
			items: [
				createHiddenField({
					value: userData.org.inn,
					name: `signer.${getSignerPathForTypeOfOwnership()}.tin`
				}),
				signerPositionCombo,
				createTextField({
					allowBlank: false,
					qtipText: !isSecond
						? signerFillFromCert.lastName
							? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
							: edi.i18n.getMessage('document.signer.get.data.from.cert')
						: undefined,
					readOnly: !isSecond,
					fieldLabel: edi.i18n.getMessage('last.name'),
					value:
						edi.utils.getObjectProperty(signer, 'legalEntity.fullName.lastName') ||
						edi.utils.getObjectProperty(signer, 'individualPerson.name.lastName') ||
						edi.utils.getObjectProperty(signer, 'individual.name.lastName'),
					name: !isIP
						? `signer.${getSignerPathForTypeOfOwnership()}.fullName.lastName`
						: `signer.${getSignerPathForTypeOfOwnership()}.name.lastName`
				}),
				createTextField({
					allowBlank: false,
					maxLength: 60,
					qtipText: !isSecond
						? signerFillFromCert.firstName
							? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
							: edi.i18n.getMessage('document.signer.get.data.from.cert')
						: undefined,
					readOnly: !isSecond,
					fieldLabel: edi.i18n.getMessage('first.name'),
					value:
						edi.utils.getObjectProperty(signer, 'legalEntity.fullName.firstName') ||
						edi.utils.getObjectProperty(signer, 'individualPerson.name.firstName') ||
						edi.utils.getObjectProperty(signer, 'individual.name.firstName'),
					name: !isIP
						? `signer.${getSignerPathForTypeOfOwnership()}.fullName.firstName`
						: `signer.${getSignerPathForTypeOfOwnership()}.name.firstName`
				}),
				createTextField({
					allowBlank: true,
					qtipText: !isSecond
						? signerFillFromCert.middleName
							? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
							: edi.i18n.getMessage('document.signer.get.data.from.cert')
						: undefined,
					readOnly: !isSecond,
					fieldLabel: edi.i18n.getMessage('patronymic.name'),
					value:
						edi.utils.getObjectProperty(signer, 'legalEntity.fullName.middleName') ||
						edi.utils.getObjectProperty(signer, 'individualPerson.name.middleName') ||
						edi.utils.getObjectProperty(signer, 'individual.name.middleName'),
					name: !isIP
						? `signer.${getSignerPathForTypeOfOwnership()}.fullName.middleName`
						: `signer.${getSignerPathForTypeOfOwnership()}.name.middleName`
				})
			]
		});

		const certificateRegistrationIP = isIP
			? createContainer({
					layout: {
						type: 'grid',
						area: [10]
					},
					items: [
						createTextField({
							fieldLabel: edi.i18n.getMessage('individual.certificateRegistration'),
							maxLength: 255,
							name: `signer.individualPerson.certificateRegistrationIP`,
							value: edi.utils.getObjectProperty(
								signer,
								`${getSignerPathForTypeOfOwnership()}.certificateRegistrationIP`
							)
						})
					]
			  })
			: null;

		// If editing or for second signer we load store manually
		let authorityArea = createCombo({
			name: 'signer.authorityArea',
			fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
			store: edi.stores.createInlineStore(Ext.clone(availableAreasData), 'AUTHORITY_AREA'),
			displayField: 'name',
			value: (signerAuthorityAreaValue = edi.utils.getObjectProperty(signer, 'authorityArea')),
			allowBlank: false,
			blankText: undefined,
			listeners: !isSecond
				? {
						change: function (comp) {
							if (!isSecond && !isInitialBuild) {
								checkSignersFields(comp, createSignerFields);
							}
						}
				  }
				: undefined,
			validator: function (val) {
				if (!val) {
					if (!isEdit && !isCopy && !availableAreasData.length) {
						return edi.i18n.getMessage('document.fns_upd.field.authorityArea.mandatory.qtip');
					} else {
						return true; // EW override
					}
				}
				return true;
			},
			forceSelection: true
		});

		if (!isSecond) {
			authorityAreaInput = authorityArea;
			authorityArea.on('select', function (comp) {
				if (secondAuthorityField && comp.getValue() == secondAuthorityField.getValue()) {
					secondAuthorityField.setValue('');
				}
			});
		} else {
			secondAuthorityField = authorityArea;
			authorityArea.on('expand', function (comp) {
				let secondSignerAreaStore = comp.getStore();
				let firstSignerArea = authorityAreaInput.getValue();

				//Second signer areas contains all available except an area, already selected by first signer
				const secondSignerAvailableAreas = availableAreasData.filter(function (item) {
					return item.id != firstSignerArea;
				});

				secondSignerAreaStore.loadData(secondSignerAvailableAreas);
			});
		}

		const signerStateStore = edi.stores.createInlineStore(edi.stores.data.signerStatuses, 'AUTHORITY_AREA');
		const authorityStatusCheck = function (comp) {
			const value = comp.getValue();
			let baseAuthoryInput = baseAuthory.down('[name=signer.baseAuthory]');
			let baseOrgAuthoryInput = baseOrgAuthory.down('[name=signer.baseOrgAuthory]');
			if (value && baseAuthoryInput) {
				baseOrgAuthoryInput.allowBlank = value != '3';
				baseOrgAuthoryInput.isValid();
				if (!isFillingFromBackend) {
					if (['1', '2', '3'].indexOf(value) > -1) {
						baseAuthoryInput.setValue(edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default'));
					} else {
						baseAuthoryInput.setValue(
							edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default.state.4')
						);
					}
				}
				checkValid();
			}
		};

		const authorityAreaAndStatus = createContainer({
			layout: {
				type: 'grid',
				area: [[5, 5]]
			},
			items: [
				authorityArea,
				(authorityStatus = createCombo({
					name: 'signer.state',
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.status'),
					store: signerStateStore,
					displayField: 'name',
					value: signer.state,
					allowBlank: false,
					forceSelection: true,
					qtipText: signerFillFromCert.state
						? edi.i18n.getMessage('document.signer.inn.cert.not.equal.inn.org')
						: undefined,
					plugins: [
						{
							ptype: PTYPE_COMBO_ITEMS_DISABLEABLE,
							disabledField: 'disabled'
						}
					],
					listeners: {
						afterrender: authorityStatusCheck,
						change: authorityStatusCheck
					}
				}))
			]
		});

		const baseAuthoryFields = createContainer({
			layout: {
				type: 'grid',
				area: [[5, 5]]
			},
			items: [baseAuthory, baseOrgAuthory]
		});

		return [
			topText,
			createFieldBlock({
				title: signerTitle ?? edi.i18n.getMessage('company.signer') + (isSecond ? ' 2' : ''),
				cls: 'edi-form-maxwidth',
				layout: {
					type: 'grid',
					gap: 16
				},
				items: [
					getSecondSignerBtn,
					signerSection,
					authorityAreaAndStatus,
					certificateRegistrationIP,
					baseAuthoryFields
				]
			})
		];
	};

	const createSignerItems = function () {
		signerContainer = createContainer({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: 16
			}
		});

		let signer = edi.utils.getObjectProperty(documentData, 'signer');
		if (signer && Array.isArray(signer) && signer.length > 0) {
			let signersLength = signer.length;
			if (
				signersLength === 2 &&
				isSCHFDOP_BussinessProcess(bpName) &&
				['5', '6'].indexOf(edi.utils.getObjectProperty(signer[0], 'authorityArea')) > -1
			) {
				signersLength = 1;
			}
			for (let i = 0; i < signersLength; i++) {
				const signerTitle =
					signersLength > 1 ? `${edi.i18n.getMessage('company.signer')}` + (i > 0 ? ' 2' : '') : null;
				let fields = signerContainer.add(createSignerFields(signer[i], !!i, signerTitle));
				if (!!i) {
					signerSecond = fields;
				}
			}
		} else {
			signerContainer.add(createSignerFields({}, false));
		}

		signerContainer.layout.redoLayout();
		return signerContainer;
	};

	const createTextInfosBlock = function () {
		let infos = edi.utils.getObjectProperty(documentData, 'invoice.info.textInfos') || [];
		infos.forEach((inf) => {
			if (
				inf.identifier === getPurposeTypeValue(PURPOSES_TYPES.VID_OBOBOROTA) ||
				inf.identifier === getPurposeTypeValue(PURPOSES_TYPES.SV_VIBITIYA)
			) {
				inf.identifier = getPurposeTypeFromValue(inf.identifier);
			}
		});

		const textInfos = edi.utils.getObjectProperty(documentData, 'invoice.info.textInfos', true);
		const exludedTextInfos = [TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER];
		invoiceTextInfosColumns = createRowsBlock({
			createContentFieldsFn: createInvoiceTextInfosField,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			initialData:
				isEdit || isCorrect || isCopy
					? textInfos.filter((textInfo) => !exludedTextInfos.includes(textInfo.identifier))
					: [],
			setExtraDataToRow(row) {
				row.identifierInput = row.contentFieldsPanel.identifierInput;
				row.valueInput = row.contentFieldsPanel.valueInput;
			},
			orgAdditionalInfoIdentifiers: []
		});

		return createFieldSet({
			title: edi.i18n.getMessage('documents.fns_upd.special_identifiers'),
			collapsed: true,
			collapsible: true,
			items: [
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						gap: [24, 16]
					},
					items: [
						invoiceTextInfosColumns,
						createRowsBlock({
							createContentFieldsFn: createInformationOfTheFactOfeconomicLifeField,
							initialData:
								isEdit || isCorrect || isCopy
									? edi.utils.getObjectProperty(
											documentData,
											'receiptPartDoc.informationOfTheFactOfeconomicLife.textInfos'
									  ) || []
									: [],
							setExtraDataToRow(row) {
								row.identifierInput = row.contentFieldsPanel.identifierInput;
								row.valueInput = row.contentFieldsPanel.valueInput;
							},
							isReadOnly: true
						})
					]
				})
			]
		});
	};

	const createPaymentInformationField = function (isMainField, fieldData) {
		let numberInput, dateInput;

		return createContainer({
			layout: {
				type: 'grid',
				columns: 8,
				area: [[4, 2, 2]]
			},
			items: [
				(numberInput = createTextField({
					fieldLabel: edi.i18n.getMessage('column.license.number'),
					value: edi.utils.getObjectProperty(fieldData, 'number'),
					name: 'invoice.paymentInformation.number',
					listeners: {
						change: function (field, newValue) {
							dateInput.allowBlank = !newValue;
							dateInput.isValid();
						}
					}
				})),
				(dateInput = createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					value: edi.utils.getObjectProperty(fieldData, 'date'),
					name: 'invoice.paymentInformation.date',
					listeners: {
						change: function (field, newValue) {
							numberInput.allowBlank = !newValue;
							numberInput.isValid();
						}
					}
				})),
				createNumberField({
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.paymentInformation.sum'),
					value: edi.utils.getObjectProperty(fieldData, 'sum'),
					name: 'invoice.paymentInformation.sum',
					replaceComa: true,
					allowDecimals: true,
					decimalPrecision: 2
				})
			]
		});
	};

	const createShipDocConfirmationField = function (isMainField, fieldData) {
		return createContainer({
			layout: {
				type: 'grid',
				columns: 9,
				area: [[3, 4, 2]]
			},
			items: [
				(shipDocConfirmationName = createTextField({
					allowBlank: false,
					maxLength: 255,
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.name'),
					value:
						!isEdit && !isCorrect && isMainField
							? edi.i18n.getMessage('documents.fns_upd.shipDocName.default')
							: edi.utils.getObjectProperty(fieldData, 'shipDocName'),
					name: 'invoice.shipDocConfirmation.shipDocName'
				})),
				(shipDocConfirmationNumber = createTextField({
					fieldLabel: edi.i18n.getMessage('column.license.number'),
					maxLength: 255,
					allowBlank: false,
					value:
						edi.utils.getObjectProperty(fieldData, 'shipDocNameNumber') +
						(isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : ''),
					name: 'invoice.shipDocConfirmation.shipDocNameNumber'
				})),
				(shipDocConfirmationDate = createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					value:
						!isEdit && !isCorrect && isMainField
							? new Date()
							: edi.utils.getObjectProperty(fieldData, 'shipDocDate'),
					name: 'invoice.shipDocConfirmation.shipDocDate'
				}))
			]
		});
	};

	const createInformationOfTheFactOfeconomicLifeField = function (isMainField, fieldData, props) {
		fieldData = fieldData || {};
		let isMandatoryField = props.isMandatory,
			identifierInput,
			valueInput,
			invoiceTextInfoRow;

		identifierInput = createTextField({
			fieldLabel: edi.i18n.getMessage('column.identif'),
			readOnly: true,
			msgTarget: 'qtip',
			qtipText: edi.i18n.getMessage('info.text.infos.read.only'),
			name: 'receiptPartDoc.informationOfTheFactOfeconomicLife.textInfos.identifier',
			value: edi.utils.getObjectProperty(fieldData, 'identifier')
		});

		valueInput = createTextField({
			fieldLabel: edi.i18n.getMessage('column.value'),
			allowBlank: !isMandatoryField,
			readOnly: true,
			qtipText: edi.i18n.getMessage('info.text.infos.read.only'),
			name: 'receiptPartDoc.informationOfTheFactOfeconomicLife.textInfos.value',
			value: edi.utils.getObjectProperty(fieldData, 'value')
		});

		invoiceTextInfoRow = createContainer({
			layout: {
				type: 'grid',
				area: [[4, 4]]
			},
			items: [identifierInput, valueInput]
		});

		invoiceTextInfoRow.identifierInput = identifierInput;
		invoiceTextInfoRow.valueInput = valueInput;

		return invoiceTextInfoRow;
	};

	let updateTextInfosStores = function () {
		let idents = invoiceTextInfosColumns.query('field[name="invoice.info.textInfos.identifier"]');
		idents.forEach((ident) => {
			let identValue = ident.getValue();
			if (identValue === PURPOSES_TYPES.SV_VIBITIYA || identValue === PURPOSES_TYPES.VID_OBOBOROTA) {
				let valueInput = ident.getInfoValueField();
				valueInput.getStore().removeAll();
				valueInput.getStore().add(getPurposesByTypeAndFunction(identValue, bpName));
				valueInput.setValue(null);
			}
		});
	};

	const createInvoiceTextInfosField = function (isMainField, fieldData, props) {
		fieldData = fieldData || {};
		let identifierInput, valueInput, invoiceTextInfoRow;
		let isMandatoryField = props?.isMandatory;

		identifierInput = createCombo({
			fieldLabel: edi.i18n.getMessage('column.identif'),
			readOnly: props?.disableChangeIdentifier,
			forceSelection: false,
			store: edi.stores.createInlineStore(getPurposesTypes(), 'SIMPLE'),
			name: 'invoice.info.textInfos.identifier',
			value: edi.utils.getObjectProperty(fieldData, 'identifier'),
			triggers: {
				clear: {
					extraCls: 'edi-icon edi-icon-CLOSE',
					handler: function (field, trigger) {
						field.clearValue();
					}
				}
			},
			getInfoValueField() {
				return valueInput;
			},
			validator: function (value) {
				if (!value) {
					return true;
				}
				let inputs = invoiceTextInfosColumns.query('field[name="invoice.info.textInfos.identifier"]');
				let allOtherIdents = {};
				inputs.forEach((input) => {
					if (input !== identifierInput) {
						allOtherIdents[input.getValue()] = true;
					}
				});
				let sameValueInput = allOtherIdents[value] || allOtherIdents[getPurposeTypeFromValue(value)];
				return !sameValueInput || edi.i18n.getMessage('identifier.cant.be.duplicated');
			},
			listeners: {
				change: function (field, identifierValue, oldValue) {
					if (
						identifierValue !== oldValue &&
						(identifierValue === PURPOSES_TYPES.VID_OBOBOROTA ||
							identifierValue === PURPOSES_TYPES.SV_VIBITIYA)
					) {
						valueInput.getStore().removeAll();
						valueInput.getStore().add(getPurposesByTypeAndFunction(identifierValue, bpName));
						valueInput.setValue(null);
					}
					valueInput.allowBlank = !identifierValue;
					valueInput.isValid();
				}
			}
		});

		valueInput = createCombo({
			fieldLabel: edi.i18n.getMessage('column.value'),
			allowBlank: !isMandatoryField,
			forceSelection: false,
			name: 'invoice.info.textInfos.value',
			value: edi.utils.getObjectProperty(fieldData, 'value'),
			store: edi.stores.createInlineStore([], 'SIMPLE'),
			triggers: {
				clear: {
					extraCls: 'edi-icon edi-icon-CLOSE',
					handler: function (field, trigger) {
						field.clearValue();
					}
				}
			},
			listeners: {
				change: function (field, newValue) {
					identifierInput.allowBlank = !newValue;
					identifierInput.isValid();
				},
				afterrender(combo) {
					combo.getStore().removeAll();
					combo.getStore().add(getPurposesByTypeAndFunction(identifierInput.getValue(), bpName));
				}
			}
		});

		invoiceTextInfoRow = createContainer({
			layout: {
				type: 'grid',
				area: [[6, 6]]
			},
			items: [identifierInput, valueInput]
		});

		invoiceTextInfoRow.identifierInput = identifierInput;
		invoiceTextInfoRow.valueInput = valueInput;

		return invoiceTextInfoRow;
	};

	const getNonEmptyRowFromBaseShipments = function () {
		let rows = baseShipmentOfGoodsColumns.items.items;
		return rows.filter(function (row) {
			return row.isNonEmptyRow();
		});
	};

	const removeNotSelectedRecord = function (store) {
		const notSelectedRecord = store?.findRecord('id', 'notSelected');
		store?.remove(notSelectedRecord);
	};
	const addNotSelectedRecord = function (store) {
		const notSelectedRecord = {
			id: 'notSelected',
			name: edi.i18n.getMessage('form.combo.not.selected')
		};
		store?.insert(0, notSelectedRecord);
	};

	// Updates nameInput allowBlank in all rows of base shipment panel
	const updateBaseShipRowsMandatory = function () {
		if (baseShipmentOfGoodsColumns) {
			let rows = baseShipmentOfGoodsColumns.items.items;
			const nonEmptyRows = getNonEmptyRowFromBaseShipments(),
				emptyRows = rows.filter(function (row) {
					return !row.isNonEmptyRow();
				});

			//For all non-empty rows set mandatory name field
			nonEmptyRows.forEach(function (row) {
				row.setMandatoryNameField(true);
			});

			//If we have non-empty rows or SCHF buiseness process, empty row's name field can be non-mandatory
			const setMandatoryNameFieldInEmptyRow = !isSCHFSelected;
			emptyRows.forEach(function (row) {
				row.setMandatoryNameField(setMandatoryNameFieldInEmptyRow);
			});
		}
	};

	const validateBaseShipmentOfGoodsFields = function (isCheckSCHFTransportFieldsIfNeeded) {
		if (isSCHFSelected) {
			let allFieldsEmpty = isSCHFTransportFieldsEmpty(),
				allowBlank = false;
			if (isCheckSCHFTransportFieldsIfNeeded) {
				checkSCHFTransportFieldsIfNeeded(allFieldsEmpty);
			}
			const contentActionField = mainForm.down('[name=receiptPartDoc.transferInformation.contentAction]');
			if (allFieldsEmpty && !contentActionField.getValue() && getNonEmptyRowFromBaseShipments().length === 0) {
				allowBlank = true;
			}
			let fields = [];
			fields.push(contentActionField);
			fields.forEach(function (field) {
				field.allowBlank = allowBlank;
				field.validate(field.getValue());
			});
		}
	};

	const createBaseShipmentOfGoodsRow = function (isMainField, fieldData) {
		let baseShipmentPanel;

		//Set nameInput allowBlank
		const setMandatoryNameField = function (isMandatory) {
			if (!isSCHFSelected && baseShipmentPanel.isNonEmptyRow()) {
				isMandatory = true;
			}
			if (isSCHFSelected && (contentActionField.getValue() || baseShipmentPanel.isNonEmptyRow())) {
				isMandatory = true;
			}
			//nameInput.allowBlank = !isMandatory;
			//nameInput.isValid();
			contentActionField.allowBlank = !isMandatory;
			contentActionField.isValid();
			checkValid();
		};

		const onFieldValueChange = function () {
			updateBaseShipRowsMandatory();
			validateBaseShipmentOfGoodsFields(true);
		};

		baseShipmentPanel = createMaxWidthContainer({
			layout: {
				type: 'grid',
				columns: 9,
				gap: 16,
				area: [
					[3, 3, 3],
					[2, 4]
				]
			},
			items: [
				createCombo({
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.name'),
					allowBlank: false,
					maxLength: 255,
					store: edi.stores.createSimpleInlineStore(
						[edi.i18n.getMessage('receiptPartDoc.transferInformation.baseShipmentOfGoods.withoutBaseDoc')],
						function (id) {
							return id;
						}
					),
					value: edi.utils.getObjectProperty(fieldData, 'name'),
					forceSelection: false,
					anyMatch: true,
					name: 'receiptPartDoc.transferInformation.baseShipmentOfGoods.name',
					listeners: {
						change: onFieldValueChange
					}
				}),
				createTextField({
					fieldLabel: edi.i18n.getMessage('column.license.number'),
					allowBlank: true,
					value: edi.utils.getObjectProperty(fieldData, 'number'),
					name: 'receiptPartDoc.transferInformation.baseShipmentOfGoods.number',
					listeners: {
						change: onFieldValueChange
					}
				}),
				createTextField({
					grid: {
						scol: 7,
						col: 3,
						srow: 1,
						row: 2
					},
					allowBlank: true,
					isTextarea: true,
					minHeight: 'auto',
					maxLength: 1000,
					fieldLabel: edi.i18n.getMessage(
						'documents.fns_upd.transferInfo.baseShipmentOfGoods.additionalInfo'
					),
					value: edi.utils.getObjectProperty(fieldData, 'additionalInfo'),
					name: 'receiptPartDoc.transferInformation.baseShipmentOfGoods.additionalInfo',
					listeners: {
						change: onFieldValueChange
					}
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					allowBlank: true,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					maxValue: new Date(),
					value: edi.utils.getObjectProperty(fieldData, 'date'),
					name: 'receiptPartDoc.transferInformation.baseShipmentOfGoods.date',
					listeners: {
						change: onFieldValueChange
					}
				}),
				createTextField({
					allowBlank: true,
					maxLength: 255,
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.baseId'),
					value: edi.utils.getObjectProperty(fieldData, 'baseId'),
					name: 'receiptPartDoc.transferInformation.baseShipmentOfGoods.baseId',
					listeners: {
						change: onFieldValueChange
					}
				})
			]
		});

		baseShipmentPanel.isNonEmptyRow = function () {
			let fieldNames = [
				'receiptPartDoc.transferInformation.baseShipmentOfGoods.name',
				'receiptPartDoc.transferInformation.baseShipmentOfGoods.number',
				'receiptPartDoc.transferInformation.baseShipmentOfGoods.date',
				'receiptPartDoc.transferInformation.baseShipmentOfGoods.additionalInfo',
				'receiptPartDoc.transferInformation.baseShipmentOfGoods.baseId'
			];

			const isEmptyField = function (fieldName) {
				let input = baseShipmentPanel.down('[name=' + fieldName + ']');
				return !input.getValue();
			};

			let nonEmptyInput = fieldNames.find(function (fieldName) {
				return !isEmptyField(fieldName);
			});

			return !!nonEmptyInput;
		};

		baseShipmentPanel.setMandatoryNameField = setMandatoryNameField;
		baseShipmentPanel.onRemoveRow = function () {
			updateBaseShipRowsMandatory();
			validateBaseShipmentOfGoodsFields(true);
		};
		baseShipmentPanel.onAddRow = function () {
			updateBaseShipRowsMandatory();
			validateBaseShipmentOfGoodsFields(true);
		};

		return baseShipmentPanel;
	};

	const createWayBillField = function (isMainField, fieldData) {
		let numberInput, dateInput;

		let wayBillField = createContainer({
			layout: {
				type: 'grid',
				columns: 6,
				area: [[4, 2]]
			},
			items: [
				(numberInput = createTextField({
					fieldLabel: edi.i18n.getMessage('column.license.number'),
					allowBlank: true,
					name: 'receiptPartDoc.transferInformation.transportAndCargo.wayBill.number',
					value: edi.utils.getObjectProperty(fieldData, 'number'),
					listeners: {
						change: function (field, newValue) {
							dateInput.allowBlank = !newValue;
							dateInput.isValid();

							validateBaseShipmentOfGoodsFields(true);
						}
					}
				})),
				(dateInput = createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					allowBlank: true,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					name: 'receiptPartDoc.transferInformation.transportAndCargo.wayBill.date',
					value: edi.utils.getObjectProperty(fieldData, 'date'),
					listeners: {
						change: function (field, newValue) {
							numberInput.allowBlank = !newValue;
							numberInput.isValid();

							validateBaseShipmentOfGoodsFields(true);
						}
					}
				}))
			]
		});

		wayBillField.isNonEmptyRow = function () {
			return !!(numberInput.getValue() || dateInput.getValue());
		};

		return wayBillField;
	};

	const createTransportAndCargo = function () {
		const carrierProperty = 'receiptPartDoc.transferInformation.transportAndCargo.carrier',
			carrierInfo = edi.utils.getObjectProperty(documentData, carrierProperty),
			isCarrierDefined = !!carrierInfo,
			canEditCarrier = isEdit || isCopy || isCorrect || !isCarrierDefined;
		const personHandingGoodsValues = edi.utils.getObjectProperty(
			documentData,
			'receiptPartDoc.transferInformation.personHandingGoods'
		);
		let handingGoodsTypeValue,
			dataValues = {};

		const changeHandingGoodsFields = function (value) {
			const isNotSelected = value === 'notSelected';
			let position = personHandingGoods.down(
					'[name=receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.position]'
				),
				firstNameInput = fio.down(
					'[name=receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.firstName]'
				),
				lastNameInput = fio.down(
					'[name=receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.lastName]'
				),
				middleNameInput = fio.down(
					'[name=receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.middleName]'
				);

			fio.setVisible(!isNotSelected);

			firstNameInput.allowBlank = isNotSelected;
			lastNameInput.allowBlank = isNotSelected;

			firstNameInput.setDisabled(isNotSelected);
			lastNameInput.setDisabled(isNotSelected);
			middleNameInput.setDisabled(isNotSelected);

			if (isNotSelected) {
				position.setValue(null);
			}
			position.setVisible(
				value == handingGoodsType.sellerOrganizationWorker || value == handingGoodsType.shippedGoodsWorker
			);
			position.hidden = value == handingGoodsType.individualPersonShipsGoogs || isNotSelected;
			position.allowBlank = value == handingGoodsType.individualPersonShipsGoogs || isNotSelected;
			position.isValid();
			authority.setVisible(
				value == handingGoodsType.individualPersonShipsGoogs || value == handingGoodsType.shippedGoodsWorker
			);
			baseAuthority.setVisible(
				value == handingGoodsType.sellerOrganizationWorker || value == handingGoodsType.shippedGoodsWorker
			);

			orgNameInput.allowBlank = value !== handingGoodsType.shippedGoodsWorker;
			orgNameInput.isValid();
			orgName.setVisible(value == handingGoodsType.shippedGoodsWorker);

			checkValid();
		};

		if (personHandingGoodsValues) {
			if (edi.utils.getObjectProperty(personHandingGoodsValues, 'sellerOrganizationWorker')) {
				dataValues = edi.utils.getObjectProperty(personHandingGoodsValues, 'sellerOrganizationWorker');
				handingGoodsTypeValue = handingGoodsType.sellerOrganizationWorker;
			} else if (edi.utils.getObjectProperty(personHandingGoodsValues, 'anotherPerson.shippedGoodsWorker')) {
				dataValues = edi.utils.getObjectProperty(personHandingGoodsValues, 'anotherPerson.shippedGoodsWorker');
				handingGoodsTypeValue = handingGoodsType.shippedGoodsWorker;
			} else {
				dataValues = edi.utils.getObjectProperty(
					personHandingGoodsValues,
					'anotherPerson.individualPersonShipsGoogs'
				);
				handingGoodsTypeValue = handingGoodsType.individualPersonShipsGoogs;
			}
		}

		const isNotFullNameFromDoc =
			isCreatedFromDoc &&
			!edi.utils.getObjectProperty(dataValues, 'fullName.firstName') &&
			!edi.utils.getObjectProperty(dataValues, 'fullName.lastName');
		const personHandingGoods = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.personHandingGoods'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: 16,
				area: [[4, 4]]
			},
			items: [
				createCombo({
					store: edi.stores.createInlineStore([
						{
							id: 'notSelected',
							name: edi.i18n.getMessage('form.combo.not.selected')
						},
						{
							id: handingGoodsType.sellerOrganizationWorker,
							name: edi.i18n.getMessage('documents.fns_upd.sellerOrganizationWorker')
						},
						{
							id: handingGoodsType.shippedGoodsWorker,
							name: edi.i18n.getMessage('documents.fns_upd.shippedGoodsWorker')
						},
						{
							id: handingGoodsType.individualPersonShipsGoogs,
							name: edi.i18n.getMessage('documents.fns_upd.individualPersonShipsGoogs')
						}
					]),
					matchFieldWidth: false,
					forceSelection: false,
					editable: false,
					name: 'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.handingGoodsType',
					value:
						(!handingGoodsTypeValue || isCreatedFromDoc) &&
						!edi.utils.getObjectProperty(dataValues, 'fullName.firstName') &&
						!edi.utils.getObjectProperty(dataValues, 'fullName.lastName')
							? 'notSelected'
							: handingGoodsTypeValue,
					listeners: {
						change: function (comp) {
							validateBaseShipmentOfGoodsFields(false);
							changeHandingGoodsFields(comp.getValue());
						},
						afterrender: function (comp) {
							validateBaseShipmentOfGoodsFields(false);
							changeHandingGoodsFields(comp.getValue());
							const isFillingUPDTransferPerson =
								edi.utils.getAttributeByName(buyerOrg?.attributes, 'FILLING_UPD_TRANSFER_PERSON') ===
								'true';
							if (isFillingUPDTransferPerson) {
								removeNotSelectedRecord(comp.getStore());
							}
						}
					}
				}),
				isHasWorkers
					? createCombo({
							store: edi.stores.initPositionsStore(),
							displayField: 'shortName',
							forceSelection: false,
							maxLength: 128,
							allowBlank: false,
							fieldLabel: edi.i18n.getMessage('job.title'),
							name: 'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.position',
							value:
								isEdit || isCorrect || isCopy
									? edi.utils.getObjectProperty(dataValues, 'position')
									: undefined,
							listeners: {
								change: function () {
									validateBaseShipmentOfGoodsFields(true);
								}
							}
					  })
					: createTextField({
							maxLength: 128,
							allowBlank: false,
							fieldLabel: edi.i18n.getMessage('job.title'),
							name: 'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.position',
							value:
								isEdit || isCorrect || isCopy
									? edi.utils.getObjectProperty(dataValues, 'position')
									: undefined,
							listeners: {
								change: function () {
									validateBaseShipmentOfGoodsFields(true);
								}
							}
					  })
			]
		});

		let fio = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.sellerOrganizationWorker.personal.data'),
			hidden: true,
			layout: {
				type: 'grid',
				area: [[3, 3, 3]]
			},
			items: [
				createTextField({
					allowBlank: isNotFullNameFromDoc,
					disabled: isNotFullNameFromDoc,
					maxLength: 60,
					fieldLabel: edi.i18n.getMessage('first.name'),
					name: 'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.firstName',
					value:
						isEdit || isCorrect || isCopy
							? edi.utils.getObjectProperty(dataValues, 'fullName.firstName')
							: undefined,
					listeners: {
						change: function () {
							validateBaseShipmentOfGoodsFields(true);
						}
					}
				}),
				createTextField({
					allowBlank: isNotFullNameFromDoc,
					disabled: isNotFullNameFromDoc,
					maxLength: 60,
					fieldLabel: edi.i18n.getMessage('last.name'),
					name: 'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.lastName',
					value:
						isEdit || isCorrect || isCopy
							? edi.utils.getObjectProperty(dataValues, 'fullName.lastName')
							: undefined,
					listeners: {
						change: function () {
							validateBaseShipmentOfGoodsFields(true);
						}
					}
				}),
				createTextField({
					allowBlank: true,
					disabled: isNotFullNameFromDoc,
					maxLength: 60,
					fieldLabel: edi.i18n.getMessage('patronymic.name'),
					name: 'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.middleName',
					value:
						isEdit || isCorrect || isCopy
							? edi.utils.getObjectProperty(dataValues, 'fullName.middleName')
							: undefined,
					listeners: {
						change: function () {
							validateBaseShipmentOfGoodsFields(true);
						}
					}
				})
			]
		});

		let baseAuthority = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.sellerOrganizationWorker.baseAuthority'),
			layout: {
				type: 'grid',
				area: [5]
			},
			items: [
				createTextField({
					maxLength: 120,
					name: 'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.baseAuthority',
					value:
						isEdit || isCorrect || isCopy
							? handingGoodsTypeValue == handingGoodsType.shippedGoodsWorker
								? edi.utils.getObjectProperty(dataValues, 'rightPresentResults')
								: edi.utils.getObjectProperty(dataValues, 'baseAuthority')
							: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default'),
					listeners: {
						change: function () {
							validateBaseShipmentOfGoodsFields(true);
						}
					}
				})
			]
		});

		let authority = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.individualPersonShipsGoogs.authority'),
			hidden: true,
			layout: {
				type: 'grid',
				area: [5]
			},
			items: [
				createTextField({
					allowBlank: true,
					maxLength: 120,
					name: 'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.authority',
					value: edi.utils.getObjectProperty(dataValues, 'authority'),
					listeners: {
						change: function () {
							validateBaseShipmentOfGoodsFields(true);
						}
					}
				})
			]
		});

		let orgName = createFieldBlock({
			title: edi.i18n.getMessage('document.company.name'),
			hidden: true,
			layout: {
				type: 'grid',
				area: [5]
			},
			items: [
				(orgNameInput = createTextField({
					maxLength: 128,
					allowBlank: true,
					name: 'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.orgName',
					value: edi.utils.getObjectProperty(dataValues, 'orgName'),
					listeners: {
						change: function () {
							validateBaseShipmentOfGoodsFields(true);
						}
					}
				}))
			]
		});

		carrier = createOS({
			itemId: 'carrier',
			allowBlank: true,
			ownCatalog: false,
			alwaysShowSelect: canEditCarrier,
			allowReset: canEditCarrier,
			ownOrg: canEditCarrier,
			fieldValues: isCarrierDefined ? documentData : undefined,
			selectedOrgValues: isCarrierDefined ? documentData : undefined,
			forceChangeFields: forceChangeFieldsForShips,
			callback: function () {
				checkCarrierSelector();
				initialData
					? edi.utils.setObjectProperty(
							initialData,
							'document.receiptPartDoc.transferInformation.transportAndCargo.carrier',
							null
					  )
					: null;
				validateBaseShipmentOfGoodsFields(true);

				checkValid();
			},
			fieldsMap: edi.selectors.getUPDFieldsMap(
				'receiptPartDoc.transferInformation.transportAndCargo.carrier',
				false,
				true
			),
			modalConf: edi.selectors.getGridFNSModalConf(modalAdditionalConf, getDefaultsNewFNSFields())
		});
		setNameForCompanySelector(carrier, 'document.carrier');

		const carrierField = createFieldBlock({
			title: edi.i18n.getMessage('document.carrier'),
			layout: {
				type: 'grid',
				area: [4]
			},
			items: [carrier]
		});

		checkCarrierSelector();
		transportAndCargoColumns = createRowsBlock({
			contentCols: 6,
			buttonsCols: 6,
			createContentFieldsFn: createWayBillField,
			initialData:
				isEdit || isCorrect || isCopy
					? edi.utils.getObjectProperty(
							documentData,
							'receiptPartDoc.transferInformation.transportAndCargo.wayBill'
					  ) || []
					: [],
			setExtraDataToRow(row) {
				if ('function' == typeof row.contentFieldsPanel.isNonEmptyRow) {
					row.isNonEmptyRow = row.contentFieldsPanel.isNonEmptyRow;
				}
				if ('function' == typeof row.contentFieldsPanel.setMandatoryNameField) {
					row.setMandatoryNameField = row.contentFieldsPanel.setMandatoryNameField;
				}
			},
			onAddRow() {
				checkValid();
			},
			onRemoveRow() {
				checkValid();
			}
		});

		const thingTransferInfoInfo = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.transportAndCargo.info'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[3, 3]]
			},
			items: [
				createTextField({
					allowBlank: true,
					maxLength: 1000,
					fieldLabel: edi.i18n.getMessage('documents.info'),
					qtipText: edi.i18n.getMessage('documents.fns_upd.transferInfo.info.toolTip'),
					value: edi.utils.getObjectProperty(
						documentData,
						'receiptPartDoc.transferInformation.thingTransferInfo.info'
					),
					name: 'receiptPartDoc.transferInformation.thingTransferInfo.info',
					listeners: {
						change: function () {
							validateBaseShipmentOfGoodsFields(true);
						}
					}
				}),
				createDateField({
					allowBlank: true,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.transferInfo.date'),
					value: edi.utils.getObjectProperty(
						documentData,
						'receiptPartDoc.transferInformation.thingTransferInfo.date'
					),
					listeners: {
						change: function () {
							validateBaseShipmentOfGoodsFields(true);
						}
					},
					name: 'receiptPartDoc.transferInformation.thingTransferInfo.date'
				})
			]
		});

		const contentActionItems = {
			itemsTransferred: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.itemsTransferred'),
			workPassed: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.workPassed'),
			servicesRendered: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.servicesRendered')
		};
		let contentActionValue = '';

		for (let key in contentActionItems) {
			if (
				contentActionItems[key] ===
				edi.utils.getObjectProperty(documentData, 'receiptPartDoc.transferInformation.contentAction')
			) {
				contentActionValue = edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.' + key);
			} else {
				contentActionValue = edi.utils.getObjectProperty(
					documentData,
					'receiptPartDoc.transferInformation.contentAction'
				);
			}
		}

		contentAction = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [
				(contentActionField = createCombo({
					maxLength: 255,
					forceSelection: false,
					store: edi.stores.createMemoryStore(
						[
							{
								id: edi.i18n.getMessage(
									'documents.fns_upd.transferInfo.contentAction.itemsTransferred'
								),
								name: edi.i18n.getMessage(
									'documents.fns_upd.transferInfo.contentAction.itemsTransferred'
								)
							},
							{
								id: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.workPassed'),
								name: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.workPassed')
							},
							{
								id: edi.i18n.getMessage(
									'documents.fns_upd.transferInfo.contentAction.servicesRendered'
								),
								name: edi.i18n.getMessage(
									'documents.fns_upd.transferInfo.contentAction.servicesRendered'
								)
							}
						],
						'SIMPLE',
						true
					),
					valueSrc: documentData,
					value:
						isEdit || isCopy
							? contentActionValue
							: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.servicesRendered'),
					name: 'receiptPartDoc.transferInformation.contentAction',
					validator: function () {
						return this.allowBlank || !!this.getValue();
					},
					listeners: {
						select: function () {
							validateBaseShipmentOfGoodsFields(true);
						}
					}
				}))
			]
		});

		const typeAction = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.typeAction'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [
				createTextField({
					allowBlank: true,
					maxLength: 255,
					qtipText: edi.i18n.getMessage('documents.fns_upd.transferInfo.typeAction.toolTip'),
					value: edi.utils.getObjectProperty(documentData, 'receiptPartDoc.transferInformation.typeAction'),
					name: 'receiptPartDoc.transferInformation.typeAction',
					listeners: {
						change: function () {
							validateBaseShipmentOfGoodsFields(true);
						}
					}
				})
			]
		});

		const shippingDate = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.shippingDate'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [2]
			},
			items: [
				createDateField({
					allowBlank: true,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					value: edi.utils.getObjectProperty(documentData, 'receiptPartDoc.transferInformation.shippingDate'),
					name: 'receiptPartDoc.transferInformation.shippingDate',
					listeners: {
						change: function () {
							validateBaseShipmentOfGoodsFields(true);
						}
					}
				})
			]
		});

		let startingDate, endingDate;
		const transferInfoDate = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.starting'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[2, 2]]
			},
			items: [
				(startingDate = createDateField({
					allowBlank: true,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					value: edi.utils.getObjectProperty(documentData, 'receiptPartDoc.transferInformation.startingDate'),
					name: 'receiptPartDoc.transferInformation.startingDate',
					listeners: {
						change: function (comp) {
							validateBaseShipmentOfGoodsFields(true);
							if (comp.getValue()) {
								endingDate.setMinValue(comp.getValue());
							}
							comp.isValid();
						}
					}
				})),
				(endingDate = createDateField({
					allowBlank: true,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					value: edi.utils.getObjectProperty(documentData, 'receiptPartDoc.transferInformation.endingDate'),
					name: 'receiptPartDoc.transferInformation.endingDate',
					listeners: {
						change: function (comp) {
							validateBaseShipmentOfGoodsFields(true);
							if (!startingDate.getValue()) {
								comp.setValue(null);
							}
							comp.isValid();
						}
					}
				}))
			]
		});

		baseShipmentOfGoods = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods'),
			items: [
				(baseShipmentOfGoodsColumns = createRowsBlock({
					//title: ,
					createContentFieldsFn: createBaseShipmentOfGoodsRow,
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					initialData:
						isEdit || isCorrect || isCopy
							? edi.utils.getObjectProperty(
									documentData,
									'receiptPartDoc.transferInformation.baseShipmentOfGoods'
							  ) || []
							: [],
					contentCols: 6,
					buttonsCols: 6,
					setExtraDataToRow(row) {
						if ('function' == typeof row.contentFieldsPanel.isNonEmptyRow) {
							row.isNonEmptyRow = row.contentFieldsPanel.isNonEmptyRow;
						}
						if ('function' == typeof row.contentFieldsPanel.setMandatoryNameField) {
							row.setMandatoryNameField = row.contentFieldsPanel.setMandatoryNameField;
						}
					},
					onAddRow() {
						checkValid();
					},
					onRemoveRow() {
						updateBaseShipRowsMandatory();
						checkValid();
					}
				}))
			]
		});

		return createFieldSet({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.additionalInfo'),
			collapsible: true,
			items: [
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						gap: [24, 16]
					},
					items: [
						baseShipmentOfGoods,
						personHandingGoods,
						fio,
						orgName,
						authority,
						baseAuthority,
						carrierField,
						createFieldBlock({
							title: edi.i18n.getMessage('documents.create.torg.trans.name.full'),
							items: transportAndCargoColumns
						}),
						thingTransferInfoInfo,
						contentAction,
						typeAction,
						shippingDate,
						transferInfoDate
					]
				})
			]
		});
	};

	const createAdditionalInformation = function () {
		const id = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.govContract'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[3, 3, 2]]
			},
			items: [
				createTextField({
					allowBlank: true,
					fieldLabel: edi.i18n.getMessage('column.identifier'),
					minLength: 20,
					maxLength: 25,
					value: edi.utils.getObjectProperty(documentData, 'invoice.additionalInfo.govAgreementId'),
					name: 'invoice.additionalInfo.govAgreementId'
				}),
				(numberGovConField = createTextField({
					allowBlank: true,
					maxLength: 100,
					fieldLabel: edi.i18n.getMessage('column.license.number'),
					value: edi.utils.getObjectProperty(
						documentData,
						'invoice.additionalInfo.additionalInfoSeller.numberGovCon'
					),
					name: 'invoice.additionalInfo.additionalInfoSeller.numberGovCon',
					listeners: {
						change: function (field, value) {
							if (isSCHFSelected) {
								checkSCHFTransportFieldsIfNeeded();
							}
							validateGovContractFields(value);
						}
					}
				})),
				(dateGovContractField = createDateField({
					allowBlank: true,
					name: 'invoice.additionalInfo.additionalInfoSeller.dateGovContract',
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					fieldLabel: edi.i18n.getMessage('date'),
					value: edi.utils.getObjectProperty(
						documentData,
						'invoice.additionalInfo.additionalInfoSeller.dateGovContract'
					),
					listeners: {
						change: function (field, value) {
							if (isSCHFSelected) {
								checkSCHFTransportFieldsIfNeeded();
							}
							validateGovContractFields(value);
						}
					}
				}))
			]
		});

		const exchangeRate = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.exchangeRate'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [3]
			},
			items: [
				createTextField({
					allowBlank: true,
					regex: /^[\d]{0,10}(\.[\d]{0,4})?$/,
					value: edi.utils.getObjectProperty(documentData, 'invoice.additionalInfo.exchangeRate'),
					name: 'invoice.additionalInfo.exchangeRate'
				})
			]
		});

		let isShowQtip = !!documentData;

		if (documentData && documentData.hasOwnProperty('invoice')) {
			isShowQtip = false;
		}

		const infoInvoice = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.infoInvoice'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [
				(infoInvoiceCombo = createCombo({
					disabled: isShowQtip,
					qtipText: isShowQtip
						? edi.i18n.getMessage('documents.fns_upd.additionalInfo.infoInvoice.qtip')
						: '',
					allowBlank: true,
					valueSrc: documentData,
					store: edi.stores.createInlineStore(availableInvoicesData, 'AUTHORITY_AREA'),
					name: 'invoice.additionalInfo.infoInvoice'
				}))
			]
		});

		const additionalInfoSellerInfo = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.seller'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[3, 3, 3]]
			},
			items: [
				createTextField({
					allowBlank: true,
					minLength: 11,
					fieldLabel: edi.i18n.getMessage(
						'documents.fns_upd.additionalInfo.additionalInfoSeller.accountSeller'
					),
					maxLength: 11,
					value: edi.utils.getObjectProperty(
						documentData,
						'invoice.additionalInfo.additionalInfoSeller.accountSeller'
					),
					name: 'invoice.additionalInfo.additionalInfoSeller.accountSeller',
					listeners: {
						change: function (field, value) {
							if (isSCHFSelected) {
								checkSCHFTransportFieldsIfNeeded();
							}
							validateGovContractFields(value);
						}
					}
				}),
				createTextField({
					allowBlank: true,
					minLength: 20,
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.code'),
					maxLength: 20,
					value: edi.utils.getObjectProperty(
						documentData,
						'invoice.additionalInfo.additionalInfoSeller.codeSeller'
					),
					name: 'invoice.additionalInfo.additionalInfoSeller.codeSeller',
					listeners: {
						change: function (field, value) {
							if (isSCHFSelected) {
								checkSCHFTransportFieldsIfNeeded();
							}
							validateGovContractFields(value);
						}
					}
				}),
				createTextField({
					allowBlank: true,
					minLength: 20,
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.codeGoal'),
					maxLength: 20,
					value: edi.utils.getObjectProperty(
						documentData,
						'invoice.additionalInfo.additionalInfoSeller.codeSellerGoal'
					),
					name: 'invoice.additionalInfo.additionalInfoSeller.codeSellerGoal',
					listeners: {
						change: function (field, value) {
							if (isSCHFSelected) {
								checkSCHFTransportFieldsIfNeeded();
							}
							validateGovContractFields(value);
						}
					}
				})
			]
		});

		const sellerTreasuryInfo = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.sellerTreasury'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[3, 6]]
			},
			items: [
				createTextField({
					allowBlank: true,
					maxLength: 4,
					fieldLabel: edi.i18n.getMessage('column.code'),
					value: edi.utils.getObjectProperty(
						documentData,
						'invoice.additionalInfo.additionalInfoSeller.codeSellerTreasury'
					),
					name: 'invoice.additionalInfo.additionalInfoSeller.codeSellerTreasury',
					listeners: {
						change: function (field, value) {
							if (isSCHFSelected) {
								checkSCHFTransportFieldsIfNeeded();
							}
							validateGovContractFields(value);
						}
					}
				}),
				createTextField({
					allowBlank: true,
					fieldLabel: edi.i18n.getMessage('column.short.name'),
					maxLength: 2000,
					value: edi.utils.getObjectProperty(
						documentData,
						'invoice.additionalInfo.additionalInfoSeller.nameSellerTreasury'
					),
					name: 'invoice.additionalInfo.additionalInfoSeller.nameSellerTreasury',
					listeners: {
						change: function (field, value) {
							if (isSCHFSelected) {
								checkSCHFTransportFieldsIfNeeded();
							}
							validateGovContractFields(value);
						}
					}
				})
			]
		});

		const createAssignmentBasisField = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.assignmentBasis'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [
					[3, 3, 3],
					[3, 3]
				]
			},
			items: [
				(assignmentBasisName = createTextField({
					allowBlank: true,
					maxLength: 255,
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.name'),
					value: edi.utils.getObjectProperty(documentData, 'invoice.additionalInfo.assignmentBasis.name'),
					name: 'invoice.additionalInfo.assignmentBasis.name'
				})),
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.form.number'),
					maxLength: 255,
					allowBlank: true,
					value: edi.utils.getObjectProperty(documentData, 'invoice.additionalInfo.assignmentBasis.number'),
					name: 'invoice.additionalInfo.assignmentBasis.number',
					listeners: {
						change: function (combo, value) {
							assignmentBasisName.allowBlank = !value;
							assignmentBasisName.isValid();
							validateBaseShipmentOfGoodsFields(false);
						}
					}
				}),
				createTextField({
					grid: {
						scol: 7,
						col: 3,
						srow: 1,
						row: 2
					},
					allowBlank: true,
					maxLength: 1000,
					isTextarea: true,
					minHeight: 'auto',
					fieldLabel: edi.i18n.getMessage(
						'documents.fns_upd.transferInfo.baseShipmentOfGoods.additionalInfo'
					),
					value: edi.utils.getObjectProperty(
						documentData,
						'invoice.additionalInfo.assignmentBasis.additionalInfo'
					),
					name: 'invoice.additionalInfo.assignmentBasis.additionalInfo',
					listeners: {
						change: function (combo, value) {
							assignmentBasisName.allowBlank = !value;
							assignmentBasisName.isValid();
							validateBaseShipmentOfGoodsFields(false);
						}
					}
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					allowBlank: true,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					maxValue: new Date(),
					value: edi.utils.getObjectProperty(documentData, 'invoice.additionalInfo.assignmentBasis.date'),
					name: 'invoice.additionalInfo.assignmentBasis.date',
					listeners: {
						change: function (combo, value) {
							assignmentBasisName.allowBlank = !value;
							assignmentBasisName.isValid();
							validateBaseShipmentOfGoodsFields(false);
						}
					}
				}),
				createTextField({
					allowBlank: true,
					maxLength: 255,
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.baseId'),
					value: edi.utils.getObjectProperty(documentData, 'invoice.additionalInfo.assignmentBasis.baseId'),
					name: 'invoice.additionalInfo.assignmentBasis.baseId',
					listeners: {
						change: function (combo, value) {
							assignmentBasisName.allowBlank = !value;
							assignmentBasisName.isValid();
							validateBaseShipmentOfGoodsFields(false);
						}
					}
				})
			]
		});

		const factorInfoProperty = 'invoice.additionalInfo.factorInfo',
			factorInfoInfo = edi.utils.getObjectProperty(documentData, factorInfoProperty),
			isFactorInfoDefined = !!factorInfoInfo,
			canEditFactorInfo = isEdit || isCopy || isCorrect || !isFactorInfoDefined;

		factorInfo = createOS({
			itemId: 'factorInfo',
			allowBlank: true,
			ownCatalog: false,
			relations: factors,
			alwaysShowSelect: canEditFactorInfo,
			allowReset: canEditFactorInfo,
			ownOrg: false,
			fieldValues: isFactorInfoDefined ? documentData : undefined,
			selectedOrgValues: isFactorInfoDefined ? documentData : undefined,
			downloadBankAccountsData: true,
			forceChangeFields: forceChangeFieldsForShips,
			callback: function () {
				checkValid();
				factorInfo.checkOrgDataValid();
			},
			fieldsMap: edi.selectors.getUPDFieldsMap('invoice.additionalInfo.factorInfo', false, true),
			modalConf: edi.selectors.getGridFNSModalConf(modalAdditionalConf, getDefaultsNewFNSFields())
		});
		setNameForCompanySelector(factorInfo, 'documents.fns_upd.additionalInfo.factorInfo');

		const factorField = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.factorInfo'),
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [factorInfo]
		});

		checkCarrierSelector();

		return createFieldSet({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo'),
			collapsible: true,
			collapsed: true,
			items: [
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						gap: [24, 16]
					},
					items: [
						id,
						additionalInfoSellerInfo,
						sellerTreasuryInfo,
						infoInvoice,
						exchangeRate,
						factorField,
						createAssignmentBasisField
					]
				})
			]
		});
	};

	const saveProject = function (callback) {
		let cb = typeof callback === 'function' ? callback : () => {};

		if (
			!showProjectCombo ||
			!projectCombo ||
			!edi.permissions.hasPermission('CLIENTS_DOCUMENT_ATTRIBUTES_EDIT_DELETE')
		) {
			cb();
			return;
		}

		let newProjectId = projectCombo.getValue();
		let newProjectName = projectCombo.getRawValue();

		//если проекта не было изначально и сейчас не выбран, то и сохранять\удалять ничего не нужно
		if (!initialProjectValue && !newProjectId) {
			cb();
			return;
		}

		//если удаляем
		if (!newProjectId) {
			var failure = function (resp) {
				edi.core.showError(edi.utils.formatComplexServerError(resp, 'error.saving.upd.project'));
				cb();
			};
			var success = function (data) {
				let url = edi.utils.formatString(edi.rest.services.DOCUMENTS.ATTRIBUTES.DELETE, {
					docId: parentData.data.id,
					attrName: PROJECT_ATTR_ID
				});
				edi.rest.sendRequest(url, 'DELETE', null, cb, failure);
			};
			let url = edi.utils.formatString(edi.rest.services.DOCUMENTS.ATTRIBUTES.DELETE, {
				docId: parentData.data.id,
				attrName: PROJECT_ATTR_NAME
			});
			edi.rest.sendRequest(url, 'DELETE', null, success, failure);
		}
		//добавляем
		else {
			var failure = function (resp) {
				edi.core.showError(edi.utils.formatComplexServerError(resp, 'error.saving.upd.project'));
				cb();
			};
			let payload = Ext.encode({
				[PROJECT_ATTR_ID]: newProjectId,
				[PROJECT_ATTR_NAME]: newProjectName
			});
			let url = edi.utils.formatString(edi.rest.services.DOCUMENTS.ATTRIBUTES.PUT, {
				docId: parentData.data.id
			});
			edi.rest.sendRequest(url, 'PUT', payload, cb, failure);
		}
	};

	const createInvoiceFormItems = function () {
		let titleText = edi.i18n.getMessage(isCorrect ? 'korrupd.title' : 'upd.title');
		if (isEdit) {
			titleText = edi.i18n.getMessage(
				isCorrect ? 'documents.fns_upd.invoice.corrections' : 'document.edit.fns_upd'
			);
		}
		const title = createLabel({
			cls: 'heading_02',
			text: titleText
		});

		invoiceDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			allowBlank: false,
			submitFormat: edi.constants.DATE_FORMAT.FNS,
			readOnly: isCorrect,
			value: isEdit || isCorrect ? edi.utils.getObjectProperty(documentData, 'invoice.invoiceDate') : new Date(),
			name: 'invoice.invoiceDate',
			listeners: {
				change: function (comp, newValue) {
					if (!isEdit && !isCorrect) {
						shipDocConfirmationDate.setValue(newValue);
					}
				}
			}
		});

		const invoiceCurrency = createCombo({
			fieldLabel: edi.i18n.getMessage('invoice.currency'),
			valueSrc: documentData,
			value:
				edi.utils.getObjectProperty(documentData, 'invoice.okv') ||
				edi.utils.getOkv({
					charCode: edi.constants.DEFAULT.CURRENCY
				}).codeStr,
			name: 'invoice.okv',
			store: (okvStore = edi.stores.initOkvCurrencyStore()),
			anyMatch: true,
			displayField: 'displayName',
			valueField: 'codeStr',
			allowBlank: false,
			listeners: {
				select: function (combo, record) {
					if (record && record.get) {
						currencyName = record.get('name');
					}
				}
			}
		});

		const invoiceMainData = createFieldBlock({
			title:
				(isEdit || isCorrect || isCopy) && bpName
					? edi.i18n.getMessage('documents.fns_upd.type.' + bpName)
					: edi.i18n.getMessage('document.data'),
			layout: {
				type: 'grid',
				gap: 16,
				area: [[3, 4, 2], [4]]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				!((isEdit || isCorrect || isCopy) && bpName)
					? createCombo({
							fieldLabel: edi.i18n.getMessage('document.type'),
							emptyText: '',
							displayField: 'name',
							valueField: 'id',
							allowBlank: false,
							store: edi.stores.createSimpleInlineStore(documentFunctionsComboItems(), function (id) {
								return edi.i18n.getMessage('documents.fns_upd.type.' + id);
							}),
							listeners: {
								change: function (comp) {
									Ext.suspendLayouts();
									const value = comp.getValue();
									if (value) {
										bpName = value;
										isSCHFSelected = isSCHF_BusinessProcess(bpName);
										getAuthorityAreasByBP();
										getInfoInvoicesByBP();
										checkMandatoryFields();
										updateBaseShipRowsMandatory();
										updateTextInfosStores();

										if (authorityAreaInput) {
											let store = authorityAreaInput.getStore();
											store.loadData(Ext.clone(availableAreasData));
											authorityAreaInput.setValue(signerAuthorityAreaValue);
										}

										if (infoInvoiceCombo) {
											infoInvoiceCombo.clearTip();
											infoInvoiceCombo.updateLayout();

											infoInvoiceCombo.setDisabled(!bpName);
											let infoInvoiceStore = infoInvoiceCombo.getStore();
											infoInvoiceStore.loadData(availableInvoicesData);
											infoInvoiceCombo.setValue(infoInvoiceValue);
										}
									}
									Ext.resumeLayouts(true);
								}
							},
							name: 'updBpName'
					  })
					: null,
				createTextField({
					fieldLabel: isNumberAutoGenerated
						? edi.i18n.getMessage('document.generation.numbers.text')
						: edi.i18n.getMessage('document.form.number'),
					allowBlank: isNumberAutoGenerated,
					disabled: isNumberAutoGenerated,
					maxLength: 35,
					readOnly: isCorrect,
					value:
						isEdit || isCorrect || isCopy
							? edi.utils.getObjectProperty(documentData, 'invoice.invoiceNumber') +
							  (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
							: undefined,
					name: 'invoice.invoiceNumber',
					listeners: {
						change: function (comp, newValue) {
							if (!isEdit && !isCorrect) {
								shipDocConfirmationNumber.setValue(newValue);
							}
						}
					}
				}),
				invoiceDate,
				invoiceCurrency
			]
		});

		let correctionNumber = edi.utils.getObjectProperty(documentData, 'invoice.corrections.Number');
		const correctUPdField = isCorrect
			? createFieldBlock({
					title: edi.i18n.getMessage('documents.fns_upd.invoice.corrections'),
					layout: {
						type: 'grid',
						gap: 16,
						area: [[4, 2]]
					},
					items: [
						createNumberField({
							fieldLabel: edi.i18n.getMessage('document.form.number'),
							maxLength: 3,
							allowBlank: false,
							value: isEdit || isCopy ? correctionNumber : correctionNumber + 1,
							name: 'invoice.corrections.Number'
						}),
						createDateField({
							fieldLabel: edi.i18n.getMessage('date'),
							allowBlank: false,
							value:
								isEdit || isCopy
									? edi.utils.getObjectProperty(documentData, 'invoice.corrections.Date')
									: new Date(),
							submitFormat: edi.constants.DATE_FORMAT.FNS,
							name: 'invoice.corrections.Date'
						})
					]
			  })
			: null;

		currencyName = isEdit ? edi.utils.getObjectProperty(documentData, 'invoice.additionalInfo.currencyName') : null;

		const userOrganization = edi.converters.convertOrgToPartie(userData.org);

		let sellerInfo = edi.utils.getObjectProperty(documentData, 'invoice.sellerInfo');
		if (Array.isArray(sellerInfo) && sellerInfo.length) {
			sellerInfo = sellerInfo[0];
		}
		const isSellerDefined = !!sellerInfo;
		const isValidSeller = isValidOrganization({
			organization: sellerInfo || userOrganization,
			useOrganizationMap: isSellerDefined,
			organizationType: 'seller'
		});

		if (isEdit || isCorrect || isCopy) {
			const orgNames = ['invoice.sellerInfo', 'invoice.buyerInfo', 'invoice.cargoReceiver'];
			orgNames.forEach(function (org) {
				const orgInfo = edi.utils.getObjectProperty(documentData, org + '.0');
				edi.utils.setObjectProperty(documentData, org, orgInfo);
			});
		}

		const forcedBankData = {
			bank_id: true,
			bank_name: true,
			bank_corr_acc: true,
			bank_acc: true
		};

		seller = createOS({
			itemId: 'seller',
			is_valid: isValidSeller,
			fieldValues: isSellerDefined ? documentData : userOrganization,
			selectedOrgValues: isSellerDefined ? documentData : userOrganization,
			selectedRelationByMap: isEdit || isCorrect || isCopy,
			readOnly: (isEdit || isCorrect) && !isCreate,
			valuesByMap: isSellerDefined,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id ?? userData.org.id,
				converter: orgConverter
			}),
			forceChangeFields: isCreatedFromDoc ? forceChangeFields : forcedBankData,
			downloadBankAccountsData: true,
			callback: function (values, org) {
				seller.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'seller'
				});
				const sellerId = org ? org.id : null;
				if (shipFrom && shipFrom.getOrgIdForLoccat() !== sellerId) {
					shipFrom.reset();
					shipFrom.setOrgIdForLoccat(sellerId);
				}
				checkValid();
			},
			fieldsMap: edi.selectors.getUPDFieldsMap('invoice.sellerInfo', false, true),
			modalConf: edi.selectors.getGridFNSModalConf(
				modalAdditionalConf,
				getDefaultsNewFNSFields({
					main: {
						company_type: {
							fieldConfig: {
								disabled: function () {
									return seller.isFieldDisabled({ fieldName: 'type' });
								}
							}
						}
					}
				})
			)
		});

		let sellerOrg = seller?.selectedOrg;
		if (!sellerOrg && seller && sellerInfo && isCreatedFromDoc) {
			let orgINN =
				sellerInfo?.identification?.legalEntity?.tin || sellerInfo?.identification?.individualPerson?.tin;
			sellerOrg = edi.utils.getOrg({ orgINN });
			seller.setOrganization(sellerOrg);
		}

		let buyerInfo = edi.utils.getObjectProperty(documentData, 'invoice.buyerInfo'),
			isBuyerDefined = !!buyerInfo;
		if (Array.isArray(buyerInfo) && buyerInfo.length) {
			buyerInfo = buyerInfo[0];
		}
		const isValidBuyer = isValidOrganization({
			organization: buyerInfo,
			useOrganizationMap: isBuyerDefined,
			organizationType: 'buyer'
		});

		const fillTransferPersonFn = function () {
			const isFillingUPDTransferPerson =
				edi.utils.getAttributeByName(buyerOrg?.attributes, 'FILLING_UPD_TRANSFER_PERSON') === 'true';
			const handingGoodsTypeCombo = mainForm?.down(
				'[name="receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.handingGoodsType"]'
			);
			const handingGoodsTypeComboStore = handingGoodsTypeCombo?.getStore();
			isFillingUPDTransferPerson
				? removeNotSelectedRecord(handingGoodsTypeComboStore)
				: addNotSelectedRecord(handingGoodsTypeComboStore);
			if (!isEdit || isCreatedFromDoc) {
				handingGoodsTypeCombo?.setValue(
					isFillingUPDTransferPerson
						? handingGoodsType.sellerOrganizationWorker
						: handingGoodsTypeCombo.originalValue
				);
				if (isFillingUPDTransferPerson) {
					mainForm?.getForm()?.setValues({
						'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.firstName':
							userData?.firstName,
						'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.lastName':
							userData?.lastName,
						'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.fullName.middleName':
							userData?.patronymicName || '',
						'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker.baseAuthority':
							edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default')
					});
				}
			}
		};

		buyer = createOS({
			itemId: 'buyer',
			relationsOnly: true,
			allowBlank: false,
			is_valid: isValidBuyer,
			relations: !isCreate
				? !isEdit && !isCopy && !isCorrect && buyerOrg
					? buyerOrg
					: edi.relations.getRelations()
				: undefined,
			allowReset: (!(isEdit || isCorrect) && !isBuyerDefined) || isCopy,
			fieldValues: documentData,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id,
				converter: orgConverter
			}),
			readOnly: isEdit && !isCreate,
			selectedOrgValues:
				!(isEdit || isCorrect || isCopy) && buyerOrg
					? buyerOrgValues
					: isBuyerDefined
					? documentData
					: undefined,
			selectedOrg: buyerOrg ? buyerOrg : null,
			selectedRelationByMap: isEdit || isCopy || isCorrect || buyerOrg,
			forceChangeFields: isCreatedFromDoc ? forceChangeFields : forcedBankData,
			downloadBankAccountsData: true,
			callback: function (values, org) {
				const orgId = org ? org.id : isCreate && buyerOrg ? buyerOrg.id : null,
					sameOrgSelected = buyerOrg && buyerOrg.id == orgId;

				if (!sameOrgSelected) {
					shipFromInfoField = null;
					deliveryInfoField = null;
				}
				if (cargoReceiver && cargoReceiver.getOrgIdForDelcat() != orgId) {
					cargoReceiver.reset();
					cargoReceiver.setOrgIdForDelcat(orgId);
					cargoReceiver.setPartnerOrg(org);
				}
				if (productsGrid && productsGrid.getPartnerId() != orgId) {
					productsGrid.productsGrid.getStore().removeAll();
				}
				productsGrid?.setPartnerId(orgId);
				if (orgId && !sameOrgSelected) {
					buyerOrg = org;
					taxCalculationMethod = edi.utils.getAttributeByName(buyerOrg.attributes, 'taxCalculationMethod');
					if (!taxCalculationMethod || taxCalculationMethod === 'null') {
						taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
					}
				} else if (!orgId) {
					buyerOrg = null;
					taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
				}

				getOrganizationAdditionalFields(buyerOrg ? buyerOrg.id : null);

				buyer.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'buyer'
				});

				updateProjectsByOrg(orgId);

				fillTransferPersonFn();

				checkValid();

				if (!orgId || !sameOrgSelected) {
					removeOrgAdditionalInfoFields();
				}
			},
			fieldsMap: edi.selectors.getUPDFieldsMap('invoice.buyerInfo', false, true),
			modalConf: edi.selectors.getGridFNSModalConf(
				modalAdditionalConf,
				getDefaultsNewFNSFields({
					main: {
						company_type: {
							fieldConfig: {
								disabled: function () {
									return buyer.isFieldDisabled({ fieldName: 'type' });
								}
							}
						}
					}
				})
			)
		});

		if (buyer && buyerInfo && isCreatedFromDoc) {
			let orgINN =
				buyerInfo?.identification?.legalEntity?.tin || buyerInfo?.identification?.individualPerson?.tin;
			let fullBuyerInfo = edi.utils.getOrg({ orgINN });
			buyer.setOrganization(fullBuyerInfo);
		}

		Ext.merge(modalAdditionalConf, {
			main: {
				company_name: {
					maxLength: 1000
				},
				company_inn_kpp: {
					fields: {
						kpp: {
							allowBlank: true
						}
					}
				}
			},
			bank: {
				bank_account: null
			}
		});

		let shipFromInfo =
			edi.utils.getObjectProperty(documentData, 'invoice.cargoFrom.0.cargoSender') ||
			edi.utils.getObjectProperty(documentData, 'invoice.cargoFrom.cargoSender');
		edi.utils.setObjectProperty(documentData, 'invoice.cargoFrom', { cargoSender: shipFromInfo });
		let isShipFromDefined = !!shipFromInfo;
		let canEditShip = isEdit || isCopy || isCorrect || !isShipFromDefined;

		const isValidShipFrom = isValidOrganization({
			organization: shipFromInfo,
			useOrganizationMap: isShipFromDefined,
			organizationType: 'cargoSender'
		});
		shipFrom = createOS({
			itemId: 'shipFrom',
			allowBlank: true,
			is_valid: isValidShipFrom || !isShipFromDefined,
			ownCatalog: false,
			allowReset: canEditShip,
			ownOrg: canEditShip,
			fieldValues: isShipFromDefined ? documentData : undefined,
			relationsFromLoccatByOrgId: sellerOrg?.id || userOrganization.id,
			selectedOrgValues: isShipFromDefined ? documentData : undefined,
			forceChangeFields: forceChangeFieldsForShips,
			downloadBankAccountsData: true,
			callback: function (values, org) {
				shipFromAddressType = values.address_type;
				initialData
					? edi.utils.setObjectProperty(initialData, 'document.invoice.cargoFrom.cargoSender', null)
					: null;
				shipFrom.is_valid =
					isValidOrganization({
						organization: values,
						organizationType: 'cargoSender'
					}) || shipFrom.isEmptyValues();

				shipFromOrg = org || values;
				if (shipFromInfoField) {
					shipFromInfoField.valueInput.setValue(values.company_iln || values.individual_gln);
					shipFromInfoField.valueInput.isValid();
				}
				checkValid();
			},
			fieldsMap: edi.selectors.getUPDFieldsMap('invoice.cargoFrom.cargoSender', false, true),
			modalConf: edi.selectors.getGridFNSModalConf(modalAdditionalConf, getDefaultsNewFNSFields()),
			processValues: orgConverter
		});

		let cargoReceiverInfo = edi.utils.getObjectProperty(documentData, 'invoice.cargoReceiver'),
			isCargoReceiverDefined = !!cargoReceiverInfo,
			canEditReceiver = isEdit || isCopy || isCorrect || !isCargoReceiverDefined,
			partnerOrg = null;
		if (Array.isArray(cargoReceiverInfo) && cargoReceiverInfo.length) {
			cargoReceiverInfo = cargoReceiverInfo[0];
		}
		const isCargoReceiverValid = isValidOrganization({
			organization: cargoReceiverInfo,
			useOrganizationMap: isCargoReceiverDefined,
			organizationType: 'cargoReceiver'
		});
		if (!!buyerOrg) {
			partnerOrg = edi.utils.getOrg({
				orgId: buyerOrg.id
			});
		}

		cargoReceiver = createOS({
			itemId: 'cargoReceiver',
			allowBlank: true,
			ownCatalog: false,
			alwaysShowSelect: canEditReceiver,
			allowReset: canEditReceiver,
			is_valid: isCargoReceiverValid || !isCargoReceiverDefined,
			partnerOrg: canEditReceiver,
			partnerOrgText: 'form.btn.buyer.org',
			partnerOrgValues: partnerOrg,
			relationsFromDelcatByOrgId: canEditReceiver && isBuyerDefined && buyerOrg ? buyerOrg.id : undefined,
			fieldValues: isCargoReceiverDefined ? documentData : undefined,
			selectedOrgValues: isCargoReceiverDefined ? documentData : undefined,
			forceChangeFields: forceChangeFieldsForShips,
			downloadBankAccountsData: true,
			callback: function (values, org) {
				cargoReceiverAddressType = values.address_type;
				initialData ? edi.utils.setObjectProperty(initialData, 'document.invoice.cargoReceiver', null) : null;
				cargoReceiver.is_valid =
					isValidOrganization({
						organization: values,
						organizationType: 'cargoReceiver'
					}) || cargoReceiver.isEmptyValues();

				deliveryOrg = org || values;
				if (deliveryInfoField) {
					deliveryInfoField.valueInput.setValue(values.company_iln || values.individual_gln);
					deliveryInfoField.valueInput.isValid();
				}

				checkValid();
			},
			fieldsMap: edi.selectors.getUPDFieldsMap('invoice.cargoReceiver', false, true),
			modalConf: edi.selectors.getGridFNSModalConf(modalAdditionalConf, getDefaultsNewFNSFields())
		});
		setNameForCompanySelector(seller, 'document.seller');
		setNameForCompanySelector(buyer, 'document.buyer');
		setNameForCompanySelector(shipFrom, 'document.ship.from');
		setNameForCompanySelector(cargoReceiver, 'document.delivery');

		const organizationsColumnsLayout = createMaxWidthContainer({
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [
					[6, 6],
					[6, 6]
				]
			},
			items: [
				createFieldBlock({
					title: edi.i18n.getMessage('document.seller'),
					items: [seller]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('document.buyer'),
					items: [buyer]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('document.ship.from'),
					items: [shipFrom]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('document.delivery'),
					items: [cargoReceiver]
				})
			]
		});

		return [title, invoiceMainData, correctUPdField, organizationsColumnsLayout];
	};

	const createInvoiceTableItem = function () {
		let taxValue,
			lines = edi.utils.getObjectProperty(documentData, 'invoiceTable.productInfo', true),
			i;

		if ('string' != typeof lines && lines && lines.length) {
			productValues.products = [];
			for (i = 0; i < lines.length; i++) {
				let values = Ext.clone(lines[i]);
				edi.document.actions.setInternalLineId(lines[i], values);
				productValues.products.push(values);
			}
		}

		const taxValueValid = function (tax) {
			let taxRates = Ext.clone(edi.constants.TAX_RATES.BY_COUNTRY.RUS);
			taxRates.push(edi.constants.TAX_RATES.ALL.taxVatByTaxAgent);
			let types = taxRates.map(function (item) {
				return item.id;
			});
			return !types.some((it) => it === tax.replace(/[%]*/g, ''))
				? edi.i18n.getMessage('form.field.tax.invalid')
				: true;
		};

		const recordValueAfterEdit = function (values, isLoadData) {
			//Сумма без НДС
			let costWithoutNDS = 0,
				//Сумма НДС
				taxSum = 0,
				//Сумма с НДС
				gross = 0,
				//Цена с НДС
				unitGross =
					values[TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER] ||
					values.textInfos?.find(
						(textInfo) => textInfo?.identifier === TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER && textInfo.value
					)?.value,
				//Цена без НДС
				cost = values.cost;
			const amount = parseFloat(values.count);
			const price = parseFloat(values.cost);
			const rate = edi.utils.getObjectProperty(values, 'tax') || parseFloat(values.TaxRate);
			const theRate = edi.methods.calculateTaxRate(rate) / 100;

			if (amount && price) {
				costWithoutNDS = amount * price;
				taxSum = costWithoutNDS * theRate;
			}

			if (!taxCalculationMethod || taxCalculationMethod === 'netPrice') {
				gross = costWithoutNDS + taxSum;
				unitGross = price * (1 + theRate);
			} else if (taxCalculationMethod === 'without') {
				gross = isCreatedFromDoc && isLoadData && values.info ? values.info : costWithoutNDS + taxSum;
				taxSum =
					isCreatedFromDoc && isLoadData && 'object' == typeof values.taxSum && values.taxSum.taxSum
						? values.taxSum.taxSum
						: taxSum;
				costWithoutNDS =
					isCreatedFromDoc && isLoadData && values.costWithoutNDS ? values.costWithoutNDS : costWithoutNDS;
			} else if (taxCalculationMethod === 'grossPrice') {
				gross = unitGross * amount;
				taxSum = (unitGross - unitGross / (1 + theRate)) * amount;
				costWithoutNDS = (unitGross / (1 + theRate)) * amount;
				cost = unitGross / (1 + theRate);
			}

			values.cost = edi.utils.roundTo(parseFloat(cost), 2);
			values.taxSum = {
				taxSum: edi.utils.roundTo(parseFloat(taxSum), 2)
			};
			values.costWithoutNDS = edi.utils.roundTo(parseFloat(costWithoutNDS), 2);
			values.info = edi.utils.roundTo(parseFloat(gross), 2);
			values[TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER] = edi.utils.roundTo(parseFloat(unitGross), 2);
		};

		const makeCountryOfOriginLine = function (codeList) {
			let countryNames = [];
			codeList.forEach(function (elem) {
				const countryOfOrigin = edi.utils.getCountryFULLByISO(null, null, null, elem);
				if (countryOfOrigin) {
					countryNames.push(countryOfOrigin.name);
				}
			});
			return countryNames.join(',');
		};

		// для вызова из грида в событиях обновления и удаления
		const updateCountryOfOriginWithStore = function (store, field) {
			let codes = [];
			// обновление списка стран деклараций (атрибут КрНаимСтрПр)
			for (let i = 0, len = store.getCount(); i < len; ++i) {
				codes.push(store.getAt(i).get('countryOrigin'));
			}
			let fullLine = makeCountryOfOriginLine(codes);
			field.setValue(fullLine);
		};

		for (i = 0; i < productValues.products.length; i++) {
			if (!isCorrect && ((!isEdit && !isCopy) || isCreatedFromDoc)) {
				recordValueAfterEdit(productValues.products[i], true);
			}

			// Собрать все страны таможенных деклараций и записать отдельно (КрНаимСтрПр)
			const declarations = productValues.products[i].customsDeclarationInfo || [];
			let countryCodes = [];
			declarations.forEach(function (elem) {
				countryCodes.push(elem.countryOrigin);
			});
			const fullLine = makeCountryOfOriginLine(countryCodes);
			edi.utils.setObjectProperty(productValues.products[i], 'additionalProductInfo.countryOfOrigin', fullLine);
		}

		const renderIdentifyProductToolNumbers = function (comp) {
			let formValues = comp.up('form').getValues();
			edi.utils.clearEmptyValues(formValues);
			if (Object.keys(formValues).length === 0) {
				comp.getFieldBlock().setVisible(true);
				return null;
			}
			//const packId = formValues?.packId ?? null;
			//comp.getFieldBlock().setVisible(!!packId);
		};

		const setNumbersField = function (field, value, itemId) {
			const form = field.up('panel'),
				numbersField = form && form.down ? form.down('[itemId="numbers"]') : null,
				itemIdField = form && form.down ? form.down(`[itemId="${itemId}"]`) : null,
				gtin = itemId === 'purchaseQuantity' ? value : itemIdField.getValue(),
				purchaseQuantity = itemId === 'purchaseQuantity' ? itemIdField.getValue() : value;
			if (!gtin || !purchaseQuantity) {
				numbersField.setValue([]);
				return;
			}

			numbersField.setValue(['02' + gtin + '37' + purchaseQuantity]);
		};

		const renderUseSortAccounting = function (comp) {
			comp.getFieldBlock().setVisible(false);
		};

		const isSortAccounting = function (form) {
			const value = Ext.clone(form.getValues());
			const isSortAccountingNumber =
				value?.numbers?.length === 1 && !!value?.numbers[0]?.match(/02[0-9]{14}37[0-9]*$/);

			if (isSortAccountingNumber) {
				//ОСУ = [02]GTIN[37]purchaseQuantity
				form.down('[itemId="sortAccounting"]').setValue(!!isSortAccountingNumber);
				const sortAccountingValue = value.numbers[0];
				form.down('[name="GTIN"]').setValue(sortAccountingValue.slice(2, 16));
				form.down('[name="purchaseQuantity"]').setValue(sortAccountingValue.slice(18));
			}
		};

		const checkExistSortAccounting = function () {
			const editableGrid = productsGrid.getFormFields()['additionalProductInfo.identifyProductToolNumbers'];
			const gridValues = Ext.clone(editableGrid?.getStore()?.getRange());

			return !!gridValues?.find((identifyProductToolNumber) => {
				const numbersArr = identifyProductToolNumber?.getData()?.numbers;
				return numbersArr.length === 1 && !!numbersArr[0].match(/02[0-9]{14}37[0-9]*$/);
			});
		};

		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'FNS_UPD_LINE',
			totalModel: 'FNS_UPD_SUMMARY',
			allowBlank: false,
			padding: '24 0 0 0',
			gridColumnConfig: 'fns_upd_lines',
			totalColumnConfig: 'fns_upd_summary_editable',
			data: productValues.products,
			lineNumberFieldName: 'lineNumber',
			lockIfNoPartner: true,
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				checkValid();
			},
			changeValuesBeforeEdit: function (values) {
				if (isCreate || isCorrect) {
					let alreadyAddedInfos = values.textInfos,
						infoFieldsToAdd = [],
						findInArrayByIdentifier = function (identifier, array) {
							return array.find(function (element) {
								return identifier == element.identifier;
							});
						};

					orgProductFields.forEach(function (productInfo) {
						let textInfo = findInArrayByIdentifier(productInfo.identifier, alreadyAddedInfos);
						if (textInfo) {
							textInfo.infoFieldTooltip = productInfo.infoFieldTooltip;
							textInfo.allowEmptyValue = productInfo.allowEmptyValue;
						} else {
							infoFieldsToAdd.push(productInfo);
						}
					});

					values.textInfos = !infoFieldsToAdd.length
						? alreadyAddedInfos
						: alreadyAddedInfos.concat(infoFieldsToAdd);
				}

				taxValue = !!values.tax ? values.tax.replace(/[%]*/g, '') : null;

				const textInfos = values.textInfos || [];
				const textInfosUnitGrossPrice = textInfos.find(
					(textInfo) => textInfo.identifier === TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER && textInfo.value
				);
				if (textInfosUnitGrossPrice) {
					values[TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER] = textInfosUnitGrossPrice.value;
				}
			},
			isValidRecord: function (record) {
				let product = record.getData(),
					textInfos = product.textInfos,
					isValidInfPolStr = true,
					tax = record.get('tax'),
					taxSumValid = product.taxSum.taxSum <= edi.utils.getMaxValueByLength(15),
					isValidDeclarations = true,
					customsDeclarationInfo = product.customsDeclarationInfo,
					isValidTax = (tax == 0 || !!tax) && taxValueValid(tax) === true && taxSumValid;

				if (Array.isArray(customsDeclarationInfo) && customsDeclarationInfo.length > 0) {
					for (let i = 0; i < customsDeclarationInfo.length && isValidDeclarations; i++) {
						if (
							'object' == typeof customsDeclarationInfo[i] &&
							!!customsDeclarationInfo[i].countryOrigin &&
							!customsDeclarationInfo[i].number
						) {
							isValidDeclarations = false;
						}
					}
				}

				//Check info fields
				for (i = 0; i < textInfos.length && isValidInfPolStr; i++) {
					const info = textInfos[i];
					isValidInfPolStr = !!(info.identifier && (info.allowEmptyValue || info.value));
				}

				let okeiIsOk =
					product.productOkei === '0000'
						? !!product.additionalProductInfo?.unitOfMeasure
						: !!product.productOkei;

				let isValidProductInfo = !!(
					product.productName ||
					product.additionalProductInfo.productCode ||
					product.additionalProductInfo.productDesc ||
					product.additionalProductInfo.productReference
				);

				return !!(
					isValidProductInfo &&
					product.count &&
					okeiIsOk &&
					product.cost &&
					isValidTax &&
					isValidInfPolStr &&
					isValidDeclarations
				);
			},
			changeValuesAfterEdit: function (values) {
				if (values.excise.sumExcise == null || values.excise.sumExcise == '') {
					values.excise.withoutExcise = 'без акциза';
					values.excise.sumExcise = null;
				} else {
					values.excise.sumExcise = parseFloat(+values.excise.sumExcise).toFixed(2);
					values.excise.withoutExcise = null;
				}

				if (edi.methods.taxRates.getNumberTypeRates('RUS', true).some((it) => it === values.tax)) {
					values.tax += '%';
				}

				if (values.productOkei) {
					let okeiRecord = edi.stores.initOkeiStoreForN().getById(values.productOkei);
					if (okeiRecord) {
						if (!values.additionalProductInfo) {
							values.additionalProductInfo = {};
						}
						if (okeiRecord.get('id') != '0000') {
							values.additionalProductInfo.unitOfMeasure = okeiRecord.get('name');
						}
					} else {
						values.additionalProductInfo.unitOfMeasure = '';
					}
				} else {
					values.additionalProductInfo.unitOfMeasure = '';
				}
				if (
					values.additionalProductInfo &&
					values.additionalProductInfo.identifyProductToolNumbers &&
					values.additionalProductInfo.identifyProductToolNumbers.length
				) {
					let identifyProductToolNumbers = values.additionalProductInfo.identifyProductToolNumbers,
						cleanedNumbers = [],
						i,
						type,
						objAdd;
					for (i = 0; i < identifyProductToolNumbers.length; i++) {
						type = identifyProductToolNumbers[i].type;
						objAdd = {
							packId: identifyProductToolNumbers[i].packId
						};
						objAdd[type] = identifyProductToolNumbers[i][type];
						cleanedNumbers.push(objAdd);
					}
					values.additionalProductInfo.identifyProductToolNumbers = cleanedNumbers;
				}

				if (values[TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER]) {
					const textInfosUnitGrossPrice = {
						identifier: TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER,
						value: values[TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER]
					};
					values.textInfos.push(textInfosUnitGrossPrice);
					delete values[TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER];
				}
			},
			afterRowEdit: function (values, field) {
				if (field === 'count') {
					recordValueAfterEdit(values);
				}
			},
			docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
			docData: documentData,
			totalsHandlerCallback: function (values) {
				if ('undefined' != typeof values) {
					Ext.Object.merge(productValues, values);
				}
				checkValid();
			},
			resetFormOnCatalogSelection: true,
			selectProductHandler: function (product) {
				if (product) {
					const grid = productsGrid.formGrids['textInfos'],
						store = grid ? grid.getStore() : null;
					if (store) {
						store.each(function (record) {
							const recordData = record.getData();
							if (recordData && recordData.valueSrc) {
								if (product.EAN && 'EANproduct' === recordData.valueSrc) {
									record.set('value', product.EAN);
								}
								if (product.BuyerItemCode && 'BuyerCode' === recordData.valueSrc) {
									record.set('value', product.BuyerItemCode);
								}
								if (product.SupplierItemCode && 'SupplierCode' === recordData.valueSrc) {
									record.set('value', product.SupplierItemCode);
								}
							}
						});
					}
				}
				let fields = this.getFormFields();
				const PackDetails = product.PackDetails ? product.PackDetails : {};
				const storeOkei = edi.stores.initOkeiStoreForN();
				const getOkeiRecord = function (field, value) {
					return storeOkei.findRecord(field, value, 0, false, false, true);
				};
				const okeiRecord =
					getOkeiRecord('name_international', product.UnitOfMeasure) ||
					getOkeiRecord('name', (product['UnitOfMeasure'] || '').toLowerCase());

				fields.count && fields.count.getValue() < PackDetails.MinOrderedQuantity
					? fields.count.setValue(PackDetails.MinOrderedQuantity)
					: null;
				fields.cost ? fields.cost.setValue(product.UnitNetPrice) : null;
				fields.tax ? fields.tax.setValue(String(product.TaxRate)) : null;
				fields.productName ? fields.productName.setValue(product.ItemDescription) : null;
				fields.productOkei && okeiRecord ? fields.productOkei.setValue(okeiRecord.get('id')) : null;
				fields.info ? fields.info.setValue(product.ItemDescriptionLong) : null;

				if (product.CountryOfOrigin) {
					fields['additionalProductInfo.countryOfOrigin']
						? fields['additionalProductInfo.countryOfOrigin'].setValue(product.CountryOfOrigin)
						: null;
				}
			},
			defaultValuesGetter: function () {
				return {
					textInfos: orgProductFields
				};
			},
			modalConf: {
				width: 860,
				height: 610
			},
			modalFormConfig: {
				title: 'document.upd.line',
				formMethods: {
					setTaxFields: function () {
						const values = Ext.clone(this.getFormValues());
						let fields = this.getFormFields();
						recordValueAfterEdit(values);

						fields.costWithoutNDS ? fields.costWithoutNDS.setValue(values.costWithoutNDS) : null;
						fields.hasOwnProperty('taxSum.taxSum')
							? fields['taxSum.taxSum'].setValue(values.taxSum.taxSum)
							: null;
						fields.info ? fields.info.setValue(values.info) : null;
						fields.cost?.setValue(values.cost);
						fields[TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER]?.setValue(
							values[TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER]
						);
					},
					validateCountryOfOriginField: function () {
						let fields = productsGrid.getFormFields();
						if (fields['additionalProductInfo.countryOfOrigin']) {
							fields['additionalProductInfo.countryOfOrigin'].isValid();
						}
					},
					validateDataProductField: function () {
						const fieldsName = [
							'productName',
							'additionalProductInfo.productDesc',
							'additionalProductInfo.productCode',
							'additionalProductInfo.productReference'
						];
						let fields = productsGrid.getFormFields();
						const fieldValues = [];
						fieldsName.forEach(function (fieldName) {
							fieldValues.push(!!fields[fieldName].value);
						});
						const isAllFieldEmpty = fieldValues.some(function (item) {
							return item;
						});
						fieldsName.forEach(function (fieldName) {
							fields[fieldName].allowBlank = isAllFieldEmpty;
							fields[fieldName].mandatory = false;
							fields[fieldName].isValid();
						});
					}
				},
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						layout: {
							type: 'grid',
							gap: 16,
							area: [8, 3, 5, 4]
						},
						items: [
							{
								title: edi.i18n.getMessage('line.name'),
								name: 'productName',
								allowBlank: true,
								mandatory: false,
								maxLength: 1000,
								listeners: {
									change: function (field) {
										field.validateDataProductField();
									},
									afterrender: function (field) {
										let fields = field.getFormFields();
										const fieldsName = [
											'productName',
											'additionalProductInfo.productDesc',
											'additionalProductInfo.productCode',
											'additionalProductInfo.productReference'
										];

										const isHasValue =
											!!fields['productName'].value ||
											!!fields['additionalProductInfo.productDesc'].value ||
											!!fields['additionalProductInfo.productCode'].value ||
											!!fields['additionalProductInfo.productReference'].value;

										fieldsName.forEach(function (fieldName) {
											fields[fieldName].allowBlank = isHasValue;
											fields[fieldName].isValid();
										});
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.invoice.quantity'),
								name: 'count',
								type: 'number',
								maxText: edi.i18n.getMessage('invalid.max.text.fns'),
								validator: edi.methods.validators.positiveZero,
								allowDecimals: true,
								replaceComa: true,
								decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
								allowBlank: false,
								listeners: {
									change: function (field) {
										field.setTaxFields();
									}
								}
							},
							{
								store: edi.stores.initOkeiStoreForN(),
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								name: 'productOkei',
								type: 'combo',
								forceSelection: true,
								anyMatch: true,
								valueField: 'id',
								displayField: 'name_code',
								allowBlank: false,
								listeners: {
									change: function (field) {
										let fields = field.getFormFields();
										let unitOfMeasureField = fields['additionalProductInfo.unitOfMeasure'];
										let isShow = field.value === '0000';

										unitOfMeasureField.getFieldBlock().setVisible(isShow);
										// unitOfMeasureField.setVisible(isShow);
										unitOfMeasureField.allowBlank = !isShow;

										if (isShow) {
											unitOfMeasureField.focus();
											unitOfMeasureField.setValue(null);
										}

										unitOfMeasureField.isValid();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure.inputManual'),
								name: 'additionalProductInfo.unitOfMeasure',
								allowBlank: true,
								mandatory: true,
								maxLength: 1000,
								isHidden: function (field, data) {
									return data.productOkei ? data.productOkei != '0000' : true;
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						layout: {
							type: 'grid',
							gap: 16,
							area: [3, [3, 3, 3], 3, [3, 3, 3]]
						},
						items: [
							{
								title: edi.i18n.getMessage('organization.taxCalculationMethod'),
								readOnly: true,
								listeners: {
									afterrender: function (field) {
										let value = taxCalculationMethod
											? edi.i18n.getMessage(
													'organization.taxCalculationMethod.' + taxCalculationMethod
											  )
											: '';
										field.setValue ? field.setValue(value) : field.setText(value);
									}
								}
							},
							{
								title: edi.i18n.getMessage('column.bill.unit.net.price'),
								name: 'cost',
								maxText: edi.i18n.getMessage('invalid.max.text.fns'),
								type: 'number',
								allowDecimals: true,
								replaceComa: true,
								decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
								allowBlank: false,
								listeners: {
									change: function (field) {
										field.setTaxFields();
									}
								}
							},
							{
								title: edi.i18n.getMessage('pricat.product.unitGrossPrice'),
								name: TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER,
								type: 'number',
								allowDecimals: true,
								replaceComa: true,
								decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
								allowBlank: false,
								listeners: {
									change: function (field) {
										field.setTaxFields();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.fns.tax.rate'),
								name: 'tax',
								type: 'combo',
								store: edi.stores.createInlineStore(edi.stores.data.fnsNTaxRates, 'SIMPLE'),
								forceSelection: false,
								allowBlank: false,
								validator: taxValueValid,
								listeners: {
									change: function (field) {
										field.setTaxFields();
									},
									afterrender: function (field) {
										field.setValue(taxValue);
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.upd.excise'),
								name: 'excise.sumExcise',
								type: 'number',
								allowDecimals: true,
								replaceComa: true,
								decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
								emptyText: 'без акциза'
							},
							{
								title: edi.i18n.getMessage('line.item.net.amount'),
								name: 'costWithoutNDS',
								type: 'number',
								maxText: edi.i18n.getMessage('invalid.max.text.fns'),
								allowDecimals: true,
								replaceComa: true,
								decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
								value: 0
							},
							{
								title: edi.i18n.getMessage('line.item.tax.amount'),
								name: 'taxSum.taxSum',
								type: 'number',
								maxText: edi.i18n.getMessage('invalid.max.text.fns'),
								allowDecimals: true,
								replaceComa: true,
								decimalPrecision: edi.constants.UPD_PRICE_PRECISION
							},
							{
								title: edi.i18n.getMessage('line.item.gross.amount'),
								name: 'info',
								type: 'number',
								maxText: edi.i18n.getMessage('invalid.max.text.fns'),
								allowDecimals: true,
								replaceComa: true,
								decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
								allowBlank: false
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.additional.data'),
						layout: {
							type: 'grid',
							gap: 16,
							area: [6, 4, [4, 6], 10, [4, 6], [4, 4]]
						},
						items: [
							{
								title: edi.i18n.getMessage('line.item.additional.productCode'),
								name: 'additionalProductInfo.productCode',
								allowBlank: true,
								mandatory: false,
								maxLength: 255,
								listeners: {
									change: function (field) {
										field.validateDataProductField();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.additional.numberShouldRelease'),
								name: 'additionalProductInfo.numberShouldRelease',
								type: 'number',
								allowDecimals: true,
								replaceComa: true,
								decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
								enforceMaxLength: true,
								maxLength: 9
							},
							{
								store: edi.stores.createSimpleInlineStore(['1', '2', '3', '4', '5'], function (id) {
									return edi.i18n.getMessage('documents.fns_upd.product.type.' + id);
								}),
								displayField: 'name',
								valueField: 'id',
								type: 'combo',
								title: edi.i18n.getMessage('line.item.additional.feature'),
								name: 'additionalProductInfo.feature'
							},
							{
								title: edi.i18n.getMessage('line.item.additional.featureAdditionalInfo'),
								type: 'string',
								name: 'additionalProductInfo.featureAdditionalInfo',
								maxLength: 4
							},
							{
								title: edi.i18n.getMessage('line.item.additional.productDesc'),
								allowBlank: true,
								mandatory: false,
								type: 'string',
								name: 'additionalProductInfo.productDesc',
								maxLength: 1000,
								listeners: {
									change: function (field) {
										field.validateDataProductField();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.additional.productSort'),
								type: 'string',
								name: 'additionalProductInfo.productSort',
								maxLength: 10
							},
							{
								title: edi.i18n.getMessage('line.item.additional.productReference'),
								type: 'string',
								allowBlank: true,
								mandatory: false,
								name: 'additionalProductInfo.productReference',
								maxLength: 50,
								listeners: {
									change: function (field) {
										field.validateDataProductField();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.additional.catalogCode'),
								type: 'string',
								name: 'additionalProductInfo.catalogCode',
								minLength: 27,
								maxLength: 27
							},
							{
								title: edi.i18n.getMessage('line.item.additional.productTypeCode'),
								type: 'string',
								name: 'additionalProductInfo.productTypeCode',
								minLength: 10,
								maxLength: 10
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.sv.td'),
						items: [
							{
								name: 'additionalProductInfo.countryOfOrigin',
								type: 'hidden'
							},
							{
								type: 'editableGrid',
								name: 'customsDeclarationInfo',
								config: {
									columnsConfig: 'upd_declaration_info',
									fields: [
										{
											title: 'line.item.country.of.origin',
											name: 'countryOrigin',
											disableIfReadonly: false,
											value: function () {
												var res = null,
													fields = productsGrid.getFormFields(),
													countryCodeField = fields
														? fields['additionalProductInfo.countryOfOrigin']
														: null;
												if (countryCodeField && countryCodeField.getValue()) {
													var country = edi.utils.getCountryFULLByISO(
														null,
														null,
														null,
														null,
														countryCodeField.getValue()
													);
													if (!Ext.Object.isEmpty(country)) {
														res = country.iso_number_3;
													}
												}
												return res;
											},
											type: createCombo,
											store: edi.stores.initValidCountryFullStore(),
											anyMatch: true,
											valueField: 'iso_number_3',
											displayField: 'display_name',
											allowBlank: false
										},
										{
											title: 'column.nomer.td',
											name: 'number',
											disableIfReadonly: false,
											type: 'text',
											minLength: 1,
											maxLength: 29,
											allowBlank: false,
											value: function (record) {
												let result = null;
												if (!record) {
													const fields = productsGrid.getFormFields();
													const gridTrackingInfo =
														fields['additionalProductInfo.infoTracking'];
													const trackingStore = gridTrackingInfo.getStore();
													if (trackingStore && trackingStore.getCount() > 0) {
														result = edi.i18n.getMessage(
															'line.item.tab.sv.td.number.value'
														);
													}
												} else {
													result = record.get('number');
												}
												return result;
											},
											readOnly: function (rec, store) {
												let result = false;
												// Если есть записи во вкладке "Сведения о прослеживаемости",
												//  то номер менять нельзя (уже всем выставлен "Прослеж")
												let fields = productsGrid.getFormFields();
												const gridTrackingInfo = fields['additionalProductInfo.infoTracking'];
												const trackingStore = gridTrackingInfo.getStore();
												if (trackingStore && trackingStore.getCount() > 0) {
													result = true;
												}
												return result;
											}
										}
									],
									limit: undefined,
									modalTitle: edi.i18n.getMessage('line.item.tab.sv.td'),
									model: 'UPD_DECLARATION_INFORMATION',
									modalWidth: MODAL_SIZE.widthSmall,
									beforeAdd: function (callback) {
										let fields = productsGrid.getFormFields();
										let infoTracking = fields['additionalProductInfo.infoTracking'];

										if (infoTracking.getStore().getCount() > 0) {
											let initialFieldsData = {
												number: edi.i18n.getMessage('line.item.tab.sv.td.number.value')
											};
											callback(undefined, undefined, { initialFieldsData });
										} else {
											callback();
										}
									},
									canAddRow: function () {
										return false; // это означает "можно добавлять декларации всегда"
									},
									canDeleteRow: function () {
										let result = true;
										let fields = productsGrid.getFormFields();
										const gridDeclarationInfo = fields['customsDeclarationInfo'];
										const gridTrackingInfo = fields['additionalProductInfo.infoTracking'];
										const trackingStore = gridTrackingInfo.getStore();
										const declarationStore = gridDeclarationInfo.getStore();
										// Если есть "сведения о прослеживаемости",
										//  то можно удалять все декларации, кроме одной
										if (
											trackingStore &&
											declarationStore &&
											declarationStore.getCount() < 2 &&
											trackingStore.getCount() > 0
										) {
											result = false;
										}
										return result;
									},
									gridConfig: {
										cls: 'modal-grid'
									},
									storeConfig: {
										listeners: {
											update: function (store) {
												let fields = productsGrid.getFormFields();
												const countryCodeField = fields
													? fields['additionalProductInfo.countryOfOrigin']
													: null;
												if (countryCodeField) {
													updateCountryOfOriginWithStore(store, countryCodeField);
												}
												setTimeout(function () {
													const currentGrid = fields['customsDeclarationInfo'];
													let view = currentGrid.getView();
													view.refresh();
												}, 50);
											},
											remove: function (store) {
												let fields = productsGrid.getFormFields(),
													countryCodeField = fields
														? fields['additionalProductInfo.countryOfOrigin']
														: null;

												if (countryCodeField) {
													updateCountryOfOriginWithStore(store, countryCodeField);
												}

												setTimeout(function () {
													const currentGrid = fields['customsDeclarationInfo'];
													let view = currentGrid.getView();
													view.refresh();
												}, 50);
											}
										}
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.infoTracking'),
						bodyPadding: 24,
						items: [
							{
								type: 'editableGrid',
								name: 'additionalProductInfo.infoTracking',
								config: {
									modalWidth: MODAL_SIZE.widthSmall,
									modalTitle: edi.i18n.getMessage('line.item.infoTracking'),
									fields: [
										{
											title: edi.i18n.getMessage('line.item.infoTracking.trackingProdNum'),
											name: 'trackingProdNum',
											type: 'text',
											regex: /^[\d]{8}[\/](0[1-9]|[12][\d]|3[01])(0[1-9]|1[012])\d{2}[\/][a-zA-ZА-Яа-яёЁ\d]{7}[\/][\d]{3}$/,
											invalidText: edi.i18n.getMessage(
												'line.item.infoTracking.trackingProdNum.format'
											),
											allowBlank: false
										},
										{
											store: edi.stores.initOkeiStore(),
											title: edi.i18n.getMessage('line.item.infoTracking.trackingUnitMes'),
											name: 'trackingUnitMes',
											type: 'combo',
											forceSelection: true,
											anyMatch: true,
											valueField: 'id',
											displayField: 'name_code',
											allowBlank: false,
											renderer(val, meta, rec) {
												return rec.get('trackingUnitMesName') || val;
											}
										},
										{
											title: edi.i18n.getMessage('line.item.infoTracking.trackingProdQuan'),
											type: 'number',
											allowBlank: false,
											name: 'trackingProdQuan',
											allowDecimals: true,
											replaceComa: true,
											decimalPrecision: edi.constants.UPD_PRICE_PRECISION,
											maxLength: 9
										},
										{
											title: edi.i18n.getMessage('line.item.infoTracking.trackingAdd'),
											type: 'text',
											name: 'trackingAdd',
											maxLength: 255
										}
									],
									canDeleteRow: function (record) {
										//Can't delete organization specified product identifiers which are mandatory
										return !orgProductFields.find(function (productInfo) {
											return productInfo.trackingProdNum == record.get('trackingProdNum');
										});
									},
									getRowClass: function (record, fieldsConfig) {
										let isValidRecord = true,
											i,
											fieldValue,
											allowEmptyValue;

										for (i = 0; i < fieldsConfig.length && isValidRecord; i++) {
											fieldValue = record.get(fieldsConfig[i].name);

											//AllowBlank can be defined in editableGrid fields config or directly in record (for some info-fields)
											allowEmptyValue =
												!fieldsConfig[i].hasOwnProperty('allowBlank') ||
												fieldsConfig[i].allowBlank;
											if (!allowEmptyValue && !fieldValue) {
												isValidRecord = false;
											}
										}
										return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
									},
									limit: 20,
									autoScroll: true,
									model: 'UPD_PRODUCT_INFO_TRACKING',
									recordsUniqueByField: 'trackingProdNum',
									duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
									beforeAdd: function (callback) {
										const fields = productsGrid.getFormFields(),
											identifyProductToolNumbers =
												fields['additionalProductInfo.identifyProductToolNumbers'];

										return identifyProductToolNumbers.getStore().getCount() > 0
											? edi.core.showError(
													edi.i18n.getMessage(
														'document.warning.errorNomSredIdentTovAndSvedProslezh'
													)
											  )
											: callback();
									},
									gridConfig: {
										cls: 'modal-grid'
									},
									storeConfig: {
										listeners: {
											add: function () {
												let fields = productsGrid.getFormFields(),
													gridDeclarationInfo = fields['customsDeclarationInfo'],
													storeDeclarationInfo = gridDeclarationInfo
														? gridDeclarationInfo.getStore()
														: null;

												if (storeDeclarationInfo) {
													// если добавляется хотя бы одна строка в "сведения о прослеживаемости",
													//  все строки в таможенных декларациях получают строку "Прослеж"
													//  вместо номера (и эту строку нельзя менять)
													if (storeDeclarationInfo.getCount() > 0) {
														storeDeclarationInfo.each(function (record) {
															record.set(
																'number',
																edi.i18n.getMessage('line.item.tab.sv.td.number.value')
															);
														});
													} else {
														const constructRecord = edi.models.createInstance(
															'UPD_DECLARATION_INFORMATION',
															{
																number: edi.i18n.getMessage(
																	'line.item.tab.sv.td.number.value'
																)
															}
														);
														storeDeclarationInfo.add(constructRecord);
													}
												}
											},
											remove: function (store) {
												let fields = productsGrid.getFormFields(),
													gridDeclarationInfo = fields['customsDeclarationInfo'],
													storeDeclarationInfo = gridDeclarationInfo
														? gridDeclarationInfo.getStore()
														: null;

												// если удалены все сведения о прослеживаемости,
												//  очищаем список таможенных деклараций
												if (storeDeclarationInfo && store.getCount() === 0) {
													for (let i = storeDeclarationInfo.getCount() - 1; i >= 0; i--) {
														storeDeclarationInfo.removeAt(i);
													}
												}
											}
										}
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.identifyProductToolNumbers'),
						bodyPadding: 24,
						items: [
							{
								type: 'editableGrid',
								name: 'additionalProductInfo.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthSmall,
									modalTitle: edi.i18n.getMessage('line.item.identifyProductToolNumbers'),
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: null,
											boxLabel: edi.i18n.getMessage(
												'line.item.identifyProductToolNumbers.use.sort.accounting'
											),
											itemId: 'sortAccounting',
											type: 'checkbox',
											value: false,
											inputValue: true,
											listeners: {
												render: renderIdentifyProductToolNumbers,
												change: function (field, useSortAccounting) {
													let items = productsGrid.getFormFields(),
														identifyProductToolNumbersGrid =
															items['additionalProductInfo.identifyProductToolNumbers'],
														form = identifyProductToolNumbersGrid.getModalForm(),
														fields = edi.utils.getFormFields(form);

													const clearForm = function () {
														fields.purchaseQuantity.setValue(null);
														fields.GTIN.setValue(null);
														fields.packId.setValue(null);
														fields.type.setValue(null);
														fields.numbers.setValue([]);
													};

													const setForm = function () {
														fields.packId.getFieldBlock().setVisible(!useSortAccounting);
														fields.packId.allowBlank = useSortAccounting;
														fields.type.setValue(useSortAccounting ? 'packNumber' : null);
														useSortAccounting
															? (fields.numbers.allowBlank = useSortAccounting)
															: null;
														fields.type.setReadOnly(field.getValue());
														fields.numbers.requiredFields = useSortAccounting
															? []
															: ['type'];
														fields.numbers.getFieldBlock().setVisible(!useSortAccounting);
														fields.purchaseQuantity
															.getFieldBlock()
															.setVisible(useSortAccounting);
														fields.purchaseQuantity.allowBlank = !useSortAccounting;
														fields.GTIN.getFieldBlock().setVisible(useSortAccounting);
														fields.GTIN.allowBlank = !useSortAccounting;
													};

													clearForm();
													setForm();
													form.isValid();
												},
												afterrender: function (checkbox) {
													checkbox.up('form').on('afterrender', (form) => {
														isSortAccounting(form);
														const isSortAccountingAlreadyExist = checkExistSortAccounting();
														//Чекбокс нельзя установить если уже есть хотя бы один код ОСУ, но можно снять там где он проставлен
														checkbox.setReadOnly(
															checkbox.getValue() ? false : !!isSortAccountingAlreadyExist
														);
													});
												}
											}
										},
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											itemId: 'packId',
											type: 'text',
											allowBlank: false,
											renderer: 'desadvLineEAN',
											maxLength: 255,
											listeners: {
												render: renderIdentifyProductToolNumbers
											}
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											forceSelection: true,
											valueInitialize: false,
											requiredFields: ['numbers'],
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											itemId: 'type',
											name: 'type',
											listeners: {
												change: function (field, value) {
													const fields = productsGrid.getFormFields();
													const identifyProductToolNumbersGrid =
														fields['additionalProductInfo.identifyProductToolNumbers'];
													let packId = identifyProductToolNumbersGrid
														.getModalForm()
														.down('[name=packId]');
													packId.allowBlank = !!value;
													packId.isValid();
												}
											}
										},
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.numbers'),
											requiredFields: ['type'],
											name: 'numbers',
											itemId: 'numbers',
											type: 'tagTextField',
											delimiter: '[\\s]',
											emptyTextKey: 'uikit.tagtextfield.empty.withoutComma',
											buttonMethodName: 'createSaveButton'
										},
										{
											title: edi.i18n.getMessage(
												'line.item.identifyProductToolNumbers.purchase.quantity'
											),
											itemId: 'purchaseQuantity',
											name: 'purchaseQuantity',
											type: 'text',
											listeners: {
												change: function (field, value) {
													setNumbersField(field, value, 'GTIN');
												},
												render: renderUseSortAccounting
											}
										},
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.GTIN'),
											itemId: 'GTIN',
											name: 'GTIN',
											type: 'text',
											listeners: {
												change: function (field, value) {
													setNumbersField(field, value, 'purchaseQuantity');
												},
												render: renderUseSortAccounting
											}
										}
									],
									canDeleteRow: function (record) {
										//Can't delete organization specified product identifiers which are mandatory
										return !orgProductFields.find(function (productInfo) {
											return productInfo.packId == record.get('packId');
										});
									},
									getRowClass: function (record, fieldsConfig) {
										let isValidRecord = true,
											i,
											fieldValue,
											allowEmptyValue;

										for (i = 0; i < fieldsConfig.length && isValidRecord; i++) {
											fieldValue = record.get(fieldsConfig[i].name);

											//AllowBlank can be defined in editableGrid fields config or directly in record (for some info-fields)
											allowEmptyValue =
												!fieldsConfig[i].hasOwnProperty('allowBlank') ||
												fieldsConfig[i].allowBlank;
											if (!allowEmptyValue && !fieldValue) {
												isValidRecord = false;
											}
										}
										return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
									},
									limit: 20,
									autoScroll: true,
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									recordsUniqueByField: 'packId',
									duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
									beforeAdd: function (callback) {
										const fields = productsGrid.getFormFields(),
											infoTracking = fields['additionalProductInfo.infoTracking'];

										return infoTracking.getStore().getCount() > 0
											? edi.core.showError(
													edi.i18n.getMessage(
														'document.warning.errorNomSredIdentTovAndSvedProslezh'
													)
											  )
											: callback();
									},
									gridConfig: {
										cls: 'modal-grid'
									},
									storeConfig: {
										remoteSort: false
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.inf.pol.str'),
						bodyPadding: 24,
						items: [
							{
								type: 'editableGrid',
								name: 'textInfos',
								config: {
									modalTitle: edi.i18n.getMessage('line.item.tab.inf.pol.str'),
									fields: [
										{
											name: 'identifier',
											type: 'text',
											minLength: 1,
											maxLength: 50,
											allowBlank: false,
											disableIfReadonly: false,
											readOnly: function (record) {
												if (record) {
													const data = record.getData();
													if (data && data.identifier) {
														if (
															orgProductFields.find(function (product) {
																return product.identifier === data.identifier;
															})
														) {
															return true;
														}
													}
												}
												return false;
											},
											renderer: function (value, meta, record) {
												if (record.get('infoFieldTooltip')) {
													meta.tdAttr =
														'data-qtip="' +
														edi.utils.replaceQuotes(record.get('infoFieldTooltip')) +
														'"';
												}
												return value;
											}
										},
										{
											name: 'value',
											type: 'text',
											minLength: 1,
											maxLength: 200,
											allowBlank: false,
											renderer: function (value, meta, record) {
												if (record.get('infoFieldTooltip')) {
													meta.tdAttr =
														'data-qtip="' +
														edi.utils.replaceQuotes(record.get('infoFieldTooltip')) +
														'"';
												}
												return value;
											}
										}
									],
									canDeleteRow: function (record) {
										//Can't delete organization specified product identifiers which are mandatory
										return !orgProductFields.find(function (productInfo) {
											return (
												productInfo.identifier == record.get('identifier') &&
												!productInfo.allowEmptyValue
											);
										});
									},
									getRowClass: function (record, fieldsConfig) {
										let isValidRecord = true,
											i,
											fieldValue,
											allowEmptyValue;

										for (i = 0; i < fieldsConfig.length && isValidRecord; i++) {
											fieldValue = record.get(fieldsConfig[i].name);

											//AllowBlank can be defined in editableGrid fields config or directly in record (for some info-fields)
											allowEmptyValue =
												record.get('allowEmptyValue') ||
												!fieldsConfig[i].hasOwnProperty('allowBlank') ||
												fieldsConfig[i].allowBlank;
											if (!allowEmptyValue && !fieldValue) {
												isValidRecord = false;
											}
										}

										return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
									},
									limit: 20,
									modalWidth: MODAL_SIZE.widthSmall,
									autoScroll: true,
									model: 'UPD_PRODUCT_TEXT_INFO',
									recordsUniqueByField: 'identifier',
									duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
									storeConfig: {
										filters: [
											function (textInfo) {
												return (
													textInfo.get('identifier') !==
													TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER
												);
											}
										]
									},
									gridConfig: {
										cls: 'modal-grid'
									}
								}
							}
						]
					}
				]
			}
		});

		productsGrid.setPartnerId(buyerOrg ? buyerOrg.id : null);

		return productsGrid;
	};

	const createModuleActionsPanel = function () {
		let actionsPanel = createActionsPanel({
			layout: {
				type: 'grid',
				gap: 16,
				area: [3]
			}
		});

		if (showProjectCombo) {
			projectCombo = createCombo({
				margin: '10 0 0 20',
				fieldLabel: edi.i18n.getMessage('documents.fns_upd.project.placeholder'),
				tooltip: edi.i18n.getMessage('documents.fns_upd.project.tooltip'),
				store: edi.stores.createInlineStore([], 'SIMPLE', undefined, {
					addEmptyRecord: true
				}),
				value: null,
				listeners: {
					afterrender: function () {
						Ext.create('Ext.ToolTip', {
							target: this.getEl(),
							trackMouse: true,
							header: false,
							html: edi.i18n.getMessage('documents.fns_upd.project.tooltip')
						});
					}
				},
				setItems(newItems) {
					let me = this;
					let store = me.getStore();
					if (store) {
						store.removeAll();

						if (me.allowBlank !== false) {
							store.add(edi.stores.getEmptyRecord());
						}
						store.add(newItems || []);
					}
				}
			});
			actionsPanel.add(projectCombo);
		}

		return actionsPanel;
	};

	/**
	 * Creates upd form
	 * @returns {Object}
	 */
	const createModuleForm = function () {
		const createBtn = createButton(
			{
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.create'),
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							mainForm,
							['carrier', 'factorInfo', 'seller', 'buyer', 'cargoReceiver', 'shipFrom'],
							[carrier, seller, buyer, cargoReceiver, shipFrom],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					save();
				}
			},
			isEdit || isCorrect
		);

		// EDIWEB begin
		let action = function (callback) {
			if (
				!edi.utils.setFocusToDocumentsWithGrid(
					mainForm,
					['carrier', 'factorInfo', 'seller', 'buyer', 'cargoReceiver', 'shipFrom'],
					[carrier, seller, buyer, cargoReceiver, shipFrom],
					productsGrid,
					!productValues.products.length
				)
			) {
				return;
			}
			let values = edi.utils.collectFormValues(mainForm);
			values.bpName = bpName;
			values.type = edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD;
			values.versionId = '5.01-N';
			'function' == typeof callback ? callback(values) : null;
		};

		const createAndSignSendMenuButton = createSendButton(
			function () {
				if (
					!edi.utils.setFocusToDocumentsWithGrid(
						mainForm,
						['carrier', 'factorInfo', 'seller', 'buyer', 'cargoReceiver', 'shipFrom'],
						[carrier, seller, buyer, cargoReceiver, shipFrom],
						productsGrid,
						!productValues.products.length
					)
				) {
					return;
				}
				save();
			},
			{
				disabled: false,
				formBind: false,
				text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.create'),
				handler: null,
				menu: {
					plain: true,
					hideMode: 'display',
					items: [
						{
							text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.create'),
							handler() {
								save();
							}
						},
						{
							text: edi.i18n.getMessage('ediweb.send.to.approval.documents.btn'),
							handler() {
								action((values) => {
									showApprovalModal(
										values,
										moduleData,
										save,
										edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_DOCUMENTS
									);
								});
							}
						},
						{
							text: edi.i18n.getMessage('ediweb.send.to.approval.employees.btn'),
							handler() {
								action((values) => {
									showApprovalModal(
										values,
										moduleData,
										save,
										edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES
									);
								});
							}
						}
					]
				}
			}
		);

		// EDIWEB end

		paymentInformationColumns = createRowsBlock({
			createContentFieldsFn: createPaymentInformationField,
			initialData:
				isEdit || isCorrect || isCopy
					? edi.utils.getObjectProperty(documentData, 'invoice.paymentInformation') || []
					: [],
			onAddRow() {
				checkValid();
			},
			onRemoveRow() {
				checkValid();
			}
		});

		shipDocConfirmationColumns = createRowsBlock({
			contentCols: 9,
			buttonsCols: 3,
			createContentFieldsFn: createShipDocConfirmationField,
			initialData:
				isEdit || isCorrect || isCopy
					? edi.utils.getObjectProperty(documentData, 'invoice.shipDocConfirmation') || []
					: [],
			onAddRow() {
				checkValid();
			},
			onRemoveRow() {
				checkValid();
			}
		});

		const container = createMaxWidthContainer({
			layout: {
				type: 'grid',
				gap: 24
			},
			items: [].concat(
				createInvoiceFormItems(),
				createFieldBlock({
					title: edi.i18n.getMessage('documents.fns_upd.paymentInformation'),
					items: paymentInformationColumns
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.shipDocConfirmation'),
					items: shipDocConfirmationColumns
				})
			)
		});

		mainForm = createFormForModule({
			//cls: 'edi-form document-add-form',
			items: [
				container,
				createTransportAndCargo(),
				createAdditionalInformation(),
				createTextInfosBlock(),
				createInvoiceTableItem(),
				createSignerItems()
			],
			buttons: createButtonContainer({
				items: [
					!edi.constants.IS_EDIWEB_CLIENT ? createBtn : null,
					edi.constants.IS_EDIWEB_CLIENT ? createAndSignSendMenuButton : null
				]
			})
		});

		mainForm.on('validitychange', checkValid);
		mainForm.isValid();
		edi.utils.processModuleFormChange(mainForm, moduleData);

		return mainForm;
	};

	const buildDocument = function (formValues) {
		if (!isEdit && !bpName && formValues.updBpName) {
			bpName = formValues.updBpName;
		}
		delete formValues.updBpName;
		delete formValues.receiptPartDoc.transferInformation.transportAndCargo.carrier['true'];
		delete formValues.invoice.cargoFrom.cargoSender['true'];
		delete formValues.invoice.cargoReceiver['true'];

		formValues.invoice.sellerInfo = [formValues.invoice.sellerInfo];
		formValues.invoice.buyerInfo = [formValues.invoice.buyerInfo];
		formValues.invoice.cargoFrom = [formValues.invoice.cargoFrom];
		formValues.invoice.cargoReceiver = [formValues.invoice.cargoReceiver];

		const checkSignersPersonType = function (signers) {
			for (let i = 0; i < signers.length; i++) {
				if (signers[i].state == '4' && userData.org.orgType !== edi.constants.ORGANIZATION_TYPES.INDIVIDUAL) {
					const personPropName = 'individual';
					if (
						!signers[i].hasOwnProperty(personPropName) ||
						Object.keys(signers[i][personPropName]).length === 0
					) {
						['individualPerson', 'legalEntity', 'person'].every(function (value) {
							if (signers[i].hasOwnProperty(value) && Object.keys(signers[i][value]).length > 0) {
								const innFromCert = String(signerFillFromCert['inn'] ?? '');
								const isLegalEntityINN =
									(innFromCert.length === 12 && innFromCert.substring(0, 2) == '00') ||
									innFromCert.length === 10;
								if (signerFillFromCert.hasOwnProperty('inn') && !isLegalEntityINN) {
									signers[i][value]['tin'] = signerFillFromCert['inn'];
								} else {
									delete signers[i][value]['tin'];
								}
								signers[i][personPropName] = signers[i][value];
								signers[i][value]['name'] = signers[i][value]['fullName'];
								delete signers[i][value]['fullName'];

								delete signers[i][value];
								return false;
							}
							return true;
						});
					}
				}
			}
			return signers;
		};

		let build = {
			signer: checkSignersPersonType(
				edi.methods.convertValuesFromMultipleFields(edi.utils.getObjectProperty(formValues, 'signer'))
			),
			receiptPartDoc: {
				transferInformation: {
					transportAndCargo: {
						wayBill: edi.methods.convertValuesFromMultipleFields(
							edi.utils.getObjectProperty(
								formValues,
								'receiptPartDoc.transferInformation.transportAndCargo.wayBill'
							)
						)
					},
					baseShipmentOfGoods: edi.methods.convertValuesFromMultipleFields(
						edi.utils.getObjectProperty(
							formValues,
							'receiptPartDoc.transferInformation.baseShipmentOfGoods'
						)
					)
				}
			},
			invoiceTable: getProductsData(productValues),
			function: edi.i18n.getMessage('documents.fns_upd.type.' + bpName),
			nameEconomicSubject: userData.org.name
		};

		if (!isSCHF_BusinessProcess(bpName)) {
			build.economiclifeDocName = edi.i18n.getMessage('documents.fns_upd.economiclifeDocName.value');
			build.primaryDocName = edi.i18n.getMessage('documents.fns_upd.primaryDocName.' + bpName);
		}

		if (isEdit) {
			const cloneFormValues = Ext.clone(formValues);
			delete formValues.receiptPartDoc.transferInformation.personHandingGoods;
			edi.utils.setObjectProperty(formValues, 'receiptPartDoc.transferInformation.personHandingGoods', {});

			edi.utils.setObjectProperty(
				formValues,
				'receiptPartDoc.transferInformation.personHandingGoods',
				convertHandingGoodsValues(
					edi.utils.getObjectProperty(
						cloneFormValues,
						'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker'
					)
				)
			);
		} else {
			edi.utils.setObjectProperty(
				formValues,
				'receiptPartDoc.transferInformation.personHandingGoods',
				convertHandingGoodsValues(
					edi.utils.getObjectProperty(
						formValues,
						'receiptPartDoc.transferInformation.personHandingGoods.sellerOrganizationWorker'
					)
				)
			);
		}

		edi.methods.setObjectValuesWithConverts(formValues, build, 'invoice.paymentInformation');
		edi.methods.setObjectValuesWithConverts(formValues, build, 'invoice.shipDocConfirmation');
		edi.methods.setObjectValuesWithConverts(
			formValues,
			build,
			'receiptPartDoc.informationOfTheFactOfeconomicLife.textInfos'
		);

		let textInfos = edi.methods.convertValuesFromMultipleFields(
			edi.utils.getObjectProperty(formValues, 'invoice.info.textInfos')
		);
		textInfos = textInfos.filter(function (item) {
			return !!(item.identifier && item.value);
		});

		textInfos.forEach((inf) => {
			if (inf.identifier === PURPOSES_TYPES.VID_OBOBOROTA || inf.identifier === PURPOSES_TYPES.SV_VIBITIYA) {
				inf.identifier = getPurposeTypeValue(inf.identifier);
			}
		});

		edi.utils.setObjectProperty(build, 'invoice.info.textInfos', textInfos);
		return Ext.Object.merge(formValues, build);
	};

	//Save document
	const saveDocument = function (document, afterSave, onSaveFailed, options) {
		const doc = document.document;
		const success = function (data) {
			if (data && data.data && data.data.id) {
				parentData = data;
			}
			const savedProject = edi.utils.getAttributeByName(parentData.data.attributes, PROJECT_ATTR_ID);
			var afterSaveProject =
				options?.isSendingToApproval === true && !isEdit
					? function (respData) {
							//после создания дока сразу обновим модуль, т.к. он уже черновик в редактировании
							//и после обновления запустим уже процесс согласования
							//это все из-за того, что в процессе создания или отправки согласования
							//что-то может пойти не так, и это позволит нам сразу работать с черновиком,
							//у которого уже есть id
							if (respData?.data?.id) {
								moduleData.initData.data = respData.data;
								let title = edi.i18n.getMessage(
									edi.modulesCfg['document.create.fns_upd_5_01n'].title + '.edit'
								);
								title += ` ${respData.data.number}`;
								moduleData.tab.setTitle(title);
								moduleData.tab.tab.setTooltip(title);
								moduleData.tab.setGlyph(edi.constants.ICONS.EDIT);
								moduleData.instance.init(moduleData, () => afterSave(respData.data.id));
							}
					  }
					: edi.document.actions.createSaveSuccessHandler(
							moduleData,
							isEdit ? parentId : undefined,
							afterSave,
							isEdit && !isCreate
					  );
			if (isEdit || !savedProject) {
				saveProject(function () {
					return afterSaveProject(parentData);
				});
			} else {
				afterSaveProject(parentData);
			}
		};
		const failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
			function () {
				if (typeof onSaveFailed === 'function') {
					onSaveFailed(moduleData);
				}
			}
		);

		if (isEdit && !isCreate) {
			edi.document.actions.processDocument(
				buyerOrg.id,
				undefined,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1,
				parentId,
				moduleData.initData.data.id,
				success,
				failure,
				{
					data: Ext.encode(document),
					number: edi.utils.getObjectProperty(doc, 'invoice.invoiceNumber'),
					date: edi.utils.getObjectProperty(doc, 'invoice.invoiceDate'),
					agreement: contract && contract.id ? contract.id : null,
					bpName: bpName,
					dateFormat: edi.constants.DATE_FORMAT.FNS,
					versionId: '5.01-N'
				}
			);
		} else {
			//логика нужно для того чтобы бек сам определял префиксы при создании для БП( __DECREE_14  _WITHOUT_OPERATOR и тд)
			let bpNameToCreate = edi.constants.FNS_BPNAMES_CREATE_MAP[bpName]
				? edi.constants.FNS_BPNAMES_CREATE_MAP[bpName]
				: bpName;

			let headerData = {
				data: Ext.encode(document),
				number: edi.utils.getObjectProperty(doc, 'invoice.invoiceNumber'),
				date: isCorrect
					? edi.utils.getObjectProperty(doc, 'invoice.corrections.Date')
					: edi.utils.getObjectProperty(doc, 'invoice.invoiceDate'),
				agreement: contract && contract.id ? contract.id : null,
				bpName: bpNameToCreate,
				dateFormat: edi.constants.DATE_FORMAT.FNS,
				versionId: '5.01-N'
			};
			if (isNumberAutoGenerated) {
				headerData.autogen = true;
			} else {
				headerData.number = edi.utils.getObjectProperty(doc, 'invoice.invoiceNumber');
			}

			edi.document.actions.processDocument(
				buyerOrg.id,
				undefined,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1,
				!isCopy ? parentId : undefined,
				undefined,
				success,
				failure,
				headerData,
				{
					postData: {
						containerDocType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
					},
					createUri: edi.rest.services.DOCUMENTS.WITH_CONTAINER.POST
				}
			);
		}
	};

	/**
	 * Saves fns upd
	 */
	const save = function (formValues, afterSave, onSaveFailed, options) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		let values = buildDocument(edi.utils.collectFormValues(mainForm)),
			textInfos = edi.utils.getObjectProperty(values, 'invoice.info.textInfos', true);
		//чистим адреса
		const companiesPath = [
			'receiptPartDoc.transferInformation.transportAndCargo.carrier',
			'invoice.additionalInfo.factorInfo',
			'invoice.sellerInfo.0',
			'invoice.buyerInfo.0',
			'invoice.cargoFrom.0.cargoSender',
			'invoice.cargoReceiver.0'
		];
		companiesPath.forEach(function (companyPath) {
			switch (edi.utils.getObjectProperty(values, companyPath + '.address_type')) {
				case '0':
					edi.utils.setObjectProperty(values, companyPath + '.address.codeStateAddressRegister', null);
					edi.utils.setObjectProperty(values, companyPath + '.address.addressInfo', null);
					break;
				case '1':
					edi.utils.setObjectProperty(values, companyPath + '.address.codeStateAddressRegister', null);
					edi.utils.setObjectProperty(values, companyPath + '.address.addressRu', null);
					break;
				case '2':
					edi.utils.setObjectProperty(values, companyPath + '.address.addressRu', null);
					edi.utils.setObjectProperty(values, companyPath + '.address.addressInfo', null);
					break;
			}
			edi.utils.setObjectProperty(values, companyPath + '.address_type', null);
		});

		if (!isDOP_BusinessProcess(bpName)) {
			// тэг ИспрСчФ должен присутствовать, но в нём могут быть разные пары атрибутов
			//  либо ДефНомИспрСчФ-ДефДатаИспрСчФ, либо НомИспрСчФ-ДатаИспрСчФ, но не обе сразу
			const hasCorrValues = edi.utils.getObjectProperty(values, 'invoice.corrections.Number');
			if (!hasCorrValues) {
				edi.utils.setObjectProperty(values, 'invoice.corrections.DefNumber', '-');
				edi.utils.setObjectProperty(values, 'invoice.corrections.DefDate', '-');
			} else {
				edi.utils.setObjectProperty(values, 'invoice.corrections.DefNumber', null);
				edi.utils.setObjectProperty(values, 'invoice.corrections.DefDate', null);
			}
		}

		edi.utils.setObjectProperty(values, 'invoice.additionalInfo.currencyName', currencyName);

		delete values['true'];
		values = {
			document: values
		};
		let fieldsToRemove = [].concat(edi.constants.FIELDS_TO_REMOVE_AFTER_MERGE_FNS);
		if (isCopy) {
			fieldsToRemove.push(
				'fileId',
				'formVersion',
				'progVersion',
				'docFlowMembers',
				'document.fileInvoiceCreationDate',
				'document.fileInvoiceCreationTime'
			);
		}
		if (isEdit || isCopy) {
			const personHandingGoodsValue = edi.utils.getObjectProperty(
				values,
				'receiptPartDoc.transferInformation.personHandingGoods'
			);
			edi.utils.setObjectProperty(
				initialData,
				'document.receiptPartDoc.transferInformation.personHandingGoods',
				personHandingGoodsValue
			);
		}
		values = edi.document.actions.mergeDataBeforeSave(
			initialData,
			values,
			[
				{
					path: 'document.invoiceTable.productInfo'
				}
			],
			{
				fieldsToRemove: fieldsToRemove
			}
		);
		edi.utils.clearEmptyValues(values);

		if (
			edi.utils.getObjectProperty(values, 'document.invoice.additionalInfo.factorInfo', true).length !== 0 &&
			edi.utils.getObjectProperty(values, 'document.invoice.additionalInfo.assignmentBasis', true).length === 0
		) {
			edi.utils.setObjectProperty(
				values,
				'document.invoice.additionalInfo.assignmentBasis.name',
				edi.i18n.getMessage('documents.fns_upd.additionalInfo.assignmentBasis.name.without.document')
			);
		}

		const def = [
			'document.invoice.sellerInfo.0.identification.individualPerson',
			'document.invoice.sellerInfo.0.identification.legalEntity',
			'document.invoice.cargoFrom.0.cargoSender.identification.individualPerson',
			'document.invoice.cargoFrom.0.cargoSender.identification.legalEntity',
			'document.invoice.cargoReceiver.0.identification.individualPerson',
			'document.invoice.cargoReceiver.0.identification.legalEntity',
			'document.invoice.buyerInfo.0.identification.individualPerson',
			'document.invoice.buyerInfo.0.identification.legalEntity',
			'document.receiptPartDoc.transportAndCargo.carrier.identification.individualPerson',
			'document.receiptPartDoc.transportAndCargo.carrier.identification.legalEntity',
			'document.signer.0.individualPerson',
			'document.signer.0.legalEntity',
			'document.signer.0.person',
			'document.invoice.additionalInfo.factorInfo.individualPerson',
			'document.invoice.additionalInfo.factorInfo.legalEntity'
		];

		for (let i = 0; i < def.length; i++) {
			if (edi.utils.getObjectProperty(values, def[i], true).length > 0) {
				edi.utils.getObjectProperty(values, def[i] + '.tin') !== ''
					? edi.utils.setObjectProperty(values, def[i] + '.defTin', null)
					: edi.utils.setObjectProperty(values, def[i] + '.defTin', '-');
			}
		}

		//If org has specified fields and values of these fields must be taken from specified document values,
		//Create map of fields to check them (fields with "valueSrc" property) and show modal of differs if needed
		if (orgInfoFields.length && textInfos.length) {
			let identifiersToCheckMap = {};

			orgInfoFields.forEach(function (fieldConfig) {
				if (fieldConfig.valueSrc) {
					identifiersToCheckMap[fieldConfig.infoId] = fieldConfig.valueSrc;
				}
			});

			if (Object.keys(identifiersToCheckMap).length > 0) {
				checkSpecifiedInfosActuality(textInfos, identifiersToCheckMap, function () {
					saveDocument(values, afterSave, onSaveFailed, options);
				});
			} else {
				saveDocument(values, afterSave, onSaveFailed, options);
			}
		} else {
			saveDocument(values, afterSave, onSaveFailed, options);
		}
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		const data = moduleData.initData.data;
		let modulePanel = createAddModulePanel();
		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		if (data && data.id) {
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;

			parentData = moduleData.initData;
			parentId = data.id;

			buyerOrg = data.toOrg;
			contract = data.agreement;
			bpName = edi.utils.getAttributeByName(moduleData.initData.data.attributes, 'bpName');
			taxCalculationMethod = edi.utils.getAttributeByName(buyerOrg.attributes, 'taxCalculationMethod');
			if (!taxCalculationMethod || taxCalculationMethod === 'null') {
				taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
			}
			getAuthorityAreasByBP();
			getInfoInvoicesByBP();
			isSCHFSelected = isSCHF_BusinessProcess(bpName);
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
					documentId: parentId
				}),
				'GET',
				undefined,
				function (data) {
					if (data && data.data && data.data.children && data.data.children.length) {
						for (let i = 0; i < data.data.children.length; i++) {
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1) {
								id = data.data.children[i].id;
								break;
							}
						}
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
								documentId: id
							}),
							'GET',
							{},
							function (data) {
								if (data && data.data) {
									documentData = edi.utils.getObjectProperty(data.data, 'document');
									// в заголовке сейчас не всегда есть данные об ИУПД, поэтому проверяем ещё и тут
									isCorrect = !!edi.utils.getObjectProperty(
										documentData,
										'invoice.corrections.Number'
									);
									initialData = data.data;
									const buildForm = function (fieldsFillFromCert, signerSettings) {
										isInitialBuild = true;
										signerFillFromCert = fieldsFillFromCert;
										certSignerSettings = signerSettings;
										const form = createModuleForm();
										isSCHFSelected ? checkSCHFTransportFieldsIfNeeded() : checkMandatoryFields();
										modulePanel.add(form);
										moduleData.tab.removeAll();
										moduleData.tab.add(createModuleActionsPanel());
										moduleData.tab.add(modulePanel);
										getOrganizationAdditionalFields(buyerOrg.id);
										initProjectCombo(function () {
											moduleData.tab.setLoading(false);
											isInitialBuild = false;
											if ('function' == typeof initCallBack) {
												initCallBack();
											}
										});
									};

									const signersData = edi.utils.getObjectProperty(documentData, 'signer');
									edi.methods.fillUPDSignerValues(
										edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
										signersData[0],
										edi.utils.getObjectProperty(documentData, 'invoice.sellerInfo'),
										buildForm,
										null,
										null,
										!isEdit && !isCopy
									);
								} else {
									failure(data);
								}
							},
							failure
						);
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			const createModulePanel = function () {
				if (isCreatedFromDoc && !buyerOrg) {
					buyerOrg = data.toOrg ? data.toOrg : null;
					taxCalculationMethod = edi.utils.getAttributeByName(buyerOrg.attributes, 'taxCalculationMethod');
					if (!taxCalculationMethod || taxCalculationMethod === 'null') {
						taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
					}
				}

				if (!documentData.signer) {
					documentData.signer = [{}];
				}
				const buildForm = function (fieldsFillFromCert) {
					signerFillFromCert = fieldsFillFromCert;
					const form = createModuleForm();
					if (isCreatedFromDoc) {
						isSCHFSelected ? checkSCHFTransportFieldsIfNeeded() : checkMandatoryFields();
					}
					modulePanel.add(form);
					moduleData.tab.removeAll();
					moduleData.tab.add(createModuleActionsPanel());
					moduleData.tab.add(modulePanel);
					buyer.presetFromRelation(function () {
						if (buyer.selectedOrg) {
							getOrganizationAdditionalFields(buyer.selectedOrg.id);
						}
						initProjectCombo(function () {
							moduleData.tab.setLoading(false);
							if ('function' == typeof initCallBack) {
								initCallBack();
							}
						});
					});
				};

				edi.methods.fillUPDSignerValues(
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
					documentData.signer[0],
					edi.converters.convertOrgToPartie(userData.org),
					buildForm,
					null,
					null,
					!isEdit
				);
			};
			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET_BY_TYPE.GET, {
						doctype: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
					}),
					'GET',
					undefined,
					function (docNumberSettings) {
						if (docNumberSettings && docNumberSettings.data) {
							isNumberAutoGenerated =
								docNumberSettings.data &&
								docNumberSettings.data.policy &&
								'ALLOW' === docNumberSettings.data.policy;
						}
						createModulePanel();
					},
					failure
				);
			} else {
				createModulePanel();
			}
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};

export { TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER };
