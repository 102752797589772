import { isDSFV3 } from '@Edi/methods/methods';

const actionRulesForDsfModule = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		conditions: [
			{
				allow: function (documentData) {
					let docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		conditions: [
			{
				allow: function (documentData) {
					let docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				documents: [
					edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
					edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE,
					edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
					edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
					edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
					edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
					edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
					edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT
				]
			}
		]
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	EXPORT_GROUP_XML: {},
	EXPORT_GROUP: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				documents: [
					edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
					edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
					edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP,
					edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP02,
					edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP12,
					edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP14,
					edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT,
					edi.constants.DOCUMENT_TYPES.DSF_EDI_KC2,
					edi.constants.DOCUMENT_TYPES.DSF_EDI_KC3,
					edi.constants.DOCUMENT_TYPES.DSF_EDI_KC11,
					edi.constants.DOCUMENT_TYPES.DSF_EDI_KC14
				],
				allow: false
			},
			{
				documents: [
					edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
					edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
					edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
					edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
					edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT
				],
				allow: function (checkOptions) {
					return edi.permissions.hasPermission('CREATE_EDI_GROUP_EXPORT_REQUEST');
				}
			},
			{
				allow: true
			}
		]
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {}
	},
	SIGN: {
		OUTGOING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT,
				edi.constants.STATE.OPERATOR_INVOICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE,
				edi.constants.STATE.OPERATOR_INVOICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE,
				edi.constants.STATE.RECEIVER_INVOICE_RECEIVED_ACK,
				edi.constants.STATE.SENDER_INVOICE_RECEIVED_ACK_ADVISE,
				edi.constants.STATE.DSF_DP_IZVPOL_SENDER
			]
		},
		LOOP: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT,
				edi.constants.STATE.WAIT_SIGNATURE_STATUS10,
				edi.constants.STATE.WAIT_PARTNER_DECISION
			]
		},
		INCOMING: {
			DOCUMENTS: [
				edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
				edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
				edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
				edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
				edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
				edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
				edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
				edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
			],
			BUSINESS_STATE: [
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_APPROVAL,
				edi.constants.STATE.PROCESSING_TITLES,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT
			]
		},
		permissionChanging: [
			{
				documents: [edi.constants.DSF_TYPES_FROV],
				change: function (actionPermission, checkOptions) {
					return (
						actionPermission +
						(checkOptions.direction === edi.constants.DIRECTIONS.INCOMING ? '_RECEIVER' : '_SENDER')
					);
				}
			}
		],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED],
				allow: function (documentData) {
					const isBlocked = edi.utils.getAttributeByName(
						documentData.attributes,
						'isBlocked',
						undefined,
						true
					);
					return documentData.needSignatures && !isBlocked;
				}
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT],
				states: [edi.constants.STATE.SENT, edi.constants.STATE.RECEIVER_REVIEW],
				allow: true
			},
			{
				documents: [edi.constants.FREE_DOCUMENT_TYPES],
				states: [edi.constants.STATE.DSF_DP_IZVPOL_SENDER, edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER],
				allow: function (document) {
					return (
						!document.isUnsupportedDSF &&
						!(
							edi.utils.getAttributeByName(document.attributes, 'RECEIVER_SIGNATURE_EXPECTED') ===
								'false' &&
							document.state === 'DSF_DP_IZVPOL_RECEIVER' &&
							document.direction === edi.constants.DIRECTIONS.INCOMING
						)
					);
				}
			},
			{
				documents: [edi.constants.FREE_DOCUMENT_TYPES],
				customMethod: function (checkOptions) {
					//Restrict sign documents with active annul
					return (
						checkOptions.isUnsupportedDSF ||
						!checkOptions.annulNotActive ||
						checkOptions.annulStatus == edi.constants.STATE.CREATED ||
						checkOptions.utochNotFinished
					);
				},
				allow: false
			},
			{
				//DSF in state sent can be signed only if new buiseness process used (Edidsfv2) or docType in edi.constants.DSF_TYPES_FROV
				documents: [edi.constants.FREE_DOCUMENT_TYPES],
				states: [edi.constants.STATE.SENT],
				allow: function (documentData) {
					let isNoHasIntegration =
						edi.utils.getAttributeByName(documentData.attributes, 'INTEGRATION') === 'false' &&
						edi.utils.getAttributeByName(documentData.attributes, 'RECEIVER_SIGNATURE_EXPECTED') === 'true';
					let trueAttribute =
						edi.utils.getAttributeByName(documentData.attributes, 'bpName') ==
							edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.V2 ||
						edi.utils.getAttributeByName(documentData.attributes, 'bpName') ==
							edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_INTEGRATION ||
						edi.constants.DSF_TYPES_FROV.some((it) => it === documentData.docType);
					return (!documentData.isUnsupportedDSF && trueAttribute) || isNoHasIntegration;
				}
			},
			{
				states: [
					edi.constants.STATE.DEBTOR_REVIEW,
					edi.constants.STATE.RECEIVER_REVIEW,
					edi.constants.STATE.READ
				],
				allow: function (documentData) {
					return (
						documentData.needSignatures ||
						edi.constants.LEGACY_DOCUMENTS.concat(edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM).some(
							(it) => it === documentData.docType
						)
					);
				}
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT],
				states: [edi.constants.STATE.DRAFT],
				allow: false
			}
		]
	},
	REJECT: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.WAIT_SIGNATURE,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_APPROVAL,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DSF_DP_IZVPOL_SENDER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.DSF_DP_UTOCH_RECEIVER,
				edi.constants.STATE.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL
			]
		},
		permissionChanging: [
			{
				change: function (actionPermission, checkOptions) {
					return edi.utils.getAttributeByName(checkOptions.attributes, 'currentBpName') == 'DSF_V3'
						? 'DSF_V3_CREATE_REJECT'
						: actionPermission;
				}
			}
		],
		conditions: [
			{
				documents: edi.constants.ACTUAL_FREE_DOCUMENT_TYPES,
				directions: [edi.constants.DIRECTIONS.INCOMING],
				states: [edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER],
				allow: function (docOptions) {
					const isBlocked =
						docOptions.docType === edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED &&
						edi.utils.getAttributeByName(docOptions.attributes, 'isBlocked', undefined, true);

					return !isBlocked;
				}
			},
			{
				states: [edi.constants.STATE.COMPLETED],
				allow: function (docOptions) {
					const isBlocked =
						docOptions.docType === edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED &&
						edi.utils.getAttributeByName(docOptions.attributes, 'isBlocked', undefined, true);

					docOptions.type = docOptions.docType;

					return !isBlocked && isDSFV3(docOptions);
				}
			}
		]
	},
	ROUTE_REJECT: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_INBOX]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_OUTBOX]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction == edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction == edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	ROUTE_CONFIRM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_INBOX]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_OUTBOX]
		},
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction == edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction == edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	CLARIFY: {},
	DELETE: {
		OUTGOING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		LOOP: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		INCOMING: {
			DOCUMENTS: edi.constants.FREE_DOCUMENT_TYPES,
			BUSINESS_STATE: [edi.constants.STATE.SENT]
		},
		conditions: [
			{
				documents: [edi.constants.FREE_DOCUMENT_TYPES],
				states: [edi.constants.STATE.SENT],
				allow: function (document) {
					return (
						!document.isUnsupportedDSF &&
						edi.utils.getAttributeByName(document.attributes, 'bpName') ==
							edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_INTEGRATION
					);
				}
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED],
				states: [edi.constants.STATE.SENDER_REVIEW],
				allow: () => false
			}
		]
	},
	EDIT: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		}
	},
	COMPLETE: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.READ,
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.RECEIVER_REVIEW
			]
		},
		LOOP: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		conditions: [
			{
				documents: [edi.constants.DSF_FOR_MULTY_SIGN],
				BUSINESS_STATE: [edi.constants.STATE.RECEIVER_REVIEW],
				allow: function (checkOptions) {
					let canMultiSign = edi.utils.getAttributeByName(checkOptions.attributes, 'canMultiSign');
					let isIncoming = checkOptions.direction == edi.constants.DIRECTIONS.INCOMING;
					let countSignature = parseInt(
						edi.utils.getAttributeByName(checkOptions.attributes, 'countSignature_CONSUMER'),
						10
					);
					return (
						countSignature > 1 &&
						isIncoming &&
						(canMultiSign === true || canMultiSign === 'true') &&
						edi.permissions.hasPermission('COMPLETE_' + checkOptions.docType + '_BUTTON')
					);
				}
			}
		]
	},
	READ: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.DP_IZVPOL_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION
			]
		},
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT],
				states: [edi.constants.STATE.SENT, edi.constants.STATE.RECEIVER_REVIEW],
				allow: true
			},
			{
				//DSF in state  can be read only if old buiseness process used (Edidsfv1)
				documents: [edi.constants.FREE_DOCUMENT_TYPES],
				allow: function (documentData) {
					let isNotFromIntegration =
						edi.utils.getAttributeByName(documentData.attributes, 'INTEGRATION') === 'false' &&
						edi.utils.getAttributeByName(documentData.attributes, 'RECEIVER_SIGNATURE_EXPECTED') === 'true';

					const isV2 =
						edi.utils.getAttributeByName(documentData.attributes, 'bpName') ===
						edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.V2;

					const isDsfUnknownIntegration =
						edi.utils.getAttributeByName(documentData.attributes, 'bpName') ===
						edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_INTEGRATION;

					const isBlocked =
						documentData.docType === edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED &&
						edi.utils.getAttributeByName(documentData.attributes, 'isBlocked', undefined, true);

					return (
						!isBlocked &&
						!documentData.isUnsupportedDSF &&
						!isV2 &&
						!isDsfUnknownIntegration &&
						!isNotFromIntegration
					);
				}
			}
		]
	},
	SEND: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		LOOP: {},
		conditions: [
			{
				documents: [edi.constants.DSF_FOR_MULTY_SIGN],
				BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW],
				allow: function (checkOptions) {
					let canMultiSign = edi.utils.getAttributeByName(checkOptions.attributes, 'canMultiSign');
					let isIncoming = checkOptions.direction == edi.constants.DIRECTIONS.INCOMING;
					let countSignature = parseInt(checkOptions?.parent?.countSignatures || 0, 10);
					return (
						countSignature >= 1 &&
						!isIncoming &&
						(canMultiSign === true || canMultiSign === 'true') &&
						edi.permissions.hasPermission('SEND_' + checkOptions.docType + '_BUTTON')
					);
				}
			},
			{
				states: [edi.constants.STATE.SENDER_REVIEW],
				allow: false
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT],
				BUSINESS_STATE: [edi.constants.STATE.DRAFT],
				allow: true
			}
		]
	},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.ACCEPTED, edi.constants.STATE.SENT]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		conditions: [
			{
				states: [edi.constants.STATE.SENT],
				allow: false
			}
		]
	},
	CONVERT_TO_DRAFT: {},
	COPY: {
		OUTGOING: {
			DOCUMENTS: [
				edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
				edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
				edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
				edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
				edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
				edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
				edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
				edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
			]
		},
		INCOMING: {
			DOCUMENTS: []
		},
		LOOP: {
			DOCUMENTS: [
				edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
				edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
				edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
				edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL,
				edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
				edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
				edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
				edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE
			]
		}
	},
	PACKAGING: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		conditions: [
			{
				allow: function (checkOptions) {
					if (checkOptions.record.get('packageId')) {
						return edi.permissions.hasPermission('UNLINK_PACKAGE_OBJECT');
					} else {
						return (
							edi.permissions.hasPermission('LINK_PACKAGE_OBJECT') ||
							edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT')
						);
					}
				}
			}
		]
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {
		INCOMING: {
			DOCUMENTS: [
				edi.constants.INCOMING_ANNUL_DOCUMENT_TYPES,
				// проверить, не включены ли эти документы ниже в список edi.constants.INCOMING_ANNUL_DOCUMENT_TYPES
				edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
			],
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.DSF_DP_UTOCH_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DSF_DP_IZVPOL_SENDER,
				edi.constants.STATE.DP_IZVPOL_TO_ON_AVZ_AWAITING_SIGNATURE,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN
			]
		},
		OUTGOING: {
			DOCUMENTS: [
				edi.constants.OUTGOING_ANNUL_DOCUMENT_TYPES,
				// проверить, не включены ли эти документы ниже в список edi.constants.INCOMING_ANNUL_DOCUMENT_TYPES
				edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT,
				edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
			],
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.DSF_DP_UTOCH_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL,
				edi.constants.STATE.ON_ANNUL,
				edi.constants.STATE.ANNUL_SIGN
			]
		},
		conditions: [
			{
				documents: [edi.constants.FREE_DOCUMENT_TYPES],
				customMethod: function (checkOptions) {
					const isBlocked =
						checkOptions.docType === edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED &&
						edi.utils.getAttributeByName(checkOptions.attributes, 'isBlocked', undefined, true);

					let bpName = edi.utils.getAttributeByName(checkOptions.attributes, 'bpName');
					//Restrict annul  DSF with old business process
					const isNotAllowedBp = [
						edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.V1,
						edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_INTEGRATION,
						edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_APERAK,
						edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_CONTRL
					].includes(bpName);

					return isBlocked || checkOptions.isUnsupportedDSF || isNotAllowedBp;
				},
				allow: false
			},
			{
				documents: [
					edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
					edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
					edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
					edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
					edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT
				],
				allow: false
			},
			{
				allow: function (checkOptions) {
					return !checkOptions.record.get('archived');
				}
			}
		],
		permissionChanging: [
			{
				change: function (actionPermission, checkOptions) {
					return checkOptions.docType.substr(0, 4) === 'DSF_' ? 'ANNUL_DSF' : actionPermission;
				}
			}
		]
	},
	CREATE_UKD: {},
	RENOUNCEMENT: {},
	CREATE_CUD: {}
};

// http://confluence.jira.lan:8090/pages/viewpage.action?pageId=42863168
Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_INVOICE]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT]: actionRulesForDsfModule, // Проект заморожен
	[edi.constants.DOCUMENT_TYPES.DSF_PRICAT]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE]: actionRulesForDsfModule,
	[edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT]: actionRulesForDsfModule, // Проект заморожен
	[edi.constants.DOCUMENT_TYPES.DSF_EDI_KC2]: actionRulesForDsfModule, // Не используются на проде
	[edi.constants.DOCUMENT_TYPES.DSF_EDI_KC3]: actionRulesForDsfModule, // Не используются на проде
	[edi.constants.DOCUMENT_TYPES.DSF_EDI_KC11]: actionRulesForDsfModule, // Не используются на проде
	[edi.constants.DOCUMENT_TYPES.DSF_EDI_KC14]: actionRulesForDsfModule // Не используются на проде

	// DSF_REESTR_ROUTE - не входит в данный список у него своя директория
});

export { actionRulesForDsfModule };
