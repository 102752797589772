import { isPackingRecord, isProductRecord, Packing, PackingBase, Product, TreeRecord } from './definitions';

export const PACKING_LIST_LINE_CONFIG_NAME = 'packing_list_line';
export const PACKING_LIST_TOTAL_COLUMNS_CONFIG_NAME = 'packing_list_summary';

const defaultTreeRenderer = ({
	path,
	converter,
	forProduct,
	forPacking
}: {
	path: string;
	converter?: (value: any, record: TreeRecord<Product | PackingBase | Packing>) => any;
	forProduct?: boolean;
	forPacking?: boolean;
}) => {
	return (val: string, meta: AnyObject, record: TreeRecord<Product | PackingBase | Packing>) => {
		const data = record?.data?.data;
		if (!data) return;

		if (forProduct && forPacking) {
			const value = edi.utils.getObjectProperty(data, path);
			return typeof converter === 'function' ? converter(value, record) : value;
		} else if (forProduct && isProductRecord(record)) {
			const value = edi.utils.getObjectProperty(data, path);
			return typeof converter === 'function' ? converter(value, record) : value;
		} else if (forPacking && isPackingRecord(record)) {
			const value = edi.utils.getObjectProperty(data, path);
			return typeof converter === 'function' ? converter(value, record) : value;
		}
	};
};

edi.columns.addColumns({
	[PACKING_LIST_LINE_CONFIG_NAME]: {
		lineNumber: {
			text: 'column.line.number',
			flex: 0.5,
			renderer: defaultTreeRenderer({ path: 'lineNumber', forProduct: true })
		},
		itemDescription: {
			xtype: 'treecolumn',
			text: 'line.item.product.description',
			tdCls: 'x-grid-cell-inner-portable-row',
			movable: false,
			minWidth: 350,
			maxWidth: 500,
			flex: 2,
			htmlEncode: true,
			renderer: defaultTreeRenderer({
				path: 'itemDescription',
				forProduct: true,
				forPacking: true,
				converter: (value, record) => {
					if (isProductRecord(record)) return value;
					if (isPackingRecord(record)) {
						const packing = record?.data?.data;
						const packingType = packing?.type;
						if (packingType === edi.constants.PACKAGE_TYPES.PALLET) {
							return edi.i18n.getMessage('desadv.packing.type.BJ');
						} else if (packingType === edi.constants.PACKAGE_TYPES.OTHER) {
							const packingName = packing?.packagingUnitName;
							return packingName ?? edi.i18n.getMessage('desadv.packing.type.NUL');
						}
					}
					return ' ';
				}
			})
		},
		ean: {
			text: 'column.ean',
			flex: 1,
			renderer: defaultTreeRenderer({ path: 'ean', forProduct: true })
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			flex: 1,
			renderer: defaultTreeRenderer({ path: 'buyerItemCode', forProduct: true })
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			flex: 1,
			renderer: defaultTreeRenderer({ path: 'supplierItemCode', forProduct: true })
		},
		sscc: {
			text: 'desadv.packing.sscc',
			flex: 1.5,
			renderer: defaultTreeRenderer({ path: 'range.idBegin', forPacking: true })
		},
		unitPacksize: {
			text: 'column.unit.packsize',
			flex: 1,
			hidden: true,
			renderer: defaultTreeRenderer({
				path: 'packageMeasurements',
				forPacking: true,
				converter: (packageMeasurements, record) => {
					const { length, width, height, unitOfMeasure } = packageMeasurements?.size ?? {};
					const units = edi.stores.data.okei_codes.find(
						(item: { id: string; name: string }) => item.id === unitOfMeasure
					);
					return length && width && height ? `${length} x ${width} x ${height} ${units?.name || ''}` : '';
				}
			})
		},
		orderedQuantity: {
			text: 'line.item.quantity.orders',
			flex: 1,
			renderer: defaultTreeRenderer({
				path: 'orderedQuantity',
				forProduct: true,
				forPacking: true,
				converter: (value, record) => {
					if (isProductRecord(record)) return value;
					if (isPackingRecord(record)) {
						const packing = record?.data?.data;
						if ('quantityPerPack' in packing) return packing?.quantityPerPack;
					}
				}
			})
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			flex: 1,
			hidden: true,
			renderer: defaultTreeRenderer({
				path: 'unitOfMeasure',
				forProduct: true,
				converter: (unitOfMeasure) => {
					return edi.utils.getUnitOfMeasureName(unitOfMeasure);
				}
			})
		},
		unitNetPrice: {
			text: 'column.unit.net.price',
			flex: 1,
			hidden: true,
			renderer: defaultTreeRenderer({
				path: 'unitNetPrice',
				forProduct: true,
				converter: (unitNetPrice) => {
					return edi.renderers.displayCurrencyDecimals(unitNetPrice);
				}
			})
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			flex: 1,
			hidden: true,
			renderer: defaultTreeRenderer({ path: 'taxRate', forProduct: true })
		},
		netAmount: {
			text: 'column.net.amount',
			flex: 1,
			hidden: true,
			renderer: defaultTreeRenderer({
				path: 'netAmount',
				forProduct: true,
				converter: (netAmount) => {
					return edi.renderers.displayCurrencyDecimals(netAmount);
				}
			})
		},
		taxAmount: {
			text: 'column.tax.amount',
			flex: 1,
			hidden: true,
			renderer: defaultTreeRenderer({
				path: 'taxAmount',
				forProduct: true,
				converter: (taxAmount) => {
					return edi.renderers.displayCurrencyDecimals(taxAmount);
				}
			})
		},
		grossAmount: {
			text: 'column.gross.amount',
			flex: 1,
			hidden: true,
			renderer: defaultTreeRenderer({
				path: 'grossAmount',
				forProduct: true,
				converter: (grossAmount) => {
					return edi.renderers.displayCurrencyDecimals(grossAmount);
				}
			})
		}
	},
	[PACKING_LIST_TOTAL_COLUMNS_CONFIG_NAME]: {
		totalLines: {
			text: 'column.total.lines',
			dataIndex: 'totalLines',
			flex: 1,
			sortable: false,
			hideable: false
		},
		totalPackages: {
			text: 'column.total.packages',
			dataIndex: 'totalPSequence',
			flex: 1,
			sortable: false,
			hideable: false
		}
	}
});
