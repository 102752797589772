const ukdActionRules = Ext.clone(edi.action.rulesByDocType[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD]);

const isApprovalStateRejected = function (checkOptions) {
	let approvalState = edi.utils.getAttributeByName(checkOptions.attributes, 'APPROVAL_STATE');
	return approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED;
};

//На первом месте в исходном массиве стоит кондишен с customMethod, поэтому наш новый кондишен мы вставляем после него
//По факту это копия оригинального кондишена, в которой добавили в bpNamesWithoutSignAction ..._WITHOUT_OPERATOR
ukdActionRules.SIGN.conditions.splice(0, 0, {
	directions: [edi.constants.DIRECTIONS.INCOMING],
	documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
	allow: function (checkOptions) {
		let attrs = checkOptions.attributes;
		let bpName = edi.utils.getAttributeByName(attrs, 'bpName');
		let bpNamesWithoutSignAction = [
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHF_ROAMING_IN,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHF_ROAMING_OUT,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_ROAMING_IN,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_ROAMING_OUT,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_DECREE_14_WITHOUT_OPERATOR,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14_WITHOUT_OPERATOR,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_IN_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_OUT_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISRK_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISZK_DECREE_14
		];
		let isRenounced =
			edi.utils.getAttributeByName(attrs, 'RENOUNCEMENT') === 'true' &&
			(checkOptions.state === edi.constants.STATE.SENT ||
				checkOptions.state === edi.constants.STATE.READ ||
				checkOptions.state === edi.constants.STATE.RECEIVER_REVIEW);
		let annulIsActive =
			checkOptions.annulStatus === edi.constants.STATE.ACTIVE ||
			checkOptions.annulStatus === edi.constants.STATE.CREATED;
		return (
			!isRenounced &&
			!annulIsActive &&
			!bpNamesWithoutSignAction.some((it) => it === bpName) &&
			checkOptions.state !== edi.constants.STATE.SIGNED_BY_CONSUMER
		);
	}
});

ukdActionRules.SIGN.conditions.unshift({
	customMethod: isApprovalStateRejected,
	allow: false
});

//Это копия оригинального кондишена, в которой добавили в bpNamesWithoutReadAction ..._WITHOUT_OPERATOR
ukdActionRules.READ.conditions.unshift({
	documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
	customMethod: function (checkOptions) {
		return checkOptions.annulNotActive;
	},
	allow: function (checkOptions) {
		let attrs = checkOptions.attributes;
		let bpName = edi.utils.getAttributeByName(attrs, 'bpName');
		let bpNamesWithoutReadAction = [
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHF_ROAMING_IN,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHF_ROAMING_OUT,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_ROAMING_IN,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_ROAMING_OUT,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_IN_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_OUT_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISRK_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISZK_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DIS_DECREE_14_WITHOUT_OPERATOR,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14_WITHOUT_OPERATOR,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_DECREE_14_WITHOUT_OPERATOR
		];
		let isRenounced =
			checkOptions.state === edi.constants.STATE.SENT &&
			edi.utils.getAttributeByName(attrs, 'RENOUNCEMENT') === 'true';

		return checkOptions.annulNotActive && !isRenounced && !bpNamesWithoutReadAction.some((it) => it === bpName);
	}
});

ukdActionRules.SEND_TO_APPROVE_DOCUMENTS = {
	OUTGOING: {
		DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
		BUSINESS_STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
	},
	INCOMING: {
		DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
		BUSINESS_STATE: [edi.constants.STATE.READ, edi.constants.STATE.RECEIVER_REVIEW, edi.constants.STATE.SENT]
	},
	conditions: [
		{
			documents: edi.constants.UKD_WITH_APPROVE,
			directions: [edi.constants.DIRECTIONS.OUTGOING],
			states: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW],
			allow: () => true
		},
		{
			documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
			directions: [edi.constants.DIRECTIONS.INCOMING],
			states: [edi.constants.STATE.READ, edi.constants.STATE.RECEIVER_REVIEW, edi.constants.STATE.SENT],
			allow: function (checkOptions) {
				let approvalState = edi.utils.getAttributeByName(checkOptions.attributes, 'APPROVAL_STATE');
				return !approvalState || approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED;
			}
		}
	]
};
ukdActionRules.SEND_TO_APPROVE_EMPLOYEES = {
	OUTGOING: {
		DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
		BUSINESS_STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
	},
	INCOMING: {
		DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
		BUSINESS_STATE: [edi.constants.STATE.READ, edi.constants.STATE.RECEIVER_REVIEW, edi.constants.STATE.SENT]
	},
	conditions: [
		{
			documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
			directions: [edi.constants.DIRECTIONS.OUTGOING],
			states: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW],
			allow: () => true
		},
		{
			documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD],
			directions: [edi.constants.DIRECTIONS.INCOMING],
			states: [edi.constants.STATE.READ, edi.constants.STATE.RECEIVER_REVIEW, edi.constants.STATE.SENT],
			allow: function (checkOptions) {
				let approvalState = edi.utils.getAttributeByName(checkOptions.attributes, 'APPROVAL_STATE');
				return !approvalState || approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED;
			}
		}
	]
};

//Перепишем правила уже нашими модифицированными
Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_2_DP_PDPOL]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_3_DP_PDOTPR]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_DP_IZVPOL]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_IZVPOL]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_DP_IZVPOL]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_DP_PDOTPR]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_DP_IZVPOL]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_9_DP_UVUTOCH]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_10_DP_IZVPOL]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_2_DP_PDPOL_DECREE_14]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_3_DP_PDOTPR_DECREE_14]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_4_DP_PDOTPR_DECREE_14]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_IZVPOL_DECREE_14]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_IOP_DP_PDPOL_DECREE_14]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_IOP_DP_PDOTPR_DECREE_14]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_IOP_DP_PDOTPR_DECREE_14]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_DP_PDOTPR_DECREE_14]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_5_DP_UVUTOCH_DECREE_14]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_UOU_DP_PDPOL_DECREE_14]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_7_UOU_DP_PDOTPR_DECREE_14]: ukdActionRules,
	[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_8_UOU_DP_PDOTPR_DECREE_14]: ukdActionRules
});
