import { createLabel } from '@UIkit/components/fields_old/Label';
import { createContainer } from '@Components/miscComponents';
import { createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createForm } from '@Components/panels';
import { createModalRemoteSelect } from '@Core/specialComponents/modals';

export const moduleMethods_003 = {
	//заглушка для EW, если определить функцию будет выбор сотрудника в уполномеченый ФЛ
	selectionModalFl: function (callback, context) {
		let selectCallback = function (orgValues) {
			let snils = orgValues?.user?.personalId;
			if (snils) {
				snils = edi.converters.SNILS.convertForShow(snils);
			}
			callback.call(context, {
				svedFL: {
					fio: {
						familiya: orgValues.lastName,
						imya: orgValues.firstName,
						otchestvo: orgValues.middleName
					},
					adrElPocht: orgValues.email
				},
				dolzhnost: orgValues.position,
				snils: snils
			});
		};

		createModalRemoteSelect(
			edi.utils.compileURL(edi.rest.services.ORG_PROFILE.EMPLOYEES.GET, {
				gridName: 'active'
			}),
			selectCallback,
			{
				columns: edi.columns.get('ew_employees_modal'),
				model: edi.models.getModel('EW_EMPLOYEES'),
				title: 'employee.select.title',
				noFilter: true,
				storeConfig: {
					autoLoad: true
				}
			}
		);
	},
	defPrincipalType: {
		INDIVIDUAL: '4',
		COMPANY: '1',
		INDIV_ENTREPRENEUR: '3',
		FOREIGN_COMPANY: '2'
	},
	defConfidantType: {
		INDIVIDUAL: '3',
		COMPANY: '1',
		INDIV_ENTREPRENEUR: '2'
	},
	getDoveritSideTypes: () => [
		{
			id: '4',
			name: edi.i18n.getMessage('company.type.individualParticipant')
		},
		{
			id: '1',
			name: edi.i18n.getMessage('company.type.company')
		},
		{
			id: '3',
			name: edi.i18n.getMessage('company.type.individual')
		}
	],
	getUpPredSideTypes: () => [
		{
			id: '3',
			name: edi.i18n.getMessage('company.type.individualParticipant')
		},
		{
			id: '1',
			name: edi.i18n.getMessage('company.type.company')
		},
		{
			id: '2',
			name: edi.i18n.getMessage('company.type.individual')
		}
	],
	createDoveritSidesStore: () => {
		return edi.stores.createInlineStore(moduleMethods_003.getDoveritSideTypes(), 'SIMPLE');
	},
	createUpPredSidesStore: () => {
		return edi.stores.createInlineStore(moduleMethods_003.getUpPredSideTypes(), 'SIMPLE');
	},
	getPersonIdentityCardTypes: () => {
		return [
			'21', //Паспорт гражданина Российской Федерации
			'01', //Паспорт гражданина СССР
			'02', //Загранпаспорт гражданина СССР
			'03', //Свидетельство о рождении
			'04', //Удостоверение личности офицер
			'05', //Справка об освобождении из места лишения свободы
			'06', //Паспорт Минморфлота
			'07', //Военный билет
			'08', //Временное удостоверение, выданное взамен военного билета
			'09', //Дипломатический паспорт
			'10', //Паспорт иностранного гражданина
			'11', //Свидетельство о рассмотрении ходатайства о признании лица беженцем на территории Российской Федерации по существу
			'12', //Вид на жительство в Российской Федерации
			'13', //Удостоверение беженца
			'14', //Временное удостоверение личности гражданина Российской Федерации
			'15', //Разрешение на временное проживание в Российской Федерации
			'18', //Свидетельство о предоставлении временного убежища на территории Российской Федерации
			'19', //Свидетельство о предоставлении временного убежища на территории Российской Федерации
			'22', //Загранпаспорт гражданина Российской Федерации
			'23', //Свидетельство о рождении, выданное уполномоченным органом иностранного государства
			'24', //Удостоверение личности военнослужащего Российской Федерации
			'26', //Паспорт моряка
			'27', //Военный билет офицера запаса
			'60', //Документы, подтверждающие факт регистрации по месту жительства
			'61', //Свидетельство о регистрации по месту жительства
			'62', //Вид на жительство иностранного гражданина
			'81', //Свидетельство о смерти
			'91' //Иные документы
		].map((it) => ({
			id: it,
			name: edi.i18n.getMessage('person.identity.card.type.' + it)
		}));
	},
	getPersonIdentityCardValue: function (code) {
		const personIdentityCardTypes = this.getPersonIdentityCardTypes();
		const personIdentityCard = personIdentityCardTypes.find((type) => type.id === code);
		return personIdentityCard ? personIdentityCard.name : code;
	},
	createPersonIdentityCardTypeStore: () => {
		return edi.stores.createInlineStore(moduleMethods_003.getPersonIdentityCardTypes(), 'SIMPLE', undefined, {
			addEmptyRecord: true
		});
	},
	createIPPersonIdentityCardTypeStore: () => {
		const ids = [
			'01',
			'02',
			'04',
			'06',
			'07',
			'08',
			'09',
			'10',
			'12',
			'13',
			'14',
			'21',
			'22',
			'24',
			'26',
			'27',
			'91'
		];
		const array = moduleMethods_003.getPersonIdentityCardTypes().filter(function (item) {
			return ids.includes(item.id);
		});
		return edi.stores.createInlineStore(array, 'SIMPLE', undefined, {
			addEmptyRecord: true
		});
	},
	createFLPersonIdentityCardTypeStore: () => {
		const ids = ['21', '07', '10', '11', '12', '13', '15', '19', '24'];
		const array = moduleMethods_003.getPersonIdentityCardTypes().filter(function (item) {
			return ids.includes(item.id);
		});
		return edi.stores.createInlineStore(array, 'SIMPLE', undefined, {
			addEmptyRecord: true
		});
	},
	getRegionNameByCode: (code) => {
		const regionStore = edi.stores.initRegionsStore();
		const region = regionStore.getById(code);
		const regionName = region?.get('name');
		return regionName ? `${code} - ${regionName}` : code;
	},
	calculateApplicationArea: function (applicationAreaValue) {
		// Значение в "Область применения" имеет формат 8 символов, в котором реально значение имеют только цифры со 2 по 4
		const [ecia, notary, taxAuthorities] = applicationAreaValue?.slice(1, 4).split('') || [];
		const result = [edi.i18n.getMessage('power.of.attorney.applicationArea.b2b')];
		if (+ecia) {
			result.push(edi.i18n.getMessage('power.of.attorney.applicationArea.ecia'));
		}
		if (+notary) {
			result.push(edi.i18n.getMessage('power.of.attorney.applicationArea.notary'));
		}
		if (+taxAuthorities) {
			result.push(edi.i18n.getMessage('power.of.attorney.applicationArea.taxAuthorities'));
		}

		return result.join(', ');
	},
	getLegalEntityWithLitsoBezDov: (namePath, title) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!!this.getTitle()">`,
			'<div class="title-type">{[this.getTitle()]}</div>',
			'</tpl>',
			`<tpl if="!this.isEmpty($v, 'svRosOrg.naimOrg')">`,
			'<div class=" body-short_02">{[this.getValue(values, "svRosOrg.naimOrg")]}</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svRosOrg.ogrn\')">',
			`<span class="code">${edi.i18n.getMessage('column.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svRosOrg.ogrn")]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, 'svRosOrg.innyul')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svRosOrg.innyul")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'svRosOrg.kpp\')">',
			`<span class="code">${edi.i18n.getMessage('company.kpp.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svRosOrg.kpp")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svRosOrg.regNomer\')">',
			`<span class="code">${edi.i18n.getMessage('company.filiation.regnumber')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svRosOrg.regNomer")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svRosOrg.naimUchrDok\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.establish.doc.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svRosOrg.naimUchrDok")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svRosOrg.kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svRosOrg.kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svRosOrg.adrElPocht\')">',
			`<span class="code">${edi.i18n.getMessage('user.profile.email')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svRosOrg.adrElPocht")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svRosOrg.adrReg.region\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.rus.region')}:</span>`,
			'<span class="row-data">{[this.getRegion(this.getValue(values, "svRosOrg.adrReg.region"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svRosOrg.adrReg.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.russ.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svRosOrg.adrReg.adrRF")]}</span>',
			'</tpl>',
			'</div>',

			// getLitsoBezDovFlTpl
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'litsoBezDov.0.svFL.svedFL.fio.familiya') ||
				!this.isEmpty($v, 'litsoBezDov.0.svFL.svedFL.fio.imya') ||
				!this.isEmpty($v, 'litsoBezDov.0.svFL.svedFL.fio.otchestvo')">`,
			`<div class="sub-title-type">${edi.i18n.getMessage('power.of.attorney.principal.person.column')}</div>`,
			'<div class="body-short_02">',
			'<span class="row-data edi-company-individual-lastname">{[this.getValue(values, "litsoBezDov.0.svFL.svedFL.fio.familiya")]}</span>&nbsp;',
			'<span class="row-data edi-company-individual-firstname">{[this.getValue(values, "litsoBezDov.0.svFL.svedFL.fio.imya")]}</span>&nbsp;',
			'<span class="row-data edi-company-individual-patronymicname">{[this.getValue(values, "litsoBezDov.0.svFL.svedFL.fio.otchestvo")]}</span>',
			'</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.innfl\')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svFL.innfl")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.snils\')">',
			`<span class="code">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svFL.snils")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.svedFL.pol\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.gender')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(`power.of.attorney.gender.${this.getValue(values, "litsoBezDov.0.svFL.svedFL.pol")}`)]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.svedFL.grazhdanstvo\')">',
			`<span class="code">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[this.getCountry(this.getValue(values, "litsoBezDov.0.svFL.svedFL.grazhdanstvo"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.svedFL.nomERN\')">',
			`<span class="code">${edi.i18n.getMessage('person.population.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svFL.svedFL.nomERN")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.svedFL.dataRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('person.birthday')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "litsoBezDov.0.svFL.svedFL.dataRozhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.svedFL.mestoRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.birth.place')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svFL.svedFL.mestoRozhd")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.svedFL.adrMZH.region\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.rus.region')}:</span>`,
			'<span class="row-data">{[this.getRegion(this.getValue(values, "litsoBezDov.0.svFL.svedFL.adrMZH.region"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.svedFL.adrMZH.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.residence.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svFL.svedFL.adrMZH.adrRF")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.svedFL.kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svFL.svedFL.kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.svedFL.adrElPocht\')">',
			`<span class="code">${edi.i18n.getMessage('user.profile.email')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svFL.svedFL.adrElPocht")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.dolzhnost\')">',
			`<span class="code">${edi.i18n.getMessage('job.title')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svFL.dolzhnost")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			"<tpl if=\"!this.isEmpty(values, 'litsoBezDov.0.svFL.svedFL.udLichnFL.vidDok') || " +
				"!this.isEmpty(values, 'litsoBezDov.0.svFL.svedFL.udLichnFL.serNomDok') || " +
				"!this.isEmpty(values, 'litsoBezDov.0.svFL.svedFL.udLichnFL.dataDok')\">",
			`<span class="code">${edi.i18n.getMessage('person.identity.document')}:</span>`,
			'<span class="row-data">{[this.getPersonIdentityCardValue(this.getValue(values, "litsoBezDov.0.svFL.svedFL.udLichnFL.kodVidDok"))]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.series.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svFL.svedFL.udLichnFL.serNomDok")]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "litsoBezDov.0.svFL.svedFL.udLichnFL.dataDok"))]}</span>',
			'</tpl>',
			'</div>',
			`<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svFL.svedFL.udLichnFL.vidDok\') || this.getValue(values, 'litsoBezDov.0.svFL.svedFL.udLichnFL.kodVidDok') === '10' && (!this.isEmpty(values, \'litsoBezDov.0.svFL.svedFL.udLichnFL.kodVydDok\'))">`,
			'<div class="edi-company-row">',
			`<tpl if=\"!this.isEmpty(values, 'litsoBezDov.0.svFL.svedFL.udLichnFL.kodVydDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.department.code')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svFL.svedFL.udLichnFL.kodVydDok")]}</span>',
			'</tpl>',
			`<tpl if=\"!this.isEmpty(values, 'litsoBezDov.0.svFL.svedFL.udLichnFL.vidDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.issuing.authority')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svFL.svedFL.udLichnFL.vidDok")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			`<tpl if="this.getValue(values, 'litsoBezDov.0.svFL.svedFL.udLichnFL.kodVidDok') === '22' && !this.isEmpty(values, 'litsoBezDov.0.svFL.svedFL.udLichnFL.expDate')">`,
			'<div class="edi-company-row">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.document.expiration.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "litsoBezDov.0.svFL.svedFL.udLichnFL.expDate"))]}</span>',
			'</div>',
			'</tpl>',
			'</tpl>',

			//getLitsoBezDovUkTpl
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svYULEIO.ogrn\')">',
			`<div class="sub-title-type">${edi.i18n.getMessage('power.of.attorney.managementCompany.column')}</div>`,
			'</tpl>',
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'litsoBezDov.0.svyul.svYULEIO.naimOrg')">`,
			'<div class=" body-short_02">{[this.getValue(values, "litsoBezDov.0.svyul.svYULEIO.naimOrg")]}</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svYULEIO.ogrn\')">',
			`<span class="code">${edi.i18n.getMessage('column.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svYULEIO.ogrn")]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, 'litsoBezDov.0.svyul.svYULEIO.innyul')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svYULEIO.innyul")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svYULEIO.kpp\')">',
			`<span class="code">${edi.i18n.getMessage('company.kpp.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svYULEIO.kpp")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svYULEIO.regNomer\')">',
			`<span class="code">${edi.i18n.getMessage('company.filiation.regnumber')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svYULEIO.regNomer")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svYULEIO.naimUchrDok\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.establish.doc.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svYULEIO.naimUchrDok")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svYULEIO.kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svYULEIO.kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svYULEIO.adrElPocht\')">',
			`<span class="code">${edi.i18n.getMessage('user.profile.email')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svYULEIO.adrElPocht")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svYULEIO.adrReg.region\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.rus.region')}:</span>`,
			'<span class="row-data">{[this.getRegion(this.getValue(values, "litsoBezDov.0.svyul.svYULEIO.adrReg.region"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svYULEIO.adrReg.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.russ.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svYULEIO.adrReg.adrRF")]}</span>',
			'</tpl>',
			'</div>',

			`<tpl if="!this.isEmpty($v, 'litsoBezDov.0.svyul.svFL.0.svedFL.fio.familiya') ||
				!this.isEmpty($v, 'litsoBezDov.0.svyul.svFL.0.svedFL.fio.imya') ||
				!this.isEmpty($v, 'litsoBezDov.0.svyul.svFL.0.svedFL.fio.otchestvo')">`,
			'<div class="edi-company-row">',
			`<div class=" body-short_02">${edi.i18n.getMessage('power.of.attorney.organization.head.info')}</div>`,
			'</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.fio.familiya\')">`,
			`<span class="code">${edi.i18n.getMessage('last.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.fio.familiya")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.fio.imya\')">',
			`<span class="code">${edi.i18n.getMessage('first.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.fio.imya")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.fio.otchestvo\')">',
			`<span class="code">${edi.i18n.getMessage('patronymic.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.fio.otchestvo")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.innfl\')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.innfl")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.snils\')">',
			`<span class="code">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.snils")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.dataRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('person.birthday')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.dataRozhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.pol\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.gender')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(`power.of.attorney.gender.${this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.pol")}`)]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.grazhdanstvo\')">',
			`<span class="code">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[this.getCountry(this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.grazhdanstvo"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.nomERN\')">',
			`<span class="code">${edi.i18n.getMessage('person.population.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.nomERN")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.mestoRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.birth.place')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.mestoRozhd")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.adrMZH.region\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.rus.region')}:</span>`,
			'<span class="row-data">{[this.getRegion(this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.adrMZH.region"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.adrMZH.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.residence.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.adrMZH.adrRF")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.adrElPocht\')">',
			`<span class="code">${edi.i18n.getMessage('user.profile.email')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.adrElPocht")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.dolzhnost\')">',
			`<span class="code">${edi.i18n.getMessage('job.title')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.dolzhnost")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			"<tpl if=\"!this.isEmpty(values, 'litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.vidDok') || " +
				"!this.isEmpty(values, 'litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.serNomDok') || " +
				"!this.isEmpty(values, 'litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.dataDok')\">",
			`<span class="code">${edi.i18n.getMessage('person.identity.document')}:</span>`,
			'<span class="row-data">{[this.getPersonIdentityCardValue(this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.kodVidDok"))]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.series.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.serNomDok")]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.dataDok"))]}</span>',
			'</tpl>',
			'</div>',
			`<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.vidDok\') || this.getValue(values, 'litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.kodVidDok') === '10' && (!this.isEmpty(values, \'litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.kodVydDok\'))">`,
			'<div class="edi-company-row">',
			`<tpl if=\"!this.isEmpty(values, 'litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.kodVydDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.department.code')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.kodVydDok")]}</span>',
			'</tpl>',
			`<tpl if=\"!this.isEmpty(values, 'litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.vidDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.issuing.authority')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.vidDok")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			`<tpl if="this.getValue(values, 'litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.kodVidDok') === '22' && !this.isEmpty(values, 'litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.expDate')">`,
			'<div class="edi-company-row">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.document.expiration.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "litsoBezDov.0.svyul.svFL.0.svedFL.udLichnFL.expDate"))]}</span>',
			'</div>',
			'</tpl>',
			'</tpl>',

			// getLitsoBezDovIpTpl
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.ogrnip\')">',
			`<div class="sub-title-type">${edi.i18n.getMessage('power.of.attorney.svIP.column')}</div>`,
			'</tpl>',
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'litsoBezDov.0.svIP.naimIP')">`,
			'<div class=" body-short_02">{[this.getValue(values, "litsoBezDov.0.svIP.naimIP")]}</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.ogrnip\')">',
			`<span class="code">${edi.i18n.getMessage('company.ogrnip.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.ogrnip")]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, 'litsoBezDov.0.svIP.innfl')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.innfl")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.snils\')">',
			`<span class="code">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.snils")]}</span>',
			'</tpl>',
			'</div>',

			`<tpl if="!this.isEmpty($v, 'litsoBezDov.0.svIP.svedFL.fio.familiya') ||
				!this.isEmpty($v, 'litsoBezDov.0.svIP.svedFL.fio.imya') ||
				!this.isEmpty($v, 'litsoBezDov.0.svIP.svedFL.fio.otchestvo')">`,
			'<div class="edi-company-row">',
			`<div class=" body-short_02">${edi.i18n.getMessage('power.or.attorney.individual.data')}</div>`,
			'</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.fio.familiya\')">`,
			`<span class="code">${edi.i18n.getMessage('last.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.svedFL.fio.familiya")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.fio.imya\')">',
			`<span class="code">${edi.i18n.getMessage('first.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.svedFL.fio.imya")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.fio.otchestvo\')">',
			`<span class="code">${edi.i18n.getMessage('patronymic.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.svedFL.fio.otchestvo")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.dataRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('person.birthday')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "litsoBezDov.0.svIP.svedFL.dataRozhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.pol\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.gender')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(`power.of.attorney.gender.${this.getValue(values, "litsoBezDov.0.svIP.svedFL.pol")}`)]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.grazhdanstvo\')">',
			`<span class="code">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[this.getCountry(this.getValue(values, "litsoBezDov.0.svIP.svedFL.grazhdanstvo"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.nomERN\')">',
			`<span class="code">${edi.i18n.getMessage('person.population.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.svedFL.nomERN")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.mestoRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.birth.place')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.svedFL.mestoRozhd")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.adrMZH.region\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.rus.region')}:</span>`,
			'<span class="row-data">{[this.getRegion(this.getValue(values, "litsoBezDov.0.svIP.svedFL.adrMZH.region"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.adrMZH.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.residence.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.svedFL.adrMZH.adrRF")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.svedFL.kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.adrElPocht\')">',
			`<span class="code">${edi.i18n.getMessage('user.profile.email')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.svedFL.adrElPocht")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.dolzhnost\')">',
			`<span class="code">${edi.i18n.getMessage('job.title')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.svedFL.dolzhnost")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			"<tpl if=\"!this.isEmpty(values, 'litsoBezDov.0.svIP.svedFL.udLichnFL.vidDok') || " +
				"!this.isEmpty(values, 'litsoBezDov.0.svIP.svedFL.udLichnFL.serNomDok') || " +
				"!this.isEmpty(values, 'litsoBezDov.0.svIP.svedFL.udLichnFL.dataDok')\">",
			`<span class="code">${edi.i18n.getMessage('person.identity.document')}:</span>`,
			'<span class="row-data">{[this.getPersonIdentityCardValue(this.getValue(values, "litsoBezDov.0.svIP.svedFL.udLichnFL.kodVidDok"))]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.series.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.svedFL.udLichnFL.serNomDok")]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "litsoBezDov.0.svIP.svedFL.udLichnFL.dataDok"))]}</span>',
			'</tpl>',
			'</div>',
			`<tpl if="!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.udLichnFL.vidDok\') || this.getValue(values, 'litsoBezDov.0.svIP.svedFL.udLichnFL.kodVidDok') === '10' && (!this.isEmpty(values, \'litsoBezDov.0.svIP.svedFL.udLichnFL.kodVydDok\'))">`,
			'<div class="edi-company-row">',
			`<tpl if=\"!this.isEmpty(values, 'litsoBezDov.0.svIP.svedFL.udLichnFL.kodVydDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.department.code')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.svedFL.udLichnFL.kodVydDok")]}</span>',
			'</tpl>',
			`<tpl if=\"!this.isEmpty(values, 'litsoBezDov.0.svIP.svedFL.udLichnFL.vidDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.issuing.authority')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "litsoBezDov.0.svIP.svedFL.udLichnFL.vidDok")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			`<tpl if="this.getValue(values, 'litsoBezDov.0.svIP.svedFL.udLichnFL.kodVidDok') === '22' && !this.isEmpty(values, 'litsoBezDov.0.svIP.svedFL.udLichnFL.expDate')">`,
			'<div class="edi-company-row">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.document.expiration.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "litsoBezDov.0.svIP.svedFL.udLichnFL.expDate"))]}</span>',
			'</div>',
			'</tpl>',
			'</tpl>',

			'</tpl>',
			{
				getTitle: function () {
					return title;
				},
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;

					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				},
				getRegion: function (code) {
					const record = edi.stores.initRegionsStore().findRecord('id', code.toString());
					return record ? record.get('display_name') : '';
				},
				getSeriesIndentityDoc: function (value) {
					return value.split('-')[0];
				},
				getPersonIdentityCardValue: (value) => moduleMethods_003.getPersonIdentityCardValue(value),
				formatDate: function (value) {
					return Ext.Date.format(new Date(value), edi.constants.DATE_FORMAT.FNS);
				},
				getCountry(value) {
					var countryStore = edi.stores.initValidCountryFullStore(),
						country = countryStore.findRecord('iso_number', value, 0, false, true, true);
					return country?.get('name');
				}
			}
		),
	getLegalEntityTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'naimOrg')">`,
			'<div class=" body-short_02">{[this.getValue(values, "naimOrg")]}</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'ogrn\')">',
			`<span class="code">${edi.i18n.getMessage('column.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "ogrn")]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, 'innyul')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "innyul")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'kpp\')">',
			`<span class="code">${edi.i18n.getMessage('company.kpp.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "kpp")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'regNomer\')">',
			`<span class="code">${edi.i18n.getMessage('company.filiation.regnumber')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "regNomer")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'naimUchrDok\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.establish.doc.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "naimUchrDok")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'adrElPocht\')">',
			`<span class="code">${edi.i18n.getMessage('user.profile.email')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "adrElPocht")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'adrReg.region\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.rus.region')}:</span>`,
			'<span class="row-data">{[this.getRegion(this.getValue(values, "adrReg.region"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'adrReg.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.russ.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "adrReg.adrRF")]}</span>',
			'</tpl>',
			'</div>',

			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;

					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				},
				getRegion: function (code) {
					const record = edi.stores.initRegionsStore().findRecord('id', code.toString());
					return record ? record.get('display_name') : '';
				}
			}
		),
	getLitsoBezDovFlTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'svedFL.fio.familiya') || !this.isEmpty($v, 'svedFL.fio.imya') || !this.isEmpty($v, 'svedFL.fio.otchestvo')">`,
			'<div class="body-short_02">',
			'<span class="row-data edi-company-individual-lastname">{[this.getValue(values, "svedFL.fio.familiya")]}</span>&nbsp;',
			'<span class="row-data edi-company-individual-firstname">{[this.getValue(values, "svedFL.fio.imya")]}</span>&nbsp;',
			'<span class="row-data edi-company-individual-patronymicname">{[this.getValue(values, "svedFL.fio.otchestvo")]}</span>',
			'</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, \'innfl\')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "innfl")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'snils\')">',
			`<span class="code">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "snils")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.svedFL.pol\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.gender')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(`power.of.attorney.gender.${this.getValue(values, "svFL.svedFL.pol")}`)]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.grazhdanstvo\')">',
			`<span class="code">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[this.getCountry(this.getValue(values, "svedFL.grazhdanstvo"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.svedFL.nomERN\')">',
			`<span class="code">${edi.i18n.getMessage('person.population.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.svedFL.nomERN")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.svedFL.dataRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('person.birthday')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svFL.svedFL.dataRozhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.svedFL.mestoRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.birth.place')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.svedFL.mestoRozhd")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.adrMZH.region\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.rus.region')}:</span>`,
			'<span class="row-data">{[this.getRegion(this.getValue(values, "svedFL.adrMZH.region"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.svedFL.adrMZH.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.residence.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.svedFL.adrMZH.adrRF")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.adrElPocht\')">',
			`<span class="code">${edi.i18n.getMessage('user.profile.email')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.adrElPocht")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'dolzhnost\')">',
			`<span class="code">${edi.i18n.getMessage('job.title')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "dolzhnost")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			"<tpl if=\"!this.isEmpty(values, 'svedFL.udLichnFL.vidDok') || !this.isEmpty(values, 'svedFL.udLichnFL.serNomDok') || !this.isEmpty(values, 'svedFL.udLichnFL.dataDok')\">",
			`<span class="code">${edi.i18n.getMessage('person.identity.document')}:</span>`,
			'<span class="row-data">{[this.getPersonIdentityCardValue(this.getValue(values, "svedFL.udLichnFL.kodVidDok"))]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.series.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.udLichnFL.serNomDok")]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svedFL.udLichnFL.dataDok"))]}</span>',
			'</tpl>',
			'</div>',
			`<tpl if="!this.isEmpty(values, \'svedFL.udLichnFL.vidDok\') || this.getValue(values, 'svedFL.udLichnFL.kodVidDok') === '10' && (!this.isEmpty(values, \'svedFL.udLichnFL.kodVydDok\'))">`,
			'<div class="edi-company-row">',
			`<tpl if=\"!this.isEmpty(values, 'svedFL.udLichnFL.kodVydDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.department.code')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.udLichnFL.kodVydDok")]}</span>',
			'</tpl>',
			`<tpl if=\"!this.isEmpty(values, 'svedFL.udLichnFL.vidDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.issuing.authority')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.udLichnFL.vidDok")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			`<tpl if="this.getValue(values, 'svedFL.udLichnFL.kodVidDok') === '22' && !this.isEmpty(values, 'svedFL.udLichnFL.expDate')">`,
			'<div class="edi-company-row">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.document.expiration.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svedFL.udLichnFL.expDate"))]}</span>',
			'</div>',
			'</tpl>',
			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;
					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				},
				getRegion: function (code) {
					const record = edi.stores.initRegionsStore().findRecord('id', code.toString());
					return record ? record.get('display_name') : '';
				},
				getSeriesIndentityDoc: function (value) {
					return value.split('-')[0];
				},
				getPersonIdentityCardValue: (value) => moduleMethods_003.getPersonIdentityCardValue(value),
				formatDate: function (value) {
					return Ext.Date.format(new Date(value), edi.constants.DATE_FORMAT.FNS);
				},
				getCountry(value) {
					var countryStore = edi.stores.initValidCountryFullStore(),
						country = countryStore.findRecord('iso_number', value, 0, false, true, true);
					return country?.get('name');
				}
			}
		),
	getLitsoBezDovUkTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'svYULEIO.naimOrg')">`,
			'<div class=" body-short_02">{[this.getValue(values, "svYULEIO.naimOrg")]}</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svYULEIO.ogrn\')">',
			`<span class="code">${edi.i18n.getMessage('column.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svYULEIO.ogrn")]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, 'svYULEIO.innyul')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svYULEIO.innyul")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'svYULEIO.kpp\')">',
			`<span class="code">${edi.i18n.getMessage('company.kpp.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svYULEIO.kpp")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svYULEIO.regNomer\')">',
			`<span class="code">${edi.i18n.getMessage('company.filiation.regnumber')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svYULEIO.regNomer")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svYULEIO.naimUchrDok\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.establish.doc.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svYULEIO.naimUchrDok")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svYULEIO.kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svYULEIO.kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svYULEIO.adrElPocht\')">',
			`<span class="code">${edi.i18n.getMessage('user.profile.email')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svYULEIO.adrElPocht")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svYULEIO.adrReg.region\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.rus.region')}:</span>`,
			'<span class="row-data">{[this.getRegion(this.getValue(values, "svYULEIO.adrReg.region"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svYULEIO.adrReg.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.russ.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svYULEIO.adrReg.adrRF")]}</span>',
			'</tpl>',
			'</div>',

			`<tpl if="!this.isEmpty($v, 'svFL.0.svedFL.fio.familiya') || !this.isEmpty($v, 'svFL.0.svedFL.fio.imya') || !this.isEmpty($v, 'svFL.0.svedFL.fio.otchestvo')">`,
			'<div class="edi-company-row">',
			`<div class=" body-short_02">${edi.i18n.getMessage('power.of.attorney.organization.head.info')}</div>`,
			'</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.fio.familiya\')">`,
			`<span class="code">${edi.i18n.getMessage('last.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.svedFL.fio.familiya")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.fio.imya\')">',
			`<span class="code">${edi.i18n.getMessage('first.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.svedFL.fio.imya")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.fio.otchestvo\')">',
			`<span class="code">${edi.i18n.getMessage('patronymic.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.svedFL.fio.otchestvo")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, \'svFL.0.innfl\')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.innfl")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'svFL.0.snils\')">',
			`<span class="code">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.snils")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.dataRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('person.birthday')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svFL.0.svedFL.dataRozhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.pol\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.gender')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(`power.of.attorney.gender.${this.getValue(values, "svFL.0.svedFL.pol")}`)]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.grazhdanstvo\')">',
			`<span class="code">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[this.getCountry(this.getValue(values, "svFL.0.svedFL.grazhdanstvo"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.nomERN\')">',
			`<span class="code">${edi.i18n.getMessage('person.population.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.svedFL.nomERN")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.mestoRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.birth.place')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.svedFL.mestoRozhd")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.adrMZH.region\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.rus.region')}:</span>`,
			'<span class="row-data">{[this.getRegion(this.getValue(values, "svFL.0.svedFL.adrMZH.region"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.adrMZH.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.residence.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.svedFL.adrMZH.adrRF")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.svedFL.kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.adrElPocht\')">',
			`<span class="code">${edi.i18n.getMessage('user.profile.email')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.svedFL.adrElPocht")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.dolzhnost\')">',
			`<span class="code">${edi.i18n.getMessage('job.title')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.svedFL.dolzhnost")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			"<tpl if=\"!this.isEmpty(values, 'svFL.0.svedFL.udLichnFL.vidDok') || !this.isEmpty(values, 'svFL.0.svedFL.udLichnFL.serNomDok') || !this.isEmpty(values, 'svFL.0.svedFL.udLichnFL.dataDok')\">",
			`<span class="code">${edi.i18n.getMessage('person.identity.document')}:</span>`,
			'<span class="row-data">{[this.getPersonIdentityCardValue(this.getValue(values, "svFL.0.svedFL.udLichnFL.kodVidDok"))]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.series.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.svedFL.udLichnFL.serNomDok")]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svFL.0.svedFL.udLichnFL.dataDok"))]}</span>',
			'</tpl>',
			'</div>',
			`<tpl if="!this.isEmpty(values, \'svFL.0.svedFL.udLichnFL.vidDok\') || this.getValue(values, 'svFL.0.svedFL.udLichnFL.kodVidDok') === '10' && (!this.isEmpty(values, \'svFL.0.svedFL.udLichnFL.kodVydDok\'))">`,
			'<div class="edi-company-row">',
			`<tpl if=\"!this.isEmpty(values, 'svFL.0.svedFL.udLichnFL.kodVydDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.department.code')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.svedFL.udLichnFL.kodVydDok")]}</span>',
			'</tpl>',
			`<tpl if=\"!this.isEmpty(values, 'svFL.0.svedFL.udLichnFL.vidDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.issuing.authority')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.0.svedFL.udLichnFL.vidDok")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			`<tpl if="this.getValue(values, 'svFL.0.svedFL.udLichnFL.kodVidDok') === '22' && !this.isEmpty(values, 'svFL.0.svedFL.udLichnFL.expDate')">`,
			'<div class="edi-company-row">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.document.expiration.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svFL.0.svedFL.udLichnFL.expDate"))]}</span>',
			'</div>',
			'</tpl>',
			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;
					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				},
				getRegion: function (code) {
					const record = edi.stores.initRegionsStore().findRecord('id', code.toString());
					return record ? record.get('display_name') : '';
				},
				getSeriesIndentityDoc: function (value) {
					return value.split('-')[0];
				},
				getPersonIdentityCardValue: (value) => moduleMethods_003.getPersonIdentityCardValue(value),
				formatDate: function (value) {
					return Ext.Date.format(new Date(value), edi.constants.DATE_FORMAT.FNS);
				},
				getCountry(value) {
					var countryStore = edi.stores.initValidCountryFullStore(),
						country = countryStore.findRecord('iso_number', value, 0, false, true, true);
					return country?.get('name');
				}
			}
		),
	getLitsoBezDovIpTpl: (namePath, title) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!!this.getTitle()">`,
			'<div class="title-type">{[this.getTitle()]}</div>',
			'</tpl>',
			`<tpl if="!this.isEmpty($v, 'naimIP')">`,
			'<div class=" body-short_02">{[this.getValue(values, "naimIP")]}</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'ogrnip\')">',
			`<span class="code">${edi.i18n.getMessage('company.ogrnip.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "ogrnip")]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, 'innfl')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "innfl")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'snils\')">',
			`<span class="code">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "snils")]}</span>',
			'</tpl>',
			'</div>',

			`<tpl if="!this.isEmpty($v, 'svedFL.fio.familiya') || !this.isEmpty($v, 'svedFL.fio.imya') || !this.isEmpty($v, 'svedFL.fio.otchestvo')">`,
			'<div class="edi-company-row">',
			`<div class=" body-short_02">${edi.i18n.getMessage('power.or.attorney.individual.data')}</div>`,
			'</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, \'svedFL.fio.familiya\')">`,
			`<span class="code">${edi.i18n.getMessage('last.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.fio.familiya")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'svedFL.fio.imya\')">',
			`<span class="code">${edi.i18n.getMessage('first.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.fio.imya")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'svedFL.fio.otchestvo\')">',
			`<span class="code">${edi.i18n.getMessage('patronymic.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.fio.otchestvo")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.dataRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('person.birthday')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svedFL.dataRozhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.pol\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.gender')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(`power.of.attorney.gender.${this.getValue(values, "svedFL.pol")}`)]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.grazhdanstvo\')">',
			`<span class="code">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[this.getCountry(this.getValue(values, "svedFL.grazhdanstvo"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.nomERN\')">',
			`<span class="code">${edi.i18n.getMessage('person.population.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.nomERN")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.mestoRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.birth.place')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.mestoRozhd")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.adrMZH.region\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.rus.region')}:</span>`,
			'<span class="row-data">{[this.getRegion(this.getValue(values, "svedFL.adrMZH.region"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.adrMZH.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.residence.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.adrMZH.adrRF")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.adrElPocht\')">',
			`<span class="code">${edi.i18n.getMessage('user.profile.email')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.adrElPocht")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.dolzhnost\')">',
			`<span class="code">${edi.i18n.getMessage('job.title')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.dolzhnost")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			"<tpl if=\"!this.isEmpty(values, 'svedFL.udLichnFL.vidDok') || !this.isEmpty(values, 'svedFL.udLichnFL.serNomDok') || !this.isEmpty(values, 'svedFL.udLichnFL.dataDok')\">",
			`<span class="code">${edi.i18n.getMessage('person.identity.document')}:</span>`,
			'<span class="row-data">{[this.getPersonIdentityCardValue(this.getValue(values, "svedFL.udLichnFL.kodVidDok"))]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.series.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.udLichnFL.serNomDok")]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svedFL.udLichnFL.dataDok"))]}</span>',
			'</tpl>',
			'</div>',
			`<tpl if="!this.isEmpty(values, \'svedFL.udLichnFL.vidDok\') || this.getValue(values, 'svedFL.udLichnFL.kodVidDok') === '10' && (!this.isEmpty(values, \'svedFL.udLichnFL.kodVydDok\'))">`,
			'<div class="edi-company-row">',
			`<tpl if=\"!this.isEmpty(values, 'svedFL.udLichnFL.kodVydDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.department.code')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.udLichnFL.kodVydDok")]}</span>',
			'</tpl>',
			`<tpl if=\"!this.isEmpty(values, 'svedFL.udLichnFL.vidDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.issuing.authority')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.udLichnFL.vidDok")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			`<tpl if="this.getValue(values, 'svedFL.udLichnFL.kodVidDok') === '22' && !this.isEmpty(values, 'svedFL.udLichnFL.expDate')">`,
			'<div class="edi-company-row">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.document.expiration.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svedFL.udLichnFL.expDate"))]}</span>',
			'</div>',
			'</tpl>',
			'</tpl>',
			{
				getTitle: function () {
					return title;
				},
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;
					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				},
				getRegion: function (code) {
					const record = edi.stores.initRegionsStore().findRecord('id', code.toString());
					return record ? record.get('display_name') : '';
				},
				getSeriesIndentityDoc: function (value) {
					return value.split('-')[0];
				},
				getPersonIdentityCardValue: (value) => moduleMethods_003.getPersonIdentityCardValue(value),
				formatDate: function (value) {
					return Ext.Date.format(new Date(value), edi.constants.DATE_FORMAT.FNS);
				},
				getCountry(value) {
					var countryStore = edi.stores.initValidCountryFullStore(),
						country = countryStore.findRecord('iso_number', value, 0, false, true, true);
					return country?.get('name');
				}
			}
		),
	getIpDoverTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'naimIP')">`,
			'<div class=" body-short_02">{[this.getValue(values, "naimIP")]}</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'ogrnip\')">',
			`<span class="code">${edi.i18n.getMessage('company.ogrnip.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "ogrnip")]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, 'innfl')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "innfl")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'snils\')">',
			`<span class="code">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "snils")]}</span>',
			'</tpl>',
			'</div>',

			`<tpl if="!this.isEmpty($v, 'svedFL.fio.familiya') || !this.isEmpty($v, 'svedFL.fio.imya') || !this.isEmpty($v, 'svedFL.fio.otchestvo')">`,
			'<div class="ui-editableblock">',
			'<div class="edi-company-row">',
			`<div class="block heading_01">${edi.i18n.getMessage('power.or.attorney.individual.data')}</div>`,
			'</div>',
			'</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, \'svedFL.fio.familiya\')">`,
			`<span class="code">${edi.i18n.getMessage('last.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.fio.familiya")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'svedFL.fio.imya\')">',
			`<span class="code">${edi.i18n.getMessage('first.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.fio.imya")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'svedFL.fio.otchestvo\')">',
			`<span class="code">${edi.i18n.getMessage('patronymic.name')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.fio.otchestvo")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.dataRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('person.birthday')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svedFL.dataRozhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.pol\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.gender')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(`power.of.attorney.gender.${this.getValue(values, "svedFL.pol")}`)]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.grazhdanstvo\')">',
			`<span class="code">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[this.getCountry(this.getValue(values, "svedFL.grazhdanstvo"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.nomERN\')">',
			`<span class="code">${edi.i18n.getMessage('person.population.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.nomERN")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.mestoRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.birth.place')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.mestoRozhd")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.adrMZH.region\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.rus.region')}:</span>`,
			'<span class="row-data">{[this.getRegion(this.getValue(values, "svedFL.adrMZH.region"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.adrMZH.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.residence.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.adrMZH.adrRF")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.adrElPocht\')">',
			`<span class="code">${edi.i18n.getMessage('user.profile.email')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.adrElPocht")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.dolzhnost\')">',
			`<span class="code">${edi.i18n.getMessage('job.title')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.dolzhnost")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			"<tpl if=\"!this.isEmpty(values, 'svedFL.udLichnFL.vidDok') || !this.isEmpty(values, 'svedFL.udLichnFL.serNomDok') || !this.isEmpty(values, 'svedFL.udLichnFL.dataDok')\">",
			`<span class="code">${edi.i18n.getMessage('person.identity.document')}:</span>`,
			'<span class="row-data">{[this.getPersonIdentityCardValue(this.getValue(values, "svedFL.udLichnFL.kodVidDok"))]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.series.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.udLichnFL.serNomDok")]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svedFL.udLichnFL.dataDok"))]}</span>',
			'</tpl>',
			'</div>',
			`<tpl if="!this.isEmpty(values, \'svedFL.udLichnFL.vidDok\') || this.getValue(values, 'svedFL.udLichnFL.kodVidDok') === '10' && (!this.isEmpty(values, \'svedFL.udLichnFL.kodVydDok\'))">`,
			'<div class="edi-company-row">',
			`<tpl if=\"!this.isEmpty(values, 'svedFL.udLichnFL.kodVydDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.department.code')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.udLichnFL.kodVydDok")]}</span>',
			'</tpl>',
			`<tpl if=\"!this.isEmpty(values, 'svedFL.udLichnFL.vidDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.issuing.authority')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.udLichnFL.vidDok")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			`<tpl if="this.getValue(values, 'svedFL.udLichnFL.kodVidDok') === '22' && !this.isEmpty(values, 'svedFL.udLichnFL.expDate')">`,
			'<div class="edi-company-row">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.document.expiration.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svedFL.udLichnFL.expDate"))]}</span>',
			'</div>',
			'</tpl>',
			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;
					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				},
				getRegion: function (code) {
					const record = edi.stores.initRegionsStore().findRecord('id', code.toString());
					return record ? record.get('display_name') : '';
				},
				getSeriesIndentityDoc: function (value) {
					return value.split('-')[0];
				},
				getPersonIdentityCardValue: (value) => moduleMethods_003.getPersonIdentityCardValue(value),
				formatDate: function (value) {
					return Ext.Date.format(new Date(value), edi.constants.DATE_FORMAT.FNS);
				},
				getCountry(value) {
					var countryStore = edi.stores.initValidCountryFullStore(),
						country = countryStore.findRecord('iso_number', value, 0, false, true, true);
					return country?.get('name');
				}
			}
		),
	getSvedFizLTpl: (namePath, title) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!!this.getTitle()">`,
			'<div class="title-type">{[this.getTitle()]}</div>',
			'</tpl>',
			`<tpl if="!this.isEmpty($v, 'svedFL.fio.familiya') || !this.isEmpty($v, 'svedFL.fio.imya') || !this.isEmpty($v, 'svedFL.fio.otchestvo')">`,
			'<div class="body-short_02">',
			'<span class="row-data edi-company-individual-lastname">{[this.getValue(values, "svedFL.fio.familiya")]}</span>&nbsp;',
			'<span class="row-data edi-company-individual-firstname">{[this.getValue(values, "svedFL.fio.imya")]}</span>&nbsp;',
			'<span class="row-data edi-company-individual-patronymicname">{[this.getValue(values, "svedFL.fio.otchestvo")]}</span>',
			'</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, 'innfl')">`,
			`<span class="code ui-label-heading">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "innfl")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'snils\')">',
			`<span class="code ui-label-heading">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "snils")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.dataRozhd\')">',
			`<span class="code ui-label-heading">${edi.i18n.getMessage('person.birthday')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svedFL.dataRozhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.pol\')">',
			`<span class="code ui-label-heading">${edi.i18n.getMessage('power.of.attorney.gender')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(`power.of.attorney.gender.${this.getValue(values, "svedFL.pol")}`)]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.prGrazhd\')">',
			`<span class="code ui-label-heading">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(`power.of.attorney.citizenship.${this.getValue(values, "svedFL.prGrazhd") ?? 1}`)]}</span>',
			"<tpl if=\"!this.isEmpty(values, 'svedFL.prGrazhd') && this.getValue(values, 'svedFL.prGrazhd') !== '3'\">",
			`<span class="code ui-label-heading">${edi.i18n.getMessage(
				'power.of.attorney.nationality.country'
			)}:</span>`,
			'<span class="row-data">{[this.getNationalityCountryValue(values)]}</span>',
			'</tpl>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.nomERN\')">',
			`<span class="code ui-label-heading">${edi.i18n.getMessage('person.population.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.nomERN")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.mestoRozhd\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.birth.place')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.mestoRozhd")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.adrMZH.region\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.rus.region')}:</span>`,
			'<span class="row-data">{[this.getRegion(this.getValue(values, "svedFL.adrMZH.region"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.adrMZH.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.residence.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.adrMZH.adrRF")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.adrElPocht\')">',
			`<span class="code">${edi.i18n.getMessage('user.profile.email')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.adrElPocht")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'dolzhnost\')">',
			`<span class="code">${edi.i18n.getMessage('job.title')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "dolzhnost")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			"<tpl if=\"!this.isEmpty(values, 'svedFL.udLichnFL.vidDok') || !this.isEmpty(values, 'svedFL.udLichnFL.serNomDok') || !this.isEmpty(values, 'svedFL.udLichnFL.dataDok')\">",
			`<span class="code">${edi.i18n.getMessage('person.identity.document')}:</span>`,
			'<span class="row-data">{[this.getPersonIdentityCardValue(this.getValue(values, "svedFL.udLichnFL.kodVidDok"))]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.series.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.udLichnFL.serNomDok")]}</span>',
			`<span class="code">${edi.i18n.getMessage('person.identity.document.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svedFL.udLichnFL.dataDok"))]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, \'svedFL.udLichnFL.vidDok\') || this.getValue(values, 'svedFL.udLichnFL.kodVidDok') === '10' && (!this.isEmpty(values, \'svedFL.udLichnFL.kodVydDok\'))">`,
			'<div class="edi-company-row">',
			`<tpl if=\"!this.isEmpty(values, 'svedFL.udLichnFL.kodVydDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.department.code')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.udLichnFL.kodVydDok")]}</span>',
			'</tpl>',
			`<tpl if=\"!this.isEmpty(values, 'svedFL.udLichnFL.vidDok')\">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.issuing.authority')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.udLichnFL.vidDok")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			`<tpl if="this.getValue(values, 'svedFL.udLichnFL.kodVidDok') === '22' && !this.isEmpty(values, 'svedFL.udLichnFL.expDate')">`,
			'<div class="edi-company-row">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.document.expiration.date')}:</span>`,
			'<span class="row-data">{[this.formatDate(this.getValue(values, "svedFL.udLichnFL.expDate"))]}</span>',
			'</div>',
			'</tpl>',
			'</div>',
			'</tpl>',
			{
				getTitle: function () {
					return title;
				},
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;

					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}

					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				},
				getRegion: function (code) {
					const record = edi.stores.initRegionsStore().findRecord('id', code.toString());
					return record ? record.get('display_name') : '';
				},
				getSeriesIndentityDoc: function (value) {
					return value.split('-')[0];
				},
				getPersonIdentityCardValue: (value) => moduleMethods_003.getPersonIdentityCardValue(value),
				formatDate: function (value) {
					return value && Ext.Date.format(new Date(value), edi.constants.DATE_FORMAT.FNS);
				},
				getNationalityCountryValue: (values) => {
					const ruCode = '643';
					const citizenValue = values['svedFL.prGrazhd'] ?? '1';
					const nationalityCountryCode = citizenValue === '1' ? ruCode : values['svedFL.grazhdanstvo'];
					const nationalityCountryFullInfo = edi.stores.data.countries_full.find(
						(country) =>
							nationalityCountryCode === country?.iso_number ||
							nationalityCountryCode === country?.iso_number_3
					);
					return nationalityCountryFullInfo?.name;
				},
				getCountry(value) {
					var countryStore = edi.stores.initValidCountryFullStore(),
						country = countryStore.findRecord('iso_number', value, 0, false, true, true);
					return country?.get('name');
				}
			}
		),
	getIPTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'naimIP')">`,
			'<div class=" body-short_02">{[this.getValue(values, "naimIP")]}</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'ogrnip\')">',
			`<span class="code">${edi.i18n.getMessage('company.ogrnip.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "ogrnip")]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, 'innfl')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "innfl")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'snils\')">',
			`<span class="code">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "snils")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;
					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				}
			}
		),
	createPowerListSection: function (props, isDetailModal) {
		let panels = [];
		const { svPolnLists, mashPolnLists } = props || {};

		const headPowerListSection = createLabel({
			cls: `typography ${isDetailModal ? 'heading_01' : 'heading_02'}`,
			text: ''
		});

		if (!svPolnLists && !mashPolnLists?.length) {
			return null;
		}
		if (mashPolnLists?.length) {
			headPowerListSection.setText(edi.i18n.getMessage('power.of.attorney.power.mashPolnLists'));

			let cards = [];
			const createMachineReadableAuthority = function (card) {
				return createContainer({
					cls: 'edi-poa-power-list-card-container',
					margin: '16 0',
					items: [
						card.kodPoln && card.naimPoln
							? createFieldBlock({
									cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.small}`,
									title: edi.i18n.getMessage('power.of.attorney.code.name.authority'),
									layout: {
										type: 'grid',
										gap: [24, 16]
									},
									items: [
										createLabel({
											cls: 'typography body-short_01',
											text: card.kodPoln + ' - ' + card.naimPoln
										}),
										card.mnPoln || card.ogrSvPoln
											? createFieldBlock({
													cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.small}`,
													title: edi.i18n.getMessage(
														'power.of.attorney.limitations.of.powers'
													),
													layout: {
														type: 'grid',
														gap: [8, 16]
													},
													items: [
														createContainer({
															tpl: Ext.create(
																'Ext.XTemplate',
																'<ol class="edi-poa-limitations-list"><tpl for=".">' +
																	'<li>' +
																	'<div class="body-short_01">{kodOgr} - {naimOgr}</div>' +
																	'<div class="caption_01 detail-label">{[edi.i18n.getMessage(\'power.of.attorney.values.limitations\')]}:</div>' +
																	'<div class="body-short_01">{kodZnachOgr} - {naimZnachOgr}</div>' +
																	'</li>' +
																	'</tpl></ol>'
															),
															data: card.ogrSvPoln ?? []
														})
													]
											  })
											: null
									]
							  })
							: null
					]
				});
			};
			mashPolnLists.forEach((item) => {
				cards.push(createMachineReadableAuthority(item));
			});

			panels = panels.concat(cards);
		} else if (svPolnLists) {
			headPowerListSection.setText(edi.i18n.getMessage('power.of.attorney.power.svPolnLists'));

			const createPowerListsPanel = function (powerList) {
				let tekstPolnValue = '';
				if (powerList) {
					const poln = powerList.soderzhPoln
						? powerList.kodPoln + ' - ' + powerList.soderzhPoln
						: powerList.kodPoln;
					tekstPolnValue = powerList.tekstPoln ?? poln;
				}

				return createFieldBlock({
					cls: `${FIELD_BLOCK_CLS.default} ${isDetailModal ? '' : FIELD_BLOCK_CLS.detailsBlock}`,
					items: [
						createLabel({
							cls: 'typography body-short_01',
							html: '<pre>' + tekstPolnValue + '</pre>'
						})
					]
				});
			};
			panels.push(createPowerListsPanel(svPolnLists));
		}

		return createContainer({
			layout: 'anchor',
			defaults: {
				anchor: '100%'
			},
			items: [headPowerListSection].concat(panels)
		});
	},
	isValidSimpleSelectorWindow: function (values, context) {
		if (edi.utils.isEmptyObject(values) && context.allowBlank) {
			return true;
		}

		let formValid = true;
		if (context.createFormPanel && 'function' == typeof context.createFormPanel) {
			const formTabPanel = context.createFormPanel(context.fieldValues, false, context.modalConf);
			const formPanel = createForm({
				cls: 'edi-form',
				layout: 'fit',
				submitEmptyText: false,
				items: [formTabPanel]
			});
			const fields = formPanel.getForm().getFields();
			fields?.items.forEach((field) => {
				field.fireEvent('change', field, field.getValue());
			});
			formValid = formPanel.isValid();
		}
		return formValid;
	},
	getFieldValue: function (partData, docPath, name) {
		if (edi.utils.isEmptyObject(partData)) return null;
		if (!!partData[docPath + `UI Platform Maven Webapp`]) {
			return partData[docPath + `UI Platform Maven Webapp`];
		} else if (!!edi.utils.getObjectProperty(partData, name)) {
			return edi.utils.getObjectProperty(partData, name);
		} else if (!!edi.utils.getObjectProperty(partData, docPath + `UI Platform Maven Webapp`)) {
			return edi.utils.getObjectProperty(partData, docPath + `UI Platform Maven Webapp`);
		}
	},
	powersSection: {
		kodPolnItems: null,
		//Коды загружаются асинхронно, и только при использовании модуля, т.к. их много и распухать бандл ради них не стоит
		loadKodPolnItems: function () {
			return new Promise((resolve, reject) => {
				//Грузим коды только один раз
				if (moduleMethods_003.powersSection.kodPolnItems) {
					resolve();
					return;
				}

				const fail = () => reject();

				const success = function (resp) {
					if (Array.isArray(resp.items)) {
						moduleMethods_003.powersSection.kodPolnItems = resp.items;
						resolve();
					} else {
						fail(resp);
					}
				};
				edi.rest.sendRequest(
					edi.rest.services.POWER_OF_ATTORNEY.KOD_POLN_CODES.GET,
					'GET',
					null,
					success,
					fail
				);
			});
		},
		kodOgrItems: ['B2B_01', 'B2B_02', 'B2B_03', 'B2B_04'],
		kodZnachOgrItems: {
			B2B_01: ['B2B_01_01', 'B2B_01_02', 'B2B_01_03', 'B2B_01_04', 'B2B_01_05', 'B2B_01_06'],
			B2B_02: [
				'B2B_02_01',
				'B2B_02_02',
				'B2B_02_03',
				'B2B_02_04',
				'B2B_02_05',
				'B2B_02_06',
				'B2B_02_07',
				'B2B_02_08'
			],
			B2B_03: ['B2B_03_01', 'B2B_03_02', 'B2B_03_03', 'B2B_03_04', 'B2B_03_05', 'B2B_03_06'],
			B2B_04: ['B2B_04_01', 'B2B_04_02', 'B2B_04_03', 'B2B_04_04', 'B2B_04_05', 'B2B_04_06']
		},
		createKodPolnStore: function () {
			const storeItems = moduleMethods_003.powersSection.kodPolnItems.map(({ id, name }) => ({
				id,
				name: `com.edisoft:general_ui:war:1.0.18.0-RC20 - UI Platform Maven Webapp`,
				originalName: name
			}));
			return edi.stores.createInlineStore(storeItems, 'SIMPLE');
		},
		createKodOgrStore: function () {
			return edi.stores.createSimpleInlineStore(moduleMethods_003.powersSection.kodOgrItems, function (kodPoln) {
				const naimPoln = edi.i18n.getMessage(`power.of.attorney.power.kodOgr.${kodPoln}`);
				return `${kodPoln} - ${naimPoln}`;
			});
		},
		createKodZnachOgrStore: function () {
			let items = [];
			Object.entries(moduleMethods_003.powersSection.kodZnachOgrItems).forEach(([kodOgr, kodZnachOgrItems]) => {
				kodZnachOgrItems.forEach((kodZhachOgr) => {
					const naimZnachOgr = edi.i18n.getMessage(`power.of.attorney.power.kodZhachOgr.${kodZhachOgr}`);
					items.push({
						id: kodZhachOgr,
						name: `${kodZhachOgr} - ${naimZnachOgr}`,
						kodOgr: kodOgr
					});
				});
			});
			return edi.stores.createInlineStore(items, 'SIMPLE');
		},
		convertLimitationsFromBlock: function (values) {
			const toArray = function (value) {
				return Array.isArray(value) ? value : !!value ? [value] : [];
			};
			//так ограничения приходят при первом рендере
			let limitations = toArray(values?.ogrSvPoln) || [];
			const kodOgr = toArray(values?.kodOgr);
			const naimOgr = toArray(values?.naimOgr);
			const kodZnachOgr = toArray(values?.kodZnachOgr);
			const naimZnachOgr = toArray(values?.naimZnachOgr);
			if (kodOgr.length > 0) {
				//а так они выглядят когда вставились в полях +/-
				limitations = kodOgr.map((kod, index) => ({
					idOgr: index + 1,
					kodOgr: kod,
					naimOgr: naimOgr[index] || '',
					kodZnachOgr: kodZnachOgr[index] || '',
					naimZnachOgr: naimZnachOgr[index] || ''
				}));
			}
			return limitations;
		}
	}
};
