export const packingListSelector = {
	buyer: {
		getFieldsMap() {
			const prefix = 'buyer';
			return {
				company_name: `${prefix}.name`,
				company_iln: `${prefix}.iln`,
				company_util_reg_nr: `${prefix}.utilizationRegisterNumber`,
				company_inn: `${prefix}.taxID`,
				addr_category: `${prefix}.type`,
				addr_country: `${prefix}.country`,
				addr_zip: `${prefix}.postalCode`,
				addr_locality: `${prefix}.locality`,
				addr_block: `${prefix}.housing`,
				addr_addInf: `${prefix}.additionalInformation`,
				addr_area: `${prefix}.district`,
				addr_region_name: `${prefix}.state`,
				addr_region_code: `${prefix}.stateCode`,
				addr_city: `${prefix}.cityName`,
				addr_street: `${prefix}.streetAndNumber`,
				addr_home: `${prefix}.houseNumber`,
				addr_flat: `${prefix}.roomNumber`
			};
		},
		getModalConf() {
			const defaults = Ext.clone(edi.selectors.defaults);
			const commonOrgAddress = Ext.clone(edi.selectors.COMMON_ORGANIZATION_ADDRESS);
			const modalConf = {
				main: {
					company_name: defaults.company_name,
					company_inn: defaults.company_inn,
					company_gln: defaults.company_gln,
					company_reg_number: defaults.company_reg_nr
				},
				address: {
					category: defaults.category,
					country: Ext.merge(
						{},
						commonOrgAddress.country,
						Ext.merge({}, defaults.iso2_country_combo, {
							fieldConfig: {
								mandatory: false,
								allowBlank: true
							}
						})
					),
					region: commonOrgAddress.region,
					area: commonOrgAddress.area,
					city: commonOrgAddress.city,
					zip: commonOrgAddress.zip,
					street: commonOrgAddress.street,
					locality: commonOrgAddress.locality,
					home: commonOrgAddress.home,
					block: commonOrgAddress.block,
					flat: commonOrgAddress.flat,
					addInf: commonOrgAddress.addInf
				}
			};
			return {
				tabs: modalConf
			};
		}
	},
	getBuyerSelectors() {
		const fieldsMap = this.buyer.getFieldsMap();
		const modalConf = this.buyer.getModalConf();

		return { fieldsMap, modalConf };
	},

	seller: {
		getFieldsMap: function () {
			const prefix = 'seller';
			return {
				company_name: `${prefix}.name`,
				company_iln: `${prefix}.iln`,
				company_util_reg_nr: `${prefix}.utilizationRegisterNumber`,
				company_inn: `${prefix}.taxID`,
				addr_category: `${prefix}.type`,
				addr_country: `${prefix}.country`,
				addr_zip: `${prefix}.postalCode`,
				addr_locality: `${prefix}.locality`,
				addr_block: `${prefix}.housing`,
				addr_addInf: `${prefix}.additionalInformation`,
				addr_area: `${prefix}.district`,
				addr_region_name: `${prefix}.state`,
				addr_region_code: `${prefix}.stateCode`,
				addr_city: `${prefix}.cityName`,
				addr_street: `${prefix}streetAndNumber`,
				addr_home: `${prefix}.houseNumber`,
				addr_flat: `${prefix}.roomNumber`,
				code_by_buyer: `${prefix}.codeByBuyer`,
				code_by_seller: `${prefix}.codeBySeller`
			};
		},
		getModalConf: function () {
			return Ext.merge(
				edi.selectors.mergeModalCfgWithCommonOrgAddress(
					edi.selectors.getLegacyModalConf({
						main: {
							company_inn: edi.selectors.defaults.company_inn
						},
						contact: null
					})
				),
				{ title: edi.i18n.getMessage('document.seller') }
			);
		}
	},
	getSellerSelectors() {
		const fieldsMap = this.seller.getFieldsMap();
		const modalConf = this.seller.getModalConf();

		return { fieldsMap, modalConf };
	},

	deliveryPoint: {
		getFieldsMap: function () {
			const prefix = 'deliveryPoint';
			return {
				company_name: `${prefix}.name`,
				company_iln: `${prefix}.iln`,
				company_inn: `${prefix}.taxID`,
				addr_category: `${prefix}.type`,
				addr_country: `${prefix}.country`,
				addr_zip: `${prefix}.postalCode`,
				addr_locality: `${prefix}.locality`,
				addr_block: `${prefix}.housing`,
				addr_addInf: `${prefix}.additionalInformation`,
				addr_area: `${prefix}.district`,
				addr_region_name: `${prefix}.state`,
				addr_region_code: `${prefix}.stateCode`,
				addr_city: `${prefix}.cityName`,
				addr_street: `${prefix}streetAndNumber`,
				addr_home: `${prefix}.houseNumber`,
				addr_flat: `${prefix}.roomNumber`,
				code_by_buyer: `${prefix}.codeByBuyer`,
				code_by_seller: `${prefix}.codeBySeller`
			};
		},
		getModalConf: function () {
			return Ext.merge(
				edi.selectors.mergeModalCfgWithCommonOrgAddress(
					edi.selectors.getLegacyModalConf({
						main: {
							company_reg_number: null,
							company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
								regex: null,
								maxLength: 35
							})
						},
						contact: null
					})
				),
				{ title: edi.i18n.getMessage('document.delivery') }
			);
		}
	},
	getDeliveryPointSelectors() {
		const fieldsMap = this.deliveryPoint.getFieldsMap();
		const modalConf = this.deliveryPoint.getModalConf();

		return { fieldsMap, modalConf };
	},

	shipFrom: {
		getFieldsMap: function () {
			const prefix = 'shipFrom';
			return {
				company_name: `${prefix}.name`,
				company_iln: `${prefix}.iln`,
				company_inn: `${prefix}.taxID`,
				addr_category: `${prefix}.type`,
				addr_country: `${prefix}.country`,
				addr_zip: `${prefix}.postalCode`,
				addr_locality: `${prefix}.locality`,
				addr_block: `${prefix}.housing`,
				addr_addInf: `${prefix}.additionalInformation`,
				addr_area: `${prefix}.district`,
				addr_region_name: `${prefix}.state`,
				addr_region_code: `${prefix}.stateCode`,
				addr_city: `${prefix}.cityName`,
				addr_street: `${prefix}streetAndNumber`,
				addr_home: `${prefix}.houseNumber`,
				addr_flat: `${prefix}.roomNumber`,
				code_by_buyer: `${prefix}.codeByBuyer`,
				code_by_seller: `${prefix}.codeBySeller`
			};
		},
		getModalConf: function () {
			return Ext.merge(
				edi.selectors.mergeModalCfgWithCommonOrgAddress(
					edi.selectors.getLegacyModalConf({
						main: {
							company_reg_number: null,
							company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
								regex: null,
								maxLength: 35
							})
						},
						contact: null
					})
				),
				{ title: edi.i18n.getMessage('document.ship.from') }
			);
		}
	},
	getShipFromSelectors() {
		const fieldsMap = this.shipFrom.getFieldsMap();
		const modalConf = this.shipFrom.getModalConf();

		return { fieldsMap, modalConf };
	},

	ultimateCustomer: {
		getFieldsMap: function () {
			const prefix = 'ultimateCustomer';
			return {
				company_name: `${prefix}.name`,
				company_iln: `${prefix}.iln`,
				addr_category: `${prefix}.type`,
				addr_country: `${prefix}.country`,
				addr_zip: `${prefix}.postalCode`,
				addr_locality: `${prefix}.locality`,
				addr_block: `${prefix}.housing`,
				addr_addInf: `${prefix}.additionalInformation`,
				addr_area: `${prefix}.district`,
				addr_region_name: `${prefix}.state`,
				addr_region_code: `${prefix}.stateCode`,
				addr_city: `${prefix}.cityName`,
				addr_street: `${prefix}streetAndNumber`,
				addr_home: `${prefix}.houseNumber`,
				addr_flat: `${prefix}.roomNumber`
			};
		},
		getModalConf: function () {
			return Ext.merge(
				edi.selectors.mergeModalCfgWithCommonOrgAddress(
					edi.selectors.getLegacyModalConf({
						main: {
							company_reg_number: null
						},
						contact: null
					})
				),
				{ title: edi.i18n.getMessage('documents.ultimate.customer') }
			);
		}
	},
	getUltimateCustomerSelectors() {
		const fieldsMap = this.ultimateCustomer.getFieldsMap();
		const modalConf = this.ultimateCustomer.getModalConf();

		return { fieldsMap, modalConf };
	}
};
