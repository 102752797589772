type PackingListUnitOfMeasure = string;

export interface Product {
	lineNumber: number;
	ean?: string;
	buyerItemCode?: string;
	supplierItemCode?: string;
	itemDescription?: string;
	orderedQuantity?: number;
	unitOfMeasure?: PackingListUnitOfMeasure;
	supplierPackCode?: string;
	unitNetPrice?: number;
	unitGrossPrice?: number;
	taxRate?: string;
	netAmount?: number;
	taxAmount?: number;
	grossAmount?: number;
	remarks?: string;
	temperatureMode?: number;
}

export interface PackingBase {
	sequenceNumber: number;
	type: string;
	range?: {
		idBegin?: string;
		idEnd?: string;
	};
	packagingUnitUniversalCode?: string;
	packagingUnitName?: string;
	packagingUnitBuyerCode?: string;
	packagingUnitSupplierCode?: string;
	packExpirationDate?: string; //date YYYY-MM-DD
	packExpirationDateTill?: string; //date YYYY-MM-DD
	packProductionDate?: string; //date YYYY-MM-DD
	packProductionDateTill?: string; //date YYYY-MM-DD
	batchNumber?: string;
	vsdNumber?: string;
	packageMeasurements?: {
		size?: {
			length?: number;
			width?: number;
			height?: number;
			unitOfMeasure?: PackingListUnitOfMeasure;
		};
		weight?: {
			netWeight?: number;
			grossWeight?: number;
			unitOfMeasure?: PackingListUnitOfMeasure;
			unitWeight?: number;
			boxWeight?: number;
			palletWeight?: number;
		};
	};
	destination?: {
		iln: string;
		codeByBuyer?: string;
		codeBySeller?: string;
		name?: string;
	};
}

export interface Packing extends PackingBase {
	quantityPerPack?: number;
	boxesQuantity?: number;
	baseUnit?: string;
	packagingUnit?: string;
	thirdLevelPackaging?: PackingBase;
}

export interface TreeRecord<T> extends AnyObject {
	data: {
		data: T;
	};
}

/**
 * Type guard for TreeRecord<Product> interface
 */
export const isProductRecord = function (
	productRecord: TreeRecord<Product | PackingBase | Packing>
): productRecord is TreeRecord<Product> {
	return !!(productRecord.data.data as Product).lineNumber;
};

/**
 * Type guard for TreeRecord<PackingBase | Packing> interface
 */
export const isPackingRecord = function (
	packingRecord: TreeRecord<Product | PackingBase | Packing>
): packingRecord is TreeRecord<PackingBase | Packing> {
	return !!(packingRecord.data.data as PackingBase | Packing).sequenceNumber;
};

export enum TREE_MODE {
	PACKING = 'PACKING',
	PRODUCT = 'PRODUCT'
}
