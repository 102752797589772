import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createEditableProductsTreeGrid } from '@Components/product.tree.grid.editable';
import { createRowsBlock } from '@Components/createRows/create.rows';
import {
	createPanel,
	createForm,
	createFieldBlock,
	createContainer,
	createFieldSet,
	createFormContainer,
	createModalForm
} from '@UIkit/components/panels';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createTab, createTabPanel } from '@UIkit/components/tab';
import { createAddModulePanel } from '@Components/panels';
import { createGrid, ROW_COLOR_CLS } from '@Components/grid';
import {
	createCombo,
	createDateField,
	createHiddenField,
	createNumberField,
	createLabel,
	createTextField
} from '@UIkit/components/fields';
import { createEditableGrid } from '@Components/editableGrid/EditableGrid';
import {
	createUKDLineModalForm,
	isSCHFDOP_DIS_BussinessProcess,
	isKSCHF_DIS_BussinessProcess,
	isWithoutEconomicLifeDocName
} from './methods';
import { PTYPE_COMBO_ITEMS_DISABLEABLE } from '@UIkit/plugins';
import { createButtonContainer } from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createLabelWithIcon } from '@Components/LabelWithIcon/LabelWithIcon';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { createSendButton } from '@Components/buttons';
import { showApprovalModal } from '@Ediweb/modules/APPROVAL_DOCUMENTS/methods';

/**
 * @author Vsevolod Liapin
 * Class for new corrected upd creation
 */
Ext.namespace('edi.modules');
edi.modules['document.create.fns_ukd_5_01n'] = function () {
	let moduleData,
		parentData,
		isNumberAutoGenerated = false,
		parentId,
		id,
		userData = edi.core.getUserData(),
		productValues = { products: [] },
		isEdit = false,
		buyerOrg,
		buyerOrgValues,
		seller,
		buyer,
		productsGrid,
		form,
		contract,
		firstAuthorityField,
		signerFillFromCert = {},
		clearSecondSignerBtn,
		initialData,
		secondAuthorityField,
		documentData = {},
		bpName,
		signerContainer,
		signerSecond,
		isCorrect = false,
		isCopy = false,
		invoiceTextInfosColumns,
		transferDocColumns,
		baseOfCorrectionsColumns,
		baseOfCorrectionsContsName = 'Без документа-основания',
		infoColumnsConfig,
		isCreateOnBasis = false,
		signerType,
		isNotLegalEntity,
		taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE,
		isCreate = false;
	let certSignerSettings;
	let isBasedOnRetann, hasDataFromTransformation;
	let availableAuthotiryAreas =
		edi.constants.SIGN_DOCUMENTS.AUTHORITY_AREAS_FOR_DOC_TYPES[edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD]['OUTBOX'];
	let isFillingFromBackend = false;
	const signerTypeFIOMap = {
		individualPerson: 'name',
		individual: 'nameType',
		legalEntity: 'fullName'
	};

	const getBpNameFromUpd = function (functionName) {
		for (let key in edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD) {
			if (
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.hasOwnProperty(key) &&
				functionName ===
					edi.i18n.getMessage('documents.fns_upd.type.' + edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD[key])
			) {
				return edi.constants.BP_NAME_MAP[edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD[key]];
			}
		}
		return null;
	};

	const getBpNameUkd = function (functionName) {
		for (let key in edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD) {
			if (
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.hasOwnProperty(key) &&
				functionName ===
					edi.i18n.getMessage('documents.fns_ukd.type.' + edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD[key])
			) {
				return edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD[key];
			}
		}
		return null;
	};

	const calculateSignerType = () => {
		const signerData = documentData?.signers?.[0] || {};
		const signerTypes = ['legalEntity', 'individualPerson', 'individual'];

		//Вычисляем тип подписанта по данным signers из documentData
		signerTypes.forEach((type) => {
			if (!Ext.Object.isEmpty(signerData[type])) {
				signerType = type;
			}
		});

		isNotLegalEntity = ['individualPerson', 'individual'].some((type) => type === signerType);
	};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;

		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		isBasedOnRetann = moduleData.initData.isBasedOnRetann;
		isCorrect = moduleData.initData.isCorrect === true;
		hasDataFromTransformation = moduleData.initData && moduleData.initData.data && moduleData.initData.data.data;

		if (moduleData.initData.hasOwnProperty('fns_upd') && moduleData.initData.fns_upd) {
			mappingData(moduleData.initData.fns_upd.document);
			bpName = getBpNameFromUpd(moduleData.initData.fns_upd.document.function);
			productValues = edi.converters.convertUPDProductsToUKDGridValues(
				edi.utils.getObjectProperty(documentData, 'cinvoiceTable.productInfo'),
				'5.01-N',
				bpName
			);
			initialData = {
				document: documentData
			};
			isEdit = true;
			isCreate = true;
		} else if (hasDataFromTransformation && isCorrect) {
			documentData = moduleData.initData.data.data.document;
			initialData = moduleData.initData.data.data;
			bpName = getBpNameUkd(documentData.function);
		} else if (hasDataFromTransformation) {
			bpName = getBpNameUkd(moduleData.initData.data.function);
			documentData = moduleData.initData.data.data.document;
			if (!documentData['invoiceCorrectionInfo']) {
				documentData['invoiceCorrectionInfo'] = {};
			}
			if (!documentData['invoiceCorrectionInfo']['buyerInfo']) {
				documentData['invoiceCorrectionInfo']['buyerInfo'] = {};
			}
			if (!documentData['cinvoiceTable']) {
				documentData['cinvoiceTable'] = {};
			}
			isEdit = true;
			isCreate = true;
		}
		if (initialData) {
			delete initialData.fileId;
		}
		if (bpName && !hasDataFromTransformation) {
			buyerOrg = moduleData.initData.meta?.toOrg;
			contract = moduleData.initData.meta?.agreement;
		}

		renderData(initCallBack);

		if (buyerOrg) {
			if (!buyerOrg.hasOwnProperty('fnsId')) {
				buyerOrg = edi.utils.getOrg({
					orgId: buyerOrg.id
				});
			}
			buyerOrgValues = edi.converters.convertOrgToPartie(buyerOrg);
			taxCalculationMethod = edi.utils.getTaxCalculationMethod(buyerOrg.attributes);
		}
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	const mappingData = function (updData) {
		updData = Ext.clone(updData);
		documentData = {
			cinvoiceTable: edi.utils.getObjectProperty(updData, 'invoiceTable'),
			invoiceCorrectionInfo: {
				invoiceInfo: [
					{
						number: edi.utils.getObjectProperty(updData, 'invoice.invoiceNumber'),
						date: edi.utils.getObjectProperty(updData, 'invoice.invoiceDate')
					}
				],
				sellerInfo: edi.utils.getObjectProperty(updData, 'invoice.sellerInfo'),
				buyerInfo: edi.utils.getObjectProperty(updData, 'invoice.buyerInfo'),
				okv: edi.utils.getObjectProperty(updData, 'invoice.okv'),
				info: {
					textInfos: edi.utils.getObjectProperty(updData, 'invoice.info.textInfos')
				}
			},
			informationOnCoordination: {
				transferDoc: [
					{
						name: edi.i18n.getMessage('document.fns_upd'),
						number: edi.utils.getObjectProperty(updData, 'invoice.invoiceNumber'),
						date: edi.utils.getObjectProperty(updData, 'invoice.invoiceDate')
					}
				]
			},
			signers: edi.utils.getObjectProperty(updData, 'signer')
		};
		const corrections = edi.utils.getObjectProperty(updData, 'invoice.corrections'),
			invoicesCorrectInfoObj = {};
		if (corrections.Date) {
			invoicesCorrectInfoObj.date = corrections.Date;
		}

		if (corrections.Number) {
			invoicesCorrectInfoObj.number = corrections.Number;
		}
		documentData.invoiceCorrectionInfo.invoiceInfo[0].invoicesCorrectInfo = [invoicesCorrectInfoObj];
	};

	const isValidOrganization = function (params) {
		params = params || {};

		const organization = params.organization;
		const organizationType = params.organizationType;
		const useOrganizationMap = params.useOrganizationMap;

		const getFieldValue = function (mapKey) {
			const organizationFieldsMap = {
				company_name: 'identification.legalEntity.orgName',
				company_kpp: 'identification.legalEntity.kpp',
				company_inn: 'identification.legalEntity.tin',
				individual_lastname: 'identification.individualPerson.name.lastName',
				individual_firstname: 'identification.individualPerson.name.firstName',
				individual_inn: 'identification.individualPerson.tin',

				addr_rus_region: 'address.addressRu.regionCode',
				addr_rus_county: 'address.addressRu.area',

				addr_for_country: 'address.addressInfo.codeOfCountry',
				addr_for_text: 'address.addressInfo.addressText'
			};
			const fieldName = useOrganizationMap ? organizationFieldsMap[mapKey] : mapKey;
			return edi.utils.getObjectProperty(organization, fieldName);
		};

		let valid = getFieldValue('company_name');
		valid = valid || (getFieldValue('individual_lastname') && getFieldValue('individual_firstname'));

		if ('seller' === organizationType || 'buyer' === organizationType) {
			valid = valid && (getFieldValue('company_inn') || getFieldValue('individual_inn'));
			valid = valid && (!getFieldValue('company_inn') || getFieldValue('company_kpp'));
		}

		valid = valid && getFieldValue('addr_rus_region');
		valid = valid || (getFieldValue('addr_for_country') && getFieldValue('addr_for_text'));

		return !!valid;
	};

	const clearSecondSigner = function () {
		const filds = [
			'signers.legalEntity.position',
			`signers.${signerType}.${signerTypeFIOMap[signerType]}.firstName`,
			`signers.${signerType}.${signerTypeFIOMap[signerType]}.lastName`,
			`signers.${signerType}.${signerTypeFIOMap[signerType]}.middleName`,
			'signers.authorityArea',
			'signers.baseAuthory',
			'signers.baseOrgAuthory',
			'signers.state'
		];

		if (signerSecond && signerSecond.length) {
			for (let j = 0; j < filds.length; j++) {
				for (let i = 0, len = signerSecond.length; i < len; i++) {
					let elm = signerSecond[i].down('[name=' + filds[j] + ']');
					if (elm) {
						elm.setValue('');
					}
				}
			}
		}
		clearSecondSignerBtn.setDisabled(true);
	};

	const showModalListOfSecondSigner = function () {
		const rowClick = function (record) {
			moduleData.tab.setLoading(true);
			const data = record.getData(),
				mapper = {
					'subjectName.position': 'signers.legalEntity.position',
					'subjectName.firstname': `signers.${signerType}.${signerTypeFIOMap[signerType]}.firstName`,
					'subjectName.lastname': `signers.${signerType}.${signerTypeFIOMap[signerType]}.lastName`,
					'subjectName.middlename': `signers.${signerType}.${signerTypeFIOMap[signerType]}.middleName`
				},
				second_signer_default = {
					authorityArea: '6',
					baseAuthory: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default'),
					baseOrgAuthory: '',
					state: '1'
				};

			if (data && data.user && data.user.id) {
				clearSecondSignerBtn.setDisabled(false);
				edi.rest.sendRequest(
					edi.rest.services.SIGNER.SIGNERS.GET,
					'GET',
					{
						direction: edi.constants.DIRECTIONS.OUTBOX,
						userId: data.user.id,
						docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
					},
					function (headerData) {
						let prop, elm;
						if (signerSecond && signerSecond.length) {
							for (prop in mapper) {
								for (let i = 0; i < signerSecond.length; i++) {
									if (mapper.hasOwnProperty(prop)) {
										if (signerSecond[i] instanceof UI.components.FieldBlock) {
											elm = signerSecond[i].down('[name=' + mapper[prop] + ']');
											if (elm) {
												elm.setValue(edi.utils.getObjectProperty(data, prop));
												break;
											}
										}
									}
								}
							}
							if (headerData && headerData.items) {
								const itemsLen = headerData.items.length;
								let item;
								if (itemsLen) {
									item = headerData.items[0]; // must be One item or Zero
								}
								isFillingFromBackend = true;
								const fillOrder = ['authorityArea', 'state', 'baseAuthory', 'baseOrgAuthory'];
								for (let k = 0; k < fillOrder.length; ++k) {
									if (second_signer_default.hasOwnProperty(fillOrder[k])) {
										prop = fillOrder[k];
										for (let j = 0; j < signerSecond.length; j++) {
											if (signerSecond[j] instanceof UI.components.FieldBlock) {
												elm = signerSecond[j].down('[name=' + 'signers.' + prop + ']');
												if (elm) {
													if (item && item.hasOwnProperty(prop)) {
														elm.setValue(item[prop]);
													} else if (null !== second_signer_default[prop]) {
														elm.setValue(second_signer_default[prop]);
													}
													break;
												}
											}
										}
									}
								}
								isFillingFromBackend = false;
							}
						}
					},
					undefined
				);
			}
			moduleData.tab.setLoading(false);
			modal.close();
		};

		const container = createModalForm({
			items: [
				createGrid({
					proxyConfig: {
						type: 'ajax',
						url: edi.utils.formatString(edi.rest.services.USER.CERTIFICATE.CERTIFICATES.ACTIVE.GET, {
							type: 'EDI_FNS_UKD'
						}),
						extraParams: {
							excludeCurrentUser: true
						}
					},
					storeConfig: {
						model: edi.models.getModel('USER_CERT'),
						remoteSort: true,
						sortOnLoad: true,
						sorters: {
							property: 'id',
							direction: 'ASC'
						}
					},
					gridConfig: {
						maxHeight: 500,
						columns: edi.columns.get('user_cert'),
						disablePaging: false,
						disableSelection: false,
						enableTextSelection: true,
						checkboxes: false,
						listeners: {
							select: function (comp, record) {
								rowClick(record);
							}
						},
						dockedItems: undefined
					}
				})
			]
		});

		let modal = createModalPanel({
			cls: 'edi-modal-documents-selector',
			title: edi.i18n.getMessage('btn.tooltip.secondSigner'),
			width: MODAL_SIZE.widthLarge,
			items: [container],
			buttonsBefore: []
		});
		modal.show();
	};

	const createSignerFields = function (signer, isSecond, signerTitle) {
		const hasPermission =
			edi.permissions.hasPermission('READ_CERTIFICATE_DOCUMENT') &&
			edi.permissions.hasPermission('CLIENT_READ_EDI_SIGNER');

		clearSecondSignerBtn = hasPermission
			? createButton({
					cls: [BUTTON_CLS.light, BUTTON_CLS.small],
					tooltip: edi.i18n.getMessage('btn.tooltip.reset'),
					text: '',
					disabled: true,
					glyph: edi.constants.ICONS.HIGHLIGHT_OFF,
					handler: clearSecondSigner
			  })
			: null;

		const topText = !!isSecond
			? createLabelWithIcon({
					padding: 16,
					text: edi.i18n.getMessage('documents.fns_upd.signer.beforeSecondSigner'),
					glyph: edi.constants.ICONS.REJECT
			  })
			: null;

		const getSecondSignerBtn = !!isSecond
			? createButton({
					width: 200,
					margin: '8 0 16 0',
					cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
					tooltip: edi.i18n.getMessage('btn.tooltip.secondSigner'),
					text: edi.i18n.getMessage('form.btn.select.product'),
					disabled: false,
					glyph: edi.constants.ICONS.SEARCH,
					handler: showModalListOfSecondSigner
			  })
			: null;

		const baseAuthory = createContainer({
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
					maxLength: 255,
					allowBlank: false,
					name: 'signers.baseAuthory',
					value: edi.utils.getObjectProperty(signer, 'baseAuthory')
				})
			]
		});

		const baseOrgAuthory = createContainer({
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
					maxLength: 255,
					allowBlank: '3' != edi.utils.getObjectProperty(signer, 'state'),
					name: 'signers.baseOrgAuthory',
					value: edi.utils.getObjectProperty(signer, 'baseOrgAuthory'),
					validator: function (value) {
						if (!value) {
							return true;
						}
						return (
							value === '' ||
							!edi.constants.VALIDATORS.SPACES.test(value) ||
							edi.i18n.getMessage('invalid.value.format')
						);
					}
				})
			]
		});
		//Данные сохраненного серта всегда хранятся в signer.legalEntity (см fillUPDSignerValues)
		//поэтому если есть сохраненный серт заполняем данными из legalEntity иначе данными из документа-основания signerType
		const signerValueKey = Ext.Object.isEmpty(signerFillFromCert) ? signerType : 'legalEntity';
		const isSignerValueNotLegalEntity = ['individualPerson', 'individual'].some((type) => type === signerValueKey);
		const signerPositionCombo = !isSignerValueNotLegalEntity
			? createCombo({
					store: edi.stores.initPositionsStore(),
					displayField: 'shortName',
					autoSelect: true,
					forceSelection: false,
					maxLength: 128,
					allowBlank: false,
					qtipText: signerFillFromCert.position
						? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
						: edi.i18n.getMessage('document.signer.get.data.from.cert'),
					readOnly: !isSecond,
					fieldLabel: edi.i18n.getMessage('signer.job.position'),
					name: `signers.${signerType}.position`,
					value: edi.utils.getObjectProperty(signer, `${signerValueKey}.position`)
			  })
			: null;
		const signerSection = createContainer({
			layout: {
				type: 'grid',
				area: [[0, 3, 3, 2, 2]]
			},
			items: [
				createHiddenField({
					value: signerFillFromCert.inn
						? signerFillFromCert.inn.slice(0, 2) == '00'
							? signerFillFromCert.inn.slice(2, 12)
							: signerFillFromCert.inn
						: edi.utils.getObjectProperty(signer, `${signerValueKey}.tin`) ?? userData.org.inn,
					name: `signers.${signerType}.tin`
				}),
				signerPositionCombo,
				createTextField({
					allowBlank: false,
					qtipText: signerFillFromCert.lastName
						? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
						: edi.i18n.getMessage('document.signer.get.data.from.cert'),
					readOnly: !isSecond,
					fieldLabel: edi.i18n.getMessage('last.name'),
					// бэк в УПД присылает ФИО в individual.name, но сохранять в УКД нужно в individual.nameType
					value:
						edi.utils.getObjectProperty(
							signer,
							`${signerValueKey}.${signerTypeFIOMap[signerValueKey]}.lastName`
						) || edi.utils.getObjectProperty(signer, 'individual.name.lastName'),
					name: `signers.${signerType}.${signerTypeFIOMap[signerType]}.lastName`
				}),
				createTextField({
					allowBlank: false,
					maxLength: 60,
					qtipText: signerFillFromCert.firstName
						? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
						: edi.i18n.getMessage('document.signer.get.data.from.cert'),
					readOnly: !isSecond,
					fieldLabel: edi.i18n.getMessage('first.name'),
					// бэк в УПД присылает ФИО в individual.name, но сохранять в УКД нужно в individual.nameType
					value:
						edi.utils.getObjectProperty(
							signer,
							`${signerValueKey}.${signerTypeFIOMap[signerValueKey]}.firstName`
						) || edi.utils.getObjectProperty(signer, 'individual.name.firstName'),
					name: `signers.${signerType}.${signerTypeFIOMap[signerType]}.firstName`
				}),
				createTextField({
					allowBlank: true,
					qtipText: signerFillFromCert.middleName
						? edi.i18n.getMessage('document.signer.get.data.from.active.cert')
						: edi.i18n.getMessage('document.signer.get.data.from.cert'),
					readOnly: !isSecond,
					fieldLabel: edi.i18n.getMessage('patronymic.name'),
					// бэк в УПД присылает ФИО в individual.name, но сохранять в УКД нужно в individual.nameType
					value:
						edi.utils.getObjectProperty(
							signer,
							`${signerValueKey}.${signerTypeFIOMap[signerValueKey]}.middleName`
						) || edi.utils.getObjectProperty(signer, 'individual.name.middleName'),
					name: `signers.${signerType}.${signerTypeFIOMap[signerType]}.middleName`
				})
			]
		});

		const certificateRegistrationIP =
			signerType === 'individualPerson'
				? createContainer({
						layout: {
							type: 'grid',
							area: [10]
						},
						items: [
							createTextField({
								fieldLabel: edi.i18n.getMessage('individual.certificateRegistration'),
								maxLength: 255,
								name: 'signers.individualPerson.certificateRegistrationIP',
								value:
									edi.utils.getObjectProperty(signer, 'individualPerson.certificateRegistrationIP') ||
									edi.utils.getObjectProperty(signer, 'individual.certificateRegistrationIP')
							})
						]
				  })
				: null;

		const areasData = edi.stores.getStoreItemsByIds(edi.stores.data.authorityAreas, availableAuthotiryAreas),
			authorityAreaStore = edi.stores.createInlineStore(areasData, 'AUTHORITY_AREA');

		const authorityArea = createCombo({
			name: 'signers.authorityArea',
			fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
			store: authorityAreaStore,
			displayField: 'name',
			value: edi.utils.getObjectProperty(signer, 'authorityArea'),
			allowBlank: false,
			listeners: !isSecond
				? {
						change: function (comp) {
							if (!isSecond) {
								checkSignersFields(comp, createSignerFields);
							}
						}
				  }
				: undefined,
			forceSelection: true
		});
		if (!isSecond) {
			firstAuthorityField = authorityArea;
			authorityArea.on('select', function (comp) {
				if (secondAuthorityField && comp.getValue() == secondAuthorityField.getValue()) {
					secondAuthorityField.setValue('');
				}
			});
		} else {
			secondAuthorityField = authorityArea;
			authorityArea.on('expand', function (comp) {
				let store = comp.getStore(),
					selectedFirtsAuthority = firstAuthorityField.getValue(),
					secondAuthorityAreas = availableAuthotiryAreas.filter(function (area) {
						return area != selectedFirtsAuthority;
					});

				store.loadData(edi.stores.getStoreItemsByIds(edi.stores.data.authorityAreas, secondAuthorityAreas));
			});
		}

		const signerStateStore = edi.stores.createInlineStore(edi.stores.data.signerStatuses, 'AUTHORITY_AREA');
		const authorityAreaAndStatus = createContainer({
			layout: {
				type: 'grid',
				area: [[5, 5]]
			},
			items: [
				authorityArea,
				createCombo({
					name: 'signers.state',
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.signer.status'),
					store: signerStateStore,
					displayField: 'name',
					value: signer.state,
					allowBlank: false,
					forceSelection: true,
					plugins: [
						{
							ptype: PTYPE_COMBO_ITEMS_DISABLEABLE,
							disabledField: 'disabled'
						}
					],
					listeners: {
						change: function (comp) {
							const value = comp.getValue();
							let baseAuthoryInput = baseAuthory.down('[name=signers.baseAuthory]');
							let baseOrgAuthoryInput = baseOrgAuthory.down('[name=signers.baseOrgAuthory]');
							if (value && baseAuthoryInput) {
								baseOrgAuthoryInput.allowBlank = value != '3';
								baseOrgAuthoryInput.isValid();

								if (!isFillingFromBackend) {
									if (['1', '2', '3'].indexOf(value) > -1) {
										baseAuthoryInput.setValue(
											edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default')
										);
									} else {
										baseAuthoryInput.setValue(
											edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default.state.4')
										);
									}
								}
								checkValid();
							}
						}
					}
				})
			]
		});

		const baseAuthoryFields = createContainer({
			layout: {
				type: 'grid',
				area: [[5, 5]]
			},
			items: [baseAuthory, baseOrgAuthory]
		});

		return [
			topText,
			createFieldBlock({
				title: signerTitle ?? edi.i18n.getMessage('company.signer'),
				layout: {
					type: 'grid',
					gap: 16
				},
				items: [
					getSecondSignerBtn,
					signerSection,
					authorityAreaAndStatus,
					certificateRegistrationIP,
					baseAuthoryFields
				]
			})
		];
	};

	const createSignerItems = function () {
		signerContainer = createContainer({
			margin: '32 0 0 0',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: 16
			}
		});

		//signerContainer.rowsContainer = signerContainer.items.items[0];
		const isKSCHF_DIS = isKSCHF_DIS_BussinessProcess(bpName);

		const signer = edi.utils.getObjectProperty(documentData, 'signers');
		if (signer && Array.isArray(signer) && signer.length > 0) {
			let signersLength = signer.length;
			if (
				signersLength === 2 &&
				isKSCHF_DIS &&
				['5', '6'].indexOf(edi.utils.getObjectProperty(signer[0], 'authorityArea')) > -1
			) {
				signersLength = 1;
			}
			for (let i = 0; i < signersLength; i++) {
				const signerTitle =
					signersLength > 1 ? `${edi.i18n.getMessage('company.signer')}` + (i > 0 ? ' 2' : '') : null;
				let fields = signerContainer.add(createSignerFields(signer[i], !!i, signerTitle));
				if (!!i) {
					signerSecond = fields;
				}
			}
			if (
				signer.length === 1 &&
				isKSCHF_DIS &&
				['0', '3'].some((it) => it === edi.utils.getObjectProperty(signer[0], 'authorityArea'))
			) {
				signerSecond = signerContainer.add(
					createSignerFields({}, true, edi.i18n.getMessage('company.signer') + ' 2')
				);
			}
		} else {
			signerContainer.add(createSignerFields({}, false));
		}

		signerContainer.layout.redoLayout();
		return signerContainer;
	};

	const checkSignersFields = function (field, addSignerFieldsHandler) {
		const value = field.getValue();
		if (
			['0', '1', '2', '3', '4'].indexOf(value) > -1 &&
			(bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHFDIS ||
				bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DIS_DECREE_14 ||
				bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_KSCHFDIS_BPV4)
		) {
			if (!signerSecond?.length && addSignerFieldsHandler) {
				signerSecond = signerContainer.add(
					addSignerFieldsHandler({}, true, edi.i18n.getMessage('company.signer') + ' 2')
				);
				signerContainer.layout.redoLayout();
			}
		} else if (signerSecond?.length) {
			for (let i = 0; i < signerSecond.length; i++) {
				signerContainer.remove(signerSecond[i]);
			}
			signerSecond = null;
			secondAuthorityField = null;
			signerContainer.layout.redoLayout();
		}
		checkValid();
	};

	/**
	 * Creates company.selector with default params
	 * @param	{Object}	selectorConfig
	 * @returns	{Object}	company.selector instance
	 */
	const createOS = function (selectorConfig) {
		let modalConf = edi.selectors.getFNSModalConf({
			main: {
				addr_for_text: {
					maxLength: 1000
				}
			},
			contact: undefined
		});

		Ext.merge(modalConf, {
			tabs: {
				main: {
					company_data: {
						fields: {
							company_gln: undefined,
							company_okpo: undefined
						}
					},
					structural_subdivision: undefined
				}
			}
		});

		Ext.applyIf(selectorConfig, {
			margin: 0,
			cls: '',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			useHiddenFields: true,
			disableAutoValidation: true,
			forceChangeAnyField: isBasedOnRetann,
			modalConf,
			readOnly: !isBasedOnRetann
		});

		return createOrgSelector(selectorConfig);
	};

	let isKSCHF = function (bpName) {
		return (
			bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF ||
			bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14 ||
			bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISRK_DECREE_14 ||
			bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISZK_DECREE_14
		);
	};

	/**
	 * Create invoice form
	 */
	const createInvoiceFormItems = function () {
		const title = createLabel({
			cls: 'heading_02',
			text: edi.i18n.getMessage('document.add.fns_ukd_5_01n')
		});
		const invoiceInfoData = edi.utils.getObjectProperty(documentData, 'invoiceCorrectionInfo.invoiceInfo');
		const invoiceMainData = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_ukd.type.' + bpName),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[4, 2]]
			},
			items: [
				createTextField({
					fieldLabel: isNumberAutoGenerated
						? edi.i18n.getMessage('document.generation.numbers.text')
						: edi.i18n.getMessage('document.form.number'),
					allowBlank: isNumberAutoGenerated,
					disabled: isNumberAutoGenerated,
					maxLength: 35,
					readOnly: isCorrect,
					value:
						isEdit || isCorrect || isCopy
							? edi.utils.getObjectProperty(
									documentData,
									'invoiceCorrectionInfo.invoiceCorrectionNumber'
							  ) + (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
							: undefined,
					name: 'invoiceCorrectionInfo.invoiceCorrectionNumber'
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					allowBlank: false,
					readOnly: isCorrect,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					minValue: Array.isArray(invoiceInfoData)
						? edi.utils.getObjectProperty(invoiceInfoData[0], 'date')
						: undefined,
					value:
						isEdit || isCorrect || isCopy
							? edi.utils.getObjectProperty(documentData, 'invoiceCorrectionInfo.invoiceCorrectionDate')
							: new Date(),
					name: 'invoiceCorrectionInfo.invoiceCorrectionDate'
				})
			]
		});

		let correctionNumber = edi.utils.getObjectProperty(
			documentData,
			'invoiceCorrectionInfo.editedInvoiceCorrectionInfo.numberEditedInvoiceCorrectionInfo'
		);
		const correctUkdField = isCorrect
			? createFieldBlock({
					title: edi.i18n.getMessage('documents.fns_ukd.editedInvoiceCorrectionInfo'),
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					layout: {
						type: 'grid',
						area: [[4, 2]]
					},
					items: [
						createNumberField({
							fieldLabel: edi.i18n.getMessage('document.form.number'),
							maxLength: 3,
							allowBlank: !isSCHFDOP_DIS_BussinessProcess(bpName),
							value: isEdit ? correctionNumber : correctionNumber + 1,
							name: 'invoiceCorrectionInfo.editedInvoiceCorrectionInfo.numberEditedInvoiceCorrectionInfo'
						}),
						createDateField({
							fieldLabel: edi.i18n.getMessage('date'),
							allowBlank: !isSCHFDOP_DIS_BussinessProcess(bpName),
							value: isEdit
								? edi.utils.getObjectProperty(
										documentData,
										'invoiceCorrectionInfo.editedInvoiceCorrectionInfo.dateEditedInvoiceCorrectionInfo'
								  )
								: new Date(),
							submitFormat: edi.constants.DATE_FORMAT.FNS,
							name: 'invoiceCorrectionInfo.editedInvoiceCorrectionInfo.dateEditedInvoiceCorrectionInfo'
						})
					]
			  })
			: null;

		const invoiceInfoDataNumber = Array.isArray(invoiceInfoData)
			? edi.utils.getObjectProperty(invoiceInfoData[0], 'number')
			: undefined;
		const invoiceInfoToUpd = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_ukd.invoiceInfoToUpd'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [[4, 2]]
			},
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.form.number'),
					readOnly: !isCreateOnBasis && !invoiceInfoDataNumber,
					maxLength: 35,
					value: invoiceInfoDataNumber,
					allowBlank: false,
					name: 'invoiceCorrectionInfo.invoiceInfo.number'
				}),
				createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					allowBlank: false,
					readOnly: !isCreateOnBasis,
					value: Array.isArray(invoiceInfoData)
						? edi.utils.getObjectProperty(invoiceInfoData[0], 'date')
						: undefined,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					name: 'invoiceCorrectionInfo.invoiceInfo.date'
				})
			]
		});

		const toCorrectedUpd = function (correctInfo) {
			let items = [];
			if (correctInfo && Array.isArray(correctInfo) && correctInfo.length > 0) {
				for (let i = 0; i < correctInfo.length; i++) {
					items.push(
						createFieldBlock({
							title: edi.i18n.getMessage('documents.fns_ukd.invoicesCorrectInfo'),
							userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
							layout: {
								type: 'grid',
								area: [[4, 2]]
							},
							items: [
								createNumberField({
									fieldLabel: edi.i18n.getMessage('document.form.number'),
									readOnly: true,
									maxLength: 3,
									value: edi.utils.getObjectProperty(correctInfo[i], 'number'),
									name: 'invoiceCorrectionInfo.invoiceInfo.invoicesCorrectInfo.number'
								}),
								createDateField({
									fieldLabel: edi.i18n.getMessage('date'),
									readOnly: true,
									value: edi.utils.getObjectProperty(correctInfo[i], 'date'),
									submitFormat: edi.constants.DATE_FORMAT.FNS,
									name: 'invoiceCorrectionInfo.invoiceInfo.invoicesCorrectInfo.date'
								})
							]
						})
					);
				}
			}

			return items;
		};

		const invoiceCurrency = createContainer({
			layout: {
				type: 'grid',
				area: [4]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createCombo({
					fieldLabel: edi.i18n.getMessage('invoice.currency'),
					value:
						edi.utils.getObjectProperty(documentData, 'invoiceCorrectionInfo.okv') ||
						edi.utils.getOkv({
							charCode: edi.constants.DEFAULT.CURRENCY
						}).code,
					name: 'invoiceCorrectionInfo.okv',
					store: edi.stores.initOkvCurrencyStore(),
					anyMatch: true,
					displayField: 'displayName',
					valueField: 'codeStr',
					readOnly: true,
					allowBlank: false
				})
			]
		});

		const economiclifeDocName = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_ukd.economiclifeDocName'),
			hidden: isKSCHF(bpName),
			layout: {
				type: 'grid',
				area: [6]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createTextField({
					isTextarea: true,
					maxLength: 255,
					minHeight: 68,
					value:
						edi.utils.getObjectProperty(documentData, 'economiclifeDocName') ||
						edi.i18n.getMessage('documents.fns_ukd.economiclifeDocName.text'),
					name: 'economiclifeDocName'
				})
			]
		});

		const primaryDocName = createFieldBlock({
			hidden: isKSCHF(bpName),
			title: edi.i18n.getMessage('documents.fns_ukd.primaryDocName'),
			layout: {
				type: 'grid',
				area: [6]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createTextField({
					isTextarea: true,
					minHeight: 68,
					maxLength: 255,
					value:
						edi.utils.getObjectProperty(documentData, 'primaryDocName') ||
						edi.i18n.getMessage('documents.fns_ukd.economiclifeDocName.text'),
					name: 'primaryDocName'
				})
			]
		});

		const govAgreementId = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.govAgreementId'),
			layout: {
				type: 'grid',
				area: [4]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createTextField({
					allowBlank: true,
					readOnly: true,
					minLength: 20,
					maxLength: 25,
					value: edi.utils.getObjectProperty(
						documentData,
						'invoiceCorrectionInfo.additionalInfo.govagreementid'
					),
					name: 'invoiceCorrectionInfo.additionalInfo.govagreementid'
				})
			]
		});

		const orgNames = ['invoiceCorrectionInfo.sellerInfo', 'invoiceCorrectionInfo.buyerInfo'];
		orgNames.forEach(function (org) {
			if (Array.isArray(edi.utils.getObjectProperty(documentData, org))) {
				const orgInfo = edi.utils.getObjectProperty(documentData, org + '.0');
				edi.utils.setObjectProperty(documentData, org, orgInfo);
			}
		});

		const userOrganization = edi.converters.convertOrgToPartie(userData.org);
		const sellerInfo = edi.utils.getObjectProperty(documentData, 'invoiceCorrectionInfo.sellerInfo');
		const isSellerDefined = !!sellerInfo;
		const isValidSeller = isValidOrganization({
			organization: sellerInfo || userOrganization,
			useOrganizationMap: isSellerDefined,
			organizationType: 'seller'
		});

		const sellerID =
			moduleData.initData.meta?.fromOrg?.id ?? moduleData.initData.data?.buyerOrg?.id ?? userData.org.id;
		seller = createOS({
			itemId: 'seller',
			is_valid: isValidSeller,
			fieldValues: isSellerDefined ? documentData : userOrganization,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: sellerID
			}),
			selectedOrgValues: isSellerDefined ? documentData : userOrganization,
			valuesByMap: isSellerDefined,
			callback: function (values) {
				seller.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'seller'
				});
				checkValid();
			},
			fieldsMap: edi.selectors.getUPDFieldsMap('invoiceCorrectionInfo.sellerInfo', false, false, true)
		});

		const buyerInfo = edi.utils.getObjectProperty(documentData, 'invoiceCorrectionInfo.buyerInfo');
		const isBuyerDefined = !!buyerInfo;
		const isValidBuyer = isValidOrganization({
			organization: buyerInfo,
			useOrganizationMap: isBuyerDefined,
			organizationType: 'buyer'
		});

		const buyerID = moduleData.initData.meta?.toOrg?.id ?? moduleData.initData.data?.toOrg?.id ?? buyerOrg?.id;
		buyer = createOS({
			itemId: 'buyer',
			relationsOnly: true,
			allowBlank: false,
			valuesByMap: true,
			is_valid: isValidBuyer,
			relations: isBasedOnRetann
				? undefined
				: !isEdit && !isCopy && buyerOrg
				? buyerOrg
				: edi.relations.getRelations(),
			allowReset: !isEdit && !isCopy && !isBuyerDefined,
			fieldValues: documentData,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: buyerID
			}),
			selectedOrgValues:
				!isEdit && !isCopy && buyerOrg ? buyerOrgValues : isBuyerDefined ? documentData : undefined,
			selectedOrg: !(isEdit && !hasDataFromTransformation) && !isCopy && buyerOrg ? buyerOrg : null,
			selectedRelationByMap: (isEdit && !isCopy) || buyerOrg,
			callback: function (values, org) {
				const orgId = org ? org.id : null;
				if (orgId && (!buyerOrg || buyerOrg.id != orgId)) {
					buyerOrg = org;
				} else if (!orgId) {
					buyerOrg = null;
				}

				getOrganizationAdditionalFields(buyerOrg ? buyerOrg.id : null);

				buyer.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'buyer'
				});
				checkValid();
			},
			fieldsMap: edi.selectors.getUPDFieldsMap('invoiceCorrectionInfo.buyerInfo', false, false, true)
		});

		const organizationsColumnsLayout = createPanel({
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[6, 6]]
			},
			items: [
				createFieldBlock({
					title: edi.i18n.getMessage('document.seller'),
					items: [seller]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('document.buyer'),
					items: [buyer]
				})
			]
		});

		return [].concat(
			title,
			invoiceMainData,
			correctUkdField,
			invoiceInfoToUpd,
			toCorrectedUpd(
				Array.isArray(invoiceInfoData) && invoiceInfoData[0] ? invoiceInfoData[0].invoicesCorrectInfo : null
			),
			invoiceCurrency,
			economiclifeDocName,
			primaryDocName,
			govAgreementId,
			organizationsColumnsLayout
		);
	};

	const createInvoiceTableItem = function () {
		/**
		 * Calculates price and tax summary according to tax recalculation method
		 * @param    {Object}     values              product data
		 * @param    {Object}     calcFieldsData      object with additional data that could override/extend values returned by gatherValuesRow
		 * @param    {Boolean}    roundResults        true to round calculated values
		 * @returns {*}
		 */
		let calculateValues = function (values, calcFieldsData, roundResults) {
				let calcData = edi.methods.product.price.recalculation(
					calcFieldsData,
					taxCalculationMethod,
					undefined,
					calcFieldsData.amount === 0,
					edi.constants.UKD_PRICE_PRECISION
				);
				values.Cost = calcData.netPrice;
				values.CostWithoutNDS = calcData.netSum;
				values.NDSCost = calcData.taxSum;
				values.CostWithNDS = calcData.grossSum;
				roundResults
					? (values = edi.methods.product.grid.roundValues(
							values,
							['Cost', 'CostWithoutNDS', 'NDSCost', 'CostWithNDS'],
							edi.constants.UKD_PRICE_PRECISION
					  ))
					: null;
				return values;
			},
			i,
			columnsRegen;

		if ((isEdit && !isCreate) || (isCreateOnBasis && isCreate) || isCorrect || isCopy) {
			let products = edi.utils.getObjectProperty(documentData, 'cinvoiceTable.productInfo'),
				lines = [];
			for (i = 0; i < products.length; i++) {
				const values = Ext.clone(products[i]);
				edi.document.actions.setInternalLineId(products[i], values);
				lines.push(values);
			}
			productValues = edi.converters.convertUKDProductToUKDGridValues(
				edi.utils.getObjectProperty(documentData, 'cinvoiceTable'),
				lines,
				'5.01-N'
			);
		}

		const gridTotalData = {
			Increase: productValues.totalIncrease,
			Decrease: productValues.totalDecrease
		};

		if (infoColumnsConfig) {
			const newConfig = edi.methods.createInfoColumns(infoColumnsConfig, function (value, metaData, record) {
				const arrInf = record.get('InfPolStr');
				if (Array.isArray(arrInf) && arrInf.length) {
					let i, elm;
					for (i = 0; i < arrInf.length; i++) {
						elm = arrInf[i];
						if (elm.Identif && elm.Identif === metaData.column.infoId) {
							return elm.Znachen;
						}
					}
				}
				return '';
			});
			const valuesColumnConfigurator = function (config) {
				Ext.merge(config, newConfig);
				return config;
			};
			columnsRegen = edi.columns.get('fns_ukd_lines_5_01n', function (config) {
				return valuesColumnConfigurator(config);
			});
		}

		const columnConfig = edi.columns.get('fns_ukd_lines_5_01n', function (config) {
			const isMandatoryCostWithoutNDS = !(
				gridTotalData.Increase.TotalWithNDS == 0 || gridTotalData.Decrease.TotalWithNDS == 0
			);

			Object.entries(config).forEach(function ([key, value]) {
				if (key === 'costWithoutNDS') {
					value.allowBlank = isMandatoryCostWithoutNDS && !isSCHFDOP_DIS_BussinessProcess(bpName);
					value.editor.allowBlank = !isSCHFDOP_DIS_BussinessProcess(bpName);
				}
				if (key === 'costWithVat') {
					value.allowBlank = isSCHFDOP_DIS_BussinessProcess(bpName);
					value.editor.allowBlank = isSCHFDOP_DIS_BussinessProcess(bpName);
				}
			});
			return config;
		});

		let grid = createEditableProductsTreeGrid({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			totalsGridHeaderLabel: 'grid.title.total',
			gridModel: 'FNS_UKD_PRODUCT',
			lineModel: 'FNS_UKD_LINE',
			gridColumnConfig: infoColumnsConfig ? columnsRegen : columnConfig,
			totalModel: 'FNS_UKD_SUMMARY',
			margin: '24 0',
			totalColumnConfig: 'corrected_fns_invoice_summary',
			totalData: gridTotalData,
			lineNumberFieldName: 'lineNumInvoice',
			callback: function () {
				moduleData.isChanged = true;
				grid.getView().refresh();
				checkValid();
			},
			afterRowEdit: function (values, fieldName) {
				if (['Count', 'Cost', 'TaxRate'].some((it) => it === fieldName)) {
					const data = edi.methods.product.grid.gatherValuesRow(
						values,
						edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
					);
					calculateValues(
						values,
						{
							taxRate: data.rateVal,
							amount: parseFloat(values.Count),
							netPrice: parseFloat(values.Cost),
							netSum: parseFloat(values.CostWithoutNDS),
							taxSum: parseFloat(values.NDSCost),
							grossSum: parseFloat(values.CostWithNDS)
						},
						true
					);
				}
			},
			afterTotalRowEdit: function (values) {
				const isMandatoryCostWithoutNDS = values.TotalWithNDS !== 0;

				productsGrid.columns.forEach(function (field) {
					if (field.cName === 'CostWithoutNDS') {
						field.allowBlank = isMandatoryCostWithoutNDS && !isSCHFDOP_DIS_BussinessProcess(bpName);
						field.editor.allowBlank = isMandatoryCostWithoutNDS && !isSCHFDOP_DIS_BussinessProcess(bpName);
						grid.getView().refresh();
					}
				});
			},
			isValidRecord: function (line) {
				let state = line.get('State'),
					valid = line.childNodes.length > 1 || line.get('Count') >= 0;

				if (!isSCHFDOP_DIS_BussinessProcess(bpName)) {
					const configFields = [
						{
							defField: 'defCostBeforeEdit',
							state: 'OriginalData'
						},
						{
							defField: 'defCostAfterEdit',
							state: 'NewData'
						},
						{
							defField: 'defCostIncreased',
							state: 'Increase'
						},
						{
							defField: 'defCostReduced',
							state: 'Decrease'
						}
					];
					configFields.forEach(function (item) {
						if (state === item.state) {
							if (line.get(item.defField) !== undefined) {
								line.set(
									item.defField,
									line.get('CostWithNDS') !== null && line.get('CostWithNDS').length ? '' : '-'
								);
								valid = !!line.get('CostWithoutNDS')
									? !!line.get('CostWithoutNDS')
									: (line.get('CostWithNDS') !== null && line.get('CostWithNDS').length) ||
									  line.get(item.defField) === '-';
							}
						}
					});
				}

				if (state === 'NewData') {
					valid = valid && grid.hasChangedProducts();
				}
				return valid;
			},
			productDetailsHandler: function (record) {
				let infoGrid,
					infoTrackingGrid,
					identifyProductToolNumbersBeforeGrid,
					identifyProductToolNumbersAfterGrid,
					lineModalFormConfig = [
						createTextField({
							fieldLabel: edi.i18n.getMessage('line.item.additional.productDesc'),
							minLength: 1,
							maxLength: 1000,
							allowBlank:
								!!record.get('productCode') ||
								!!record.get('ProductName') ||
								!!record.get('productReference'),
							name: 'productDesc',
							value: record.get('productDesc')
						}),
						createTextField({
							fieldLabel: edi.i18n.getMessage('line.item.additional.productSort'),
							minLength: 1,
							maxLength: 10,
							name: 'productSort',
							value: record.get('productSort')
						}),
						createTextField({
							fieldLabel: edi.i18n.getMessage('line.item.additional.productReference'),
							minLength: 1,
							maxLength: 50,
							allowBlank:
								!!record.get('productCode') ||
								!!record.get('ProductName') ||
								!!record.get('productDesc'),
							name: 'productReference',
							value: record.get('productReference')
						}),
						createTextField({
							fieldLabel: edi.i18n.getMessage('line.item.additional.productCode'),
							minLength: 1,
							maxLength: 100,
							allowBlank:
								!!record.get('productReference') ||
								!!record.get('ProductName') ||
								!!record.get('productDesc'),
							name: 'productCode',
							value: record.get('productCode')
						}),
						createTextField({
							fieldLabel: edi.i18n.getMessage('line.item.additional.productTypeCode'),
							minLength: 10,
							maxLength: 10,
							name: 'productTypeCode',
							value: record.get('productTypeCode')
						})
					];

				const renderIdentifyProductToolNumbers = function (comp) {
					let formValues = identifyProductToolNumbersAfterGrid.getModalForm().getValues();
					edi.utils.clearEmptyValues(formValues);
					if (Object.keys(formValues).length === 0) {
						comp.getFieldBlock().setVisible(true);
						return null;
					}
					//const packId = formValues?.packId ?? null;
					//comp.up().setVisible(!!packId);
				};

				const setNumbersField = function (field, value, itemId) {
					const form = field.up('panel'),
						numbersField = form && form.down ? form.down('[itemId="numbers"]') : null,
						itemIdField = form && form.down ? form.down(`[itemId="${itemId}"]`) : null,
						gtin = itemId === 'purchaseQuantity' ? value : itemIdField.getValue(),
						purchaseQuantity = itemId === 'purchaseQuantity' ? itemIdField.getValue() : value;

					if (!gtin || !purchaseQuantity) {
						numbersField.setValue([]);
						return;
					}
					numbersField.setValue(['02' + gtin + '37' + purchaseQuantity]);
				};

				const renderUseSortAccounting = function (comp) {
					comp.getFieldBlock().setVisible(false);
				};

				const isSortAccounting = function (form) {
					const value = Ext.clone(form.getValues());
					const isSortAccountingNumber =
						value?.numbers?.length === 1 && !!value?.numbers[0]?.match(/02[0-9]{14}37[0-9]*$/);

					if (isSortAccountingNumber) {
						//ОСУ = [02]GTIN[37]purchaseQuantity
						form.down('[itemId="sortAccounting"]').setValue(!!isSortAccountingNumber);
						const sortAccountingValue = value.numbers[0];
						form.down('[name="GTIN"]').setValue(sortAccountingValue.slice(2, 16));
						form.down('[name="purchaseQuantity"]').setValue(sortAccountingValue.slice(18));
					}
				};

				const checkExistSortAccounting = function () {
					const gridValues = Ext.clone(identifyProductToolNumbersAfterGrid?.getStore()?.getRange());

					return !!gridValues?.find((identifyProductToolNumber) => {
						const numbersArr = identifyProductToolNumber?.getData()?.numbers;
						return numbersArr.length === 1 && !!numbersArr[0].match(/02[0-9]{14}37[0-9]*$/);
					});
				};

				infoGrid = createEditableGrid(
					{
						fields: [
							{
								name: 'Identif',
								type: 'text',
								allowBlank: false
							},
							{
								name: 'Znachen',
								type: 'text',
								allowBlank: false
							}
						],
						limit: 20,
						readOnly: false,
						model: 'FIELD_LINE_INFORMATION',
						modalTitle: edi.i18n.getMessage('line.item.tab.inf.pol.str'),
						gridConfig: {
							autoScroll: true,
							maxHeight: 234
						}
					},
					Ext.clone(record.get('InfPolStr'))
				);

				const tabPanel = createTabPanel({
					items: [
						createTab({
							// Дополнительные данные
							title: edi.i18n.getMessage('line.item.tab.additional.data'),
							closable: false,
							autoScroll: true,
							items: createFormContainer({
								padding: 24,
								gap: [24, 16],
								area: [8, 4, 4, [4, 4]],
								items: lineModalFormConfig
							})
						}),
						createTab({
							// Информационное поле
							padding: 24,
							title: edi.i18n.getMessage('line.item.tab.inf.pol.str'),
							closable: false,
							items: [infoGrid]
						}),
						createTab({
							// Сведения о прослеживаемости
							padding: 24,
							title: edi.i18n.getMessage('line.item.infoTracking'),
							closable: false,
							items: [
								(infoTrackingGrid = createEditableGrid(
									{
										fields: [
											{
												title: edi.i18n.getMessage('line.item.infoTracking.trackingProdNum'),
												name: 'trackingProdNum',
												type: 'text',
												allowBlank: false,
												readOnly: true,
												maxLength: 29
											},
											{
												store: edi.stores.initOkeiStore(),
												title: edi.i18n.getMessage('line.item.infoTracking.trackingUnitMes'),
												name: 'trackingUnitMes',
												type: 'combo',
												readOnly: true,
												forceSelection: true,
												anyMatch: true,
												valueField: 'id',
												displayField: 'name_code',
												allowBlank: false
											},
											{
												title: edi.i18n.getMessage(
													'line.item.infoTracking.trackingProdQuanBefore'
												),
												type: 'text',
												readOnly: true,
												disableIfReadonly: false,
												name: 'trackingProdQuanDiff.trackingProdQuanBefore',
												renderer: function (value, meta, record) {
													return edi.utils.getObjectProperty(
														record.get('trackingProdQuanDiff'),
														'trackingProdQuanBefore'
													);
												}
											},
											{
												title: edi.i18n.getMessage(
													'line.item.infoTracking.trackingProdQuanAfter'
												),
												type: 'text',
												allowBlank: false,
												name: 'trackingProdQuanDiff.trackingProdQuanAfter',
												allowDecimals: true,
												maxValue: edi.utils.getMaxValueByLength(26, 11),
												regex: /^[\d]{1,15}(\.[\d]{1,11})?$/,
												renderer: function (value, meta, record) {
													return edi.utils.getObjectProperty(
														record.get('trackingProdQuanDiff'),
														'trackingProdQuanAfter'
													);
												}
											},
											{
												title: edi.i18n.getMessage('line.item.infoTracking.trackingAdd'),
												type: 'text',
												readOnly: true,
												name: 'trackingAdd',
												maxLength: 255
											}
										],
										canDeleteRow: function () {
											//Can't delete organization specified product identifiers which are mandatory
											return false;
										},
										limit: 0,
										isHideAddBtn: true,
										readOnly: false,
										model: 'UKD_PRODUCT_INFO_TRACKING',
										modalWidth: MODAL_SIZE.widthLarge,
										modalTitle: edi.i18n.getMessage('line.item.infoTracking'),
										autoScroll: true,
										gridConfig: {
											autoScroll: true,
											minHeight: 234
										}
									},
									record.get('infoTracking')
								))
							]
						}),
						createTab({
							// Номера средств идентификации
							padding: 24,
							title: edi.i18n.getMessage('line.item.identifyProductToolNumbers'),
							closable: false,
							autoScroll: true,
							layout: {
								type: 'vbox',
								align: 'stretch'
							},
							items: [
								createContainer({
									items: [
										(identifyProductToolNumbersBeforeGrid = createEditableGrid(
											{
												fields: [
													{
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.packId'
														),
														name: 'packId',
														type: 'text',
														readOnly: true,
														allowBlank: false,
														renderer: 'desadvLineEAN',
														maxLength: 255,
														afterrender: function (field) {
															let typeField = identifyProductToolNumbersBeforeGrid
																.getModalForm()
																.down('[name=type]');

															field.allowBlank = !!typeField.getValue();
															form.isValid();
														},
														change: function (field, value) {
															let typeField = identifyProductToolNumbersBeforeGrid
																.getModalForm()
																.down('[name=type]');

															if (!value) {
																field.allowBlank = !!typeField.getValue();
																form.isValid();
															}
														}
													},
													{
														store: edi.stores.createSimpleInlineStore(
															['kiz', 'packNumber'],
															function (id) {
																return edi.i18n.getMessage(
																	'line.item.identifyProductToolNumbers.' + id
																);
															}
														),
														displayField: 'name',
														valueField: 'id',
														readOnly: true,
														type: 'combo',
														requiredFields: ['numbers'],
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.type'
														),
														name: 'type',
														listeners: {
															change: function (field, value) {
																let packId = identifyProductToolNumbersBeforeGrid
																	.getModalForm()
																	.down('[name=packId]');
																packId.allowBlank = !!value;
																packId.isValid();
															}
														}
													},
													{
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.numbers'
														),
														name: 'numbers',
														readOnly: true,
														requiredFields: ['type'],
														hideTrigger: true,
														itemId: 'numbers',
														type: 'tagTextField',
														delimiter: '[\\s]',
														emptyTextKey: 'uikit.tagtextfield.empty.withoutComma',
														buttonMethodName: 'createSaveButton'
													}
												],
												canEditRow: function () {
													//Can't edit organization specified product identifiers which are mandatory
													return false;
												},
												canDeleteRow: function () {
													//Can't delete organization specified product identifiers which are mandatory
													return false;
												},
												columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
												limit: 0,
												//isHideAddBtn: true,
												readOnly: false,
												model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
												autoScroll: true,
												gridConfig: {
													autoScroll: true,
													maxHeight: 234,
													title: edi.i18n.getMessage(
														'line.item.identifyProductToolNumbersBefore'
													)
												}
											},
											Ext.clone(record.get('identifyProductToolNumbersBefore'))
										)),
										(identifyProductToolNumbersAfterGrid = createEditableGrid(
											{
												modalWidth: MODAL_SIZE.widthLarge,
												columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
												fields: [
													{
														title: null,
														boxLabel: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.use.sort.accounting'
														),
														type: 'checkbox',
														itemId: 'sortAccounting',
														value: false,
														inputValue: true,
														listeners: {
															render: renderIdentifyProductToolNumbers,
															change: function (field, useSortAccounting) {
																const form = field.up('panel');
																const packIdField =
																	form && form.down
																		? form.down('[itemId="packId"]')
																		: null;
																const packIdPanel =
																	form && form.up ? packIdField.up() : null;
																const typeField =
																	form && form.down
																		? form.down('[itemId="type"]')
																		: null;
																const numbersField =
																	form && form.down
																		? form.down('[itemId="numbers"]')
																		: null;
																const numbersPanel =
																	numbersField && numbersField.up
																		? numbersField.up()
																		: null;
																const purchaseQuantityField =
																	form && form.down
																		? form.down('[itemId="purchaseQuantity"]')
																		: null;
																const purchaseQuantityPanel =
																	purchaseQuantityField && purchaseQuantityField.up
																		? purchaseQuantityField.up()
																		: null;
																const gtinField =
																	form && form.down
																		? form.down('[itemId="GTIN"]')
																		: null;
																const gtinPanel =
																	gtinField && gtinField.up ? gtinField.up() : null;

																const clearForm = function () {
																	purchaseQuantityField.setValue(null);
																	gtinField.setValue(null);
																	packIdField.setValue(null);
																	typeField.setValue(null);
																	numbersField.setValue([]);
																};

																const setForm = function () {
																	packIdPanel.setVisible(!useSortAccounting);
																	packIdField.allowBlank = useSortAccounting;
																	typeField.setValue(
																		useSortAccounting ? 'packNumber' : null
																	);
																	useSortAccounting
																		? (numbersField.allowBlank = useSortAccounting)
																		: null;
																	numbersField.requiredFields = useSortAccounting
																		? []
																		: ['type'];
																	numbersPanel.setVisible(!useSortAccounting);
																	purchaseQuantityPanel.setVisible(useSortAccounting);
																	purchaseQuantityField.allowBlank =
																		!useSortAccounting;
																	gtinPanel.setVisible(useSortAccounting);
																	gtinField.allowBlank = !useSortAccounting;
																	typeField.setReadOnly(field.getValue());
																};
																clearForm();
																setForm();
																form.isValid();
															},
															afterrender: function (checkbox) {
																checkbox.up('form').on('afterrender', (form) => {
																	isSortAccounting(form);
																	const isSortAccountingAlreadyExist =
																		checkExistSortAccounting();
																	//Чекбокс нельзя установить если уже есть хотя бы один код ОСУ, но можно снять там где он проставлен
																	checkbox.setReadOnly(
																		checkbox.getValue()
																			? false
																			: !!isSortAccountingAlreadyExist
																	);
																});
															}
														}
													},
													{
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.packId'
														),
														name: 'packId',
														itemId: 'packId',
														type: 'text',
														allowBlank: false,
														renderer: 'desadvLineEAN',
														maxLength: 255,
														afterrender: function (field) {
															let typeField = identifyProductToolNumbersAfterGrid
																.getModalForm()
																.down('[name=type]');

															field.allowBlank = !!typeField.getValue();
															form.isValid();
														},
														change: function (field, value) {
															let typeField = identifyProductToolNumbersAfterGrid
																.getModalForm()
																.down('[name=type]');

															if (!value) {
																field.allowBlank = !!typeField.getValue();
																form.isValid();
															}
														}
													},
													{
														store: edi.stores.createSimpleInlineStore(
															['kiz', 'packNumber'],
															function (id) {
																return edi.i18n.getMessage(
																	'line.item.identifyProductToolNumbers.' + id
																);
															}
														),
														displayField: 'name',
														valueField: 'id',
														type: 'combo',
														requiredFields: ['numbers'],
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.type'
														),
														name: 'type',
														itemId: 'type',
														listeners: {
															change: function (field, value) {
																let packId = identifyProductToolNumbersAfterGrid
																	.getModalForm()
																	.down('[name=packId]');
																packId.allowBlank = !!value;
																packId.isValid();
															}
														}
													},
													{
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.numbers'
														),
														name: 'numbers',
														itemId: 'numbers',
														type: 'tagTextField',
														delimiter: '[\\s]',
														emptyTextKey: 'uikit.tagtextfield.empty.withoutComma',
														requiredFields: ['type'],
														hideTrigger: true,
														buttonMethodName: 'createSaveButton'
													},
													{
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.purchase.quantity'
														),
														itemId: 'purchaseQuantity',
														name: 'purchaseQuantity',
														type: 'text',
														listeners: {
															change: function (field, value) {
																setNumbersField(field, value, 'GTIN');
															},
															render: renderUseSortAccounting
														}
													},
													{
														title: edi.i18n.getMessage(
															'line.item.identifyProductToolNumbers.GTIN'
														),
														itemId: 'GTIN',
														name: 'GTIN',
														type: 'text',
														listeners: {
															change: function (field, value) {
																setNumbersField(field, value, 'purchaseQuantity');
															},
															render: renderUseSortAccounting
														}
													}
												],
												getRowClass: function (record, fieldsConfig) {
													let isValidRecord = true,
														i,
														fieldValue,
														allowEmptyValue;

													for (i = 0; i < fieldsConfig.length && isValidRecord; i++) {
														fieldValue = record.get(fieldsConfig[i].name);

														//AllowBlank can be defined in editableGrid fields config or directly in record (for some info-fields)
														allowEmptyValue =
															!fieldsConfig[i].hasOwnProperty('allowBlank') ||
															fieldsConfig[i].allowBlank;
														if (!allowEmptyValue && !fieldValue) {
															isValidRecord = false;
														}
													}
													return isValidRecord ? ROW_COLOR_CLS.valid : ROW_COLOR_CLS.error;
												},
												beforeAdd: function (callback) {
													return infoTrackingGrid.getStore().getCount() > 0
														? edi.core.showError(
																edi.i18n.getMessage(
																	'document.warning.errorNomSredIdentTovAndSvedProslezh'
																)
														  )
														: callback();
												},
												limit: 20,
												autoScroll: true,
												model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
												recordsUniqueByField: 'packId',
												duplicateRecordErrorMsg: 'upd.product.text.infos.duplicate.identifier',
												gridConfig: {
													autoScroll: true,
													margin: '24 0 0 0',
													maxHeight: 234,
													title: edi.i18n.getMessage(
														'line.item.identifyProductToolNumbersAfter'
													)
												}
											},
											Ext.clone(record.get('identifyProductToolNumbersAfter'))
										))
									]
								})
							]
						})
					]
				});

				const dataFormGridConfig = {
					InfPolStr: infoGrid,
					infoTracking: infoTrackingGrid,
					identifyProductToolNumbersBefore: identifyProductToolNumbersBeforeGrid,
					identifyProductToolNumbersAfter: identifyProductToolNumbersAfterGrid
				};

				lineModalFormConfig.forEach(function (field) {
					field.validate();
				});
				createUKDLineModalForm(tabPanel, record, dataFormGridConfig, true, function (values) {
					productsGrid.updateLine(record, values);
					if (record.childNodes && Array.isArray(record.childNodes)) {
						for (i = 0; i < record.childNodes.length; i++) {
							const child = record.childNodes[i];
							if (child.data.State === 'NewData') {
								productsGrid.updateLine(child, values);
							}
						}
					}
					checkValid();
				});
			}
		});

		if (productValues.products.length) {
			for (i = 0; i < productValues.products.length; i++) {
				let product = productValues.products[i];
				if (product && product.OriginalData && product.NewData && product.OriginalData.InfPolStr) {
					product.NewData.InfPolStr = product.OriginalData.InfPolStr;
				}
				grid.createPallet(product, false);
			}
		}
		return grid;
	};

	const createInvoiceTextInfosField = function (isMainField, fieldData, props) {
		fieldData = fieldData || {};
		let isMandatoryField = props?.isMandatory,
			identifierInput,
			valueInput,
			invoiceTextInfoRow;

		identifierInput = createTextField({
			fieldLabel: edi.i18n.getMessage('column.identif'),
			readOnly: props?.disableChangeIdentifier,
			maxLength: 255,
			name: 'invoiceCorrectionInfo.info.textInfos.identifier',
			value: edi.utils.getObjectProperty(fieldData, 'identifier'),
			validator: function (value) {
				if (!value) {
					return true;
				}
				const inputs = invoiceTextInfosColumns.query(
						'textfield[name="invoiceCorrectionInfo.info.textInfos.identifier"]'
					),
					sameValueInput = inputs.find(function (input) {
						return input !== identifierInput && input.getValue() == value;
					});

				return !sameValueInput || edi.i18n.getMessage('identifier.cant.be.duplicated');
			},
			listeners: {
				change: function (field, identifierValue) {
					valueInput.allowBlank = !identifierValue;
					valueInput.isValid();
				}
			}
		});

		valueInput = createTextField({
			fieldLabel: edi.i18n.getMessage('column.value'),
			allowBlank: !isMandatoryField,
			name: 'invoiceCorrectionInfo.info.textInfos.value',
			value: edi.utils.getObjectProperty(fieldData, 'value'),
			listeners: {
				change: function (field, newValue) {
					identifierInput.allowBlank = !newValue;
					identifierInput.isValid();
				}
			}
		});

		invoiceTextInfoRow = createContainer({
			layout: {
				type: 'grid',
				columns: 6,
				area: [[2, 4]]
			},
			items: [identifierInput, valueInput]
		});

		invoiceTextInfoRow.identifierInput = identifierInput;
		invoiceTextInfoRow.valueInput = valueInput;
		return invoiceTextInfoRow;
	};

	const createBaseOfCorrectionsFields = function (isMainField, fieldData) {
		let baseOfCorrectionsNameInput, dateInput;

		const checkRowFieldsAllowBlank = function () {
			let fields = baseOfCorrectionsField.query('field') || [];
			let fieldsAreEmpty = fields.every(
				(f) => f.getValue() === null || f.getValue() === undefined || f.getValue() === ''
			);
			let value = baseOfCorrectionsNameInput?.getValue();
			let isWithoutBase = value && value.toLowerCase() === baseOfCorrectionsContsName.toLowerCase();
			if (baseOfCorrectionsNameInput) {
				baseOfCorrectionsNameInput.allowBlank = fieldsAreEmpty || isWithoutBase;
			}
			if (dateInput) {
				dateInput.allowBlank = fieldsAreEmpty || isWithoutBase;
			}
			checkValid();
		};

		let baseOfCorrectionsField = createContainer({
			layout: {
				type: 'grid',
				columns: 9,
				area: [
					[3, 3, 3],
					[2, 4]
				]
			},
			listeners: {
				afterrender() {
					checkRowFieldsAllowBlank();
					checkIfWithoutBase(baseOfCorrectionsNameInput);
				}
			},
			items: [
				(baseOfCorrectionsNameInput = isMainField
					? createCombo({
							maxLength: 255,
							fieldLabel: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.name'),
							value: edi.utils.getObjectProperty(fieldData, 'name')
								? edi.utils.getObjectProperty(fieldData, 'name')
								: edi.i18n.getMessage(
										'receiptPartDoc.transferInformation.baseShipmentOfGoods.withoutBaseDoc'
								  ),
							name: 'informationOnCoordination.docBaseOfCorrections.name',
							forceSelection: false,
							store: edi.stores.createSimpleInlineStore(
								[
									edi.i18n.getMessage(
										'receiptPartDoc.transferInformation.baseShipmentOfGoods.withoutBaseDoc'
									)
								],
								function (id) {
									return id;
								}
							),
							listeners: {
								change() {
									checkRowFieldsAllowBlank();
									checkIfWithoutBase(baseOfCorrectionsNameInput);
								}
							}
					  })
					: createTextField({
							fieldLabel: edi.i18n.getMessage('documents.fns_ukd.baseOfCorrections.name'),
							maxLength: 255,
							value: edi.utils.getObjectProperty(fieldData, 'name'),
							name: 'informationOnCoordination.docBaseOfCorrections.name',
							listeners: {
								change() {
									checkRowFieldsAllowBlank();
									checkIfWithoutBase(baseOfCorrectionsNameInput);
								}
							}
					  })),
				createTextField({
					fieldLabel: edi.i18n.getMessage('field.name.number'),
					maxLength: 255,
					value: edi.utils.getObjectProperty(fieldData, 'number'),
					name: 'informationOnCoordination.docBaseOfCorrections.number',
					listeners: {
						change: checkRowFieldsAllowBlank
					}
				}),
				createTextField({
					grid: {
						scol: 7,
						col: 3,
						srow: 1,
						row: 2
					},
					isTextarea: true,
					minHeight: 'auto',
					allowBlank: true,
					maxLength: 1000,
					fieldLabel: edi.i18n.getMessage('documents.fns_ukd.baseOfCorrections.info'),
					value: edi.utils.getObjectProperty(fieldData, 'info'),
					name: 'informationOnCoordination.docBaseOfCorrections.info',
					listeners: {
						change: checkRowFieldsAllowBlank
					}
				}),
				(dateInput = createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					maxValue: new Date(),
					value: edi.utils.getObjectProperty(fieldData, 'date'),
					name: 'informationOnCoordination.docBaseOfCorrections.date',
					listeners: {
						change: checkRowFieldsAllowBlank
					}
				})),
				createTextField({
					allowBlank: true,
					maxLength: 255,
					fieldLabel: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.baseId'),
					value: edi.utils.getObjectProperty(fieldData, 'identifier'),
					name: 'informationOnCoordination.docBaseOfCorrections.identifier',
					listeners: {
						change: checkRowFieldsAllowBlank
					}
				})
			]
		});

		const checkIfWithoutBase = function (baseOfCorrectionsNameInput) {
			//если мы выбрали "Без основания", значит надо удалить все строки, кроме первой (должна быть пустой и заблоченой),
			//и заблокировать добавление новых
			let value = baseOfCorrectionsNameInput?.getValue();
			let isWithoutBase = value && value.toLowerCase() === baseOfCorrectionsContsName.toLowerCase();
			if (
				isWithoutBase &&
				Array.isArray(baseOfCorrectionsColumns?.items?.items) &&
				baseOfCorrectionsColumns.items.items.length > 1
			) {
				//первую строку не удаляем, т.к. она нужна что б оставить "Без основания"
				let rowsToDelete = baseOfCorrectionsColumns.items.items.slice(1);
				rowsToDelete.forEach((r) => baseOfCorrectionsColumns.remove(r));
				baseOfCorrectionsColumns
					.down('[name="informationOnCoordination.docBaseOfCorrections.name"]')
					?.setValue(baseOfCorrectionsContsName);
			}

			let elNumbers = baseOfCorrectionsColumns.query(
				'[name="informationOnCoordination.docBaseOfCorrections.number"]'
			);
			elNumbers.forEach((field) => {
				field.setReadOnly(isWithoutBase);
				if (isWithoutBase) {
					field.setValue('');
				}
			});

			let elDates = baseOfCorrectionsColumns.query(
				'[name="informationOnCoordination.docBaseOfCorrections.date"]'
			);
			elDates.forEach((field) => {
				field.setReadOnly(isWithoutBase);
				if (isWithoutBase) {
					field.setValue('');
				}
			});

			let elInfos = baseOfCorrectionsColumns.query(
				'[name="informationOnCoordination.docBaseOfCorrections.info"]'
			);
			elInfos.forEach((field) => {
				field.setReadOnly(isWithoutBase);
				if (isWithoutBase) {
					field.setValue('');
				}
			});

			let elIds = baseOfCorrectionsColumns.query(
				'[name="informationOnCoordination.docBaseOfCorrections.identifier"]'
			);
			elIds.forEach((field) => {
				field.setReadOnly(isWithoutBase);
				if (isWithoutBase) {
					field.setValue('');
				}
			});

			let addBtn = baseOfCorrectionsColumns.down('button[itemId="add_button"]');
			if (typeof addBtn?.setDisabled === 'function') {
				addBtn.setDisabled(isWithoutBase);
			}

			checkValid();
		};

		return baseOfCorrectionsField;
	};

	/**
	 * Creates upd form
	 * @returns {Object}
	 */
	const createModuleForm = function (isCreateBtnText) {
		const createBtn = createButton(
			{
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage(isEdit && !isCopy && !isCreate ? 'form.btn.save' : 'form.btn.create'),
				glyph: null,
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller'],
							[],
							productsGrid,
							!productValues.products.length || !productsGrid.hasChangedProducts()
						)
					) {
						return;
					}
					save();
				}
			},
			!isCreateBtnText
		);

		// EDIWEB begin
		let action = function (callback) {
			if (
				!edi.utils.setFocusToDocumentsWithGrid(
					form,
					['buyer', 'seller'],
					[],
					productsGrid,
					!productValues.products.length || !productsGrid.hasChangedProducts()
				)
			) {
				return;
			}
			let values = edi.utils.collectFormValues(form);
			values.bpName = bpName;
			values.versionId = '5.01-N';
			values.docType = edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD;
			values.number = edi.utils.getObjectProperty(values, 'invoiceCorrectionInfo.invoiceCorrectionNumber');
			'function' == typeof callback ? callback(values) : null;
		};

		const createAndSignSendMenuButton = createSendButton(
			function () {
				if (
					!edi.utils.setFocusToDocumentsWithGrid(
						form,
						['buyer', 'seller'],
						[],
						productsGrid,
						!productValues.products.length || !productsGrid.hasChangedProducts()
					)
				) {
					return;
				}
				save();
			},
			{
				disabled: false,
				formBind: false,
				text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.create'),
				handler: null,
				menu: {
					plain: true,
					hideMode: 'display',
					items: [
						{
							text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.create'),
							handler() {
								save();
							}
						},
						{
							text: edi.i18n.getMessage('ediweb.send.to.approval.documents.btn'),
							handler() {
								action((values) => {
									showApprovalModal(
										values,
										moduleData,
										save,
										edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_DOCUMENTS
									);
								});
							}
						},
						{
							text: edi.i18n.getMessage('ediweb.send.to.approval.employees.btn'),
							handler() {
								action((values) => {
									showApprovalModal(
										values,
										moduleData,
										save,
										edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES
									);
								});
							}
						}
					]
				}
			}
		);

		// EDIWEB end

		const createTextInfosBlock = function () {
			return createFieldSet({
				margin: '24 0 0 0',
				title: edi.i18n.getMessage('documents.fns_ukd.special_identifiers'),
				collapsible: true,
				items: [
					(invoiceTextInfosColumns = createRowsBlock({
						createContentFieldsFn: createInvoiceTextInfosField,
						userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
						contentCols: 6,
						buttonsCols: 6,
						initialData:
							isEdit || isCorrect || isCopy
								? edi.utils.getObjectProperty(documentData, 'invoiceCorrectionInfo.info.textInfos') ||
								  []
								: [],
						setExtraDataToRow(row) {
							row.identifierInput = row.contentFieldsPanel.identifierInput;
							row.valueInput = row.contentFieldsPanel.valueInput;
						},
						onAddRow() {
							checkValid();
						},
						onRemoveRow() {
							checkValid();
						}
					}))
				]
			});
		};

		const createTransferDocField = function (isMainField, fieldData) {
			let transferDocName;
			let transferDocNumber;
			return createContainer({
				layout: {
					type: 'grid',
					columns: 9,
					area: [
						[3, 3, 3],
						[2, 4]
					]
				},
				items: [
					(transferDocName = createTextField({
						allowBlank: true,
						maxLength: 255,
						reserveSpace: true,
						readOnly: isMainField,
						fieldLabel: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.name'),
						value: edi.utils.getObjectProperty(fieldData, 'name'),
						name: 'informationOnCoordination.transferDoc.name',
						listeners: {
							change: function (field, value) {
								transferDocNumber.allowBlank = !value;
								transferDocNumber.isValid();
							}
						}
					})),
					(transferDocNumber = createTextField({
						fieldLabel: edi.i18n.getMessage('column.license.number'),
						maxLength: 255,
						allowBlank: true,
						readOnly: isMainField,
						value: edi.utils.getObjectProperty(fieldData, 'number'),
						name: 'informationOnCoordination.transferDoc.number',
						listeners: {
							change: function (field, value) {
								transferDocName.allowBlank = !value;
								transferDocName.isValid();
							}
						}
					})),
					createTextField({
						grid: {
							scol: 7,
							col: 3,
							srow: 1,
							row: 2
						},
						fieldLabel: edi.i18n.getMessage('documents.fns_ukd.baseOfCorrections.info'),
						isTextarea: true,
						minHeight: 'auto',
						maxLength: 255,
						allowBlank: true,
						value: edi.utils.getObjectProperty(fieldData, 'info'),
						name: 'informationOnCoordination.transferDoc.info'
					}),
					createDateField({
						fieldLabel: edi.i18n.getMessage('date'),
						allowBlank: true,
						readOnly: isMainField,
						submitFormat: edi.constants.DATE_FORMAT.FNS,
						maxValue: new Date(),
						value: edi.utils.getObjectProperty(fieldData, 'date'),
						name: 'informationOnCoordination.transferDoc.date'
					}),
					createTextField({
						fieldLabel: edi.i18n.getMessage('column.identifier'),
						maxLength: 255,
						allowBlank: true,
						value: edi.utils.getObjectProperty(fieldData, 'identifier'),
						name: 'informationOnCoordination.transferDoc.identifier'
					})
				]
			});
		};

		const operContent = createFieldBlock({
			title: edi.i18n.getMessage('documents.fns_ukd.operContent'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			padding: '0 0 16 0',
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [
				createTextField({
					allowBlank: false,
					isTextarea: true,
					maxLength: 255,
					minHeight: 80,
					name: 'informationOnCoordination.operContent',
					value:
						edi.utils.getObjectProperty(documentData, 'informationOnCoordination.operContent') ||
						edi.i18n.getMessage('documents.fns_ukd.operContent.default')
				})
			]
		});

		transferDocColumns = createRowsBlock({
			title: edi.i18n.getMessage('documents.fns_ukd.transferDoc'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			createContentFieldsFn: createTransferDocField,
			initialData: edi.utils.getObjectProperty(documentData, 'informationOnCoordination.transferDoc') || [],
			contentCols: 9,
			buttonsCols: 3,
			setExtraDataToRow(row) {
				if ('function' == typeof row.contentFieldsPanel.isNonEmptyRow) {
					row.isNonEmptyRow = row.contentFieldsPanel.isNonEmptyRow;
				}
				if ('function' == typeof row.contentFieldsPanel.setMandatoryNameField) {
					row.setMandatoryNameField = row.contentFieldsPanel.setMandatoryNameField;
				}
			},
			onAddRow() {
				checkValid();
			},
			onRemoveRow() {
				checkValid();
			}
		});

		//если среди документов-оснований есть "Без основания", тогда просто все стираем,
		//потому что без основания означает что нет заполненных строк
		let basesOfCorrection = documentData?.informationOnCoordination?.docBaseOfCorrections;
		if (
			Array.isArray(basesOfCorrection) &&
			basesOfCorrection.some((b) => String(b?.name).toLowerCase() === baseOfCorrectionsContsName.toLowerCase())
		) {
			documentData.informationOnCoordination.docBaseOfCorrections = [];
		}
		baseOfCorrectionsColumns = createRowsBlock({
			title: edi.i18n.getMessage('documents.fns_ukd.baseOfCorrections.name'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			createContentFieldsFn: createBaseOfCorrectionsFields,
			initialData:
				isEdit || isCorrect || isCopy
					? edi.utils.getObjectProperty(documentData, 'informationOnCoordination.docBaseOfCorrections') || []
					: [],
			contentCols: 9,
			buttonsCols: 3,
			setExtraDataToRow(row) {
				if ('function' == typeof row.contentFieldsPanel.isNonEmptyRow) {
					row.isNonEmptyRow = row.contentFieldsPanel.isNonEmptyRow;
				}
				if ('function' == typeof row.contentFieldsPanel.setMandatoryNameField) {
					row.setMandatoryNameField = row.contentFieldsPanel.setMandatoryNameField;
				}
				if ('function' == typeof row.contentFieldsPanel.setDisabledAddButton) {
					row.addBtn.setDisabled(!!row.contentFieldsPanel.setDisabledAddButton());
				}
			},
			onAddRow() {
				checkValid();
			},
			onRemoveRow() {
				checkValid();
			}
		});

		const fields = [baseOfCorrectionsColumns, transferDocColumns, operContent];
		const baseOfCorrectionsFields = createFieldSet({
			layout: {
				type: 'grid',
				gap: 24
			},
			margin: '24 0 0 0',
			title: edi.i18n.getMessage('documents.fns_ukd.informationOnCoordination'),
			collapsible: true,
			items: fields
		});

		const container = createContainer({
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				gap: [24, 16]
			},
			items: [].concat(createInvoiceFormItems())
		});

		productsGrid = createInvoiceTableItem();

		form = createForm({
			//cls: 'edi-form document-add-form',
			bodyPadding: '24',
			autoScroll: true,
			items: [
				container,
				createTextInfosBlock(),
				baseOfCorrectionsFields,
				productsGrid.wrapper,
				createSignerItems()
			],
			buttons: createButtonContainer({
				items: [
					!edi.constants.IS_EDIWEB_CLIENT ? createBtn : null,
					edi.constants.IS_EDIWEB_CLIENT ? createAndSignSendMenuButton : null
				]
			})
		});

		form.on('validitychange', checkValid);
		form.isValid();
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	const checkValid = function () {
		if (form) {
			form.isValid();
		}
		const productsLength = productValues.products.length,
			validForm = form ? !form.hasInvalidField() : true,
			validGrid = productsGrid.isValid(),
			hasChangedProducts = productsGrid.hasChangedProducts();
		seller.isValid();
		buyer.isValid();
		return productsLength && validForm && validGrid && hasChangedProducts;
	};

	const getOrganizationAdditionalFields = function (orgId, notUpdate) {
		const failure = function (responseData) {
			const errorMsg = edi.utils.formatComplexServerError(responseData, 'org.document.data.load.failed');
			edi.core.showWarn(errorMsg, updateInfoFields);
		};
		if (!orgId) {
			updateInfoFields();
		} else {
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.DOCUMENTS.INFO_FIELDS.GET, {
					docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
					orgId: orgId
				}),
				'GET',
				null,
				function (data) {
					if (data && data.items) {
						infoColumnsConfig = data.items.length ? data.items : null;
						if (!notUpdate) {
							updateInfoFields(data.items);
						}
					} else {
						failure();
					}
				},
				failure
			);
		}
	};

	//Update fields in "Text info" block of UKD
	const processOrgInvoiceInfos = function (orgAdditionalInfoFields) {
		const createTooltip = function (targetCmp, tooltipText) {
			targetCmp.toolTip = Ext.create('Ext.tip.ToolTip', {
				target: targetCmp.getEl(),
				trackMouse: true,
				html: tooltipText
			});

			targetCmp.on('destroy', function () {
				if (targetCmp.toolTip) {
					targetCmp.toolTip.destroy();
				}
			});
		};

		//Sets tooltip for row according to its config
		const updateRowDueToConfig = function (row, rowConfig) {
			if (rowConfig.tooltip) {
				createTooltip(row.identifierInput, rowConfig.tooltip);
			}
			row.valueInput.isValid();
			row.identifierInput.isValid();
		};

		//Checks if there already exists a row with 'id' as an identifier
		const getIdentifierRow = function (id) {
			return invoiceTextInfosColumns.getRows().find(function (row) {
				return row.identifierInput.getValue() == id;
			});
		};

		orgAdditionalInfoFields.forEach(function (fieldConfig) {
			let row = getIdentifierRow(fieldConfig.infoId),
				isMandatoryField = fieldConfig.required;

			//For editing UKD we don't add rows, just check already added
			if (row) {
				row.identifierInput.setReadOnly(true);
				row.valueInput.allowBlank = !isMandatoryField;
				row.removeBtn.setDisabled(isMandatoryField);
				updateRowDueToConfig(row, fieldConfig);
			}
		});
	};

	//Shows additional text info fields, specified for selected buyer
	const updateInfoFields = function (organizationInfoFields) {
		organizationInfoFields = organizationInfoFields || [];

		const invoiceInfoObj =
				organizationInfoFields.find(function (item) {
					return item.docPart === 'InvoiceTextInfos';
				}) || {},
			invoiceInfoFields = edi.utils.getObjectProperty(invoiceInfoObj, 'fieldInfo', true);

		if (invoiceInfoFields.length) {
			processOrgInvoiceInfos(invoiceInfoFields);
		}
	};

	const buildDocument = function (formValues) {
		const docBaseOfCorrections = edi.methods.convertValuesFromMultipleFields(
			edi.utils.getObjectProperty(formValues, 'informationOnCoordination.docBaseOfCorrections')
		);
		const transferDocs = edi.methods.convertValuesFromMultipleFields(
			edi.utils.getObjectProperty(formValues, 'informationOnCoordination.transferDoc')
		);
		const copyLinesWithoutChanges =
			edi.utils.getAttributeByName(buyerOrg.attributes, 'COPY_LINES_WITHOUT_CHANGES') === 'true';
		const changedProducts = productsGrid.getValues(!copyLinesWithoutChanges); // запрашиваем все строки (false) или только изменённые (true)

		productsGrid.getValues().products.forEach(function (product) {
			let originalDataInfoTracking = edi.utils.getObjectProperty(product, 'OriginalData.infoTracking', true),
				newDataInfoTracking = edi.utils.getObjectProperty(product, 'NewData.infoTracking', true);
			newDataInfoTracking.map(function (item, index) {
				const originalDataTrackingProdQuanBefore = edi.utils.getObjectProperty(
					originalDataInfoTracking[index],
					'trackingProdQuanDiff.trackingProdQuanBefore'
				);
				edi.utils.setObjectProperty(
					item,
					'trackingProdQuanDiff.trackingProdQuanBefore',
					originalDataTrackingProdQuanBefore
				);
				return item;
			});
		});

		const checkSignersPersonType = function (signers) {
			for (let i = 0; i < signers.length; i++) {
				if (userData.org.orgType !== edi.constants.ORGANIZATION_TYPES.INDIVIDUAL) {
					let personPropName = signerType ?? 'legalEntity';
					if (signerFillFromCert && signerFillFromCert.inn && signerFillFromCert.inn.length) {
						if (signerFillFromCert.inn.slice(0, 2) == '00') {
							signerFillFromCert.inn = signerFillFromCert.inn.slice(2, 12);
						}
						personPropName = 'legalEntity';
						if (signerFillFromCert.inn.length === 12) {
							personPropName = 'individual';
						}
						//В сертификате ИП в отличие от ФЛ, есть сведения об основном регистрационном номере ИП (ОГРНИП)
						if (signerFillFromCert.inn.length === 12 && signerFillFromCert.ogrnip) {
							personPropName = 'individualPerson';
						}
					}
					if (
						!signers[i].hasOwnProperty(personPropName) ||
						Object.keys(signers[i][personPropName]).length === 0
					) {
						['individualPerson', 'legalEntity', 'individual'].every(function (value) {
							if (signers[i].hasOwnProperty(value) && Object.keys(signers[i][value]).length > 0) {
								signers[i][personPropName] = signers[i][value];
								delete signers[i][value];

								const newPersonFIOType = signerTypeFIOMap[personPropName];
								const oldPersonFIOType = signerTypeFIOMap[value];
								if (
									!signers[i][personPropName][newPersonFIOType] &&
									signers[i][personPropName][oldPersonFIOType]
								) {
									signers[i][personPropName][newPersonFIOType] =
										signers[i][personPropName][oldPersonFIOType];
									delete signers[i][personPropName][oldPersonFIOType];
								}
								return false;
							}
							return true;
						});
					}
				}
			}
			return signers;
		};

		let build = {
			invoiceCorrectionInfo: {
				invoiceInfo: [
					{
						invoicesCorrectInfo: edi.utils.getObjectProperty(
							formValues,
							'invoiceCorrectionInfo.invoiceInfo.invoicesCorrectInfo',
							true
						),
						number: edi.utils.getObjectProperty(formValues, 'invoiceCorrectionInfo.invoiceInfo.number'),
						date: edi.utils.getObjectProperty(formValues, 'invoiceCorrectionInfo.invoiceInfo.date')
					}
				]
			},
			informationOnCoordination: {
				docBaseOfCorrections: edi.utils.getObjectProperty(docBaseOfCorrections[0], 'name')
					? edi.methods.convertValuesFromMultipleFields(
							edi.utils.getObjectProperty(formValues, 'informationOnCoordination.docBaseOfCorrections')
					  )
					: [
							{
								name: edi.i18n.getMessage(
									'receiptPartDoc.transferInformation.baseShipmentOfGoods.withoutBaseDoc'
								)
							}
					  ],
				transferDoc: transferDocs // всегда будет хотя бы один "ПередатДокум"
			},
			signers: checkSignersPersonType(
				edi.methods.convertValuesFromMultipleFields(edi.utils.getObjectProperty(formValues, 'signers'))
			),
			cinvoiceTable: edi.converters.convertUKDGridValuesToUKDProduct(changedProducts, '5.01-N', bpName),
			function: edi.i18n.getMessage('documents.fns_ukd.type.' + bpName),
			nameEconomicSubject: userData.org.name
		};

		if (!isWithoutEconomicLifeDocName(bpName)) {
			build.economiclifeDocName = edi.i18n.getMessage('documents.fns_ukd.economiclifeDocName.text');
		}

		edi.methods.setObjectValuesWithConverts(formValues, build, 'invoiceCorrectionInfo.info.textInfos');

		return Ext.Object.merge(formValues, build);
	};
	/**
	 * Saves fns upd
	 */
	const save = function (formValues, afterSave, onSaveFailed, options) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		let values = buildDocument(edi.utils.collectFormValues(form));
		let fieldsToRemove = [].concat(edi.constants.FIELDS_TO_REMOVE_AFTER_MERGE_FNS);
		if (isCopy || isCorrect) {
			fieldsToRemove.push(
				'fileId',
				'formVersion',
				'progVersion',
				'docFlowMembers',
				'document.fileInvoiceCreationDate',
				'document.fileInvoiceCreationTime'
			);
		}

		edi.utils.setObjectProperty(
			values,
			'informationOnCoordination.docBaseOfCorrections',
			edi.utils.getObjectProperty(values, 'informationOnCoordination.docBaseOfCorrections', true)
		);

		if (isKSCHF(bpName)) {
			delete initialData?.document?.primaryDocName;
			delete initialData?.document?.economiclifeDocName;
			delete values.primaryDocName;
			delete values.economiclifeDocName;
		}
		let document = {
			document: values
		};
		document = edi.document.actions.mergeDataBeforeSave(
			initialData,
			document,
			[
				{
					path: 'document.cinvoiceTable.productInfo'
				}
			],
			{
				fieldsToRemove: fieldsToRemove
			}
		);

		//определенные поля если указано 0 или 0.00 не передавать на бек
		let products = edi.utils.getObjectProperty(document, 'document.cinvoiceTable.productInfo', true);
		const mapForClearEmpty = [
			'costWithoutVat.costIncreased',
			'costWithVat.costIncreased',
			'costWithoutVat.costReduced',
			'costWithVat.costReduced',
			'exciseDiff.amountIncrease',
			'exciseDiff.amountReduce'
		];
		products.forEach((product) => {
			mapForClearEmpty.forEach((key) => {
				const value = edi.utils.getObjectProperty(product, key);
				if (Number(value) === 0) {
					edi.utils.setObjectProperty(product, key, null);
				}
			});
		});
		edi.utils.clearEmptyValues(document);

		const success =
			options?.isSendingToApproval === true && !isEdit
				? function (respData) {
						//после создания дока сразу обновим модуль, т.к. он уже черновик в редактировании
						//и после обновления запустим уже процесс согласования
						//это все из-за того, что в процессе создания или отправки согласования
						//что-то может пойти не так, и это позволит нам сразу работать с черновиком,
						//у которого уже есть id
						if (respData?.data?.id) {
							moduleData.initData.data = respData.data;
							let title = edi.i18n.getMessage(
								edi.modulesCfg['document.create.fns_ukd_5_01n'].title + '.edit'
							);
							title += ` ${respData.data.number}`;
							moduleData.tab.setTitle(title);
							moduleData.tab.tab.setTooltip(title);
							moduleData.tab.setGlyph(edi.constants.ICONS.EDIT);
							moduleData.instance.init(moduleData, () => afterSave(respData.data.id));
						}
				  }
				: edi.document.actions.createSaveSuccessHandler(
						moduleData,
						isEdit ? parentId : undefined,
						afterSave,
						isEdit && !isCreate
				  );
		const failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
			function () {
				if (typeof onSaveFailed === 'function') {
					onSaveFailed(moduleData);
				}
			}
		);

		let headerData = {
			number: edi.utils.getObjectProperty(values, 'invoiceCorrectionInfo.invoiceCorrectionNumber'),
			agreement: contract && contract.id ? contract.id : null,
			bpName: bpName,
			dateFormat: edi.constants.DATE_FORMAT.FNS,
			versionId: '5.01-N'
		};

		headerData.data = Ext.encode(document);

		if (isEdit && !isCreate) {
			headerData.date = edi.utils.getObjectProperty(values, 'invoiceCorrectionInfo.invoiceCorrectionDate');

			edi.document.actions.processDocument(
				buyerOrg.id,
				undefined,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1,
				parentId,
				moduleData.initData.data.id,
				success,
				failure,
				headerData
			);
		} else {
			//логика нужно для того чтобы бек сам определял префиксы при создании для БП( __DECREE_14  _WITHOUT_OPERATOR и тд)
			let bpNameToCreate = edi.constants.FNS_BPNAMES_CREATE_MAP[bpName]
				? edi.constants.FNS_BPNAMES_CREATE_MAP[bpName]
				: bpName;
			headerData.bpName = bpNameToCreate;
			headerData.date = isCorrect
				? edi.utils.getObjectProperty(
						values,
						'invoiceCorrectionInfo.editedInvoiceCorrectionInfo.dateEditedInvoiceCorrectionInfo'
				  )
				: edi.utils.getObjectProperty(values, 'invoiceCorrectionInfo.invoiceCorrectionDate');

			if (isNumberAutoGenerated) {
				headerData.autogen = true;
			} else {
				headerData.number = edi.utils.getObjectProperty(
					values,
					'invoiceCorrectionInfo.invoiceCorrectionNumber'
				);
			}

			edi.document.actions.processDocument(
				buyerOrg.id,
				undefined,
				edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1,
				!isCopy ? parentId : undefined,
				undefined,
				success,
				failure,
				headerData,
				{
					postData: {
						containerDocType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
					},
					createUri: edi.rest.services.DOCUMENTS.WITH_CONTAINER.POST
				}
			);
		}
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		let data = moduleData.initData.data;
		if (data?.parties) {
			if (!data.toOrg && data.parties.toOrg) {
				data.toOrg = data.parties.toOrg;
			}
			if (!data.fromOrg && data.parties.fromOrg) {
				data.fromOrg = data.parties.fromOrg;
			}
		}

		let meta = moduleData.initData.meta || data;
		let modulePanel = createAddModulePanel();
		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		getOrganizationAdditionalFields(meta.toOrg.id, true);

		if (data && data.id) {
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;

			parentData = moduleData.initData;
			parentId = data.id;

			buyerOrg = data.toOrg;
			contract = data.agreement;
			taxCalculationMethod = edi.utils.getTaxCalculationMethod(buyerOrg.attributes);
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
					documentId: parentId
				}),
				'GET',
				undefined,
				function (data) {
					if (data && data.data && data.data.children && data.data.children.length) {
						for (let i = 0; i < data.data.children.length; i++) {
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1) {
								id = data.data.children[i].id;
								break;
							}
						}
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
								documentId: id
							}),
							'GET',
							{},
							function (data) {
								if (data && data.data) {
									documentData = edi.utils.getObjectProperty(data.data, 'document');
									initialData = data.data;
									bpName = getBpNameUkd(documentData.function);
									const buildForm = function (fieldsFillFromCert, signerSettings) {
										certSignerSettings = signerSettings;
										signerFillFromCert = fieldsFillFromCert;
										calculateSignerType();
										const form = createModuleForm();
										modulePanel.add(form);
										moduleData.tab.removeAll();
										moduleData.tab.add(modulePanel);
										getOrganizationAdditionalFields(buyerOrg.id);
										if ('function' == typeof initCallBack) {
											initCallBack();
										}
									};
									let signersData = edi.utils.getObjectProperty(documentData, 'signers')[0];
									edi.methods.fillUPDSignerValues(
										edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
										signersData,
										edi.utils.getObjectProperty(documentData, 'invoice.sellerInfo'),
										buildForm,
										null,
										null,
										isCreate && !isCopy
									);
								} else {
									failure(data);
								}
							},
							failure
						);
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			if (data && data.parentId) {
				isCreateOnBasis = true;
			}
			const createModulePanel = function () {
				if (data) {
					buyerOrg = data.toOrg ? data.toOrg : null;
					taxCalculationMethod = edi.utils.getTaxCalculationMethod(buyerOrg.attributes);
				}

				if (!documentData.signers || !Array.isArray(documentData.signers)) {
					documentData.signers = [{}];
				}
				let signersData = documentData.signers[0];

				const callback = function (fieldsFillFromCert, signerSettings) {
					certSignerSettings = signerSettings;
					signerFillFromCert = fieldsFillFromCert;
					calculateSignerType();
					modulePanel.add(createModuleForm(true));
					moduleData.tab.removeAll();
					moduleData.tab.add(modulePanel);
					buyer.presetFromRelation(function () {
						if (buyer.selectedOrg) {
							getOrganizationAdditionalFields(buyer.selectedOrg.id);
						}
						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					});
				};

				edi.methods.fillUPDSignerValues(
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
					signersData,
					edi.converters.convertOrgToPartie(userData.org),
					callback,
					null,
					null,
					isCreate
				);
			};
			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET_BY_TYPE.GET, {
						doctype: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
					}),
					'GET',
					undefined,
					function (docNumberSettings) {
						if (docNumberSettings && docNumberSettings.data) {
							isNumberAutoGenerated =
								docNumberSettings.data &&
								docNumberSettings.data.policy &&
								'ALLOW' === docNumberSettings.data.policy;
						}
						createModulePanel();
					},
					failure
				);
			} else {
				createModulePanel();
			}
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
