import { createModulePanel, createPanel } from '@Components/panels';
import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { exportGroupFilter } from './filter';

Ext.namespace('edi.modules');
/**
 * Class for export group request
 */
edi.modules['export.group.request'] = function () {
	var moduleData, fireSearch, filterForm, filterObject;
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'fit',
			region: 'center',
			items: [createLayout()]
		});
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
		fireSearch();
	};
	/**
	 * Create module grid
	 * @returns {Object}
	 */
	var createLayout = function () {
		var columns = edi.columns.get('export_group_request'),
			items = [];

		if (edi.permissions.hasPermission('EXPORT_EDI_GROUP_EXPORT_REQUEST')) {
			// !! и статус
			items.push({
				glyph: edi.constants.ICONS.FILE_DOWNLOAD,
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex),
						archId = record.get('id');
					edi.rest.downloadFile(
						edi.utils.formatString(edi.rest.services.EXPORT.GROUP_REQUEST.EXPORT.GET, {
							id: archId
						})
					);
				},
				isHidden: function (view, rowIndex, colIndex, item, record) {
					return edi.constants.EXPORT_GROUP_REQUEST.STATE.READY !== record.get('state');
				}
			});
		}
		if (items.length) {
			columns = columns.concat(
				createActionsColumnConfig({
					align: 'center',
					items: items,
					width: edi.utils.getActionColumnWidth(items.length)
				})
			);
		}
		fireSearch = function () {
			if (!filterObject) {
				filterObject = edi.filters.createGridFilter(
					edi.rest.services.EXPORT.GROUP_REQUEST.LIST.GET,
					filterForm,
					grid,
					exportGroupFilter.createArgs,
					null,
					{
						persistence: {
							enabled: true,
							name: `${moduleData.modName}_requests`
						}
					}
				);
			}
			filterObject.filter();
		};
		const { formItemsMap, items } = exportGroupFilter.createFormItems();
		filterForm = createModuleFilterForm(
			{
				usePlaceholderWrapper: true,
				formItemsMap,
				items
			},
			fireSearch
		);

		var grid = createGrid({
			saveSorters: true,
			savedSortersName: 'export_group_request',
			proxyConfig: {
				type: 'ajax',
				url: null
			},
			storeConfig: {
				model: edi.models.getModel('EXPORT_GROUP_REQUEST'),
				autoLoad: false,
				remoteSort: true,
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				}
			},
			gridConfig: {
				cls: 'tab-grid with-filter',
				columns: columns,
				region: 'center',
				border: 0,
				autoScroll: true,
				disableSelection: true
			}
		});

		return createPanel({
			layout: 'border',
			items: [filterForm, grid]
		});
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		if (filterObject && filterObject.searchTimeout) {
			clearTimeout(filterObject.searchTimeout);
		}
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
