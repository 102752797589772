import { createLabel } from '@Components/fields';
import {
	createActionsColumnConfig,
	createGrid,
	createGridActionBar,
	createGridCheckboxSelectionModel
} from '@Components/grid';
import { createModulePanel } from '@Components/panels';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { filterMethods } from './filter';

export class PackagesGridModule {
	filterObject;
	moduleData;
	filterForm;
	grid;

	constructor() {
		const me = this;
		me._gridDataChangedHandler = me.gridDataChangedHandler.bind(me);
		me._gridDataChangedHandler = me.gridDataChangedHandler.bind(me);
		me._fireSearch = me.fireSearch.bind(me);
	}

	/**
	 * Module initialization
	 * @param {Object} data                Data from modules handler
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	init(data, initCallBack) {
		const me = this;
		me.moduleData = data;
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		edi.events.documentPackages.on('change', me._gridDataChangedHandler);
		edi.events.documentPackages.on('delete', me._gridDataChangedHandler);
	}

	fireSearch() {
		const me = this;
		if (!me.filterObject) {
			me.filterObject = edi.filters.createGridFilter(
				edi.rest.services.DOCUMENT_PACKAGES.GET,
				me.filterForm,
				me.grid,
				filterMethods.createArgsPackages,
				null,
				{
					persistence: {
						enabled: true,
						name: `${me.moduleData.modName}_packages_grids`
					}
				}
			);
		}
		me.filterObject.filter();
	}

	/**
	 * Creates filter form
	 * @returns {Object}
	 */
	createFilterForm() {
		const me = this;
		const { formItemsMap, items } = filterMethods.createPackagesFormItems();
		return createModuleFilterForm(
			{
				usePlaceholderWrapper: true,
				formItemsMap,
				items
			},
			me._fireSearch
		);
	}

	/**
	 * Initializes grid
	 * @returns {Object} Instance of grid
	 */
	createGridFn() {
		const me = this;
		let rowSelected = [];
		const columns = edi.columns.get('document_packages');
		const actions = me.createActionColumn();
		if (actions !== null) {
			columns.push(actions);
		}

		const totalsLabel = createLabel({
			text: edi.i18n.getMessage('records.selected.totals', {
				selectedRows: 0
			}),
			cls: 'edi-tbar-total-label-right'
		});

		const groupActionsItems = [
			{
				id: edi.constants.DOCUMENT_ACTIONS.CREATE,
				name: edi.i18n.getMessage('form.btn.create'),
				notDisabled: true
			},
			{
				id: edi.constants.DOCUMENT_ACTIONS.DELETE,
				name: edi.i18n.getMessage('form.btn.delete')
			}
		];

		const groupActionsItemClick = function (id) {
			if (edi.constants.DOCUMENT_ACTIONS.DELETE === id) {
				if (edi.permissions.hasPermission('DELETE_PACKAGE_OBJECT')) {
					edi.core.confirm(null, 'documents.package.delete.confirm', function () {
						if (!rowSelected.length) {
							return;
						}
						let packageId;
						rowSelected.forEach(function (row) {
							me.moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
							packageId = row.get('id');
							const url = edi.utils.formatString(
								edi.rest.services.DOCUMENT_PACKAGES.DELETE,
								{
									id: packageId
								},
								false
							);
							edi.rest.sendRequest(
								url,
								'DELETE',
								null,
								function () {
									edi.events.documentPackages.fireEvent('delete');
								},
								edi.rest.getErrorHandler(),
								function () {
									me.moduleData.tab.setLoading(false);
								}
							);
						});
					});
				}
			}
			if (edi.constants.DOCUMENT_ACTIONS.CREATE === id) {
				edi.methods.document_packages.addDocuments(null, null, me._gridDataChangedHandler);
			}
		};

		const topBar = createGridActionBar({
			actionCfgs: groupActionsItems,
			defaultHandler: groupActionsItemClick
		});

		return createGrid({
			saveSorters: true,
			savedSortersName: 'document_packages',
			proxyConfig: {
				type: 'ajax',
				url: null
			},
			storeConfig: {
				model: edi.models.getModel('DOCUMENT_PACKAGES'),
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				autoLoad: false
			},
			gridConfig: {
				columns: columns,
				padding: 0,
				region: 'center',
				layout: 'fit',
				border: 1,
				listeners: {
					celldblclick: function (_view, _td, _cellIndex, record) {
						edi.methods.document_packages.details(record.getData());
					}
				},
				selModel:
					topBar && topBar.menuActionItemsCnt
						? createGridCheckboxSelectionModel({
								topBar: topBar,
								selectionProcessor: function (model, selected, actionItems) {
									edi.methods.gridSelModules(model, selected, actionItems, topBar, totalsLabel);
									rowSelected = selected;
								}
						  })
						: undefined,
				tbar: topBar
			},
			pagingBarConfig: {
				totalsLabel: topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined
			}
		});
	}

	/**
	 * Creates action column of the grid
	 * @returns {Object} Config for action column
	 */
	createActionColumn() {
		const items = [
			{
				glyph: edi.constants.ICONS.DETAILS,
				testCls: 'test-action-column-details',
				handler: function (_grid, _rowIndex, _colindex, _actionItem, _event, record) {
					edi.methods.document_packages.details(record.getData());
				}
			}
		];
		return createActionsColumnConfig({
			items: items,
			width: edi.utils.getActionColumnWidth(items.length)
		});
	}

	/**
	 * Renders page layout
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	renderData(initCallBack) {
		const me = this;
		const modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});

		me.grid = me.createGridFn();
		me.filterForm = me.createFilterForm();
		modulePanel.add(me.filterForm);

		modulePanel.add(me.grid);
		me.moduleData.tab.add(modulePanel);

		if (typeof initCallBack === 'function') {
			initCallBack();
		}
		me._fireSearch();
	}

	/**
	 * Reloads grid and resets filter form
	 */
	gridDataChangedHandler() {
		const me = this;
		me.grid.getStore().reload();
	}

	/**
	 * Routine that must be done before module destroy
	 */
	onDestroy() {
		const me = this;
		if (me.filterObject && me.filterObject.searchTimeout) {
			clearTimeout(me.filterObject.searchTimeout);
		}
		edi.events.documentPackages.un('change', me._gridDataChangedHandler);
		edi.events.documentPackages.un('delete', me._gridDataChangedHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
	}
}

Ext.namespace('edi.modules');
edi.modules['document.packages'] = PackagesGridModule;
