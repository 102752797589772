import './main/index';
import './organization';
import './settings';
import './notifications';
import './INVITATIONS/invitations';
import './INVITATIONS/PERSON_INVITATION/invitations.person';
import './INVITATIONS/MASS_INVITATIONS/invitations.create.mass.person';
import './INVITATIONS/MASS_INVITATIONS/invitations.details.mass.person';
import './documentGrids';
import './document.statuses';
import './documentsForSign/documentsForSign';
import './DSF/index';

import './EDI_FNS_UKD/actionRules';
import './EDI_FNS_UKD/methods';
import './EDI_FNS_UKD/document.details.fns_ukd_5_01n';

import './EDI_FNS_UPD/actionRules';
import './EDI_FNS_UPD/methods';
import './EDI_FNS_UPD/document.details.fns_upd_5_01n';

import './user_profile/user.profile';
import './ORG_PROFILE/org.profile';

import './APPROVAL_DOCUMENTS/methods';
import './APPROVAL_DOCUMENTS/actionRules';
import './APPROVAL_DOCUMENTS/approval.documents';
import './APPROVAL_DOCUMENTS/DOCUMENTS/approval.documents.create';
import './APPROVAL_DOCUMENTS/DOCUMENTS/approval.documents.details';

import './APPROVAL_DOCUMENTS/EMPLOYEES/approval.employees.create';
import './APPROVAL_DOCUMENTS/EMPLOYEES/approval.employees.details';

import './ARCHIVE/archive';

import './CONTROL_CENTER/control.center.module.panel';

import './ACCEPTANCE/index';

import './ui_sandbox';
